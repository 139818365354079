import { Controller } from "react-hook-form"
import { CustomTextField, CustomTextFieldProps } from "./CustomTextField"

type ControlledTextFieldProps = Omit<CustomTextFieldProps, "name"> & {
  name: string
}

export function ControlledTextField(props: ControlledTextFieldProps) {
  return (
    <Controller
      name={props.name}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <CustomTextField
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={!!error}
          helperText={error ? error.message : props.helperText}
          {...props}
        />
      )}
    />
  )
}
