import { Alert } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { CustomButton } from "lib/components/CustomButton"
import { CustomLink } from "lib/components/CustomLink"
import { CustomTextField } from "lib/components/FormField"
import { useRedirectAuthorizedUser } from "lib/useRedirectAuthorizedUser"
import { useTheme } from "lib/useTheme"
import * as React from "react"
import { useSendResetPasswordLink_DEPRECATED_Mutation } from "services/oldUserApi"
import { getErrorData, SendResetPasswordLinkRequest } from "services/types"
import AuthPageContainer from "./AuthPageContainer"
import { ReactComponent as OKIcon } from "./icons/ok.svg"

export default function ForgotPassword() {
  useRedirectAuthorizedUser()

  const theme = useTheme()
  const [loginFieldError, setLoginError] = React.useState<string | undefined>()
  const [loginInputValue, setLogin] = React.useState<string>("")
  const [sendResetPasswordLink, query] =
    useSendResetPasswordLink_DEPRECATED_Mutation()
  const { data, isLoading, error, isSuccess } = query
  const errorData = getErrorData<SendResetPasswordLinkRequest>(error)
  const errorMessage =
    errorData && (errorData?.detail || "Please correct errors below")

  if (isSuccess) {
    return <ForgotPasswordEmailSent email={loginInputValue} />
  }

  const validate = (form: FormData): boolean => {
    const login = (form.get("email") || "").toString()
    if (login.length == 0 || login.indexOf("@") == -1) {
      // XXX email validation
      setLoginError("Wrong email specified")
      return false
    } else {
      setLoginError(undefined)
    }
    return true
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    if (!validate(formData)) return
    const data = {
      email: formData.get("email") as string,
    }

    sendResetPasswordLink(data)
  }

  const disabled = isLoading

  return (
    <AuthPageContainer>
      <Box
        width="400px"
        mt={2}
        flexGrow={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        gap={2}
      >
        <Typography variant="h2" color={theme.palette.custom.dark900}>
          Forgot your password?
        </Typography>
        <Typography variant="body14" color={theme.palette.custom.dark700}>
          Email me a password reset link:
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          width="330px"
          pt={1}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          {data && <Alert severity="info">{data.detail}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <CustomTextField
            fullWidth
            id="email"
            label="Your e-mail"
            name="email"
            disabled={disabled}
            error={!!errorData?.email || loginFieldError !== undefined}
            helperText={errorData?.email || loginFieldError}
            autoComplete="email"
            onChange={(e) => setLogin(e.target.value)}
            value={loginInputValue}
          />
          <CustomButton
            type="submit"
            fullWidth
            disabled={disabled}
            variant="contained"
          >
            Request
          </CustomButton>
        </Box>
        <Box sx={{ pt: 2 }}></Box>
        <CustomLink href="/signin/">Back to Sign in</CustomLink>
      </Box>
    </AuthPageContainer>
  )
}

export function ForgotPasswordEmailSent({ email }: { email: string }) {
  const theme = useTheme()
  return (
    <AuthPageContainer>
      <Box
        flexGrow={1}
        mt={2}
        width="400px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        gap={2}
      >
        <OKIcon />
        <Typography variant="h2" color={theme.palette.custom.dark900}>
          Email sent!
        </Typography>
        <Typography variant="body14" color={theme.palette.custom.dark700}>
          An email has been sent to{" "}
          <Typography
            variant="body14"
            color={theme.palette.custom.accentsecondary}
          >
            {email}
          </Typography>
          <br />
          Follow the instructions in the email to reset your password.
        </Typography>
        <Box></Box>
        <CustomButton href="/signin/" variant="outlined">
          Sign in
        </CustomButton>
      </Box>
    </AuthPageContainer>
  )
}
