import { yupResolver } from "@hookform/resolvers/yup"
import { Box, FormControlLabel, Typography } from "@mui/material"
import { SellerVenueSpace, SellerVenueThumbnail } from "api/seller"
import {
  useSellerVenueDraftSpaceCreateMutation,
  useSellerVenueDraftSpacePartialUpdateMutation,
  useSellerVenueDraftSpaceThumbnailUpdateMutation,
  useSellerVenueSpaceCreateMutation,
  useSellerVenueSpacePartialUpdateMutation,
  useSellerVenueSpaceThumbnailUpdateMutation,
} from "api/sellerApi"
import { fileUploadValue } from "api/utils"
import { LoadingIndicator } from "app/LoadingIndicator"
import { useVenueSpaceQuery } from "features/venues/VenueSpaceDeleteDialog"
import { CustomButton } from "lib/components/CustomButton"
import { CustomCheckbox } from "lib/components/CustomCheckbox"
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "lib/components/Dialog"
import { ControlledTextField } from "lib/components/form/ControlledTextField"
import { UploadImage } from "lib/components/image/UploadImage"
import {
  MSG_FIELD_REQUIRED,
  MSG_MAX_NUMBER as MSG_VALID_MAX_NUMBER,
  MSG_VALID_INTEGER_REQUIRED,
  MSG_VALID_POSITIVE,
} from "lib/messages"
import { useBoolean } from "lib/useBoolean"
import { useServerFormValidation } from "lib/useServerFormValidation"
import { useSnackbar } from "lib/useSnackbar"
import { useEffect, useMemo, useState } from "react"
import {
  Controller,
  DefaultValues,
  FormProvider,
  useController,
  useForm,
} from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import * as yup from "yup"

type VenueSpaceEditable = Pick<
  SellerVenueSpace,
  | "name"
  | "size"
  | "ceiling_height"
  | "dimension_l"
  | "dimension_w"
  | "natural_lighting"
  | "capacity_theater"
  | "capacity_cabaret"
  | "capacity_classroom"
  | "capacity_ushape"
  | "capacity_boardroom"
  | "capacity_banquet"
  | "capacity_cocktail"
  | "matterport_url"
  | "thumbnail_link"
>

type VenueSpaceFormValues = {
  [key in keyof VenueSpaceEditable]: VenueSpaceEditable[key] | ""
}

const capacityValidation = (label: string) =>
  yup.lazy((value) =>
    value === ""
      ? yup.string()
      : yup
          .number()
          .typeError(MSG_VALID_INTEGER_REQUIRED)
          .nullable()
          .integer(MSG_VALID_INTEGER_REQUIRED)
          .positive(MSG_VALID_POSITIVE)
          .max(500000, MSG_VALID_MAX_NUMBER(500000))
          .label(label)
  )

const schema = yup
  .object({
    name: yup.string().required(MSG_FIELD_REQUIRED),
    capacity_theater: capacityValidation("Theater"),
    capacity_cabaret: capacityValidation("Cabaret"),
    capacity_classroom: capacityValidation("Classroom"),
    capacity_ushape: capacityValidation("Ushape"),
    capacity_boardroom: capacityValidation("Boardroom"),
    capacity_banquet: capacityValidation("Banquet"),
    capacity_cocktail: capacityValidation("Coctail"),
  })
  .required()

export function useOpenVenueSpaceDialog() {
  const navigate = useNavigate()
  return (venueId?: number, spaceId?: number) =>
    navigate(`/seller/venues/${venueId || "new"}/spaces/${spaceId || "new"}`)
}

export function VenueSpaceDialog() {
  const { id: venueId, spaceId } = useParams()
  const { space, isFetching: isVenueFetching } = useVenueSpaceQuery(
    venueId,
    spaceId
  )

  const navigate = useNavigate()
  const close = () => navigate(`/seller/venues/${venueId || "new"}/spaces`)

  const defaultValues = useMemo<DefaultValues<VenueSpaceFormValues>>(
    () =>
      space
        ? space
        : {
            name: "",
            size: "",
            ceiling_height: "",
            dimension_l: "",
            dimension_w: "",
            natural_lighting: false,
            capacity_theater: "",
            capacity_cabaret: "",
            capacity_classroom: "",
            capacity_ushape: "",
            capacity_boardroom: "",
            capacity_banquet: "",
            capacity_cocktail: "",
            matterport_url: "",
            thumbnail_link: "",
          },
    [space]
  )

  const formMethods = useForm<VenueSpaceFormValues>({
    defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    formMethods.reset(defaultValues)
  }, [defaultValues, formMethods])

  const {
    field: { value: thumbnail_link },
  } = useController({ name: "thumbnail_link", control: formMethods.control })

  const [thumbnail, setThumbnail] = useState<File | null | undefined>(undefined)
  const onChangeThumbnail = (file: File) => {
    setThumbnail(file)
    const fileReader = new FileReader()
    fileReader.onload = () => {
      formMethods.setValue("thumbnail_link", fileReader.result as string)
    }
    fileReader.readAsDataURL(file)
  }
  const removeThumbnail = () => {
    setThumbnail(null)
    formMethods.setValue("thumbnail_link", "")
  }

  const [
    sellerVenueSpacePartialUpdate,
    { error: sellerVenueSpacePartialUpdateError },
  ] = useSellerVenueSpacePartialUpdateMutation()

  const [sellerVenueSpaceCreate, { error: sellerVenueSpaceCreateError }] =
    useSellerVenueSpaceCreateMutation()

  const [
    sellerVenueDraftSpacePartialUpdate,
    { error: sellerVenueDraftSpacePartialUpdateError },
  ] = useSellerVenueDraftSpacePartialUpdateMutation()

  const [
    sellerVenueDraftSpaceCreate,
    { error: sellerVenueDraftSpaceCreateError },
  ] = useSellerVenueDraftSpaceCreateMutation()

  const [sellerVenueSpaceThumbnailUpdate] =
    useSellerVenueSpaceThumbnailUpdateMutation()

  const [sellerVenueDraftSpaceThumbnailUpdate] =
    useSellerVenueDraftSpaceThumbnailUpdateMutation()

  const error =
    sellerVenueSpacePartialUpdateError ||
    sellerVenueSpaceCreateError ||
    sellerVenueDraftSpacePartialUpdateError ||
    sellerVenueDraftSpaceCreateError

  // useLoader(isLoading)

  useServerFormValidation(error, formMethods.setError)

  const { showSuccess, showError } = useSnackbar()

  const successMessage = space
    ? "Scene has been updated"
    : "Scene has been created"
  const imageUploadErrorMessage = successMessage + ", but image upload failed"

  // Tracking isUpdating for the whole requests sequence instead of
  // combining indivifual isLoading flags to avoid flickering between requests.
  const [isUpdating, startUpdating, stopUpdating] = useBoolean(false)
  const onSubmit = (data: VenueSpaceFormValues) => {
    startUpdating()
    ;(venueId
      ? space
        ? sellerVenueSpacePartialUpdate({
            venuePk: venueId.toString(),
            id: space?.id.toString(),
            patchedSellerVenueSpace: spaceFormValuesToUpdateRequestData(data),
          })
            .unwrap()
            .then(() => {
              if (typeof thumbnail === "undefined") return
              return sellerVenueSpaceThumbnailUpdate({
                venuePk: venueId.toString(),
                id: space.id.toString(),
                sellerVenueSpaceThumbnail:
                  fileUploadValue<SellerVenueThumbnail>("thumbnail", thumbnail),
              })
                .unwrap()
                .catch((error) => {
                  showError(imageUploadErrorMessage)
                  throw error
                })
            })
        : sellerVenueSpaceCreate({
            venuePk: venueId.toString(),
            sellerVenueSpace: spaceFormValuesToCreateRequestData(data),
          })
            .unwrap()
            .then((createdSpace) => {
              if (typeof thumbnail === "undefined") return
              return sellerVenueSpaceThumbnailUpdate({
                venuePk: venueId.toString(),
                id: createdSpace.id.toString(),
                sellerVenueSpaceThumbnail:
                  fileUploadValue<SellerVenueThumbnail>("thumbnail", thumbnail),
              })
                .unwrap()
                .catch((error) => {
                  showError(imageUploadErrorMessage)
                  throw error
                })
            })
      : space
      ? sellerVenueDraftSpacePartialUpdate({
          id: space?.id.toString(),
          patchedSellerVenueSpace: spaceFormValuesToUpdateRequestData(data),
        })
          .unwrap()
          .then(() => {
            if (typeof thumbnail === "undefined") return
            return sellerVenueDraftSpaceThumbnailUpdate({
              id: space.id.toString(),
              sellerVenueSpaceThumbnail: fileUploadValue<SellerVenueThumbnail>(
                "thumbnail",
                thumbnail
              ),
            })
              .unwrap()
              .catch((error) => {
                showError(imageUploadErrorMessage)
                throw error
              })
          })
      : sellerVenueDraftSpaceCreate({
          sellerVenueSpace: spaceFormValuesToCreateRequestData(data),
        })
          .unwrap()
          .then((createdSpace) => {
            if (typeof thumbnail === "undefined") return
            return sellerVenueDraftSpaceThumbnailUpdate({
              id: createdSpace.id.toString(),
              sellerVenueSpaceThumbnail: fileUploadValue<SellerVenueThumbnail>(
                "thumbnail",
                thumbnail
              ),
            })
              .unwrap()
              .catch((error) => {
                showError(imageUploadErrorMessage)
                throw error
              })
          })
    )
      .then(() => {
        showSuccess(successMessage)
        close()
      })
      .finally(stopUpdating)
  }

  const disabled = isUpdating

  return (
    <CustomDialog open onClose={close}>
      <CustomDialogTitle>
        {space ? "Edit scene" : "Add scene"}
      </CustomDialogTitle>
      <CustomDialogContent sx={{ width: "750px" }}>
        {isVenueFetching && !isUpdating ? (
          <LoadingIndicator />
        ) : (
          <FormProvider {...formMethods}>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box display="flex" gap="24px">
                <Box flexGrow={0}>
                  <UploadImage
                    width={242}
                    height={136}
                    name="thumbnail_link"
                    uploadImage={onChangeThumbnail}
                    removeImage={removeThumbnail}
                    description="960 x 544 px, jpeg or png"
                    disabled={disabled}
                    imageUrl={thumbnail_link}
                    isLoading={false}
                    isError={false}
                    cleanError={() => undefined}
                    //   isLoading={isThumbnailLoading || isDraftThumbnailLoading}
                    //   isError={thumbnailError}
                    //   cleanError={() => setThumbnailError(false)}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  flexGrow={1}
                  gap="24px"
                >
                  <Box display="flex" flexGrow={1} gap="24px">
                    <ControlledTextField
                      name="name"
                      label="Scene name"
                      disabled={disabled}
                      fullWidth
                    />
                  </Box>
                  <Box display="flex" flexGrow={1} gap="24px">
                    <ControlledTextField
                      name="size"
                      label="Size, sq/m"
                      disabled={disabled}
                    />
                    <ControlledTextField
                      name="ceiling_height"
                      label="Ceiling, m"
                      disabled={disabled}
                    />
                    <ControlledTextField
                      name="dimension_l"
                      label="Length, m"
                      disabled={disabled}
                    />
                    <ControlledTextField
                      name="dimension_w"
                      label="Width, m"
                      disabled={disabled}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                gap="24px"
              >
                <Box display="flex" flexGrow={1} gap="24px">
                  <Box display="flex" alignItems="center" width="25%">
                    <FormControlLabel
                      sx={{ ml: 0 }}
                      labelPlacement="start"
                      label={
                        <Typography variant="body13">
                          Natural Lighting
                        </Typography>
                      }
                      control={
                        <Controller
                          name="natural_lighting"
                          render={({ field: { value, onChange, onBlur } }) => (
                            <CustomCheckbox
                              checked={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={disabled}
                            />
                          )}
                        />
                      }
                    />
                  </Box>

                  <ControlledTextField
                    name="capacity_theater"
                    label="Theater"
                    disabled={disabled}
                    sx={{ width: "25%" }}
                  />
                  <ControlledTextField
                    name="capacity_cabaret"
                    label="Cabaret"
                    disabled={disabled}
                    sx={{ width: "25%" }}
                  />
                  <ControlledTextField
                    name="capacity_classroom"
                    label="Classroom"
                    disabled={disabled}
                    sx={{ width: "25%" }}
                  />
                </Box>
                <Box display="flex" flexGrow={1} gap="24px">
                  <ControlledTextField
                    name="capacity_ushape"
                    label="U-Shape"
                    disabled={disabled}
                    sx={{ width: "25%" }}
                  />
                  <ControlledTextField
                    name="capacity_boardroom"
                    label="Boardroom"
                    disabled={disabled}
                    sx={{ width: "25%" }}
                  />
                  <ControlledTextField
                    name="capacity_banquet"
                    label="Banquet"
                    disabled={disabled}
                    sx={{ width: "25%" }}
                  />
                  <ControlledTextField
                    name="capacity_cocktail"
                    label="Cocktail"
                    disabled={disabled}
                    sx={{ width: "25%" }}
                  />
                </Box>
              </Box>

              <ControlledTextField
                name="matterport_url"
                label="Matterport URL for 360° view"
                disabled={disabled}
              />

              {/* <Box>
            <Typography variant="body14">VR scene</Typography>
            <Box>Oculus Quest</Box>
            <Box>iOS</Box>
            <Box>Android</Box>
          </Box> */}
            </Box>
          </FormProvider>
        )}
      </CustomDialogContent>
      <CustomDialogActions>
        <Box display="flex" justifyContent="space-between" flexGrow={1}>
          <CustomButton variant="outlined" onClick={close} disabled={disabled}>
            Cancel
          </CustomButton>
          <CustomButton
            variant="contained"
            onClick={formMethods.handleSubmit(onSubmit)}
            disabled={disabled}
          >
            {space ? "Update scene" : "Create scene"}
          </CustomButton>
        </Box>
      </CustomDialogActions>
    </CustomDialog>
  )
}

const emptyStringToNull = <T,>(value: T | ""): T | null =>
  value === "" ? null : value

function spaceFormValuesToUpdateRequestData(
  data: VenueSpaceFormValues
): VenueSpaceEditable {
  return {
    name: data.name,
    size: emptyStringToNull(data.size),
    ceiling_height: emptyStringToNull(data.ceiling_height),
    dimension_l: emptyStringToNull(data.dimension_l),
    dimension_w: emptyStringToNull(data.dimension_w),
    natural_lighting: !!data.natural_lighting,
    capacity_theater: emptyStringToNull(data.capacity_theater),
    capacity_cabaret: emptyStringToNull(data.capacity_cabaret),
    capacity_classroom: emptyStringToNull(data.capacity_classroom),
    capacity_ushape: emptyStringToNull(data.capacity_ushape),
    capacity_boardroom: emptyStringToNull(data.capacity_boardroom),
    capacity_banquet: emptyStringToNull(data.capacity_banquet),
    capacity_cocktail: emptyStringToNull(data.capacity_cocktail),
    matterport_url: data.matterport_url,
    thumbnail_link: "",
  }
}

function spaceFormValuesToCreateRequestData(
  data: VenueSpaceFormValues
): SellerVenueSpace {
  return {
    ...spaceFormValuesToUpdateRequestData(data),
    id: 0,
    last_modified: "",
    matterport_modified: "",
    quest_scene_link: null,
    ios_scene_link: null,
    android_scene_link: "",
    quest_scene_last_modified: "",
    ios_scene_last_modified: "",
    android_scene_last_modified: "",
  }
}
