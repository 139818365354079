import { Box, FormControlLabel, Typography } from "@mui/material"
import { ReactComponent as CloudUploadIcon } from "app/icons/cloud-upload.svg"
import { ChangeEvent, DragEvent, useState } from "react"
import { ImageSizeProps } from "./ImageProcessingCard"

export type FileDragDropCardProps = ImageSizeProps & {
  description: string
  name: string
  handleFiles: (files: File[]) => void
  multifile?: boolean
}

const acceptedFiles = ["image/jpeg", "image/jpg", "image/png"]

export function FileDragDropCard(props: FileDragDropCardProps) {
  const [dragActive, setDragActive] = useState(false)

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)

    if (e.dataTransfer.files) {
      props.handleFiles(Array.from(e.dataTransfer.files))
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (e.target.files) {
      props.handleFiles(Array.from(e.target.files))
    }
  }

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }

  return (
    <FormControlLabel
      sx={{ display: "flex", m: 0 }}
      label={
        <Box
          sx={{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            width: props.width,
            height: props.height,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: dragActive ? "custom.light800" : "white",
            border: "2px dashed #E4E4EF",
            borderRadius: 3,
          }}
          onDragEnter={handleDrag}
        >
          <CloudUploadIcon />
          <Box sx={{ display: "flex", mt: "12px" }}>
            <Typography
              variant="body12"
              sx={{
                color: "custom.accentprimary",
                fontWeight: 600,
              }}
            >
              Upload&nbsp;
            </Typography>
            <Typography
              variant="body12"
              sx={{
                color: "custom.dark900",
                fontWeight: 600,
              }}
            >
              or drop here
            </Typography>
          </Box>
          <Typography variant="body11" sx={{ color: "custom.dark600" }}>
            {props.description}
          </Typography>
          {dragActive && (
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></Box>
          )}
        </Box>
      }
      control={
        <input
          type="file"
          accept={acceptedFiles.join(", ")}
          name={props.name}
          hidden
          multiple={!!props.multifile}
          onChange={handleChange}
          value={""}
        ></input>
      }
    />
  )
}
