import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

/**
 * Utility hook that imediatelly redirects to some path
 * Useful to temporary disable some components
 */
export function useRedirectTo(path: string) {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(path)
  }, [navigate, path])
}
