import { SerializedError } from "@reduxjs/toolkit"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { useEffect } from "react"
import { Path, UseFormSetError } from "react-hook-form"

type FormValuesType = Record<string, unknown>

type FormValidationError<FormValues extends FormValuesType> = Record<
  keyof FormValues | "non_field_errors",
  string
>

export const getFormValidationErrorFromRTK = <
  FormValues extends FormValuesType
>(
  error: FetchBaseQueryError | SerializedError | undefined
): FormValidationError<FormValues> | undefined =>
  error && "status" in error && error.status == 400 && "data" in error
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (error?.data as Record<keyof FormValues, string>)
    : undefined

export function useServerFormValidation<
  FormValues extends Record<string, unknown>
>(
  error: FetchBaseQueryError | SerializedError | undefined,
  setError: UseFormSetError<FormValues>,
  parsed?: boolean,
  transformMessage?: (field: string, message: string) => string
): void {
  const validationError = parsed ? error : getFormValidationErrorFromRTK(error)

  useEffect(() => {
    if (validationError) {
      Object.entries(validationError).forEach(([field, [message]]) =>
        setError(field as Path<FormValues>, {
          message: transformMessage
            ? transformMessage(field, message)
            : message,
        })
      )
    }
  }, [setError, validationError, transformMessage])
}
