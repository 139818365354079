import { CustomButton } from "./CustomButton"
import { ReactComponent as MoreHoriz } from "./icons/more-horiz.svg"

export function ContextMenuButton({
  handleClick,
}: {
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}) {
  return (
    <CustomButton
      variant="outlined"
      sx={{
        "&.MuiButton-root": {
          width: "48px",
          minWidth: "48px",
          maxWidth: "48px",
          height: "48px",
        },
        "&.MuiButton-root.MuiButton-outlined": {
          padding: 0,
          color: "custom.dark800",
          border: "none",
          borderRadius: "8px",
          boxShadow: "0px 2px 3px rgba(17, 17, 128, 0.04)",
        },
        "&.MuiButton-root.MuiButton-outlined:hover": {
          background: "none",
          border: "1px solid #E7E7EC",
          boxShadow: "none",
        },
      }}
      onClick={handleClick}
    >
      <MoreHoriz />
    </CustomButton>
  )
}
