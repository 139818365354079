import { Box, Typography } from "@mui/material"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import { Venue } from "api/venues"

const isBig = (index: number) => index % 10 == 0 || index % 10 == 7

export function VenuePhotos({ venue }: { venue: Venue }) {
  // const photos = venue.photos.slice(0, 5)
  const photos = venue.photos
  return (
    <>
      <Typography variant="h2" color="custom.dark900">
        Photos &amp; Videos
      </Typography>
      {photos && (
        <ImageList
          sx={{ height: "100%" }}
          rowHeight={220}
          cols={4}
          variant="quilted"
        >
          {photos.map((photo, index) => (
            <ImageListItem
              key={photo.image}
              cols={isBig(index) ? 2 : 1}
              rows={isBig(index) ? 2 : 1}
              sx={{
                flexGrow: 1,
                borderRadius: "8px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <img
                src={`${photo.thumbnail_link}`}
                alt={photo.image}
                loading="lazy"
                object-fit="cover"
              />
              <Box
                sx={{
                  display: "flex",
                  height: isBig(index) ? 132 : 107,
                  width: "100%",
                  color: "red",
                  position: "absolute",
                  bottom: 0,
                  background: "linear-gradient(to top, #00000070, #00000000)",
                }}
              ></Box>
              <Typography
                variant={isBig(index) ? "h3" : "body13"}
                sx={{
                  position: "absolute",
                  bottom: isBig(index) ? 13 : 16,
                  left: 16,
                  color: "white",
                  textShadow: "0px 1px 1px rgba(64, 64, 134, 0.44)",
                }}
              >
                {photo.title}
              </Typography>
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </>
  )
}
