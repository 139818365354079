export const fileUploadValue = <ArgType>(
  property: string,
  file: File | null
) => {
  if (!file) return { [property]: null }

  const formData = new FormData()
  formData.append(property, file || undefined)
  return formData as any as ArgType
}
