import { Calendar as SellerCalendar } from "features/seller/Calendar"
import { VenueView } from "features/venues/Venue"
import { Route, Routes } from "react-router-dom"
import { Dashboard } from "./Dashboard"
import { Settings } from "./Settings"
import { VenueEdit } from "./VenueEdit"
import { VenuesList } from "./VenuesList"

export function SellerRoutes() {
  return (
    <Routes>
      <Route index element={<Dashboard />} />

      <Route path="venues">
        <Route index element={<VenuesList />} />
        <Route path=":deleteId/delete" element={<VenuesList />} />
        <Route path="new/*" element={<VenueEdit />} />
        <Route path=":id/*">
          <Route path="preview" element={<VenueView sellerPreview={true} />} />
          <Route path="*" element={<VenueEdit />} />
        </Route>
      </Route>
      <Route path="calendar" element={<SellerCalendar />} />
      <Route path="settings" element={<Settings />} />
    </Routes>
  )
}
