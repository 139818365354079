import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import FastSignUp from "features/auth/FastSignUp"
import { Logout } from "features/auth/Logout"
import BuyerProfile from "features/auth/signup/BuyerProfile"
import { SelectUserType } from "features/auth/signup/SelectUserType"
import { StrictMode } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import App from "./app/App"
import { store } from "./app/store"
import ForgotPassword from "./features/auth/ForgotPassword"
import ResetPassword, {
  ResetPasswordSuccess,
} from "./features/auth/ResetPassword"
import SignIn from "./features/auth/SignIn"
import Verify from "./features/auth/Verify"
import "./index.css"
import * as serviceWorker from "./serviceWorker"

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: SENTRY_RELEASE,
  })
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/reset-password/reset"
            element={<ResetPasswordSuccess />}
          />

          <Route path="/signin/*" element={<SignIn />} />
          <Route path="/signup/*" element={<FastSignUp />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/profile/buyer/" element={<BuyerProfile />} />
          <Route path="/verify-user/" element={<Verify />} />
          <Route path="/forgot-password/" element={<ForgotPassword />} />
          <Route path="/reset-password/" element={<ResetPassword />} />
          <Route path="/user-type" element={<SelectUserType />} />
          <Route path="/*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
