import { Box, Typography } from "@mui/material"
import { ReactComponent as EmptyVenuesIcon } from "app/icons/venue-big.svg"
import { PlusButton } from "lib/components/CustomButton"

export function EmptyVenues() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "custom.light600",
        borderRadius: 3,
        padding: 4,
        margin: 3,
        gap: 2,
      }}
    >
      <EmptyVenuesIcon />
      <Typography variant="h2" color="custom.dark900">
        No venues added yet
      </Typography>
      <Typography variant="body14" color="custom.dark700">
        Add your first venue now
      </Typography>
      <Box />
      <PlusButton variant="contained" href="/seller/venues/new">
        Add venue
      </PlusButton>
    </Box>
  )
}
