import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import { useAuthorizedUserOrRedirect } from "lib/useAuthorizedUserOrRedirect"
import { useSnackbar } from "lib/useSnackbar"
import { useTheme } from "lib/useTheme"
import { ReactNode } from "react"
import { useSelector } from "react-redux"
import "./App.css"
import CustomSnackbar from "./CustomSnackbar"
import { LeftNavigation } from "./LeftNavigation"
import { LoadingIndicatorFullWindow } from "./LoadingIndicator"
import { RootState } from "./store"

export function UserPageContainer({ children }: { children: ReactNode }) {
  const { user } = useAuthorizedUserOrRedirect()
  const theme = useTheme()
  const { snackbarState, close: closeSnackbar } = useSnackbar()
  const spinnerVisibility = useSelector(
    (state: RootState) => state.appState.loaderVisibility
  )

  if (!user) return null

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        className="full-height"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ flexGrow: 1, display: "flex", overflow: "hidden" }}>
          <LeftNavigation />

          <Box component="main" sx={{ flexGrow: 1, p: 0, display: "flex" }}>
            {children}
          </Box>
        </Box>
      </Box>

      <CustomSnackbar {...snackbarState} close={closeSnackbar} />

      {spinnerVisibility && <LoadingIndicatorFullWindow />}
    </ThemeProvider>
  )
}
