import { AccountBox } from "@mui/icons-material"
import { Button, IconButton, MenuItem, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { BoxProps } from "@mui/system"
import { BackButton } from "lib/components/CustomButton"
import { CustomMenu, CustomMenuItem, MenuItemSx } from "lib/components/Menu"
import { TabButtonGroup, TabButtonItem } from "lib/components/TabMenu"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useProfile_DEPRECATED_Query } from "services/oldUserApi"

import { ReactComponent as NotificationIcon } from "./icons/Notification.svg"
import { ReactComponent as WalletIcon } from "./icons/Wallet.svg"

const NotificationButton = ({ bg }: { bg: string | undefined }) => {
  return (
    <IconButton
      size="small"
      sx={{
        "&.MuiIconButton-root": {
          backgroundColor: bg,
          borderRadius: "12px",
          color: "inherit",
          boxShadow:
            "0px 7px 33px rgba(189, 190, 210, 0.15), 0px 6.63236px 9.94853px rgba(194, 195, 214, 0.1003), 0px 2.75474px 4.13211px rgba(166, 167, 200, 0.1), 0px 0.996336px 1.4945px rgba(121, 122, 154, 0.0696822)",
        },
      }}
    >
      <NotificationIcon />
    </IconButton>
  )
}

const WalletButton = ({ bg }: { bg: string | undefined }) => {
  return (
    <>
      <Button
        size="small"
        variant="text"
        startIcon={<WalletIcon />}
        sx={{
          display: { xs: "none", md: "flex" },
          "&.MuiButton-root": {
            backgroundColor: bg,
            borderRadius: "12px",
            textTransform: "none",
            color: "inherit",
            boxShadow:
              "0px 7px 33px rgba(189, 190, 210, 0.15), 0px 6.63236px 9.94853px rgba(194, 195, 214, 0.1003), 0px 2.75474px 4.13211px rgba(166, 167, 200, 0.1), 0px 0.996336px 1.4945px rgba(121, 122, 154, 0.0696822)",
          },
        }}
      >
        Connect wallet
      </Button>
      <IconButton
        size="small"
        color="primary"
        sx={{
          display: { xs: "flex", md: "none" },
          "&.MuiIconButton-root": {
            backgroundColor: bg,
            borderRadius: "12px",
            color: "inherit",
            boxShadow:
              "0px 7px 33px rgba(189, 190, 210, 0.15), 0px 6.63236px 9.94853px rgba(194, 195, 214, 0.1003), 0px 2.75474px 4.13211px rgba(166, 167, 200, 0.1), 0px 0.996336px 1.4945px rgba(121, 122, 154, 0.0696822)",
          },
        }}
      >
        <WalletIcon />
      </IconButton>
    </>
  )
}

const AvatarButton = ({
  onClick,
}: {
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}) => {
  return (
    <IconButton
      size="small"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={onClick}
      color="inherit"
      sx={{
        "&.MuiIconButton-root": {
          p: 0,
        },
      }}
    >
      <AccountBox
        sx={{
          width: 40,
          height: 40,
        }}
      />
    </IconButton>
  )
}

export function TopBarTitle({ label }: { label: string }) {
  return (
    <Typography
      variant="h2"
      color="custom.dark900"
      sx={{
        pr: 1,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {label}
    </Typography>
  )
}

export function TopBarRight() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { data: user } = useProfile_DEPRECATED_Query()

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const navigate = useNavigate()

  if (!user) return null

  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        position: "absolute",
        right: "24px",
        top: "20px",
        display: "flex",
      }}
    >
      <Box
        sx={{
          pr: 2,
          backgroundColor: "inherit",
          display: "flex",
          color: "custom.accentsecondary",
        }}
      >
        {/* <NotificationButton bg="custom.light500" /> */}
      </Box>

      {/* <Box
        sx={{
          pr: 2,
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            color: "custom.accentsecondary",
          }}
        >
          <WalletButton bg="custom.light500" />
        </Box>
      </Box> */}

      <Box sx={{ p: 0, color: "custom.accentsecondary" }}>
        <AvatarButton onClick={handleMenu} />
      </Box>
      <CustomMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <CustomMenuItem onClick={handleClose} disabled>
          {user.username}
        </CustomMenuItem>
        <MenuItem
          component={Link}
          to="/profile/"
          onClick={handleClose}
          sx={[
            MenuItemSx,
            {
              color: "custom.accentsecondary",
            },
          ]}
        >
          Profile
        </MenuItem>
        <CustomMenuItem
          onClick={() => navigate("/logout")}
          sx={{
            color: "custom.accentsecondary",
          }}
        >
          Logout
        </CustomMenuItem>
      </CustomMenu>
    </Box>
  )
}

export type TopBarBaseProps = BoxProps

export function TopBarBase({ children, height, sx }: TopBarBaseProps) {
  const { data: user } = useProfile_DEPRECATED_Query()

  if (!user) return null

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        minHeight: height || { xs: 123, sm: 152, md: 156 },
        maxHeight: height || { xs: 123, sm: 152, md: 156 },
        background: "linear-gradient(267.07deg, #FBFBFE 19.14%, #FBFBFE 68.5%)",
        ...sx,
      }}
    >
      {children && children}

      <TopBarRight />
    </Box>
  )
}

export type TopBarProps = {
  title: string
  back?: string
  tabs?: TabButtonItem[]
}

export function TopBar({ title, back, tabs }: TopBarProps) {
  return (
    <TopBarBase height={80} sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",

          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flex: "1 1 0",
            overflow: "hidden",
            gap: 3,
            px: { md: 3, xs: 2 },
            py: { md: 2.5, xs: 2 },
          }}
        >
          {back && <BackButton href={back} />}
          <TopBarTitle label={title} />
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          height={40}
          sx={{ flexGrow: 0, flexShrink: 0 }}
        >
          {tabs && <TabButtonGroup items={tabs} />}
        </Box>

        <Box sx={{ flex: "1 1 0" }}></Box>
      </Box>
    </TopBarBase>
  )
}
