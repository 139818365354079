import { MoreTime } from "@mui/icons-material"
import { Box, Container, Typography, useTheme } from "@mui/material"

export default function Calendar() {
  const theme = useTheme()

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          width: "500px",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ pt: "140px" }}></Box>
        <MoreTime
          htmlColor={theme.palette.custom.accentsecondary}
          sx={{ width: "86px", height: "86px" }}
        />
        <Box sx={{ pt: 1 }}></Box>
        <Typography
          component="h2"
          variant="h2"
          color={theme.palette.custom.dark900}
        >
          Scheduling system coming soon...
        </Typography>
      </Box>
    </Container>
  )
}
