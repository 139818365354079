import { Checkbox } from "@mui/material"
import { ReactComponent as CheckedIcon } from "lib/components/icons/checkbox-checked.svg"
import { ReactComponent as UncheckedIcon } from "lib/components/icons/checkbox-unchecked.svg"
import { ComponentProps } from "react"

export function CustomCheckbox(props: ComponentProps<typeof Checkbox>) {
  return (
    <Checkbox
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon />}
      {...props}
    />
  )
}
