import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { CustomSnackbarProps } from "app/CustomSnackbar"

export interface SellerVenueDirtyState {
  isBasicInfoDirty?: boolean
  isSpacesDirty?: boolean
  isManagersDirty?: boolean
}

export interface AppState {
  snackbar: CustomSnackbarProps
  loaderVisibility: boolean
  sellerVenueName?: string
}

const initialState: AppState = {
  snackbar: { open: false },
  loaderVisibility: false,
}

export const appState = createSlice({
  name: "appState",
  initialState,
  reducers: {
    snackbar: (state: AppState, action: PayloadAction<CustomSnackbarProps>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.snackbar = action.payload
    },
    sellerVenueName: (
      state: AppState,
      action: PayloadAction<string | undefined>
    ) => {
      state.sellerVenueName = action.payload
    },
    loaderVisibility: (state: AppState, action: PayloadAction<boolean>) => {
      state.loaderVisibility = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { snackbar, sellerVenueName, loaderVisibility } = appState.actions

export default appState.reducer
