import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Checkbox, Divider, Typography } from "@mui/material"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import { Meeting, SellerVenue } from "api/seller"
import {
  useSellerMeetingCreateMutation,
  useSellerMeetingRetrieveQuery,
  useSellerMeetingUpdateMutation,
  useSellerMeetingVenueVenuesUpdateMutation,
  useSellerVenueListQuery,
} from "api/sellerApi"
import { LoadingIndicator } from "app/LoadingIndicator"
import { TopBar } from "app/TopBar"
import dayjs from "dayjs"
import { CustomButton } from "lib/components/CustomButton"
// import { ControlledSelectField } from "lib/components/form/ControlledSelectField"
import { ControlledTextField } from "lib/components/form/ControlledTextField"
import { ReactComponent as CheckedIcon } from "lib/components/icons/checked.svg"
import { ReactComponent as UncheckedIcon } from "lib/components/icons/unchecked.svg"
import { MSG_FIELD_REQUIRED } from "lib/messages"
import { VENUE_THUMBNAIL_PLACEHOLDER } from "lib/public"
import {
  getFormValidationErrorFromRTK,
  useServerFormValidation,
} from "lib/useServerFormValidation"
import { useSnackbar } from "lib/useSnackbar"
import { useEffect, useMemo, useState } from "react"
import { FormProvider, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import * as yup from "yup"

const COLUMNS_COUNT = 3

const schema = yup
  .object({
    meeting_code: yup
      .string()
      .required(MSG_FIELD_REQUIRED)
      .min(4, "Should have at least 4 characters")
      .matches(/^[A-Z0-9]+$/, "Only english letters and numbers allowed"),
  })
  .required()

function generateRandomCode(): string {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  const maxCharIndex = chars.length - 1
  const length = 5

  return Array(length)
    .fill(0)
    .map(() => chars.charAt(Math.floor(Math.random() * maxCharIndex)))
    .join("")
}

type SelectableVenue =
  | (SellerVenue & {
      selected: boolean
      blank: false
    })
  | { blank: true }

export function MeetingEdit() {
  const { id } = useParams()
  const { data: venues, isFetching: isVenuesListLoading } =
    useSellerVenueListQuery({})
  const { data: meeting, isFetching: isLoading } =
    useSellerMeetingRetrieveQuery(id ? { id: +id } : skipToken)

  const [createMeeting, { error: createError }] =
    useSellerMeetingCreateMutation()
  const [updateMeeting, { error: updateError }] =
    useSellerMeetingUpdateMutation()
  const [updateVenues] = useSellerMeetingVenueVenuesUpdateMutation()

  const { showSuccess, showError } = useSnackbar()
  // useLoader(isLoading)

  const [selectedVenues, setSelectedVenues] = useState<number[]>([])

  const randomCode = useMemo(generateRandomCode, [])

  const formMethods = useForm<Meeting>({
    defaultValues: {
      expires_at: dayjs().add(1, "month").format("MMMM D, YYYY"),
      meeting_code: id ? "" : randomCode,
    },
    mode: "onBlur",
    resolver: yupResolver(schema),
  })

  const error = useMemo(
    () => createError || updateError,
    [createError, updateError]
  )
  useServerFormValidation(
    error,
    formMethods.setError,
    false,
    (field, message) => {
      if (
        field === "meeting_code" &&
        message === "private meeting with this meeting code already exists."
      )
        return "Access code already used"
      return message
    }
  )

  const selectableVenuesList = useMemo<SelectableVenue[]>(() => {
    const list: SelectableVenue[] = !venues?.results
      ? []
      : venues.results.map((venue) => ({
          ...venue,
          selected:
            selectedVenues.some((selectedId) => selectedId == venue.id) ??
            false,
          blank: false,
        }))

    while (list.length % COLUMNS_COUNT !== 0) {
      list.push({ blank: true })
    }

    return list
  }, [venues, selectedVenues])

  // This must go before formMethods.reset to peek latest value
  const meetingCode = useWatch({
    control: formMethods.control,
    name: "meeting_code",
  })

  useEffect(() => {
    if (meeting) {
      formMethods.reset({
        expires_at: dayjs(meeting.expires_at).format("MMMM D, YYYY"),
        meeting_code: meeting.meeting_code,
      })
      setSelectedVenues(meeting?.venues.map((venues) => venues.id) ?? [])
    }
  }, [formMethods, meeting])

  const navigate = useNavigate()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit: SubmitHandler<Meeting> = (data) => {
    setIsSubmitting(true)
    if (!id) {
      createMeeting({
        meeting: {
          ...data,
          venues: selectedVenues.map((id) => ({ id } as SellerVenue)),
        },
      })
        .unwrap()
        .then((response) => {
          return updateVenues({
            meetingPk: response.id,
            meetingVenues: {
              venue_ids: selectedVenues,
            },
          }).unwrap()
        })
        .finally(() => setIsSubmitting(false))
        .then(() => {
          navigate("/meetings/")
          showSuccess("Meeting was successfully created")
        })
        .catch((error) => {
          const validationError = getFormValidationErrorFromRTK<Meeting>(error)
          if (!validationError?.meeting_code) {
            showError("Cannot create meeting")
          }
        })
    } else {
      Promise.all([
        updateMeeting({
          id: +id,
          meeting: data,
        }).unwrap(),
        updateVenues({
          meetingPk: +id,
          meetingVenues: {
            venue_ids: selectedVenues,
          },
        }).unwrap(),
      ])
        .finally(() => setIsSubmitting(false))
        .then(() => {
          navigate("/meetings/")
          showSuccess("Meeting was successfully updated")
        })
        .catch((error) => {
          const validationError = getFormValidationErrorFromRTK<Meeting>(error)
          if (!validationError?.meeting_code) {
            showError("Cannot create meeting")
          }
        })
    }
  }

  // Using combined isSubmitting instead of
  // isCreating || isUpdating || isVenuesUpdating
  // for better uninterrupted loading indicator experience

  const isPageLoading = isLoading || isVenuesListLoading || isSubmitting

  if (isPageLoading)
    return (
      <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
        <TopBar
          title={
            meetingCode ? meetingCode : id ? "Update meeting" : "Create meeting"
          }
          back="/meetings/"
        />
        <LoadingIndicator />
      </Box>
    )

  return (
    <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <TopBar
        title={
          meetingCode ? meetingCode : id ? "Update meeting" : "Create meeting"
        }
        back="/meetings/"
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          backgroundColor: "custom.light600",
          borderRadius: 3,
          padding: 4,
          margin: 3,
        }}
      >
        <FormProvider {...formMethods}>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              flexDirection: "column",
            }}
            component="form"
            noValidate
            onSubmit={formMethods.handleSubmit(onSubmit)}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                position: "relative",
                gap: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: "120px 1 0",
                }}
              >
                <Typography variant="h4" color="custom.dark800">
                  Meeting details
                </Typography>
              </Box>
              <Box
                sx={{ flex: "448px 1 1", display: "flex", maxWidth: "448px" }}
              >
                <ControlledTextField
                  fullWidth
                  label="Meeting code"
                  name="meeting_code"
                  inputProps={{ maxLength: 10 }}
                  onChange={(e) =>
                    formMethods.setValue(
                      "meeting_code",
                      e.target.value.toUpperCase()
                    )
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flex: "120px 1 0",
                  alignItems: "start", // For proper positioning when "meetng_code" input error message is displayed
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center", // For proper positioning when "meetng_code" input error message is displayed
                    gap: "1px",
                    height: "54px", // For proper positioning when "meetng_code" input error message is displayed
                  }}
                >
                  <Typography variant="body12" color="custom.dark600">
                    Expiry date
                  </Typography>
                  <Typography variant="body13" color="custom.dark900">
                    {formMethods.getValues("expires_at")}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider sx={{ borderBottom: "1px solid #E7E7EC" }} />
            <Box sx={{ display: "flex", gap: 3, flexDirection: "column" }}>
              <Typography variant="h4" color="custom.dark800">
                Select venues
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  // backgroundColor: "#E7E7EC",
                  // border: "1px solid #E7E7EC",
                  // borderRadius: 2,
                  gridGap: "1px",
                }}
              >
                {selectableVenuesList.map((venue, index) => {
                  return venue.blank ? (
                    <Box
                      key={index}
                      sx={{
                        p: 2,
                        borderTopLeftRadius: index == 0 ? 8 : 0,
                        borderTopRightRadius: index == 2 ? 8 : 0,
                        borderBottomLeftRadius:
                          (index + 1) % COLUMNS_COUNT == 1 &&
                          index >= selectableVenuesList.length - COLUMNS_COUNT
                            ? 8
                            : 0,
                        borderBottomRightRadius:
                          index == selectableVenuesList.length - 1 ? 8 : 0,
                        height: 110,
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "custom.light600",
                      }}
                    ></Box>
                  ) : (
                    <Box
                      key={venue.name}
                      onClick={() => {
                        if (venue.selected) {
                          setSelectedVenues((prev) =>
                            prev.filter((item) => item != venue.id)
                          )
                        } else {
                          setSelectedVenues((prev) => [...prev, venue.id])
                        }
                      }}
                      sx={{
                        p: 2,
                        borderTopLeftRadius: index == 0 ? 8 : 0,
                        borderTopRightRadius: index == 2 ? 8 : 0,
                        borderBottomLeftRadius:
                          (index + 1) % COLUMNS_COUNT == 1 &&
                          index >= selectableVenuesList.length - COLUMNS_COUNT
                            ? 8
                            : 0,
                        borderBottomRightRadius:
                          index == selectableVenuesList.length - 1 ? 8 : 0,
                        height: 110,
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: venue.selected
                          ? "custom.light500"
                          : "custom.light600",
                        cursor: "pointer",
                      }}
                    >
                      {}
                      <Checkbox
                        checked={venue.selected}
                        icon={<UncheckedIcon />}
                        checkedIcon={<CheckedIcon />}
                      />
                      <Box
                        sx={{
                          height: 78,
                          flex: "140px 0 0",
                          ml: 2,
                          mr: "12px",
                          borderRadius: 2,
                          backgroundImage: `url(${
                            venue.thumbnail_link || VENUE_THUMBNAIL_PLACEHOLDER
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      />
                      <Typography
                        variant="body14"
                        color="custom.dark700"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {venue.name}
                      </Typography>
                    </Box>
                  )

                  // const rightColumn = (index + 1) % 3 == 0
                  // const leftColumn = (index + 1) % 3 == 1
                  // const firstLine = index >= 0 && index <= 2
                  // const lastLine =
                  //   venues.results &&
                  //   index >
                  //     venues.results.length -
                  //       ((venues.results.length + 1) % 3 ?? 3)
                })}
              </Box>
            </Box>
            {/* <Divider sx={{ border: "1px solid #E7E7EC" }} /> */}
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                position: "relative",
                gap: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: { xs: "200px 0 0", lg: "369px 0 0" },
                }}
              >
                <Typography variant="h4" color="custom.dark800">
                  Client branding options (optional)
                // TODO:
                // from https://docs.google.com/document/d/1ntXLyuwLTEPOZLNejNE_XuqeNSB0LYc7QbJilSZ_kEY/edit
                // Add context: Tailor your site visit to your client with the options below.
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1, display: "flex" }}>
                <ControlledSelectField
                  label="Start Scene"
                  name="start_scene" // TODO: Add name from Api
                  items={[]}
                  defaultValue=""
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flex: { xs: "200px 0 0", lg: "369px 0 0" },
                  alignItems: "center",
                }}
              />
            </Box> */}
            <Divider sx={{ borderBottom: "1px solid #E7E7EC" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                position: "relative",
                gap: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: "120px 1 0",
                }}
              />
              <Box
                sx={{ flex: "448px 1 1", display: "flex", maxWidth: "448px" }}
              >
                <CustomButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                >
                  {id ? "Update meeting" : "Create meeting"}
                </CustomButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flex: "120px 1 0",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "1px" }}
                />
              </Box>
            </Box>
          </Box>
        </FormProvider>
      </Box>
    </Box>
  )
}
