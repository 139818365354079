import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getCookie } from "lib/cookies"

export const generatedApi = createApi({
  reducerPath: "generatedApi",
  tagTypes: ["User", "LoginChanged"],

  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers) => {
      const csrftoken = getCookie("csrftoken")
      if (csrftoken) headers.set("X-CSRFToken", csrftoken)
      return headers
    },
  }),
  endpoints: () => ({}),
})
