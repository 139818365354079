import { Box, CircularProgress, Typography } from "@mui/material"

export type ImageSizeProps = {
  width: string | number
  height: string | number
}

export type ImageProcessingCardProps = ImageSizeProps & {
  processDescription?: string
}

const acceptedFiles = ["image/jpeg", "image/jpg", "image/png"]

export function ImageProcessingCard(props: ImageProcessingCardProps) {
  return (
    <Box
      sx={{
        height: props.height,
        width: props.width,
        display: "flex",
        borderRadius: 2,
        position: "relative",
        backgroundColor: "custom.light800",
      }}
    >
      <CircularProgress
        size={16}
        //style={{ width: 16, height: 16 }}
        sx={{
          position: "absolute",
          top: 10,
          left: 12,
          color: "custom.accentsecondary",
        }}
      />
      {props.processDescription && (
        <Typography
          variant="body11"
          sx={{
            position: "absolute",
            bottom: 12,
            left: 12,
            fontWeight: 700,
            color: "custom.dark600",
          }}
        >
          {props.processDescription}
        </Typography>
      )}
    </Box>
  )
}
