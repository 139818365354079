import { SellerVenue } from "api/seller"
import { ContextMenu, MenuItem } from "lib/components/ContextMenu"
import { useNavigate } from "react-router-dom"

export function VenueContextMenu({ venue }: { venue: SellerVenue }) {
  const navigate = useNavigate()
  const onVenueEdit = () => {
    navigate(`/seller/venues/${venue.id}`)
  }

  const onVenueDelete = () => {
    navigate(`/seller/venues/${venue.id}/delete`)
  }

  const items: MenuItem[] = [
    { label: "Edit", onClick: onVenueEdit },
    { label: "Delete", onClick: onVenueDelete },
  ]

  return <ContextMenu items={items} />
}
