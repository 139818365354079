import { useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useVerifyUser_DEPRECATED_Mutation } from "services/oldUserApi"
import { getErrorData, VerifyRegisterRequest } from "services/types"
import { useVerifyParams } from "./useVerifyParams"

function Verify() {
  const verifyParams = useVerifyParams()

  const navigate = useNavigate()
  const [verifyUser, { data, error, isLoading }] =
    useVerifyUser_DEPRECATED_Mutation()
  const verifyUserAndRedirect = useCallback(
    async (params: VerifyRegisterRequest) => {
      if (!data && !error && !isLoading) {
        await verifyUser(params).unwrap()
        navigate("/signup/verified")
      }
    },
    [data, error, isLoading, verifyUser, navigate]
  )
  useEffect(() => {
    verifyUserAndRedirect(verifyParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    const errorData = getErrorData<VerifyRegisterRequest>(error)
    const msg = errorData ? errorData.detail : "Internal server error"
    return <div>Verify error: {msg}</div>
  }

  return <div>Verifying registration...</div>
}

export default Verify
