import { Box, Typography } from "@mui/material"
import { useSellerVenueDraftDestroyMutation } from "api/sellerApi"
import { LoadingIndicator } from "app/LoadingIndicator"
import { RootState, store } from "app/store"
import { TopBar } from "app/TopBar"
import { CustomButton } from "lib/components/CustomButton"
import {
  CustomDialog,
  CustomDialogContent,
  CustomDialogTitle,
} from "lib/components/Dialog"
import { TabButtonItem } from "lib/components/TabMenu"
import { sellerVenueName } from "lib/slices/appState"
import { useBoolean } from "lib/useBoolean"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import {
  matchPath,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import { SellerVenueManagers } from "./SellerVenueManagers"
import { SellerVenueSpaces } from "./SellerVenueSpaces"
import VenueBasicInformation from "./VenueBasicInformation"

export function VenueEdit() {
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const venueFormName: string | undefined = useSelector(
    (state: RootState) => state.appState.sellerVenueName
  )

  useEffect(() => {
    return () => {
      store.dispatch(sellerVenueName(undefined))
    }
  }, [])

  const isDraft = !id
  const [destroyDraft, { isLoading: isResettingDraft }] =
    useSellerVenueDraftDestroyMutation()
  useEffect(() => {
    if (isDraft) destroyDraft()
  }, [destroyDraft, isDraft])
  // useLoader(isDraft && isResettingDraft)

  const [showPrompt, openPrompt, closePrompt] = useBoolean(false)

  const tabs: TabButtonItem[] = [
    {
      active:
        !!matchPath("/seller/venues/new", location.pathname) ||
        !!matchPath("/seller/venues/:id", location.pathname),
      onClick: () => {
        navigate(".")
      },
      label: "Overview",
    },
    {
      active:
        !!matchPath("/seller/venues/new/spaces", location.pathname) ||
        !!matchPath("/seller/venues/:id/spaces", location.pathname),
      onClick: () => {
        if (!id) openPrompt()
        else navigate("./spaces")
      },
      label: "Scenes",
    },
    {
      active:
        !!matchPath("/seller/venues/new/managers", location.pathname) ||
        !!matchPath("/seller/venues/:id/managers", location.pathname),
      onClick: () => {
        if (!id) openPrompt()
        else navigate("./managers")
      },
      label: "Venue managers",
    },
  ]

  return (
    <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
      {showPrompt && (
        <CustomDialog open onClose={closePrompt}>
          <CustomDialogTitle>Warning</CustomDialogTitle>
          <CustomDialogContent>
            <Box
              sx={{ margin: "60px 60px" }}
              maxWidth="400px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              gap="16px"
            >
              <Typography variant="h3" color="custom.dark900">
                Please enter Venue name and click Create venue button before you
                add scenes or managers for this venue.
              </Typography>
              <Box />
              <Box display="flex" justifyContent="center" flexGrow={1}>
                <CustomButton variant="contained" onClick={closePrompt}>
                  OK
                </CustomButton>
              </Box>
            </Box>
          </CustomDialogContent>
        </CustomDialog>
      )}
      <TopBar
        back="/seller/venues"
        title={venueFormName ? venueFormName : id ? "Edit venue" : "Add venue"}
        tabs={tabs}
      />

      {isDraft && isResettingDraft ? (
        <LoadingIndicator />
      ) : (
        <Box
          sx={{
            display: "flex",
            flex: "1 1 0",
            flexDirection: "column",
            overflowY: "scroll",
          }}
        >
          <Routes>
            <Route path="/spaces/*" element={<SellerVenueSpaces />} />
            <Route path="/managers" element={<SellerVenueManagers />} />
            <Route index element={<VenueBasicInformation />} />
          </Routes>
        </Box>
      )}
    </Box>
  )
}
