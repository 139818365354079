import { Button, ButtonProps } from "@mui/material"
import { styled } from "@mui/material/styles"
import { ReactComponent as BackArrowIcon } from "app/icons/long-arrow-up-left.svg"
import { useNavigate } from "react-router-dom"
import { ReactComponent as PlusIcon } from "./icons/plus.svg"

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  "&.MuiButton-root": {
    borderRadius: "12px",
    textTransform: "none",
    ...theme.typography.body14,
    padding: "14px 24px",
  },
  "&.MuiButton-root svg": {
    marginTop: "-3px",
    marginBottom: "-3px",
  },
  "&.MuiButton-root.MuiButton-contained": {
    backgroundColor: theme.palette.custom.accentprimary,
    color: theme.palette.custom.light500,
    boxShadow: "none",
  },

  "&.MuiButton-root.MuiButton-contained:hover": {
    backgroundColor: theme.palette.custom.accenthover,
    color: theme.palette.custom.light500,
    boxShadow: "0px 7px 14px rgba(12, 177, 217, 0.13)",
  },
  "&.MuiButton-root.MuiButton-contained.Mui-disabled": {
    backgroundColor: theme.palette.custom.light900,
    color: theme.palette.custom.dark500,
  },
  "&.MuiButton-root.MuiButton-containedWarning": {
    backgroundColor: theme.palette.warning.main,
  },
  "&.MuiButton-root.MuiButton-containedWarning:hover": {
    backgroundColor: theme.palette.custom.error,
  },
  "&.MuiButton-root.MuiButton-outlined": {
    color: theme.palette.custom.accentprimary,
    border: "2px solid",
    padding: "12px 22px",
    borderColor: theme.palette.custom.accentprimary,
    background: "none",
  },
  "&.MuiButton-root.MuiButton-outlined:hover": {
    border: "2px solid",
    borderColor: theme.palette.custom.accenthover,
    backgroundColor: theme.palette.custom.accenthover,
    color: theme.palette.custom.light500,
    boxShadow: "0px 7px 14px rgba(12, 177, 217, 0.13)",
  },
  "&.MuiButton-root.MuiButton-outlined.Mui-disabled": {
    color: theme.palette.custom.dark500,
    borderColor: theme.palette.custom.dark500,
  },
  "&.MuiButton-root.MuiButton-outlinedWarning": {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
  },
}))

export function CustomButton({ href, ...rest }: ButtonProps) {
  const navigate = useNavigate()
  if (href) rest.onClick = () => navigate(href)

  return <StyledButton {...rest}>{rest.children}</StyledButton>
}

export function PlusButton(props: ButtonProps) {
  return (
    <CustomButton startIcon={<PlusIcon />} {...props}>
      {props.children}
    </CustomButton>
  )
}

export function BackButton({ href, ...rest }: ButtonProps) {
  const navigate = useNavigate()
  if (href) rest.onClick = () => navigate(href)

  return (
    <Button
      {...rest}
      sx={{
        minWidth: 48,
        width: 48,
        height: 40,
        p: 0,
        borderRadius: 3,
        backgroundColor: "custom.light800",
      }}
    >
      <BackArrowIcon />
    </Button>
  )
}
