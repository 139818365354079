import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { CustomButton } from "lib/components/CustomButton"
import { useTheme } from "lib/useTheme"
import { ReactComponent as OKIcon } from "../icons/ok.svg"
import { useAuthUser } from "../useAuthUser"

export function SignUpCongratulations({ message }: { message: string }) {
  const theme = useTheme()
  const { user: profile } = useAuthUser()

  if (!profile) return null

  return (
    <Box
      flexGrow={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      gap={2}
    >
      <OKIcon />

      <Typography variant="h2" color={theme.palette.custom.dark900}>
        Congratulations!
      </Typography>

      <Typography variant="body14" color={theme.palette.custom.dark700}>
        {message}
      </Typography>

      <CustomButton variant="contained" href={profile.defaultLink}>
        Continue
      </CustomButton>
    </Box>
  )
}
