import { Rating, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import { Venue } from "api/venues"
import {
  useVenuesRetrieveQuery,
  useVenuesSendCreateMutation,
} from "api/venuesApi"
import { TopBarBase } from "app/TopBar"
import { CustomButton } from "lib/components/CustomButton"
import { useSnackbar } from "lib/useSnackbar"
import { ReactNode } from "react"
import { useParams } from "react-router-dom"
import { EnquireDialog } from "./EnquireDialog"
// import { ReactComponent as BookMarkIcon } from "./icons/bookmark.svg"
// import { ReactComponent as FollowIcon } from "./icons/follow.svg"
import { useBoolean } from "lib/useBoolean"
import { useSellerVenuePreviewRetrieveQuery } from "../../api/sellerApi"
import { ReactComponent as HotelGroupIcon } from "./icons/hotelgroup.svg"
import { ReactComponent as LocationIcon } from "./icons/location.svg"
import { ReactComponent as VRInteractionIcon } from "./icons/vrwhite.svg"
import { isVrReady } from "./utils"
import { VenueOverview } from "./VenueOverview"
import { VenuePhotos } from "./VenuePhotos"
import { VenueSpaces } from "./VenueSpaces"

type VenueParams = {
  id: string
}

const HeroImageBox = ({
  imageUrl,
  children,
}: {
  imageUrl?: string
  children?: ReactNode
}) => (
  <Box
    sx={{
      width: "100%",
      height: {
        xs: 408,
        lg: 540,
        xl: 704,
        xxl: 900,
      },
      position: "relative",
      overflow: "hidden",
    }}
  >
    <Box
      sx={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundImage: imageUrl ? `url(${imageUrl})` : "",
        backgroundSize: "cover",
      }}
    />
    <Box
      sx={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "rgba(55, 55, 92, 0.4)",
        opacity: 0.7,
        mixBlendMode: "normal",
      }}
    />
    <Box
      sx={{
        position: "absolute",
        left: "0%",
        right: "0%",
        top: "99.81%",
        bottom: "-84.81%",
        backgroundImage:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.4) -22.34%, rgba(0, 0, 0, 0.396541) -14.18%, rgba(0, 0, 0, 0.385796) -6.03%, rgba(0, 0, 0, 0.367336) 2.13%, rgba(0, 0, 0, 0.341035) 10.28%, rgba(0, 0, 0, 0.30729) 18.44%, rgba(0, 0, 0, 0.267246) 26.6%, rgba(0, 0, 0, 0.222924) 34.75%, rgba(0, 0, 0, 0.177077) 42.91%, rgba(0, 0, 0, 0.132754) 51.06%, rgba(0, 0, 0, 0.0927099) 59.22%, rgba(0, 0, 0, 0.0589645) 67.38%, rgba(0, 0, 0, 0.0326639) 75.53%, rgba(0, 0, 0, 0.014204) 83.69%, rgba(0, 0, 0, 0.00345888) 91.84%, rgba(0, 0, 0, 0) 100%)",
        filter: "drop-shadow(0px 2px 3px rgba(17, 17, 128, 0.04))",
      }}
    />
    <Box
      sx={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: "67.22%",
        backgroundImage:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.7) -68.36%, rgba(0, 0, 0, 0.693947) -57.14%, rgba(0, 0, 0, 0.675143) -45.91%, rgba(0, 0, 0, 0.642838) -34.69%, rgba(0, 0, 0, 0.596812) -23.47%, rgba(0, 0, 0, 0.537758) -12.24%, rgba(0, 0, 0, 0.467681) -1.02%, rgba(0, 0, 0, 0.390116) 10.21%, rgba(0, 0, 0, 0.309884) 21.43%, rgba(0, 0, 0, 0.232319) 32.66%, rgba(0, 0, 0, 0.162242) 43.88%, rgba(0, 0, 0, 0.103188) 55.1%, rgba(0, 0, 0, 0.0571619) 66.33%, rgba(0, 0, 0, 0.024857) 77.55%, rgba(0, 0, 0, 0.00605304) 88.78%, rgba(0, 0, 0, 0) 100%)",
        filter: "drop-shadow(0px 2px 3px rgba(17, 17, 128, 0.04))",
      }}
    />

    <Box
      sx={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </Box>
  </Box>
)

function Header({
  venue,
  visitInXplore,
}: {
  venue: Venue
  visitInXplore: () => void
}) {
  const venueLocation = [venue.city, venue.state, venue.country]
    .filter((s) => !!s)
    .join(", ")

  const vrReady = isVrReady(venue)

  // TODO: replace by useBoolean from new branch
  const [enquireDialog, openEnquireDialog, closeEnquireDialog] =
    useBoolean(false)

  return (
    <HeroImageBox
      imageUrl={
        (venue.hero_image_link || (venue.photos && venue.photos[0]?.link)) ??
        undefined
      }
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TopBarBase
          sx={{
            background: "transparent",
            minHeight: 84,
            maxHeight: 84,
          }}
        />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              margin: "auto 56px 80px 56px",
            }}
          >
            <Box>
              <Typography
                variant="h1"
                color="custom.light500"
                sx={{
                  textShadow: "0px 1px 1px rgba(64, 64, 134, 0.44)",
                }}
              >
                {venue.name}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "40px" }}>
              {vrReady && (
                <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                  <VRInteractionIcon />
                  <Typography variant="body14" color="custom.light500">
                    VR-ready
                  </Typography>
                </Box>
              )}
              {venue.brand && (
                <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                  <HotelGroupIcon />
                  <Typography variant="body14" color="custom.light500">
                    {venue.brand}
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                <LocationIcon />
                <Typography variant="body14" color="custom.light500">
                  {venueLocation}
                </Typography>
              </Box>
              {venue.star_rating && (
                <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                  <Typography variant="body14" color="custom.light500">
                    <Rating
                      value={venue.star_rating}
                      readOnly
                      sx={{
                        lineHeight: 1,
                        "& .MuiRating-iconFilled": {
                          color: "white",
                        },
                      }}
                    />
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "24px",
                  marginTop: "24px",
                }}
              >
                {/* <CustomButton variant="outlined">
                  <FollowIcon />
                  Folow
                </CustomButton> */}
                {/* <CustomButton variant="outlined">
                  <BookMarkIcon />
                </CustomButton> */}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "24px",
                  marginTop: "24px",
                  height: "56px",
                }}
              >
                <CustomButton variant="contained" onClick={openEnquireDialog}>
                  Enquire
                </CustomButton>
                {/* <CustomButton
                    variant="contained"
                  >
                    Request VR experience
                  </CustomButton> */}
                {vrReady && (
                  <CustomButton variant="contained" onClick={visitInXplore}>
                    Visit in RV Xplore app
                  </CustomButton>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {enquireDialog && (
        <EnquireDialog onClose={closeEnquireDialog} venueId={venue.id} />
      )}
    </HeroImageBox>
  )
}

const SectionsContainer = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      padding: "24px",
      gap: "8px",
    }}
  >
    {children}
  </Box>
)

const Section = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      backgroundColor: "custom.light600",
      borderRadius: "12px",
      padding: "32px",
    }}
  >
    {children}
  </Box>
)

export const VenueView = ({ sellerPreview }: { sellerPreview: boolean }) => {
  const { id } = useParams<VenueParams>()
  const { data: usualVenue } = useVenuesRetrieveQuery(
    id && !sellerPreview ? { id: +id } : skipToken
  )

  const { data: previewVenue } = useSellerVenuePreviewRetrieveQuery(
    id && sellerPreview ? { id } : skipToken
  )

  const venue = usualVenue || previewVenue

  const [openVenue, { isSuccess: isSuccessSendCreate }] =
    useVenuesSendCreateMutation()
  const { showSuccess, showError } = useSnackbar()

  const visitInXplore = () => {
    if (id && !sellerPreview) {
      openVenue({ venueId: +id })
        .unwrap()
        .then(() => {
          showSuccess("Venue was successfully opened")
        })
        .catch(() => showError("Cannot open venue"))
    }
  }

  if (!venue) {
    // TODO spinner
    return null
  }

  const vrReady = isVrReady(venue)

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "custom.light700",
        overflow: "auto",
      }}
    >
      <Header venue={venue} visitInXplore={visitInXplore} />

      {/* Main page content*/}

      <SectionsContainer>
        <Section>
          <VenuePhotos venue={venue} />
        </Section>
        {!!venue.spaces?.length && (
          <Section>
            <VenueSpaces spaces={venue.spaces} />
          </Section>
        )}
        <Section>
          <VenueOverview sellerPreview={sellerPreview} />
        </Section>
      </SectionsContainer>
      {vrReady && (
        <Box display="flex" justifyContent="center" width="100%" mb="24px">
          <CustomButton variant="contained" onClick={visitInXplore}>
            Visit in RV Xplore app
          </CustomButton>
        </Box>
      )}
    </Box>
  )
}
