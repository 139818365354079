import { yupResolver } from "@hookform/resolvers/yup"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import {
  useUsersInvitationRetrieveQuery,
  useUsersInvitationUpdateMutation,
  useUsersRegisterCreateMutation,
} from "api/usersApi"
import { CustomButton } from "lib/components/CustomButton"
import { ControlledPasswordField } from "lib/components/form/ControlledPasswordField"
import { ControlledTextField } from "lib/components/form/ControlledTextField"
import { useRedirectAuthorizedUser } from "lib/useRedirectAuthorizedUser"
import { useServerFormValidation } from "lib/useServerFormValidation"
import { useTheme } from "lib/useTheme"
import { useEffect, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import * as yup from "yup"
import { useQuery } from "../../../lib/useQuery"
import { FastSignUpData, FastSignUpWizardProps } from "../FastSignUp"

// TODO: refactoring - reuse common parts with SignUp component
// This is a quick implementation of fast sign up which made
// by copy-pasting SignUp component and removing what's not needed.

type StepFormValues = {
  first_name: string
  last_name: string
  email: string
  password: string
}

const schema = yup
  .object({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last/Family name is required"),
    email: yup
      .string()
      .email("Please enter valid email address")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password should contain at least 8 characters"),
  })
  .required()

function getError(error: any) {
  return error && "status" in error && error.status == 400 && "data" in error
    ? (error.data as Record<string, unknown>)
    : undefined
}

const convertWizardDataToRegisterData = ({
  first_name,
  last_name,
  email,
  password,
}: FastSignUpData) => ({
  username: email,
  first_name,
  last_name,
  email,
  password,
})

const convertWizardDataToInviteRegisterData = ({
  first_name,
  last_name,
  email,
  password,
}: FastSignUpData) => ({
  username: email,
  first_name,
  last_name,
  email,
  password,
  completed: true,
})

export function FastSignUpGeneral(props: FastSignUpWizardProps) {
  useRedirectAuthorizedUser()

  const { setValidationError, bulkUpdate, wizardData, setStep } = props
  const theme = useTheme()

  const [
    register,
    {
      isLoading: isRegisterLoading,
      error: registerError,
      isSuccess: registerSuccess,
    },
  ] = useUsersRegisterCreateMutation()

  const query = useQuery()
  const invite = query.get("invite") || undefined
  const { data: existingUser } = useUsersInvitationRetrieveQuery(
    invite ? { token: invite } : skipToken
  )

  const [
    inviteRegister,
    {
      isLoading: isInviteLoading,
      error: inviteError,
      isSuccess: inviteSuccess,
    },
  ] = useUsersInvitationUpdateMutation()

  const validateError = getError(registerError) || getError(inviteError)

  useEffect(() => {
    if (validateError) {
      setValidationError(validateError)
    }
  }, [validateError, setValidationError])

  useEffect(() => {
    if (registerSuccess) {
      setStep("welcome")
    }
  }, [registerSuccess, setStep])

  useEffect(() => {
    if (inviteSuccess) {
      setStep("complete")
    }
  }, [inviteSuccess, setStep])

  const disabled = isRegisterLoading || isInviteLoading

  const formMethods = useForm<StepFormValues>({
    defaultValues: wizardData,
    mode: "onBlur",
    resolver: yupResolver(schema),
  })

  // FIXME: this is a quick hack to make submitting work.
  // Posting data should go from something like reducer (async thunks)
  // Also, it's better to be controlled by the parent component.
  const [needSubmit, setNeedSubmit] = useState(false)
  const onSubmit = (data: StepFormValues) => {
    bulkUpdate(data)
    setNeedSubmit(true)
  }
  useEffect(() => {
    if (existingUser) {
      wizardData.email = existingUser.email
      formMethods.setValue("email", existingUser.email)
    }
    if (needSubmit) {
      setNeedSubmit(false)
      setValidationError({})
      if (existingUser && invite) {
        const data = convertWizardDataToInviteRegisterData(wizardData)
        inviteRegister({ token: invite, userInvitation: data })
      } else {
        const data = convertWizardDataToRegisterData(wizardData)
        register({ registerUser: data })
      }
    }
  }, [
    needSubmit,
    register,
    setValidationError,
    wizardData,
    inviteRegister,
    existingUser,
    invite,
    formMethods,
  ])

  const validationError = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { non_field_errors, ...validationError } =
      wizardData.validationError || {}
    return validationError
  }, [wizardData])
  useServerFormValidation(validationError, formMethods.setError, true)

  return (
    <Container component="main">
      <Box
        pt={8}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2}
      >
        <Typography variant="h2" color={theme.palette.custom.dark900}>
          Join the RendezVerse community
        </Typography>
        <Typography
          variant="body14"
          color={theme.palette.custom.dark700}
          textAlign="center"
        >
          Step into the metaverse.
          <br />
          {"Create your account, it's quick and easy"}
        </Typography>

        <FormProvider {...formMethods}>
          <Box
            component="form"
            noValidate
            onSubmit={formMethods.handleSubmit(onSubmit)}
            pt={3}
            width="330px"
            display="flex"
            flexDirection="column"
            gap={2}
          >
            <ControlledTextField
              label="First name"
              name="first_name"
              disabled={disabled}
              autoComplete="given-name"
              autoFocus
            />
            <ControlledTextField
              label="Last name"
              name="last_name"
              disabled={disabled}
              autoComplete="family-name"
            />
            <ControlledTextField
              label="Email address"
              name="email"
              disabled={disabled || existingUser != null}
              autoComplete="email"
            />
            <ControlledPasswordField
              name="password"
              label="Password"
              disabled={disabled}
              autoComplete="new-password"
            />
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              disabled={disabled}
            >
              {invite ? "Finish registration" : "Continue"}
            </CustomButton>
          </Box>
        </FormProvider>

        <Box
          mt="24px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="12px"
          pt="40px"
        >
          <Typography variant="h3" color={theme.palette.custom.dark800}>
            Have an account already?
          </Typography>
          <CustomButton variant="outlined" href="/signin/">
            Sign in
          </CustomButton>
        </Box>
      </Box>
    </Container>
  )
}
