import { CustomSnackbarProps } from "app/CustomSnackbar"
import { RootState, store } from "app/store"
import { useDispatch, useSelector } from "react-redux"
import { snackbar } from "./slices/appState"

export function useSnackbar() {
  const snackbarState: CustomSnackbarProps = useSelector(
    (state: RootState) => state.appState.snackbar
  )

  const dispatch = useDispatch()
  return {
    snackbarState,
    showSuccess: (message: string) =>
      store.dispatch(
        snackbar({
          open: true,
          message,
          type: "success",
        })
      ),
    showError: (message: string) =>
      store.dispatch(
        snackbar({
          open: true,
          message,
          type: "error",
        })
      ),
    close: () => dispatch(snackbar({ open: false })),
  }
}
