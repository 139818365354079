import { SellerVenue, SellerVenueSpace } from "api/seller"

type VenueSpace = {
  available_in: string[]
}

type Venue = {
  spaces?: VenueSpace[]
}

export const isVrReady = ({ spaces }: Venue) =>
  !!spaces?.find((space) => space.available_in.length > 0)

export const isSpace3D = ({ available_in }: VenueSpace) =>
  !!available_in.find(
    (value) => value === "quest" || value === "android" || value === "ios"
  )

export const isSpace360 = ({ available_in }: VenueSpace) =>
  !!available_in.find((value) => value === "360")

export const isVenue3D = ({ spaces }: Venue) => !!spaces?.find(isSpace3D)
export const isVenue360 = ({ spaces }: Venue) => !!spaces?.find(isSpace360)

export const isSellerSpace3D = (space: Partial<SellerVenueSpace>) =>
  !!(space.android_scene_link || space.ios_scene_link || space.quest_scene_link)

export const isSellerSpace360 = (space: Partial<SellerVenueSpace>) =>
  !!space.matterport_url

export const isSellerVenue3D = ({ spaces }: SellerVenue) =>
  !!spaces?.find(isSellerSpace3D)
export const isSellerVenue360 = ({ spaces }: SellerVenue) =>
  !!spaces?.find(isSellerSpace360)
