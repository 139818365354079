import { useLogout_DEPRECATED_Mutation } from "services/oldUserApi"

export function useLogout() {
  const [logoutRequest] = useLogout_DEPRECATED_Mutation()
  const logout = () => {
    logoutRequest()
      .unwrap()
      .finally(() => {
        window.location.assign("/signin")
      })
  }

  return logout
}
