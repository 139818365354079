import { Box, Typography } from "@mui/material"
import { ReactComponent as CancelIcon } from "lib/components/form/icons/close.svg"
import { ReactComponent as ErrorIcon } from "lib/components/form/icons/error-triangle-outline.svg"
import { ReactComponent as RetryIcon } from "lib/components/form/icons/retry.svg"
import { IconSize } from "./ImageCard"
import { ImageSizeProps } from "./ImageProcessingCard"

export type ImageErrorCardProps = IconSize &
  ImageSizeProps & {
    cleanError: () => void
    retry: () => void
  }

const acceptedFiles = ["image/jpeg", "image/jpg", "image/png"]

export function ImageErrorCard(props: ImageErrorCardProps) {
  return (
    <Box
      sx={{
        height: props.height,
        width: props.width,
        display: "flex",
        borderRadius: 2,
        position: "relative",
        backgroundColor: "custom.light800",
      }}
    >
      <Box
        sx={{
          left: 12,
          top: 10,
          position: "absolute",
        }}
      >
        <ErrorIcon />
      </Box>
      <Typography
        variant="body11"
        sx={{
          position: "absolute",
          bottom: 12,
          left: 12,
          fontWeight: 700,
          color: "custom.dark700",
        }}
      >
        File upload error
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "custom.light500",
          borderRadius: 2,
          boxShadow: "0px 2px 3px rgba(17, 17, 128, 0.04)",
          right: 8,
          top: 8,
          width: props.iconSize || 32,
          height: props.iconSize || 32,
          position: "absolute",
          cursor: "pointer",
        }}
        onClick={props.cleanError}
      >
        <CancelIcon />
      </Box>
      <Box
        sx={{
          right: 12,
          bottom: 12,
          position: "absolute",
          cursor: "pointer",
        }}
        onClick={props.retry}
      >
        <RetryIcon />
      </Box>
    </Box>
  )
}
