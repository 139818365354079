import { useSearchParams } from "react-router-dom"
import { VerifyRegisterRequest } from "services/types"

export function useVerifyParams() {
  const [searchParams] = useSearchParams()
  const requestVerifyParams = Object.fromEntries(searchParams)
  const verifyParams =
    "u" in requestVerifyParams
      ? {
          user_id: requestVerifyParams.u,
          timestamp: requestVerifyParams.t,
          signature: requestVerifyParams.s,
        }
      : (requestVerifyParams as VerifyRegisterRequest)

  return verifyParams
}
