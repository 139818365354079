import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import {
  useSellerVenueDraftRetrieveQuery,
  useSellerVenueDraftSpaceDestroyMutation,
  useSellerVenueRetrieveQuery,
  useSellerVenueSpaceDestroyMutation,
} from "api/sellerApi"
import { LoadingIndicator } from "app/LoadingIndicator"
import { ReactComponent as TrashBigIcon } from "features/seller/icons/trash-big.svg"
import { CustomButton } from "lib/components/CustomButton"
import {
  CustomDialog,
  CustomDialogContent,
  CustomDialogTitle,
} from "lib/components/Dialog"
import { useSnackbar } from "lib/useSnackbar"
import { useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"

export function useVenueSpaceQuery(
  venueId: string | undefined,
  spaceId: string | undefined
) {
  const { data: existingVenue, isFetching: isVenueFetching } =
    useSellerVenueRetrieveQuery(venueId ? { id: venueId } : skipToken)
  const { data: draft, isFetching: isDraftFetching } =
    useSellerVenueDraftRetrieveQuery(venueId ? skipToken : undefined)
  const venue = existingVenue || draft
  const isFetching = isVenueFetching || isDraftFetching

  const space = useMemo(
    () =>
      venue &&
      spaceId &&
      venue.spaces.filter((space) => space.id === +spaceId)[0],
    [venue, spaceId]
  )

  return { space, isFetching }
}

export function VenueSpaceDeleteDialog() {
  const { id: venueId, spaceId } = useParams()
  const { space, isFetching } = useVenueSpaceQuery(venueId, spaceId)

  const navigate = useNavigate()
  const close = () => navigate(`/seller/venues/${venueId || "new"}/spaces`)

  const [deleteVenueSpace, { isLoading: isVenueDeleting }] =
    useSellerVenueSpaceDestroyMutation()
  const [deleteVenueDraftSpace, { isLoading: isDraftDeleting }] =
    useSellerVenueDraftSpaceDestroyMutation()

  const { showSuccess, showError } = useSnackbar()

  const deleteSpace = () => {
    if (!spaceId) return
    ;(venueId
      ? deleteVenueSpace({
          venuePk: venueId.toString(),
          id: spaceId,
        })
      : deleteVenueDraftSpace({
          id: spaceId,
        })
    )
      .unwrap()
      .then(close)
      .then(() => showSuccess("Scene has been deleted"))
      .catch(() => showError("Error deleting scene"))
  }

  const disabled = isVenueDeleting || isDraftDeleting

  return (
    <CustomDialog open onClose={close}>
      <CustomDialogTitle>Delete scene</CustomDialogTitle>
      <CustomDialogContent>
        <Box
          sx={{ margin: "60px 162px" }}
          maxWidth="400px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          gap="16px"
        >
          {isFetching || !space ? (
            <LoadingIndicator />
          ) : (
            <>
              <TrashBigIcon />
              <Typography variant="h3" color="custom.dark900">
                Delete {space.name}?
              </Typography>
              <Typography variant="body14" color="custom.dark700">
                This scene will be permanently deleted.
                <br /> Are you sure you want to proceed?
              </Typography>
              <Box />
              <Box>
                <Box display="flex" gap="24px">
                  <CustomButton
                    variant="outlined"
                    disabled={disabled}
                    onClick={close}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    color="warning"
                    disabled={disabled}
                    onClick={deleteSpace}
                  >
                    Delete scene
                  </CustomButton>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </CustomDialogContent>
    </CustomDialog>
  )
}
