import { createTheme } from "@mui/material/styles"
import { useMemo } from "react"

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body14: React.CSSProperties
    body13: React.CSSProperties
    body13regular: React.CSSProperties
    body12: React.CSSProperties
    body12regular: React.CSSProperties
    body11: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body14?: React.CSSProperties
    body13?: React.CSSProperties
    body13regular: React.CSSProperties
    body12?: React.CSSProperties
    body12regular: React.CSSProperties
    body11?: React.CSSProperties
  }
  interface Palette {
    custom: Palette["primary"]
  }
  interface PaletteOptions {
    custom: PaletteOptions["primary"]
  }
  interface PaletteColor {
    dark500?: string
    dark600?: string
    dark700?: string
    dark800?: string
    dark900?: string
    light500?: string
    light600?: string
    light700?: string
    light800?: string
    light900?: string
    accentprimary?: string
    accenthover?: string
    accentlight?: string
    accentpressed?: string
    accentsecondary?: string
    error?: string
  }
  interface SimplePaletteColorOptions {
    dark500?: string
    dark600?: string
    dark700?: string
    dark800?: string
    dark900?: string
    light500?: string
    light600?: string
    light700?: string
    light800?: string
    light900?: string
    accentprimary?: string
    accenthover?: string
    accentlight?: string
    accentpressed?: string
    accentsecondary?: string
    error?: string
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body14: true
    body13: true
    body13regular: true
    body12: true
    body12regular: true
    body11: true
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    xxl: true
  }
}

const MANROE_FONT = {
  fontFamily: "Manrope, serif",
  fontStyle: "normal",
}

export function useTheme() {
  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiUseMediaQuery: {
            defaultProps: {
              noSsr: true,
            },
          },
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                backgroundColor: "#F5F5FA",
              },
            },
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 360, // 600
            md: 745, // 900
            lg: 1440, // 1200
            xl: 1920, // 1536
            xxl: 2560, // 1920
          },
        },
        palette: {
          warning: { main: "#E92A36" },
          error: { main: "#FA5862" },
          info: { main: "#58586B" },
          success: { main: "#6DC270" },
          custom: {
            main: "#00A5CD",
            light500: "#FFFFFF",
            light600: "#FCFCFD",
            light700: "#F7F7F8",
            light800: "#F4F4F6",
            light900: "#EEEEF1",
            dark500: "#BFBFCA",
            dark600: "#8A8A9F",
            dark700: "#696981",
            dark800: "#58586B",
            dark900: "#424251",
            accentprimary: "#00A5CD",
            accenthover: "#0CB1D9",
            accentlight: "#DBF8FF",
            accentpressed: "#009BC3",
            accentsecondary: "#00A5CD",
            error: "#FA5862",
          },
        },
        typography: {
          h1: {
            ...MANROE_FONT,
            fontWeight: 600,
            fontSize: 72,
            lineHeight: 1.1,
            fontFeatureSettings: "'ordn' on",
          },
          h2: {
            ...MANROE_FONT,
            fontWeight: 500,
            fontSize: 32,
            whiteSpace: "nowrap",
            lineHeight: 1.2,
          },
          h3: {
            ...MANROE_FONT,
            fontWeight: 600,
            fontSize: 20,
            lineHeight: 1.35,
            fontFeatureSettings: "'ordn' on",
          },
          h4: {
            ...MANROE_FONT,
            fontWeight: 600,
            fontSize: 16,
            lineHeight: 1.2,
            fontFeatureSettings: "'liga' off, 'calt' off",
          },
          body14: {
            ...MANROE_FONT,
            fontWeight: 600,
            fontSize: 14,
            lineHeight: 1.3,
            fontFeatureSettings: "'ss04' on",
          },
          body13: {
            ...MANROE_FONT,
            fontWeight: 600,
            fontSize: 13,
            lineHeight: 1.38,
          },
          body13regular: {
            ...MANROE_FONT,
            fontWeight: 400,
            fontSize: 13,
            lineHeight: 1.38,
            fontFeatureSettings: "'liga' off, 'calt' off",
          },
          body12: {
            ...MANROE_FONT,
            fontWeight: 600,
            fontSize: 12,
            lineHeight: 1.3,
            fontFeatureSettings: "'ss04' on",
          },
          body12regular: {
            ...MANROE_FONT,
            fontWeight: 400,
            fontSize: 12,
            lineHeight: 1.25,
            letterSpacing: "0.01em",
            fontFeatureSettings: "'liga' off, 'calt' off",
          },
          body11: {
            fontFamily: "Inter, serif",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 11,
            lineHeight: 1.36,
            letterSpacing: "0.01em",
            fontFeatureSettings: "'liga' off, 'calt' off",
          },
        },
      }),
    []
  )
  return theme
}
