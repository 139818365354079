import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Typography } from "@mui/material"
import { CustomButton } from "lib/components/CustomButton"
import { ControlledTextField } from "lib/components/form/ControlledTextField"
import { MSG_FIELD_REQUIRED } from "lib/messages"
import { useQuery } from "lib/useQuery"
import { useState } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import * as yup from "yup"
import { useServerFormValidation } from "../../lib/useServerFormValidation"
import { useActivateDeviceMutation } from "../../services/deviceApi"
import {
  DeviceActivationRequest,
  DeviceActivationResult,
} from "../../services/types"
import { useAuthUser } from "../auth/useAuthUser"

const MSG_HIDE_WRONG_INPUT_ERROR = "_hide_wrong_input_error_"

const schema = yup
  .object({
    user_code: yup
      .string()
      .required(MSG_FIELD_REQUIRED)
      .min(4, MSG_HIDE_WRONG_INPUT_ERROR)
      .max(5, MSG_HIDE_WRONG_INPUT_ERROR),
  })
  .required()

function ActivateDevice() {
  useAuthUser()

  const query = useQuery()

  const [activationResult, setActivationResult] = useState<
    DeviceActivationResult | undefined
  >(undefined)

  const formMethods = useForm<DeviceActivationRequest>({
    defaultValues: {
      user_code: query.get("user_code") || "",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  })
  const {
    setError,
    formState: { errors },
  } = formMethods
  const [activateDevice, { error, isLoading }] = useActivateDeviceMutation()
  const onSubmit: SubmitHandler<DeviceActivationRequest> = (data) =>
    activateDevice(data)
      .unwrap()
      .then((result) => setActivationResult(result))
      .catch()

  useServerFormValidation(error, setError)
  // Validation notes: we're using RHF+yup validation just to disable/enable button
  // when user code value is too short, but error is shown only on bad API response
  const errorMessage =
    errors.user_code?.message &&
    errors.user_code?.message !== MSG_HIDE_WRONG_INPUT_ERROR &&
    "The code you entered is incorrect. Please try again."

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      {activationResult ? (
        <>
          <Typography variant="h2" color="custom.dark900">
            Your app has been activated!
          </Typography>
          <Typography
            variant="body14"
            color="custom.dark700"
            sx={{ fontWeight: 600 }}
          >
            You may now use RV XPlore App.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "340px",
            }}
          >
            <CustomButton href="/" variant="contained">
              Okay
            </CustomButton>
          </Box>
        </>
      ) : (
        <FormProvider {...formMethods}>
          <Typography variant="h2" color="custom.dark900">
            Enter the code from the Xplore App
          </Typography>
          <Typography variant="h4" color="custom.dark900">
            If you don&apos;t have the app, contact us for access.
          </Typography>
          <Box
            component="form"
            onSubmit={formMethods.handleSubmit(onSubmit)}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "340px",
            }}
          >
            <ControlledTextField
              name="user_code"
              label="Registration code"
              inputProps={{
                minLength: 4,
                maxLength: 5,
                style: { textTransform: "uppercase" },
              }}
              error={!!errors.user_code?.message}
              helperText={errorMessage}
              autoFocus
            />
            <CustomButton
              variant="contained"
              type="submit"
              disabled={isLoading}
            >
              Activate
            </CustomButton>
          </Box>
        </FormProvider>
      )}
    </Box>
  )
}

export default ActivateDevice
