import { generatedApi as api } from "./api"
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    venuesList: build.query<VenuesListApiResponse, VenuesListApiArg>({
      query: (queryArg) => ({
        url: `/api/venues/`,
        params: {
          country: queryArg.country,
          featured: queryArg.featured,
          guests: queryArg.guests,
          limit: queryArg.limit,
          location: queryArg.location,
          offset: queryArg.offset,
          size_of_largest_meeting_room: queryArg.sizeOfLargestMeetingRoom,
          stars: queryArg.stars,
        },
      }),
    }),
    venuesRetrieve: build.query<
      VenuesRetrieveApiResponse,
      VenuesRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/venues/${queryArg.id}/` }),
    }),
    venuesEnquireCreate: build.mutation<
      VenuesEnquireCreateApiResponse,
      VenuesEnquireCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/venues/${queryArg.venueId}/enquire/`,
        method: "POST",
        body: queryArg.enquire,
      }),
    }),
    venuesSendCreate: build.mutation<
      VenuesSendCreateApiResponse,
      VenuesSendCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/venues/${queryArg.venueId}/send/`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as venuesGeneratedApi }
export type VenuesListApiResponse = /** status 200  */ PaginatedVenueList
export type VenuesListApiArg = {
  country?: string
  featured?: boolean
  guests?: number
  /** Number of results to return per page. */
  limit?: number
  location?: string
  /** The initial index from which to return the results. */
  offset?: number
  sizeOfLargestMeetingRoom?: string
  stars?: number
}
export type VenuesRetrieveApiResponse = /** status 200  */ Venue
export type VenuesRetrieveApiArg = {
  /** A unique integer value identifying this venue. */
  id: number
}
export type VenuesEnquireCreateApiResponse = /** status 202  */ Blob
export type VenuesEnquireCreateApiArg = {
  venueId: number
  enquire: Enquire
}
export type VenuesSendCreateApiResponse = /** status 201  */ Blob
export type VenuesSendCreateApiArg = {
  venueId: number
}
export type MemberOfEnum =
  | "Accor Hotels"
  | "Aman"
  | "Anantara Hotels, Resorts & Spas"
  | "Andaz"
  | "Angsana"
  | "Autograph Collection"
  | "Avani Hotels & Resorts"
  | "Banyan Tree"
  | "Barri\u00E8re"
  | "Belmond"
  | "Best Western Hotels & Resorts"
  | "Bulgari Hotels & Resorts"
  | "Canopy by Hilton"
  | "Centara Hotels & Resorts"
  | "Choice Hotels"
  | "Conrad Hotels"
  | "Crowne Plaza Hotels & Resorts"
  | "Curio Collection by Hilton"
  | "Dedica Anthology Hotels"
  | "Delta Hotels by Marriott"
  | "Design Hotels"
  | "Disney Parks, Experiences and Products"
  | "DoubleTree by Hilton"
  | "Dusit Hotels & Resorts"
  | "Edition"
  | "Elewana Collection"
  | "Fairmont"
  | "Four Seasons"
  | "Gran Meli\u00E1 Hotels & Resorts"
  | "Grand Hyatt"
  | "Grand Mercure"
  | "Hesperia Hotels & Resorts"
  | "Hilton"
  | "HOSHINOYA"
  | "Hyatt"
  | "Hyatt Regency"
  | "InterContinental Hotels & Resorts"
  | "Interstate Hotels & Resorts"
  | "Jumeirah"
  | "JW Marriott"
  | "Kempinski Hotels"
  | "Kimpton Hotels"
  | "Langham Hotels & Resorts"
  | "Le M\u00E9ridien"
  | "Loews Hotels"
  | "Lotte Hotels"
  | "Luxury Collection"
  | "Mandarin Oriental Hotel Group"
  | "Marriott"
  | "Meli\u00E1 Hotels & Resorts"
  | "MGM Resorts"
  | "Millennium Hotels & Resorts"
  | "NH Collection Hotels"
  | "NH Hotels"
  | "Oberoi Hotels & Resorts"
  | "Okura Hotels & Resorts"
  | "Omni Hotels & Resorts"
  | "Pan Pacific Hotels Group"
  | "Park Hyatt"
  | "Park Plaza"
  | "Preferred Hotels & Resorts"
  | "Prince Hotels"
  | "Radisson Blu"
  | "Radisson Collection"
  | "Raffles Hotels & Resorts"
  | "Regent Hotels & Resorts"
  | "Renaissance Hotels"
  | "Ritz-Carlton"
  | "RIU Hotels & Resorts"
  | "Rocco Forte Hotels"
  | "Rosewood Hotels & Resorts"
  | "Scandic Hotels"
  | "Shangri-La"
  | "Sheraton"
  | "Sofitel"
  | "Soneva"
  | "St. Regis"
  | "Swiss\u00F4tel"
  | "Tivoli Hotels & Resorts"
  | "Tokyu Hotels"
  | "Toyoko Inn"
  | "Travelodge"
  | "Treebo"
  | "Tribute Portfolio"
  | "W Hotels"
  | "Waldorf Astoria Hotels & Resorts"
  | "Warwick Hotels & Resorts"
  | "Westgate Resorts"
  | "Westin Hotels & Resorts"
  | "Wyndham"
export type ConsortiumMembershipEnum =
  | "Leading Hotels of the World (LHW)"
  | "Minor Hotels"
  | "Relais & Ch\u00E2teaux"
  | "Signature Travel Network"
  | "Small Luxury Hotels of the World (SLH)"
  | "Traveller Made"
  | "Virtuoso"
export type HealthcareCompliantEnum = "yes" | "unknown"
export type BlankEnum = ""
export type NullEnum = null
export type PropertyTypeEnum =
  | "Airport"
  | "Beach Resort"
  | "Boutique Hotel"
  | "City Centre"
  | "Conference Hotel"
  | "Countryside"
  | "Golf & Leisure Resort"
  | "Serviced Apartments"
  | "Ski Resort"
  | "Spa Retreat"
export type StarRatingEnum = 3 | 4 | 5
export type NumberOfBedroomsEnum =
  | "Under 100"
  | "100 - 250"
  | "251-500"
  | "500+"
export type NumberOfMeetingRoomsEnum =
  | "None"
  | "Under 5"
  | "5-10"
  | "11-20"
  | "20+"
export type TypeOfEventsHostedEnum =
  | "Incentives"
  | "Congresses/Conventions"
  | "Conferences"
  | "Meetings"
  | "Sustainable Events"
  | "Experiential Events"
  | "Exhibitions"
export type TotalMeetingSpaceEnum =
  | "No Meeting Rooms"
  | "1 - 250 sq. m / 10 - 2'500 sq. ft"
  | "251 - 1'000 sq. m / 2'501 - 10'000 sq. ft"
  | "1'001 - 2'500 sq. m / 10'001 - 27'000 sq. ft"
  | "2'501 - 5'000 sq. m / 27'001 - 54'000 sq. ft"
  | "5'001 - 10'000 sq. m / 54'001 - 100'000 sq. ft"
  | "10'000+ sq. m / 100'000+ sq. ft"
export type SizeOfLargestMeetingRoomEnum =
  | "No Meeting Rooms"
  | "1 - 250 sq. m / 10 - 2'500 sq. ft"
  | "251 - 1'000 sq. m / 2'501 - 10'000 sq. ft"
  | "1'000+ sq. m / 10'000+ sq. ft"
export type MaximumCapacityOfLargestMeetingRoomEnum =
  | "N/A"
  | "Under 50"
  | "50-100"
  | "101-200"
  | "201-500"
  | "501-1'000"
  | "1'001-5'000"
  | "5'000+"
export type AverageDailyRateEnum =
  | "under $150"
  | "$150-250"
  | "$250-500"
  | "$500+"
  | "under \u20AC100"
  | "\u20AC100-200"
  | "\u20AC200-300"
  | "\u20AC300+"
export type DistanceToNearestAirportEnum =
  | "1 - 10 km / 0.5 - 5 Miles"
  | "10 - 50 km / 5 - 30 Miles"
  | "50+ km / 30+ Miles"
export type LeisureFacilitiesEnum =
  | "Beach"
  | "Casino"
  | "Club"
  | "Entertainment"
  | "Golf"
  | "Gym"
  | "Kids' Club"
  | "Spa"
  | "Speciality Dining"
  | "Swimming Pool"
  | "Tennis"
  | "Water Sports"
  | "Winter Sports"
export type BestTimeOfYearForGroupsEnum =
  | "January"
  | "February"
  | "March"
  | "April"
  | "May"
  | "June"
  | "July"
  | "August"
  | "September"
  | "October"
  | "November"
  | "December"
export type VenuePhoto = {
  id: number
  image: string
  link: string | null
  thumbnail_link: string
  title: string | null
}
export type VenueSpace = {
  id: number
  thumbnail: string | null
  available_in: string[]
  name: string
  size?: number | null
  ceiling_height?: number | null
  dimension_l?: number | null
  dimension_w?: number | null
  natural_lighting?: boolean | null
  capacity_theater?: number | null
  capacity_cabaret?: number | null
  capacity_classroom?: number | null
  capacity_ushape?: number | null
  capacity_boardroom?: number | null
  capacity_banquet?: number | null
  capacity_cocktail?: number | null
  last_modified: string
  matterport_url?: string | null
  matterport_modified?: string | null
  vr_scene_quest?: string | null
  quest_scene_last_modified?: string | null
  vr_scene_ios?: string | null
  ios_scene_last_modified?: string | null
  vr_scene_android?: string | null
  android_scene_last_modified?: string | null
  guided_tour_json?: string | null
  guided_tour_wav?: string | null
}
export type Venue = {
  name: string
  brand?: string | null
  description?: string | null
  street_address?: string | null
  city?: string | null
  postal_code?: string | null
  state?: string | null
  country?: string | null
  latitude?: string | null
  longitude?: string | null
  website?: string | null
  member_of?: MemberOfEnum[] | null
  consortium_membership?: ConsortiumMembershipEnum[] | null
  sustainable_accreditation?: string | null
  healthcare_compliant?: (HealthcareCompliantEnum | BlankEnum | NullEnum) | null
  healthcare_accreditation?: string | null
  property_type?: (PropertyTypeEnum | BlankEnum | NullEnum) | null
  star_rating?: (StarRatingEnum | NullEnum) | null
  date_of_last_renovation?: number | null
  number_of_bedrooms?: (NumberOfBedroomsEnum | BlankEnum | NullEnum) | null
  number_of_meeting_rooms?:
    | (NumberOfMeetingRoomsEnum | BlankEnum | NullEnum)
    | null
  type_of_events_hosted?: TypeOfEventsHostedEnum[] | null
  total_meeting_space?: (TotalMeetingSpaceEnum | BlankEnum | NullEnum) | null
  size_of_largest_meeting_room?:
    | (SizeOfLargestMeetingRoomEnum | BlankEnum | NullEnum)
    | null
  maximum_capacity_of_largest_meeting_room?:
    | (MaximumCapacityOfLargestMeetingRoomEnum | BlankEnum | NullEnum)
    | null
  average_daily_rate?: ((AverageDailyRateEnum | NullEnum) | null)[] | null
  nearest_airports?: string | null
  distance_to_nearest_airport?:
    | (DistanceToNearestAirportEnum | BlankEnum | NullEnum)
    | null
  leisure_facilities?: LeisureFacilitiesEnum[] | null
  best_time_of_year_for_groups?: BestTimeOfYearForGroupsEnum[] | null
  maximum_capacity_of_largest_meeting_room_exact?: number | null
  id: number
  logo_link: string | null
  hero_image_link: string | null
  thumbnail_link: string | null
  photos?: VenuePhoto[]
  spaces?: VenueSpace[]
}
export type PaginatedVenueList = {
  count?: number
  next?: string | null
  previous?: string | null
  results?: Venue[]
}
export type Enquire = {
  subject: string
  message: string
}
export type CantAddVenue = {
  message: string
}
