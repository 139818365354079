import { useCallback, useEffect, useMemo, useReducer } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { useLogin_DEPRECATED_Mutation } from "services/oldUserApi"
import AuthPageContainer from "./AuthPageContainer"
import { FastSignUpGeneral } from "./signup/FastSignUpGeneral"
import { SignUpComplete } from "./signup/SignUpComplete"
import { SignUpVerified } from "./signup/SignUpVerified"
import { SignUpWelcome } from "./signup/SignUpWelcome"
import { EMAIL_VERIFICATION_DISABLED } from "./useAuthUser"

// TODO: refactoring - reuse common parts with SignUp component
// This is a quick implementation of fast sign up which made
// by copy-pasting SignUp component and removing what's not needed.

type ValidationError = Record<string, string[]>

export type FastSignUpData = {
  invite?: string
  first_name: string
  last_name: string
  email: string
  password: string

  step: "general" | "welcome" | "complete"
  validationError?: ValidationError
}

const stepFields: Record<FastSignUpData["step"], string[]> = {
  general: ["first_name", "last_name", "email", "password"],
  welcome: [],
  complete: [],
}

const initialState: FastSignUpData = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",

  step: "general",
}

type Action =
  | {
      type: "validationError"
      payload: ValidationError
    }
  | { type: "step"; payload: FastSignUpData["step"] }
  | { type: "bulkUpdate"; payload: Partial<FastSignUpData> }

function reducer(state: FastSignUpData, action: Action): FastSignUpData {
  const { type, payload } = action
  switch (type) {
    case "validationError":
      return {
        ...state,
        validationError: payload as ValidationError,
      }

    case "bulkUpdate":
      return { ...state, ...payload }

    case "step":
      return { ...state, step: payload }

    default:
      return state
  }
}

export interface FastSignUpWizardProps {
  wizardData: FastSignUpData
  setStep: (step: FastSignUpData["step"]) => void
  setValidationError: (error: unknown) => void
  bulkUpdate: (data: Partial<FastSignUpData>) => void
}

function FastSignUpWizard() {
  const [state, dispatch] = useReducer(reducer, initialState)

  const bulkUpdate = useCallback(
    (data: Partial<FastSignUpData>) => {
      dispatch({ type: "bulkUpdate", payload: data })
    },
    [dispatch]
  )

  const setStep = useCallback(
    (step: FastSignUpData["step"]) => {
      dispatch({
        type: "step",
        payload: step,
      })
    },
    [dispatch]
  )

  const setValidationError = useCallback(
    (_validationError: unknown) => {
      // Omit username as it's not used in the form but it's still returned from backend if email is not set
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { username, ...validationError } =
        _validationError as ValidationError
      console.log("validation error", validationError)
      dispatch({
        type: "validationError",
        payload: validationError as ValidationError,
      })

      const failedStep = (
        Object.keys(stepFields) as FastSignUpData["step"][]
      ).find((step) => {
        for (const field of Object.keys(
          validationError as Record<string, unknown>
        )) {
          if (stepFields[step].includes(field)) return true
        }
      })
      console.log("error", validationError, failedStep)
      if (failedStep) setStep(failedStep)
    },
    [dispatch, setStep]
  )

  const [login, { isLoading }] = useLogin_DEPRECATED_Mutation()
  const navigate = useNavigate()
  useEffect(() => {
    if (EMAIL_VERIFICATION_DISABLED && state.step === "welcome" && !isLoading) {
      login({ login: state.email, password: state.password })
        .unwrap()
        .then(() => navigate("/user-type"))
        .catch(() => {
          navigate("/signin")
        })
    }
  }, [state.step, state.email, state.password, login, navigate, isLoading])

  const stepProps: FastSignUpWizardProps = useMemo(() => {
    return {
      wizardData: state,
      setStep,
      setValidationError,
      bulkUpdate,
    }
  }, [state, setStep, setValidationError, bulkUpdate])

  return (
    <>
      {state.step === "general" && <FastSignUpGeneral {...stepProps} />}
      {state.step === "complete" && <SignUpComplete />}
      {state.step === "welcome" && !EMAIL_VERIFICATION_DISABLED && (
        <SignUpWelcome email={stepProps.wizardData.email} />
      )}
    </>
  )
}

export default function FastSignUp() {
  return (
    <AuthPageContainer>
      <Routes>
        <Route path="/verified/*" element={<SignUpVerified />} />
        <Route path="/*" element={<FastSignUpWizard />} />
      </Routes>
    </AuthPageContainer>
  )
}
