import { Box, Button, Divider, Typography } from "@mui/material"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import { VenueManager } from "api/seller"
import {
  useSellerVenueDraftManagerDestroyMutation,
  useSellerVenueDraftManagerListQuery,
  useSellerVenueManagerDestroyMutation,
  useSellerVenueManagerListQuery,
} from "api/sellerApi"
import { LoadingIndicator } from "app/LoadingIndicator"
import dayjs from "dayjs"
import { CustomButton, PlusButton } from "lib/components/CustomButton"
import {
  CustomDialog,
  CustomDialogContent,
  CustomDialogTitle,
} from "lib/components/Dialog"
import { ReactComponent as CancelIcon } from "lib/components/form/icons/close.svg"
import { useBoolean } from "lib/useBoolean"
import { useSnackbar } from "lib/useSnackbar"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { EmptyVenueManagers } from "./EmptyVenueManagers"
import { ReactComponent as TrashBigIcon } from "./icons/trash-big.svg"
import { VenueManagerDialog } from "./VenueManagerDialog"

export function SellerVenueManagers() {
  const { id } = useParams()
  const { showError } = useSnackbar()
  const [isAddManagerDialogOpened, setDialogState] = useState(false)
  const { data: venueManagers, isFetching: isVenueLoading } =
    useSellerVenueManagerListQuery(id ? { venuePk: id } : skipToken)
  const { data: draftManagers, isFetching: isDraftLoading } =
    useSellerVenueDraftManagerListQuery(id ? skipToken : undefined)

  const [removeManager, { isLoading: isManagerRemoving }] =
    useSellerVenueManagerDestroyMutation()
  const [removeDraftManager, { isLoading: isDraftManagerRemoving }] =
    useSellerVenueDraftManagerDestroyMutation()

  const navigate = useNavigate()
  useEffect(() => {
    if (!id) navigate("/seller/venues/new")
  }, [id, navigate])

  const managers = venueManagers || draftManagers
  const isLoading = isVenueLoading || isDraftLoading
  const isDeleting = isManagerRemoving || isDraftManagerRemoving

  // useLoader(
  //   (!isAddManagerDialogOpened && (isVenueLoading || isDraftLoading)) ||
  //     isManagerRemoving ||
  //     isDraftManagerRemoving
  // )

  const [managerToDelete, setManagerToDelete] = useState<VenueManager | null>(
    null
  )
  const [isDeleteConfirmOpen, openDeleteConfirm, closeDeleteConfirm] =
    useBoolean(false)

  const handleCloseDeleteConfirm = useCallback(() => {
    closeDeleteConfirm()
    setManagerToDelete(null)
  }, [closeDeleteConfirm])

  const handleRemoveManager = useCallback(
    (manager: VenueManager) => {
      ;(id
        ? removeManager({ venuePk: id, id: String(manager.id) })
        : removeDraftManager({ id: String(manager.id) })
      )
        .unwrap()
        .then(handleCloseDeleteConfirm)
        .then(() => setManagerToDelete(null))
        .catch(() => showError("Cannot remove venue manager"))
    },
    [handleCloseDeleteConfirm, id, removeDraftManager, removeManager, showError]
  )

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        height: "fit-content",
        flexGrow: 1,
      }}
    >
      {isLoading || !managers ? (
        <LoadingIndicator />
      ) : !managers.length ? (
        <EmptyVenueManagers openDialog={() => setDialogState(true)} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "custom.light600",
            borderRadius: 3,
            padding: 4,
            margin: 3,
            flexGrow: 1,
            position: "relative",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mt: -1 }}>
            <Typography variant="h4" sx={{ flexGrow: 0 }}>
              Venue managers
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <PlusButton
              onClick={() => setDialogState(true)}
              variant="contained"
              disabled={isLoading}
            >
              Add venue manager
            </PlusButton>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 3, mx: -2 }}>
            <Divider sx={{ borderBottom: "1px solid #6464BB10" }} />
            <Box sx={{ display: "flex", py: 2, px: 2, gap: 1 }}>
              <Typography
                variant="body14"
                color="custom.dark900"
                flex={2}
                sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >
                Name
              </Typography>
              <Typography
                variant="body14"
                color="custom.dark900"
                flex={1}
                sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >
                Email
              </Typography>
              <Typography
                variant="body14"
                color="custom.dark900"
                flex={1}
                sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >
                Role
              </Typography>
              <Typography
                variant="body14"
                color="custom.dark900"
                flex={1}
                sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >
                Date added
              </Typography>
              <Box sx={{ display: "flex", flex: "48px 0 0" }}></Box>
            </Box>
            <Divider sx={{ borderBottom: "1px solid #6464BB10" }} />
            {managers.map((manager) => {
              return (
                <Box key={manager.id}>
                  <Box
                    sx={{
                      display: "flex",
                      px: 2,
                      height: 80,
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body14"
                      color="custom.dark900"
                      flex={2}
                      sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {manager.invited ? (
                        <i>Pending registration</i>
                      ) : (
                        manager.user.first_name + " " + manager.user.last_name
                      )}
                    </Typography>
                    <Typography
                      variant="body14"
                      color="custom.dark900"
                      flex={1}
                      sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {manager.user.email}
                    </Typography>
                    <Typography
                      variant="body14"
                      color="custom.dark900"
                      flex={1}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        "&:first-letter": {
                          textTransform: "capitalize",
                        },
                      }}
                    >
                      {manager.role}
                    </Typography>
                    <Typography
                      variant="body14"
                      color="custom.dark900"
                      flex={1}
                      sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {dayjs(manager.added_at).format("MMMM D, YYYY")}
                    </Typography>

                    {manager.role == "owner" ? (
                      <Box width={48} />
                    ) : (
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: 48,
                          height: 48,
                          boxShadow: "0px 2px 3px rgba(17, 17, 128, 0.04)",
                          background: "white",
                          borderRadius: "8px",
                          cursor: "pointer",
                          minWidth: 48,
                        }}
                        disabled={isLoading}
                        onClick={() => {
                          setManagerToDelete(manager)
                          openDeleteConfirm()
                        }}
                      >
                        <CancelIcon height={20} width={20} />
                      </Button>
                    )}
                  </Box>
                  <Divider sx={{ borderBottom: "1px solid #6464BB10" }} />
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
      {isAddManagerDialogOpened && (
        <VenueManagerDialog
          venueId={id}
          onClose={() => setDialogState(false)}
        />
      )}
      {isDeleteConfirmOpen && managerToDelete && (
        <CustomDialog open onClose={handleCloseDeleteConfirm}>
          <CustomDialogTitle>Remove venue manager</CustomDialogTitle>
          <CustomDialogContent>
            <Box
              sx={{ margin: "60px 162px" }}
              maxWidth="400px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              gap="16px"
            >
              <TrashBigIcon />
              <Typography variant="h3" color="custom.dark900">
                Remove venue manager {managerToDelete.user.email}?
              </Typography>
              <Typography variant="body14" color="custom.dark700">
                This manager will not be able to edit current venue anymore.
                <br /> Are you sure you want to proceed?
              </Typography>
              <Box />
              <Box>
                <Box display="flex" gap="24px">
                  <CustomButton
                    variant="outlined"
                    disabled={isDeleting}
                    onClick={handleCloseDeleteConfirm}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    color="warning"
                    disabled={isDeleting}
                    onClick={() => {
                      handleRemoveManager(managerToDelete)
                    }}
                  >
                    Remove venue manager
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </CustomDialogContent>
        </CustomDialog>
      )}
    </Box>
  )
}
