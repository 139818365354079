import { yupResolver } from "@hookform/resolvers/yup"
import { Box } from "@mui/material"
import { CustomButton } from "lib/components/CustomButton"

import { VenueManager } from "api/seller"
import {
  useSellerVenueDraftManagerCreateMutation,
  useSellerVenueManagerCreateMutation,
} from "api/sellerApi"
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "lib/components/Dialog"
import { ControlledTextField } from "lib/components/form/ControlledTextField"
import { MSG_FIELD_REQUIRED } from "lib/messages"
import { useSnackbar } from "lib/useSnackbar"
import { FormProvider, useForm } from "react-hook-form"
import * as yup from "yup"

type VenueManagerDialogProps = {
  onClose: () => void
  venueId?: string
}

const schema = yup
  .object({
    email: yup.string().required(MSG_FIELD_REQUIRED),
  })
  .required()

export function VenueManagerDialog({
  onClose,
  venueId,
}: VenueManagerDialogProps) {
  const { showError, showSuccess } = useSnackbar()

  const formMethods = useForm<VenueManager>({
    defaultValues: { email: "" },
    mode: "onBlur",
    resolver: yupResolver(schema),
  })

  const [addManager, { isLoading: isManagerAdding }] =
    useSellerVenueManagerCreateMutation()
  const [addDraftManager, { isLoading: isDraftManagerAdding }] =
    useSellerVenueDraftManagerCreateMutation()

  const isLoading = isManagerAdding || isDraftManagerAdding

  // useLoader(isLoading)

  const onSubmit = (venueManager: VenueManager) => {
    ;(venueId
      ? addManager({ venuePk: venueId, venueManager })
      : addDraftManager({ venueManager })
    )
      .unwrap()
      .then(() => {
        showSuccess("Venue manager was successfully added")
        onClose()
      })
      .catch(() => {
        showError("Cannot add venue manager")
      })
  }

  return (
    <CustomDialog open disabled={isLoading} onClose={onClose}>
      <CustomDialogTitle>Add venue manager</CustomDialogTitle>
      <CustomDialogContent sx={{ width: "750px" }}>
        <FormProvider {...formMethods}>
          <Box display="flex" flexDirection="column" gap={3}>
            <ControlledTextField
              name="email"
              label="Email"
              disabled={isLoading}
            />
          </Box>
        </FormProvider>
      </CustomDialogContent>
      <CustomDialogActions>
        <Box display="flex" justifyContent="space-between" flexGrow={1}>
          <CustomButton
            variant="outlined"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </CustomButton>
          <CustomButton
            variant="contained"
            onClick={formMethods.handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            Add venue manager
          </CustomButton>
        </Box>
      </CustomDialogActions>
    </CustomDialog>
  )
}
