import { Link } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useTheme } from "lib/useTheme"
import { CustomButton } from "../../../lib/components/CustomButton"
import { ReactComponent as LockedIcon } from "./icons/locked.svg"

export default function SignInBlocked() {
  const theme = useTheme()

  return (
    <Box
      sx={{
        width: "500px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ pt: "140px" }}></Box>
      <LockedIcon width="86px" />
      <Box sx={{ pt: 1 }}></Box>
      <Typography
        component="h2"
        variant="h2"
        color={theme.palette.custom.dark900}
      >
        Your account has been blocked
      </Typography>
      <Box sx={{ pt: 2 }}></Box>
      <Typography
        variant="body14"
        color={theme.palette.custom.dark700}
        sx={{ fontWeight: 600 }}
      >
        More information has been provided to your email. Contact us if you
        disagree with administrator decision
      </Typography>
      <Box sx={{ pt: 4 }}></Box>
      <Box
        sx={{
          flexGrow: 1,
          px: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ pt: 2 }}></Box>
        <Link
          href="/contact-administrator/"
          variant="body2"
          sx={{ textDecoration: "none" }}
        >
          <CustomButton type="submit" fullWidth variant="contained">
            Contact administrator
          </CustomButton>
        </Link>
      </Box>
    </Box>
  )
}
