import { CircularProgress } from "@mui/material"
import Box from "@mui/material/Box"

export function LoadingIndicatorFullWindow() {
  return null
  return (
    <Box
      display="flex"
      sx={{
        backgroundColor: "custom.light500",
        zIndex: 1400,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0.2,
      }}
    >
      <CircularProgress
        sx={{
          margin: "auto",
          color: "custom.accentsecondary",
        }}
      />
    </Box>
  )
}

export function LoadingIndicator() {
  return (
    <Box
      display="flex"
      sx={{
        backgroundColor: "custom.light500",
        flex: "1 1 0",
        width: "100%",
        heigt: "100%",
      }}
    >
      <CircularProgress
        sx={{
          margin: "auto",
          color: "custom.accentsecondary",
        }}
      />
    </Box>
  )
}
