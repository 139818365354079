import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import {
  useSellerMeetingDestroyMutation,
  useSellerMeetingRetrieveQuery,
} from "api/sellerApi"
import { LoadingIndicator } from "app/LoadingIndicator"
import { ReactComponent as TrashBigIcon } from "features/seller/icons/trash-big.svg"
import { CustomButton } from "lib/components/CustomButton"
import {
  CustomDialog,
  CustomDialogContent,
  CustomDialogTitle,
} from "lib/components/Dialog"
import { useSnackbar } from "lib/useSnackbar"
import { useNavigate, useParams } from "react-router-dom"

export function MeetingDeleteDialog() {
  const { deleteId: id } = useParams()
  const { data: meeting, isFetching } = useSellerMeetingRetrieveQuery(
    id ? { id: +id } : skipToken
  )

  const [deleteMeetingMutation, { isLoading: isDeleting }] =
    useSellerMeetingDestroyMutation()
  const { showSuccess, showError } = useSnackbar()

  const navigate = useNavigate()

  const close = () => navigate("/meetings")

  const deleteMeeting = () => {
    if (!meeting) return

    deleteMeetingMutation({
      id: meeting.id,
    })
      .unwrap()
      .then(close)
      .then(() => showSuccess("Meeting has been deleted"))
      .catch(() => showError("Error deleting meeting"))
  }

  const disabled = isDeleting

  return (
    <CustomDialog open onClose={close}>
      <CustomDialogTitle>Delete meeting</CustomDialogTitle>
      <CustomDialogContent>
        <Box
          sx={{ margin: "60px 162px" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          gap="16px"
        >
          {isFetching || !meeting ? (
            <LoadingIndicator />
          ) : (
            <>
              <TrashBigIcon />
              <Typography variant="h3" color="custom.dark900">
                Are you sure you want to delete meeting
                <br /> {meeting.meeting_code}?
              </Typography>
              <Box />
              <Box>
                <Box display="flex" gap="24px">
                  <CustomButton
                    variant="outlined"
                    disabled={disabled}
                    onClick={close}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    color="warning"
                    disabled={disabled}
                    onClick={deleteMeeting}
                  >
                    Delete meeting
                  </CustomButton>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </CustomDialogContent>
    </CustomDialog>
  )
}
