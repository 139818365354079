import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { TopBar } from "app/TopBar"
import { CustomButton } from "lib/components/CustomButton"
import CustomTextFieldElement from "lib/components/form/CustomTextFieldElement"
import { CustomTextField } from "lib/components/FormField"
import { useAuthorizedUserOrRedirect } from "lib/useAuthorizedUserOrRedirect"
import { useServerFormValidation } from "lib/useServerFormValidation"
import { useSnackbar } from "lib/useSnackbar"
import { useEffect } from "react"
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useUpdateProfile_DEPRECATED_Mutation } from "services/oldUserApi"
import { ProfileRPMAvatar } from "./ProfileRPMAvatar"

type EditProfileValues = {
  first_name: string
  last_name: string
}

function Profile() {
  const { user: profile } = useAuthorizedUserOrRedirect()
  const { showSuccess } = useSnackbar()

  const navigate = useNavigate()
  useEffect(() => {
    if (profile && profile.rpm_avatar_url) {
      const activateDevice = window.localStorage.getItem("activateDevice")
      console.log({ activateDevice })
      window.localStorage.removeItem("activateDevice")
      if (activateDevice) {
        navigate("/activate")
      }
    }
  }, [navigate, profile, profile?.rpm_avatar_url])

  const formMethods = useForm<EditProfileValues>({
    defaultValues: {
      first_name: profile?.first_name,
      last_name: profile?.last_name,
    },
  })

  const [updateProfile, { error, isLoading }] =
    useUpdateProfile_DEPRECATED_Mutation()
  const onSubmit: SubmitHandler<EditProfileValues> = (data) => {
    updateProfile(data)
      .unwrap()
      .then(() =>
        showSuccess(
          "Your profile has been updated. Please open the Xplore app to continue."
        )
      )
      .then(() => formMethods.reset(data))
      .catch()
  }
  const disabled = isLoading

  useServerFormValidation(error, formMethods.setError)

  if (!profile) return <></>

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        backgroundColor: "custom.light700",
      }}
    >
      <TopBar title="RendezVerse Profile" />

      <Box
        sx={{
          backgroundColor: "custom.light600",
          marginTop: "24px",
          marginLeft: "24px",
          marginRight: "24px",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          height: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <FormProvider {...formMethods}>
          <Box
            component="form"
            noValidate
            onSubmit={formMethods.handleSubmit(onSubmit)}
            sx={{
              mt: "32px",
              width: "330px",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="h3" color="dark900">
              Personal Information
            </Typography>

            <ProfileRPMAvatar />

            <CustomTextFieldElement
              autoComplete="first_name"
              name="first_name"
              fullWidth
              id="first_name"
              label="First name"
              disabled={disabled}
            />
            <CustomTextFieldElement
              autoComplete="last_name"
              name="last_name"
              fullWidth
              id="last_name"
              label="Last name"
              disabled={disabled}
            />
            <CustomTextField
              autoComplete="email"
              name="email"
              fullWidth
              id="email"
              label="Email"
              value={profile.email}
              disabled
            />

            <CustomButton variant="contained" type="submit" disabled={disabled}>
              Save
            </CustomButton>

            {/* <Typography variant="subtitle2">
              <Link to="/change-password/">Change password</Link>
            </Typography> */}
          </Box>
        </FormProvider>
      </Box>
    </Box>
  )
}

export default Profile
