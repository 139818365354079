import { Visibility, VisibilityOff } from "@mui/icons-material"
import { IconButton, InputAdornment, TextFieldProps } from "@mui/material"
import { useState } from "react"
import { CustomTextField } from "./CustomTextField"

export function CustomPasswordField(props: TextFieldProps) {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)

  return (
    <CustomTextField
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toogle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={(event) => event.preventDefault()}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
