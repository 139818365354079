import { ContextMenuButton } from "lib/components/ContextMenuButton"
import { CustomMenu, CustomMenuItem } from "lib/components/Menu"
import { useState } from "react"

export type MenuItem = {
  label: string
  onClick: () => void
}

export function ContextMenu({ items }: { items: MenuItem[] }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ContextMenuButton handleClick={handleClick} />
      {open && (
        <CustomMenu anchorEl={anchorEl} open onClose={handleClose}>
          {items.map(({ onClick, label }, index) => (
            <CustomMenuItem
              key={index}
              onClick={() => {
                handleClose()
                onClick()
              }}
            >
              {label}
            </CustomMenuItem>
          ))}
        </CustomMenu>
      )}
    </>
  )
}
