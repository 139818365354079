import { useState } from "react"
import { FileDragDropCard } from "./FileDragDropCard"
import { ImageCard } from "./ImageCard"
import { ImageErrorCard } from "./ImageErrorCard"
import { ImageProcessingCard } from "./ImageProcessingCard"

export type UploadImageProps = {
  width: string | number
  height: string | number
  name: string
  description: string
  imageUrl: string | null
  uploadImage: (file: File) => void
  removeImage: () => void
  cleanError: () => void
  disabled: boolean
  isLoading: boolean
  isError: boolean
}

const acceptedFiles = ["image/jpeg", "image/jpg", "image/png"]

export function UploadImage(props: UploadImageProps) {
  const [file, setFile] = useState<File | null>(null)

  const handleFiles = (files: File[]) => {
    if (files[0] && acceptedFiles.includes(files[0].type)) {
      setFile(files[0])
      props.uploadImage(files[0])
    }
  }

  const retry = () => {
    if (file) {
      props.cleanError()
      props.uploadImage(file)
    }
  }

  return props.isError ? (
    <ImageErrorCard
      width={props.width}
      height={props.height}
      retry={retry}
      cleanError={props.cleanError}
    />
  ) : props.isLoading ? (
    <ImageProcessingCard
      width={props.width}
      height={props.height}
      processDescription={props.imageUrl ? "Removing..." : "Uploading..."}
    />
  ) : props.imageUrl ? (
    <ImageCard
      width={props.width}
      height={props.height}
      imageUrl={props.imageUrl}
      removeImage={props.removeImage}
    />
  ) : (
    <FileDragDropCard
      width={props.width}
      height={props.height}
      description={props.description}
      name={props.name}
      handleFiles={handleFiles}
    />
  )
}
