import { TextField, TextFieldProps, Theme } from "@mui/material"
import { styled } from "@mui/material/styles"

export type CustomTextFieldProps = TextFieldProps & {
  join?: "left"
}

export const InputLabelSx = (theme: Theme) => ({
  "& .MuiInputLabel-root": {
    transform: "translate(15px, 20px) scale(1)",
    ...theme.typography.body13,
    color: theme.palette.custom.dark600,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: theme.palette.custom.dark500,
    transform: "none",
    paddingLeft: "12px",
    paddingTop: "12px",
    ...theme.typography.body12regular,
  },
  "& .MuiInputLabel-root.MuiFormLabel-filled": {
    color: theme.palette.custom.dark500,
    transform: "none",
    paddingLeft: "12px",
    paddingTop: "12px",
    ...theme.typography.body12regular,
  },
})

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  "& .MuiFilledInput-root": {
    backgroundColor: theme.palette.custom.light500,
    border: "1px solid",
    borderColor: "#E7E7EC",
    borderRadius: "12px",
    overflow: "hidden",
  },
  "& .MuiFilledInput-root.Mui-disabled": {
    backgroundColor: theme.palette.custom.light500,
  },
  "& .MuiFilledInput-root.Mui-error": {
    borderColor: theme.palette.custom.error,
  },
  "& .MuiFilledInput-input": {
    backgroundColor: theme.palette.custom.light500,
    ...theme.typography.body13,
  },
  "& .MuiFilledInput-root:hover": {
    borderColor: "#E5E5EA",
    backgroundColor: theme.palette.custom.light500,
  },
  "& .MuiFilledInput-root.Mui-disabled:hover": {
    borderColor: "#E4E4EF",
  },
  "& .MuiFilledInput-root.Mui-focused": {
    borderColor: theme.palette.custom.dark500,
    backgroundColor: theme.palette.custom.light500,
  },
  ...InputLabelSx(theme),
}))

export function CustomTextFieldNoLabel(props: CustomTextFieldProps) {
  return (
    <StyledTextField
      {...props}
      sx={{
        ...props.sx,
        ...(props.join === "left"
          ? {
              "& .MuiFilledInput-root": {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              },
            }
          : {}),
        "& .MuiFormHelperText-root:first-letter": {
          textTransform: "capitalize",
        },
      }}
      variant="filled"
      InputProps={{
        disableUnderline: true,
        ...props.InputProps,
      }}
    />
  )
}

export function CustomTextField(props: CustomTextFieldProps) {
  return (
    <CustomTextFieldNoLabel
      {...props}
      InputLabelProps={{
        sx: {
          ...(props.join === "left"
            ? {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }
            : {}),
        },
      }}
    />
  )
}

export function SearchTextField(props: CustomTextFieldProps) {
  return <CustomTextField type="search" {...props} />
}
