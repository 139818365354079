import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { CustomButton } from "lib/components/CustomButton"
import { useTheme } from "lib/useTheme"
import { useResendVerify_DEPRECATED_Mutation } from "services/oldUserApi"
import { ReactComponent as NotVerifiedIcon } from "./icons/email-sent.svg"

interface SignUpWelcomeProps {
  email: string
}

export function SignUpWelcome(props: SignUpWelcomeProps) {
  const theme = useTheme()

  const email = props.email
  const [resendVerify, { isLoading }] = useResendVerify_DEPRECATED_Mutation()
  const resendRequest = () => resendVerify({ email })
  const resendVerifyDisabled = isLoading

  return (
    <Box
      mt="215px"
      width="506px"
      display="flex"
      flexDirection="column"
      textAlign="center"
      alignItems="center"
      gap={2}
    >
      <NotVerifiedIcon width="86px" />

      <Typography variant="h2" color={theme.palette.custom.dark900}>
        Verify your email address
      </Typography>

      <Box>
        <Typography
          variant="body14"
          color={theme.palette.custom.dark700}
          letterSpacing="normal"
          fontWeight={500}
        >
          We have sent an email to{" "}
          <Typography
            display="inline"
            variant="inherit"
            color={theme.palette.custom.accentprimary}
          >
            {props.email}
          </Typography>{" "}
          to verify your email address.
          <br />
          <br />
          If you haven’t received our email, please check your{" "}
          <Typography
            display="inline"
            variant="inherit"
            color="black"
            fontWeight={900}
          >
            spam
          </Typography>{" "}
          and{" "}
          <Typography
            display="inline"
            variant="inherit"
            color="black"
            fontWeight={900}
          >
            promotions
          </Typography>{" "}
          folders and make sure that emails coming from RendezVerse domain are
          on your safe senders list.
          <br />
          <br />
          You can also request a new verification email below.
          <br />
          <br />
        </Typography>
      </Box>

      <Box mt={2} display="flex" gap={2}>
        {/* <CustomButton type="button" variant="outlined">
          Change email address
        </CustomButton> */}

        <CustomButton
          type="button"
          onClick={resendRequest}
          disabled={resendVerifyDisabled}
          variant="outlined"
        >
          Resend email
        </CustomButton>
      </Box>
    </Box>
  )
}
