import { useAuthUser } from "features/auth/useAuthUser"
import { useEffect } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

/**
 * Utility hook to return current user or redirect
 */
export function useAuthorizedUserOrRedirect(): ReturnType<typeof useAuthUser> {
  const query = useAuthUser()
  const { user, isFetching, isSuccess, isError, error } = query

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (
      (!isFetching && !user) ||
      (isError && error && "status" in error && error.status === 403)
    ) {
      let locationUrl = location.pathname
      if (locationUrl === "/activate" || locationUrl === "/activate/") {
        window.localStorage.setItem("activateDevice", "true")
      }
      if (location.search) locationUrl += location.search
      const redirect = encodeURIComponent(locationUrl)
      navigate(`/signin/?redirect=${redirect}`)
    }
  }, [
    location.pathname,
    location.search,
    navigate,
    user,
    error,
    isFetching,
    isError,
  ])

  return query
}
