import { Dialog, DialogContent } from "@mui/material"
import { useAuthUser } from "features/auth/useAuthUser"
import { useServerFormValidation } from "lib/useServerFormValidation"
import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useUpdateRPMUrl_DEPRECATED_Mutation } from "../../services/oldUserApi"

const READYPLAYERME_DOMAIN = process.env.REACT_APP_READYPLAYERME_DOMAIN

type EditUrl = {
  rpm_avatar_url: string
}

function parseJson(json: string) {
  try {
    return JSON.parse(json)
  } catch (e) {
    return null
  }
}

function avatarSubscribe(setAvatarUrl: (url: string) => void) {
  function subscribe(event: MessageEvent) {
    const evtData = parseJson(event.data)
    if (evtData?.source !== "readyplayerme") {
      return
    }
    const rpmFrame = document.getElementById("rpmFrame")
    if (rpmFrame == null) {
      return
    }
    if (evtData.eventName === "v1.frame.ready") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rpmFrame.contentWindow.postMessage(
        JSON.stringify({
          target: "readyplayerme",
          type: "subscribe",
          eventName: "v1.**",
        }),
        "*"
      )
    }
    if (evtData.eventName === "v1.avatar.exported") {
      setAvatarUrl(evtData.data.url)
    }
  }
  return subscribe
}

export default function UpdateAvatarUrl({
  handleClose,
}: {
  handleClose: () => void
}) {
  const [updateAvatar, { error, isLoading }] =
    useUpdateRPMUrl_DEPRECATED_Mutation()
  const { user: profile } = useAuthUser()

  const formMethods = useForm<EditUrl>({
    defaultValues: {
      rpm_avatar_url: profile?.rpm_avatar_url,
    },
  })

  const onSubmit: SubmitHandler<EditUrl> = (data) => {
    updateAvatar(data.rpm_avatar_url).unwrap().then(handleClose)
  }

  const [connectDisabled, disableConnect] = useState(true)
  useServerFormValidation(error, formMethods.setError)

  window.addEventListener(
    "message",
    avatarSubscribe((v: string) => {
      // formMethods.setValue("rpm_avatar_url", v)
      updateAvatar(v).unwrap().then(handleClose)
      disableConnect(false)
    })
  )

  return (
    <Dialog
      open={true}
      maxWidth="lg"
      PaperProps={{
        sx: { borderRadius: "24px", backgroundColor: "custom.light700" },
      }}
      onClose={handleClose}
    >
      {/* <DialogTitle sx={{ padding: 0, backgroundColor: "custom.light700" }}>
        <Typography
          variant="h2"
          color="custom.accentsecondary"
          sx={{
            padding: "20px 0 22px 24px",
          }}
        >
          Player Me Avatar
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "20px",
            right: "24px",
            color: "custom.dark600",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle> */}

      <DialogContent
        className="center-block"
        sx={{
          padding: 0,
          // borderTopRightRadius: "24px",
          // borderTopLeftRadius: "24px",
          // backgroundColor: "white",
          overflow: "hidden",
          height: 800,
        }}
      >
        <iframe
          id="rpmFrame"
          src={`https://${READYPLAYERME_DOMAIN}/avatar?frameApi&clearCache=true`}
          style={{
            width: 1080,
            height: 800,
            padding: 0,
            fontSize: "14px",
            border: "none",
          }}
          allow="camera *; microphone *; clipboard-write"
        />
      </DialogContent>

      {/* <DialogActions
        sx={{ backgroundColor: "white", padding: "20px", paddingTop: 0 }}
      >
        <FormProvider {...formMethods}>
          <Box
            component="form"
            onSubmit={formMethods.handleSubmit(onSubmit)}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              flexGrow: 1,
            }}
          >
            <CustomTextFieldElement
              label="Avatar URL"
              id="rpm_avatar_url"
              name="rpm_avatar_url"
              sx={{ flexGrow: 1 }}
            />
            <CustomButton
              type="submit"
              variant="contained"
              disabled={connectDisabled || isLoading}
            >
              Connect
            </CustomButton>
          </Box>
        </FormProvider>
      </DialogActions> */}
    </Dialog>
  )
}
