// In this file we manipulate with RTK cache invalidation management
// documentation is here: https://redux-toolkit.js.org/rtk-query/api/created-api/code-splitting#enhanceendpoints

import { venuesGeneratedApi } from "./venues"

const sellerApi = venuesGeneratedApi.enhanceEndpoints({})

export const {
  useVenuesRetrieveQuery,
  useVenuesEnquireCreateMutation,
  useVenuesSendCreateMutation,
  useVenuesListQuery,
} = sellerApi
