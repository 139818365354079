import { BuyerProfileCreated } from "features/auth/signup/BuyerProfileCreated"
import ActivateDevice from "features/device/ActivateDevice"
import Calendar from "features/events/Calendar"
import Profile from "features/profile/Profile"
import { MeetingEdit } from "features/seller/MeetingEdit"
import { SellerMeetings } from "features/seller/SellerMeetings"
import { SellerRoutes } from "features/seller/SellerRoutes"
import { SellerRoutesContainer } from "features/seller/SellerRoutesContainer"
import { Route, Routes } from "react-router-dom"
import "./App.css"
import { Home } from "./Home"
import { UserPageContainer } from "./UserPageContainer"

function App() {
  return (
    <UserPageContainer>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/venues/*" element={<Venues />} /> */}
        <Route element={<SellerRoutesContainer />}>
          <Route path="/seller/*" element={<SellerRoutes />} />
          <Route path="/meetings">
            <Route index element={<SellerMeetings />} />
            <Route path="*" element={<SellerMeetings />} />
            <Route path="new" element={<MeetingEdit />} />
            <Route path=":id" element={<MeetingEdit />} />
          </Route>
        </Route>
        <Route path="/profile-created" element={<BuyerProfileCreated />} />
        <Route path="/profile/" element={<Profile />} />
        {/* <Route path="/change-password/" element={<ChangePassword />} /> */}
        <Route path="/activate" element={<ActivateDevice />} />
        <Route path="/calendar/" element={<Calendar />} />
      </Routes>
    </UserPageContainer>
  )
}

export default App
