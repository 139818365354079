import { useState } from "react"

/**
 * Utility hook for boolean state with set/reset functions
 * @param boolean default value
 * @returns [value, set, reset]
 */
export function useBoolean(initialValue?: boolean) {
  const [value, setValue] = useState(!!initialValue)
  return [value, () => setValue(true), () => setValue(false)] as const
}
