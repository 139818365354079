import { SelectSearchItem } from "lib/components/FormField"
import { useMemo } from "react"

export const selectItemsFromList = (
  list: readonly string[] | readonly number[]
): SelectSearchItem[] =>
  list.map((s) => ({ value: String(s), label: String(s) }))

export const useSelectItems = (list: readonly string[]) =>
  useMemo(() => selectItemsFromList(list), [list])
