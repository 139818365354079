import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  useTheme,
} from "@mui/material"
import { useBoolean } from "lib/useBoolean"
import { Controller } from "react-hook-form"
import { CustomMenuItem } from "../Menu"
import { CustomTextFieldNoLabel, InputLabelSx } from "./CustomTextField"
import { CustomChip, MultiSelectFieldProps } from "./MultiSelectField"

export type ControlledSelectFieldProps = Omit<
  MultiSelectFieldProps,
  "name" | "onDeleteChange" | "items"
> & {
  name: string
  options: string[]
  multiple?: boolean
}

export function SearchableSelectField(props: ControlledSelectFieldProps) {
  const theme = useTheme()

  const [isOpen, open, close] = useBoolean(false)
  const [isFocused, focus, leave] = useBoolean(false)

  return (
    <Controller
      name={props.name}
      render={({ field: { value, onChange } }) => (
        <FormControl variant={props.variant} sx={{ ...InputLabelSx(theme) }}>
          <InputLabel
            sx={{ color: theme.palette.custom.dark600 }}
            filled={!!value && value.length > 0} // AG: set filled state
            focused={isFocused} // AG: set focused state
          >
            {props.label}
          </InputLabel>
          <Autocomplete
            multiple={props.multiple}
            disablePortal
            sx={{
              "& + .MuiAutocomplete-popperDisablePortal": {
                borderRadius: "12px",
              },
              "& + .MuiAutocomplete-popperDisablePortal .MuiPaper-root": {
                borderRadius: "12px",
              },
              "& + .MuiAutocomplete-popperDisablePortal .MuiAutocomplete-listbox":
                {
                  py: 0,
                  borderRadius: "12px",
                },
              "& + .MuiAutocomplete-popperDisablePortal .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-selected":
                {
                  backgroundColor: theme.palette.custom.accentprimary,
                  color: theme.palette.custom.light500,
                },
              "& + .MuiAutocomplete-popperDisablePortal .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused":
                {
                  backgroundColor: theme.palette.custom.accentlight,
                  color: theme.palette.custom.accentpressed,
                },
              "& + .MuiAutocomplete-popperDisablePortal .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focusVisible":
                {
                  backgroundColor: theme.palette.custom.accentlight,
                  color: theme.palette.custom.accentpressed,
                },
              "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                width: "100%",
                ...(props.multiple && !!value && value.length > 0 && !isFocused // AG: "hide" input for filled multiselect component
                  ? {
                      height: "0px",
                      padding: 0,
                    }
                  : {}),
              },
              "& .MuiAutocomplete-inputRoot": {
                pl: "12px",
              },
            }}
            value={value}
            open={isOpen}
            onOpen={open}
            onClose={close}
            onChange={(_, values) => {
              onChange(values)
            }}
            renderInput={(params) => (
              <CustomTextFieldNoLabel
                {...params}
                fullWidth
                onFocus={focus}
                onBlur={leave}
              />
            )}
            renderOption={(_props, option, state) => (
              <CustomMenuItem
                {..._props}
                onClick={() => {
                  onChange(
                    props.multiple
                      ? state.selected
                        ? value.filter((item: string) => item != option)
                        : value.concat(option)
                      : option
                  )
                  !props.multiple && close()
                }}
                key={option}
                value={option}
                selected={state.selected}
              >
                {option}
              </CustomMenuItem>
            )}
            renderTags={(values: string[]) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "4px",
                  pt: "16px",
                  pb: "8px",
                }}
              >
                {values.map((value, idx) => (
                  <CustomChip
                    key={idx}
                    label={value}
                    onDelete={() => {
                      onChange(values.filter((item) => item != value))
                    }}
                  />
                ))}
              </Box>
            )}
            options={props.options || []}
          />
        </FormControl>
      )}
    />
  )
}
