import { useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import { StarRatingEnum } from "api/venues"
import { SelectSearchItem } from "lib/components/FormField"
import { ReactElement, useMemo } from "react"
import { ReactComponent as StarIcon } from "./icons/star.svg"

export function StarsView({
  rating,
}: {
  rating: StarRatingEnum | undefined
}): ReactElement {
  const stars = useMemo(() => {
    const rv = []
    if (rating === undefined) return null
    for (let i = 0; i < rating; ++i) {
      rv.push(
        <Box key={i} sx={{ pr: 0.5, display: "flex" }}>
          <StarIcon />
        </Box>
      )
    }
    return rv
  }, [rating])

  return (
    <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
      {stars}
    </Box>
  )
}

const StarSearchItem = ({ rating }: { rating: StarRatingEnum }) => {
  const theme = useTheme()
  return (
    <Box
      color={theme.palette.custom.dark900}
      sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}
    >
      <StarsView rating={rating} />
    </Box>
  )
}
export const STAR_CHOICES: SelectSearchItem[] = [
  { value: "", label: "I'm flexible" },
  { value: "3", label: <StarSearchItem rating={3} /> },
  { value: "4", label: <StarSearchItem rating={4} /> },
  { value: "5", label: <StarSearchItem rating={5} /> },
]
