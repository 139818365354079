import Box from "@mui/material/Box"
import { ReactComponent as Logo } from "./icons/logo-icon.svg"
import { ReactComponent as CompanyLogo } from "./icons/logo-name.svg"

export function LogoIcon({ size }: { size: number }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Logo width={`${size}px`} height={`${size}px`} />
    </Box>
  )
}

export function LogoName({ height }: { height: number }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CompanyLogo height={`${height}px`} />
    </Box>
  )
}

export function LogoName2() {
  return (
    <Box display="flex" justifyContent="center">
      <CompanyLogo />
    </Box>
  )
}
