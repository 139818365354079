import { Box } from "@mui/material"
import { ReactComponent as CancelIcon } from "lib/components/form/icons/close.svg"
import { ImageSizeProps } from "./ImageProcessingCard"

export type IconSize = {
  iconSize?: number
}

export type ImageCardProps = ImageSizeProps &
  IconSize & {
    imageUrl: string
    removeImage: () => void
  }

const acceptedFiles = ["image/jpeg", "image/jpg", "image/png"]

export function ImageCard(props: ImageCardProps) {
  return (
    <Box
      sx={{
        height: props.height,
        width: props.width,
        display: "flex",
        borderRadius: 2,
        position: "relative",
        backgroundImage: `url(${props.imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "custom.light500",
          borderRadius: 2,
          boxShadow: "0px 2px 3px rgba(17, 17, 128, 0.04)",
          right: 8,
          top: 8,
          width: props.iconSize || 32,
          height: props.iconSize || 32,
          position: "absolute",
          cursor: "pointer",
        }}
        onClick={props.removeImage}
      >
        <CancelIcon />
      </Box>
    </Box>
  )
}
