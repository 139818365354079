import { SellerVenueSpace } from "api/seller"
import { ContextMenu, MenuItem } from "lib/components/ContextMenu"
import { useNavigate } from "react-router-dom"

export function VenueSpaceContextMenu({
  space,
  venueId,
}: {
  space: SellerVenueSpace
  venueId?: number
}) {
  const navigate = useNavigate()

  const onDelete = () => {
    navigate(`/seller/venues/${venueId || "new"}/spaces/${space.id}/delete`)
  }

  const onEdit = () => {
    navigate(`/seller/venues/${venueId || "new"}/spaces/${space.id}`)
  }

  const items: MenuItem[] = [
    { label: "Edit", onClick: onEdit },
    { label: "Delete", onClick: onDelete },
  ]

  return <ContextMenu items={items} />
}
