import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { generatedApi } from "api/api"
import appState from "lib/slices/appState"
import { api } from "services/api"

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [generatedApi.reducerPath]: generatedApi.reducer,
    appState: appState,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(generatedApi.middleware),
})

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
