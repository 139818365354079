import { Box, ListItemButton, Typography } from "@mui/material"
import { NavLink, useLocation } from "react-router-dom"
import { LeftMenuItem } from "./LeftMenuItem"

export function NavigationButton({
  label,
  icon,
  link,
  isActive,
}: LeftMenuItem) {
  const location = useLocation()

  return (
    <ListItemButton
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: 102,
        minWidth: 102,
        justifyContent: "center",
        p: 0,
        "&.MuiListItemButton-root": {
          borderRadius: "20px",
          color: (theme) => theme.palette.custom.dark700,
          // stroke: (theme) => theme.palette.custom.dark700,
        },
        "&.Mui-selected": {
          color: (theme) => theme.palette.custom.accentprimary,
          backgroundColor: (theme) => theme.palette.custom.accentlight,
        },
        "&.Mui-selected path": {
          fill: (theme) => theme.palette.custom.accentprimary,
        },
      }}
      component={link ? NavLink : "span"}
      selected={location.pathname == link || (isActive && isActive(location))}
      to={link}
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
        <Box
          sx={{
            minWidth: 0,
            "&.MuiListItemIcon-root": {
              color: "inherit",
            },
          }}
        >
          {icon}
        </Box>
        <Typography variant="body13">{label}</Typography>
      </Box>
    </ListItemButton>
  )
}
