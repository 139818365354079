import { yupResolver } from "@hookform/resolvers/yup"
import { Box, CircularProgress, Typography } from "@mui/material"
import { Enquire } from "api/venues"
import { useVenuesEnquireCreateMutation } from "api/venuesApi"
import { CustomButton } from "lib/components/CustomButton"

import { ReactComponent as EmailSentIcon } from "app/icons/email-sent.svg"
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "lib/components/Dialog"
import { ControlledTextField } from "lib/components/form/ControlledTextField"
import { MSG_FIELD_REQUIRED } from "lib/messages"
import {
  getFormValidationErrorFromRTK,
  useServerFormValidation,
} from "lib/useServerFormValidation"
import { useSnackbar } from "lib/useSnackbar"
import { FormProvider, useForm } from "react-hook-form"
import * as yup from "yup"

type EnquireDialogProps = {
  onClose: () => void
  venueId: number
}

const schema = yup
  .object({
    subject: yup.string().required(MSG_FIELD_REQUIRED),
    message: yup.string().required(MSG_FIELD_REQUIRED),
  })
  .required()

export function EnquireDialog({ onClose, venueId }: EnquireDialogProps) {
  const { showError } = useSnackbar()

  const formMethods = useForm<Enquire>({
    defaultValues: { subject: "", message: "" },
    mode: "onBlur",
    resolver: yupResolver(schema),
  })

  const [sendEnquire, { isLoading, isSuccess, error }] =
    useVenuesEnquireCreateMutation()

  useServerFormValidation(error, formMethods.setError)

  const onSubmit = (data: Enquire) => {
    sendEnquire({ venueId, enquire: data })
      .unwrap()
      .catch((error) => {
        const validationError = getFormValidationErrorFromRTK(error)
        if (!validationError) showError("Error: Cannot send Enquiry")
      })
  }

  return (
    <CustomDialog open disabled={isLoading} onClose={onClose}>
      {isLoading && (
        <CircularProgress
          sx={{
            position: "absolute",
            zIndex: 100,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            color: "custom.accentsecondary",
          }}
        />
      )}

      <CustomDialogTitle>Enquire</CustomDialogTitle>
      <CustomDialogContent sx={{ width: "750px" }}>
        {!isSuccess ? (
          <FormProvider {...formMethods}>
            <Box display="flex" flexDirection="column" gap={3}>
              <ControlledTextField
                name="subject"
                label="Subject"
                disabled={isLoading}
              />
              <ControlledTextField
                name="message"
                label="Message"
                multiline
                minRows={4}
                disabled={isLoading}
              />
            </Box>
          </FormProvider>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              my: "91px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <EmailSentIcon />
            <Typography variant="h2">Your enquire has been sent!</Typography>
            <CustomButton variant="contained" onClick={onClose} sx={{ mt: 2 }}>
              Got it!
            </CustomButton>
          </Box>
        )}
      </CustomDialogContent>
      {!isSuccess && (
        <CustomDialogActions>
          <Box display="flex" justifyContent="space-between" flexGrow={1}>
            <CustomButton
              variant="outlined"
              onClick={onClose}
              disabled={isLoading}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={formMethods.handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              Send
            </CustomButton>
          </Box>
        </CustomDialogActions>
      )}
    </CustomDialog>
  )
}
