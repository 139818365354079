import { Meeting } from "api/seller"
import { ContextMenu, MenuItem } from "lib/components/ContextMenu"
import { useNavigate } from "react-router-dom"

export function MeetingsContextMenu({ meeting }: { meeting: Meeting }) {
  const navigate = useNavigate()

  const onDelete = () => {
    navigate(`/meetings/${meeting.id}/delete`)
  }

  const onEdit = () => {
    navigate(`/meetings/${meeting.id}`)
  }

  const items: MenuItem[] = [
    { label: "Edit", onClick: onEdit },
    { label: "Delete", onClick: onDelete },
  ]

  return <ContextMenu items={items} />
}
