import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAuthorizedUserOrRedirect } from "./useAuthorizedUserOrRedirect"

export function useOnlyForBuyerOrOther() {
  const { user, isError, isSuccess } = useAuthorizedUserOrRedirect()
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      if (!user.isBuyer && !user.isOther) {
        navigate(user.defaultLink)
      }
    } else if (isError || isSuccess) {
      // Navigate to main page because it always knows what to do with unauthorized users
      navigate("/")
    }
  }, [isError, isSuccess, navigate, user])

  return { user }
}
