import { Box, Typography } from "@mui/material"
import { PlusButton } from "lib/components/CustomButton"
import { ReactComponent as EmptyIcon } from "./icons/empty.svg"
import { useOpenVenueSpaceDialog } from "./VenueSpaceDialog"

export function EmptySpaces({ venueId }: { venueId?: number }) {
  const openVenueSpaceDialog = useOpenVenueSpaceDialog()
  const openAddSpaceDialog = () => openVenueSpaceDialog(venueId)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "custom.light600",
        borderRadius: 3,
        padding: 4,
        gap: 2,
      }}
    >
      <EmptyIcon />
      <Typography variant="h2" color="custom.dark900">
        No scenes added yet
      </Typography>
      <Typography variant="body14" color="custom.dark700">
        Add your first scene now
      </Typography>
      <PlusButton
        variant="contained"
        onClick={openAddSpaceDialog}
        sx={{ mt: 2 }}
      >
        Add scene
      </PlusButton>
    </Box>
  )
}
