import { yupResolver } from "@hookform/resolvers/yup"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { CustomButton } from "lib/components/CustomButton"
import { ControlledSelectField } from "lib/components/form/ControlledSelectField"
import { ControlledTextField } from "lib/components/form/ControlledTextField"
import { SearchableSelectField } from "lib/components/form/SearchableSelectField"
import { SelectSearchItem } from "lib/components/FormField"
import { MSG_FIELD_REQUIRED } from "lib/messages"
import { useRedirectAuthorizedUser } from "lib/useRedirectAuthorizedUser"
import { useServerFormValidation } from "lib/useServerFormValidation"
import { useTheme } from "lib/useTheme"
import * as React from "react"
import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { COUNTRIES } from "services/countriesdb"
import * as yup from "yup"
import { SignUpWizardProps } from "./BuyerProfile"

const countryOptions: string[] = Object.entries(COUNTRIES)
  .sort(([codeA, { country: labelA }], [codeB, { country: labelB }]) => {
    if (codeA === "GB" || codeB == "GB") {
      return codeA == "GB" ? -1 : 1
    } else if (codeA === "US" || codeB == "US") {
      return codeA == "US" ? -1 : 1
    } else {
      return labelA.localeCompare(labelB)
    }
  })
  .map(([, { country }]) => country)

const PHONE_CODE_CHOICES: SelectSearchItem[] = Object.entries(COUNTRIES)
  .map(([code, { country, phone_code }]) => ({ code, country, phone_code }))
  .sort((a, b) => a.country.localeCompare(b.country))
  .map(({ code, country, phone_code }) =>
    Array.isArray(phone_code)
      ? phone_code.map((_phone_code) => ({
          code,
          country,
          phone_code: _phone_code,
        }))
      : [{ code, country, phone_code }]
  )
  .flat()
  .map(({ code, country, phone_code }) => ({
    label: `${country} (+${phone_code}) `,
    value: phone_code,
    code: code,
  }))

function findPhoneCodeByCountryName(countryName: string): string | undefined {
  const countryFind = Object.entries(COUNTRIES).find(
    ([, { country }]) => country === countryName
  )
  if (!countryFind) return undefined
  const phoneCode = countryFind[1].phone_code
  if (!phoneCode) return undefined

  return Array.isArray(phoneCode) ? phoneCode[0] : phoneCode
}

const COUNTRY_STATES = Object.fromEntries(
  Object.entries(COUNTRIES).map(([, { country, states }]) => [country, states])
)

// const INDUSTRY_CHOICES = ["CMP", "CAE", "CDME", "Other"]

type StepFormValues = {
  company_name: string
  job_title: string
  country: string
  state: string
  city: string
  //   work_address: string
  //   postal_code: string
  phone: string
  phone_code: string

  //   industry_designation: string
  //   industry_designation_other: string
}

function countryRequiresState(country: string) {
  return country === "United States" || country === "Canada"
}

const schema = yup
  .object({
    company_name: yup.string().required(MSG_FIELD_REQUIRED),
    job_title: yup.string().required(MSG_FIELD_REQUIRED),
    country: yup.string().required(MSG_FIELD_REQUIRED),
    state: yup.string().when("country", {
      is: (country: string) => countryRequiresState(country),
      then: (schema) => schema.required("Please provide state information"),
    }),
    city: yup.string().required(MSG_FIELD_REQUIRED),
    // work_address: yup.string().required(MSG_FIELD_REQUIRED),
    phone_code: yup.string().required(MSG_FIELD_REQUIRED),
    phone: yup.string().required(MSG_FIELD_REQUIRED),
    // industry_designation_other: yup.string().when("industry_designation", {
    //   is: "Other",
    //   then: (schema) =>
    //     schema.required("Please provide your industry designation"),
    // }),
  })
  .required()

export function BuyerProfileDetails(props: SignUpWizardProps) {
  useRedirectAuthorizedUser()

  const { bulkUpdate, wizardData, user, setStep } = props
  const theme = useTheme()

  const nextStep = async () => {
    setStep("company")
  }

  const onSubmit = (data: StepFormValues) => {
    bulkUpdate(data)
    nextStep()
  }

  const formMethods = useForm<StepFormValues>({
    defaultValues: wizardData,
    mode: "onBlur",
    resolver: yupResolver(schema),
  })

  const country = formMethods.watch("country")
  const phone_code = formMethods.watch("phone_code")
  useEffect(() => {
    if (country && !phone_code) {
      const phoneCode = findPhoneCodeByCountryName(country)
      if (phoneCode) formMethods.setValue("phone_code", phoneCode)
    }
  }, [country, formMethods, phone_code])

  const stateOptions = React.useMemo(
    () => COUNTRY_STATES[country] ?? [],
    [country]
  )

  const validationError = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { non_field_errors, ...validationError } =
      wizardData.validationError || {}
    return validationError
  }, [wizardData])
  useServerFormValidation(validationError, formMethods.setError, true)

  const disabled = false

  const isStateRequired = countryRequiresState(country)

  useEffect(() => {
    if (!isStateRequired) formMethods.setValue("state", "")
  }, [isStateRequired, formMethods])

  //   const industryChoices = useSelectItems(INDUSTRY_CHOICES)
  //   const current_industry_designation = formMethods.watch("industry_designation")

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
      <Box
        mt="66px"
        width="506px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        gap={2}
      >
        <Typography variant="h2" color={theme.palette.custom.dark900}>
          Your profile details
        </Typography>
        <Typography variant="body14" color={theme.palette.custom.dark700}>
          Hi {user.first_name}, before you can explore the metaverse,
          <br />
          please complete your profile below
        </Typography>
      </Box>

      <FormProvider {...formMethods}>
        <Box
          component="form"
          noValidate
          onSubmit={formMethods.handleSubmit(onSubmit)}
          mt={5}
          width="330px"
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <ControlledTextField
            label="Company"
            name="company_name"
            autoComplete="company"
            disabled={disabled}
          />
          <ControlledTextField label="Job title" name="job_title" />
          <SearchableSelectField
            name="country"
            options={countryOptions}
            label="Country"
            disabled={disabled}
          />
          {isStateRequired && (
            <SearchableSelectField
              name="state"
              options={stateOptions}
              label={
                country === "United States"
                  ? "State/County/Province"
                  : "State/Province/Region"
              }
            />
          )}
          <ControlledTextField
            label="City/Town"
            name="city"
            autoComplete="city"
            disabled={disabled}
          />
          {/* <ControlledTextField
            label="Work address (Street address)"
            name="work_address"
            autoComplete="work_address"
            disabled={disabled}
          />
          <ControlledTextField
            label="Postal code/ZIP"
            name="postal_code"
            autoComplete="postal_code"
            disabled={disabled}
          /> */}
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "95px", display: "flex" }}>
              <ControlledSelectField
                label="Code"
                name="phone_code"
                items={PHONE_CODE_CHOICES}
                renderValue={(v: string) => `+${v}`}
                join="right"
              />
            </Box>
            <ControlledTextField
              label="Phone number"
              name="phone"
              autoComplete="phone"
              join="left"
              fullWidth
            />
          </Box>
          {/* <ControlledSelectField
            label="Industry designation"
            name="industry_designation"
            items={industryChoices}
          />
          {current_industry_designation === "Other" && (
            <ControlledTextField
              label="Industry designation: Other"
              name="industry_designation_other"
              disabled={disabled}
              autoComplete="email"
            />
          )} */}

          <CustomButton type="submit" variant="contained">
            Continue
          </CustomButton>
        </Box>
      </FormProvider>
    </Box>
  )
}
