import { createRef, useEffect, useState } from "react"

export function useSticky() {
  const [stuck, setStuck] = useState(false)
  const ref = createRef<Element>()

  useEffect(() => {
    const cachedRef = ref.current
    if (!cachedRef) return
    const observer = new IntersectionObserver(
      ([e]) => setStuck(e.intersectionRatio < 1),
      { threshold: [1] }
    )
    observer.observe(cachedRef)
    return () => observer.unobserve(cachedRef)
  }, [ref])

  return [ref, stuck] as const
}
