import { BuyerProfile } from "api/users"

export type APIErrorResponse<RequestType> = {
  detail: string
} & Partial<RequestType>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorData<RequestType>(error: any) {
  return error && "data" in error && error.data
    ? (error.data as APIErrorResponse<RequestType>)
    : undefined
}

export type LoginRequest = {
  login: string
  password: string
}

export type RegisterRequest = {
  username: string
  password: string
  email: string
  first_name: string
  last_name: string
}

export type VerifyRegisterRequest = {
  user_id: string
  timestamp: string
  signature: string
}

export type ResendVerifyRequest = {
  email: string
}

export type ChangePasswordRequest = {
  old_password: string
  password: string
}

export type SendResetPasswordLinkRequest = {
  email: string
}

export type SendResetPasswordLinkResponse = {
  detail: string
}

export type ResetPasswordParams = {
  user_id: string
  timestamp: string
  signature: string
}

export type ResetPasswordRequest = {
  password: string
} & ResetPasswordParams

export type ResetPasswordResponse = {
  detail: string
}

export type UserRole = "seller" | "buyer" | "other" | null

export type User = {
  id: number
  email: string
  email_verified: boolean
  first_name: string
  last_name: string
  username: string
  role: UserRole
  rpm_avatar_url: string
  buyer_profile: BuyerProfile | null
}

export type UpdateUser = {
  first_name: string
  last_name: string
}

export type PatchUser = {
  first_name?: string
  last_name?: string
  role?: UserRole
  buyer_profile?: Partial<BuyerProfile>
}

export type DeviceActivationRequest = {
  user_code: string
}

export type DeviceActivationResult = {
  application: string
}
