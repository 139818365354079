import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material"

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#1D1D29",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "12px 16px",
    backgroundColor: "#1D1D29",
    borderRadius: 8,
    ...theme.typography.body12,
  },
}))
