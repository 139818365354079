import { generatedApi as api } from "./api"
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    usersChangePasswordCreate: build.mutation<
      UsersChangePasswordCreateApiResponse,
      UsersChangePasswordCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/change-password/`,
        method: "POST",
        body: queryArg.changePassword,
      }),
    }),
    usersInvitationRetrieve: build.query<
      UsersInvitationRetrieveApiResponse,
      UsersInvitationRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/invitation/${queryArg.token}/`,
      }),
    }),
    usersInvitationUpdate: build.mutation<
      UsersInvitationUpdateApiResponse,
      UsersInvitationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/invitation/${queryArg.token}/`,
        method: "PUT",
        body: queryArg.userInvitation,
      }),
    }),
    usersLoginCreate: build.mutation<
      UsersLoginCreateApiResponse,
      UsersLoginCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/login/`,
        method: "POST",
        body: queryArg.defaultLogin,
      }),
    }),
    usersLogoutCreate: build.mutation<
      UsersLogoutCreateApiResponse,
      UsersLogoutCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/logout/`,
        method: "POST",
        body: queryArg.logout,
      }),
    }),
    usersProfileRetrieve: build.query<
      UsersProfileRetrieveApiResponse,
      UsersProfileRetrieveApiArg
    >({
      query: () => ({ url: `/api/users/profile/` }),
    }),
    usersProfileCreate: build.mutation<
      UsersProfileCreateApiResponse,
      UsersProfileCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/profile/`,
        method: "POST",
        body: queryArg.rpmUrlAwareProfile,
      }),
    }),
    usersProfileUpdate: build.mutation<
      UsersProfileUpdateApiResponse,
      UsersProfileUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/profile/`,
        method: "PUT",
        body: queryArg.rpmUrlAwareProfile,
      }),
    }),
    usersProfilePartialUpdate: build.mutation<
      UsersProfilePartialUpdateApiResponse,
      UsersProfilePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/profile/`,
        method: "PATCH",
        body: queryArg.patchedRpmUrlAwareProfile,
      }),
    }),
    usersRegisterCreate: build.mutation<
      UsersRegisterCreateApiResponse,
      UsersRegisterCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/register/`,
        method: "POST",
        body: queryArg.registerUser,
      }),
    }),
    usersRegisterEmailCreate: build.mutation<
      UsersRegisterEmailCreateApiResponse,
      UsersRegisterEmailCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/register-email/`,
        method: "POST",
        body: queryArg.defaultRegisterEmail,
      }),
    }),
    usersResendVerifyCreate: build.mutation<
      UsersResendVerifyCreateApiResponse,
      UsersResendVerifyCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/resend-verify/`,
        method: "POST",
        body: queryArg.resendVerify,
      }),
    }),
    usersResetPasswordCreate: build.mutation<
      UsersResetPasswordCreateApiResponse,
      UsersResetPasswordCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/reset-password/`,
        method: "POST",
        body: queryArg.resetPassword,
      }),
    }),
    usersSendResetPasswordLinkCreate: build.mutation<
      UsersSendResetPasswordLinkCreateApiResponse,
      UsersSendResetPasswordLinkCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/send-reset-password-link/`,
        method: "POST",
        body: queryArg.defaultSendResetPasswordLink,
      }),
    }),
    usersVerifyEmailCreate: build.mutation<
      UsersVerifyEmailCreateApiResponse,
      UsersVerifyEmailCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/verify-email/`,
        method: "POST",
        body: queryArg.verifyEmail,
      }),
    }),
    usersVerifyRegistrationCreate: build.mutation<
      UsersVerifyRegistrationCreateApiResponse,
      UsersVerifyRegistrationCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/users/verify-registration/`,
        method: "POST",
        body: queryArg.verifyRegistration,
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as userGeneratedApi }
export type UsersChangePasswordCreateApiResponse =
  /** status 200  */ ChangePassword
export type UsersChangePasswordCreateApiArg = {
  changePassword: ChangePassword
}
export type UsersInvitationRetrieveApiResponse =
  /** status 200  */ UserInvitation
export type UsersInvitationRetrieveApiArg = {
  /** A unique value identifying this user invitation. */
  token: string
}
export type UsersInvitationUpdateApiResponse = /** status 200  */ UserInvitation
export type UsersInvitationUpdateApiArg = {
  /** A unique value identifying this user invitation. */
  token: string
  userInvitation: UserInvitation
}
export type UsersLoginCreateApiResponse = /** status 200  */ DefaultLogin
export type UsersLoginCreateApiArg = {
  defaultLogin: DefaultLogin
}
export type UsersLogoutCreateApiResponse = /** status 200  */ Logout
export type UsersLogoutCreateApiArg = {
  logout: Logout
}
export type UsersProfileRetrieveApiResponse =
  /** status 200  */ RpmUrlAwareProfile
export type UsersProfileRetrieveApiArg = void
export type UsersProfileCreateApiResponse =
  /** status 200  */ RpmUrlAwareProfile
export type UsersProfileCreateApiArg = {
  rpmUrlAwareProfile: RpmUrlAwareProfile
}
export type UsersProfileUpdateApiResponse =
  /** status 200  */ RpmUrlAwareProfile
export type UsersProfileUpdateApiArg = {
  rpmUrlAwareProfile: RpmUrlAwareProfile
}
export type UsersProfilePartialUpdateApiResponse =
  /** status 200  */ RpmUrlAwareProfile
export type UsersProfilePartialUpdateApiArg = {
  patchedRpmUrlAwareProfile: PatchedRpmUrlAwareProfile
}
export type UsersRegisterCreateApiResponse = /** status 200  */ RegisterUser
export type UsersRegisterCreateApiArg = {
  registerUser: RegisterUser
}
export type UsersRegisterEmailCreateApiResponse =
  /** status 200  */ DefaultRegisterEmail
export type UsersRegisterEmailCreateApiArg = {
  defaultRegisterEmail: DefaultRegisterEmail
}
export type UsersResendVerifyCreateApiResponse = /** status 200  */ ResendVerify
export type UsersResendVerifyCreateApiArg = {
  resendVerify: ResendVerify
}
export type UsersResetPasswordCreateApiResponse =
  /** status 200  */ ResetPassword
export type UsersResetPasswordCreateApiArg = {
  resetPassword: ResetPassword
}
export type UsersSendResetPasswordLinkCreateApiResponse =
  /** status 200  */ DefaultSendResetPasswordLink
export type UsersSendResetPasswordLinkCreateApiArg = {
  defaultSendResetPasswordLink: DefaultSendResetPasswordLink
}
export type UsersVerifyEmailCreateApiResponse = /** status 200  */ VerifyEmail
export type UsersVerifyEmailCreateApiArg = {
  verifyEmail: VerifyEmail
}
export type UsersVerifyRegistrationCreateApiResponse =
  /** status 200  */ VerifyRegistration
export type UsersVerifyRegistrationCreateApiArg = {
  verifyRegistration: VerifyRegistration
}
export type ChangePassword = {
  old_password: string
  password: string
}
export type UserInvitation = {
  first_name: string
  last_name: string
  password: string
  completed: boolean
  email: string
}
export type DefaultLogin = {
  login: string
  password: string
}
export type Logout = {
  revoke_token?: boolean
}
export type BuyerProfile = {
  id: number
  industry_designation?: string
  industry_designation_other?: string
  country?: string
  state?: string
  city?: string
  work_address?: string
  postal_code?: string
  phone_code?: string
  phone?: string
  job_title?: string
  company_name?: string
  website?: string
  company_type?: string
  type_of_events_organised?: string[]
  type_of_events_organised_other?: string
  events_organised_per_year?: string
  average_daily_rate?: string
  industries_worked_with?: string[]
}
export type RoleEnum = "buyer" | "seller" | "other"
export type BlankEnum = ""
export type NullEnum = null
export type RpmUrlAwareProfile = {
  id: number
  username: string
  email: string
  email_verified: boolean
  is_active: boolean
  buyer_profile?: BuyerProfile
  first_name?: string
  last_name?: string
  rpm_avatar_url?: string | null
  role?: (RoleEnum | BlankEnum | NullEnum) | null
}
export type PatchedRpmUrlAwareProfile = {
  id?: number
  username?: string
  email?: string
  email_verified?: boolean
  is_active?: boolean
  buyer_profile?: BuyerProfile
  first_name?: string
  last_name?: string
  rpm_avatar_url?: string | null
  role?: (RoleEnum | BlankEnum | NullEnum) | null
}
export type RegisterUser = {
  first_name?: string
  last_name?: string
  email: string
  password: string
  buyer_profile?: BuyerProfile
}
export type DefaultRegisterEmail = {
  email: string
}
export type ResendVerify = {
  email: string
}
export type ResetPassword = {
  user_id: string
  timestamp: number
  signature: string
  password: string
}
export type DefaultSendResetPasswordLink = {
  email: string
}
export type VerifyEmail = {
  user_id: string
  email: string
  timestamp: number
  signature: string
}
export type VerifyRegistration = {
  user_id: string
  timestamp: number
  signature: string
}
