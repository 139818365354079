import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

/**
 * Utility hook that nevigate after component state will be updated
 */
export function useGentleNavigate() {
  const navigate = useNavigate()
  const [path, gentleNavigate] = useState<string | null>(null)

  useEffect(() => {
    if (path) navigate(path)
  }, [navigate, path])

  return gentleNavigate
}
