import { Box, Chip, ChipProps } from "@mui/material"
import { useTheme } from "lib/useTheme"
import { ReactElement, useMemo } from "react"
import { ReactComponent as CloseIcon } from "./icons/close.svg"
import { SearchSelectField, SearchSelectFieldProps } from "./SearchSelectField"

export function CustomChip(props: ChipProps) {
  const theme = useTheme()

  return (
    <Chip
      {...props}
      deleteIcon={
        <Box onMouseDown={(event) => event.stopPropagation()}>
          <CloseIcon />
        </Box>
      }
      sx={{
        backgroundColor: theme.palette.custom.light700,
        padding: "3px 8px 3px 6px",
        height: "auto",
        borderRadius: "9px",
        "& .MuiChip-label": {
          color: "custom.dark900",
          ...theme.typography.body12,
          padding: "0px 3px 2px 6px",
        },
        "& .MuiChip-deleteIcon": {
          margin: 0,
          padding: 0,
          width: "16px",
          height: "16px",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          color: theme.palette.custom.dark500,
        },
        "& .MuiChip-deleteIcon:hover path": {
          stroke: theme.palette.custom.accenthover,
        },
      }}
    />
  )
}

export type MultiSelectFieldProps = SearchSelectFieldProps<string[]> & {
  onDeleteChange: ({ name, value }: { name: string; value: string[] }) => void
}

type ValueLabels = Record<string, string | ReactElement | undefined>

// TODO: if default value not set to array, this controll crashes. Need fix
export function MultiSelectField(props: MultiSelectFieldProps) {
  const { renderValue, ...otherProps } = props

  const items = props.items
  const valueLabels = useMemo(
    () =>
      items.reduce(
        (acc, { value, label }) => (value ? { ...acc, [value]: label } : acc),
        {} as ValueLabels
      ),
    [items]
  )

  return (
    <SearchSelectField
      {...otherProps}
      renderValue={
        renderValue
          ? renderValue
          : (values: string[]) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "4px",
                  pt: "9px",
                }}
              >
                {values.map((value, idx) => (
                  <CustomChip
                    key={idx}
                    label={valueLabels[value]}
                    onDelete={() => {
                      const name = otherProps.name
                      const newValues = values.slice()
                      newValues.splice(idx, 1)
                      otherProps.onDeleteChange({ name, value: newValues })
                    }}
                  />
                ))}
              </Box>
            )
      }
    />
  )
}
