import { Box, Typography } from "@mui/material"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import {
  useSellerVenueDraftRetrieveQuery,
  useSellerVenueRetrieveQuery,
} from "api/sellerApi"
import { LoadingIndicator } from "app/LoadingIndicator"
import { VenueSpaceDeleteDialog } from "features/venues/VenueSpaceDeleteDialog"
import { VenueSpaces } from "features/venues/VenueSpaces"
import { PlusButton } from "lib/components/CustomButton"
import { useEffect } from "react"
import { Route, Routes, useNavigate, useParams } from "react-router-dom"
import { EmptySpaces } from "./EmptySpaces"
import { useOpenVenueSpaceDialog, VenueSpaceDialog } from "./VenueSpaceDialog"

export function SellerVenueSpaces() {
  const { id } = useParams()
  const venueId = id ? +id : undefined
  const { data: draftVenue, isFetching: isDraftLoading } =
    useSellerVenueDraftRetrieveQuery(id ? skipToken : undefined)
  const { data: existingVenue, isFetching: isVenueLoading } =
    useSellerVenueRetrieveQuery(id ? { id } : skipToken)

  const venue = id ? existingVenue : draftVenue

  const openVenueSpaceDialog = useOpenVenueSpaceDialog()
  const openAddSpaceDialog = () => openVenueSpaceDialog(venueId)

  const navigate = useNavigate()
  useEffect(() => {
    if (!id) navigate("/seller/venues/new")
  }, [id, navigate])

  // useLoader(isDraftLoading || isVenueLoading)
  const isLoading = isDraftLoading || isVenueLoading

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        padding: 3,
        height: "fit-content",
        flexGrow: 1,
      }}
    >
      <Routes>
        <Route path="new" element={<VenueSpaceDialog />} />
        <Route path=":spaceId" element={<VenueSpaceDialog />} />
        <Route path=":spaceId/delete" element={<VenueSpaceDeleteDialog />} />
      </Routes>

      {isLoading || !venue ? (
        <LoadingIndicator />
      ) : !venue.spaces.length ? (
        <EmptySpaces venueId={venueId} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "custom.light600",
            borderRadius: 3,
            padding: 4,
            flexGrow: 1,
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="h4"
              color="custom.dark800"
              flex={1}
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              Scenes
            </Typography>
            <PlusButton onClick={openAddSpaceDialog} variant="contained">
              Add scene
            </PlusButton>
          </Box>
          <VenueSpaces spaces={venue.spaces} editable venueId={venueId} />
        </Box>
      )}
    </Box>
  )
}
