import { Box } from "@mui/material"
import { ReactComponent as VR360BlueIcon } from "features/venues/icons/360blue.svg"
import { ReactComponent as VRHeadsetIcon } from "features/venues/icons/vr.svg"

export function VrIcons({ isVr, is360 }: { isVr: boolean; is360: boolean }) {
  return (
    <Box display="flex" gap="6px">
      {isVr && <VRHeadsetIcon />}
      {is360 && <VR360BlueIcon />}
    </Box>
  )
}
