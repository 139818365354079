import { generatedApi as api } from "api/api"
import {
  ChangePasswordRequest,
  LoginRequest,
  PatchUser,
  RegisterRequest,
  ResendVerifyRequest,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SendResetPasswordLinkRequest,
  SendResetPasswordLinkResponse,
  UpdateUser,
  User,
  VerifyRegisterRequest,
} from "./types"

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login_DEPRECATED_: builder.mutation<void, LoginRequest>({
      query: (body) => ({ url: "/api/users/login/", method: "post", body }),
      invalidatesTags: ["User", "LoginChanged"],
    }),

    register_DEPRECATED_: builder.mutation<void, RegisterRequest>({
      query: (body) => ({ url: "/api/users/register/", method: "post", body }),
      invalidatesTags: ["User"],
    }),

    verifyUser_DEPRECATED_: builder.mutation<User, VerifyRegisterRequest>({
      query: (body) => ({
        url: "/api/users/verify-registration/",
        method: "post",
        body,
      }),
      invalidatesTags: ["User"],
    }),

    resendVerify_DEPRECATED_: builder.mutation<void, ResendVerifyRequest>({
      query: (body) => ({
        url: "/api/users/resend-verify/",
        method: "post",
        body,
      }),
    }),

    changePassword_DEPRECATED_: builder.mutation<void, ChangePasswordRequest>({
      query: (body) => ({
        url: "/api/users/change-password/",
        method: "post",
        body,
      }),
    }),

    sendResetPasswordLink_DEPRECATED_: builder.mutation<
      SendResetPasswordLinkResponse,
      SendResetPasswordLinkRequest
    >({
      query: (body) => ({
        url: "/api/users/send-reset-password-link/",
        method: "post",
        body,
      }),
    }),

    resetPassword_DEPRECATED_: builder.mutation<
      ResetPasswordResponse,
      ResetPasswordRequest
    >({
      query: (body) => ({
        url: "/api/users/reset-password/",
        method: "post",
        body,
      }),
    }),

    logout_DEPRECATED_: builder.mutation<void, void>({
      query: () => ({ url: "/api/users/logout/", method: "post", body: {} }),
      invalidatesTags: ["User", "LoginChanged"],
    }),

    profile_DEPRECATED_: builder.query<User, void>({
      query: () => "/api/users/profile/",
      providesTags: ["User", "LoginChanged"],
    }),

    updateProfile_DEPRECATED_: builder.mutation<void, UpdateUser>({
      query: (body) => ({ url: "/api/users/profile/", method: "post", body }),
      invalidatesTags: ["User"],
    }),

    partialUpdateProfile_DEPRECATED_: builder.mutation<void, PatchUser>({
      query: (body) => ({ url: "/api/users/profile/", method: "put", body }),
      invalidatesTags: ["User"],
    }),

    updateRPMUrl_DEPRECATED_: builder.mutation<void, string>({
      query: (arg) => ({
        url: "/api/users/profile/",
        method: "post",
        body: { rpm_avatar_url: arg },
      }),
      invalidatesTags: ["User"],
    }),
  }),
})

export const {
  useLogin_DEPRECATED_Mutation,
  useRegister_DEPRECATED_Mutation,
  useVerifyUser_DEPRECATED_Mutation,
  useResendVerify_DEPRECATED_Mutation,
  useChangePassword_DEPRECATED_Mutation,
  useSendResetPasswordLink_DEPRECATED_Mutation,
  useResetPassword_DEPRECATED_Mutation,
  useLogout_DEPRECATED_Mutation,
  useProfile_DEPRECATED_Query,
  useUpdateProfile_DEPRECATED_Mutation,
  usePartialUpdateProfile_DEPRECATED_Mutation,
  useUpdateRPMUrl_DEPRECATED_Mutation,
} = userApi
