import { Alert } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { CustomButton } from "lib/components/CustomButton"
import { CustomPasswordField } from "lib/components/FormField"
import { useRedirectAuthorizedUser } from "lib/useRedirectAuthorizedUser"
import { useTheme } from "lib/useTheme"
import * as React from "react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useResetPassword_DEPRECATED_Mutation } from "services/oldUserApi"
import { getErrorData, ResetPasswordRequest } from "services/types"
import AuthPageContainer from "./AuthPageContainer"
import { ReactComponent as OKIcon } from "./icons/ok.svg"
import { useVerifyParams } from "./useVerifyParams"

export default function ResetPassword() {
  const theme = useTheme()

  const navigate = useNavigate()
  useRedirectAuthorizedUser()

  const [passwordFieldError, setPasswordError] = React.useState<
    string | undefined
  >()
  const [passwordInputValue, setPassword] = React.useState<string>("")
  React.useState<string>("")

  const [resetPassword, query] = useResetPassword_DEPRECATED_Mutation()
  const { data, isLoading, error, isSuccess } = query
  const errorData = getErrorData<ResetPasswordRequest>(error)
  const errorMessage =
    errorData && (errorData?.detail || "Please correct errors below")

  const verifyParams = useVerifyParams()

  useEffect(() => {
    if (isSuccess) {
      navigate("./reset")
    }
  }, [isSuccess, navigate])

  const validate = (form: FormData): boolean => {
    const password = (form.get("password") || "").toString()
    if (password.length == 0) {
      setPasswordError("Password should not be empty")
      return false
    } else {
      setPasswordError(undefined)
    }
    return true
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    if (!validate(formData)) return
    const data = {
      password: formData.get("password") as string,
      ...verifyParams,
    }

    await resetPassword(data).unwrap()
  }

  const disabled = isLoading

  return (
    <AuthPageContainer>
      <Box
        width="400px"
        pt={2}
        flexGrow={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        gap={2}
      >
        <Typography variant="h2" color={theme.palette.custom.dark900}>
          Reset Password
        </Typography>
        <Typography variant="body14" color={theme.palette.custom.dark700}>
          Enter a new password to finish updating your password
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          width="330px"
          display="flex"
          flexDirection="column"
          gap={2}
        >
          {data && <Alert severity="info">{data.detail}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <CustomPasswordField
            required
            fullWidth
            id="password"
            label="New password"
            name="password"
            disabled={disabled}
            error={!!errorData?.password || passwordFieldError !== undefined}
            helperText={errorData?.password || passwordFieldError}
            value={passwordInputValue}
            onChange={(e) => setPassword(e.target.value)}
          />
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            disabled={disabled || !passwordInputValue}
          >
            Reset
          </CustomButton>
        </Box>
      </Box>
    </AuthPageContainer>
  )
}

export function ResetPasswordSuccess() {
  const theme = useTheme()
  return (
    <AuthPageContainer>
      <Box
        width="400px"
        pt={2}
        flexGrow={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        gap={2}
      >
        <OKIcon />
        <Typography variant="h2" color={theme.palette.custom.dark900}>
          A new password has been set
        </Typography>
        <Typography variant="body14" color={theme.palette.custom.dark700}>
          You have successfully changed your password
        </Typography>

        <Box></Box>

        <CustomButton href="/signin/" variant="contained">
          Sign in
        </CustomButton>
      </Box>
    </AuthPageContainer>
  )
}
