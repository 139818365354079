import { ReactComponent as BeachIcon } from "app/icons/facilities_beach.svg"
import { ReactComponent as CasinoIcon } from "app/icons/facilities_casino.svg"
import { ReactComponent as ClubIcon } from "app/icons/facilities_club.svg"
import { ReactComponent as EntertainmentIcon } from "app/icons/facilities_entertainment.svg"
import { ReactComponent as GolfIcon } from "app/icons/facilities_golf.svg"
import { ReactComponent as GymIcon } from "app/icons/facilities_gym.svg"
import { ReactComponent as KidsClubIcon } from "app/icons/facilities_kids_club.svg"
import { ReactComponent as SpaIcon } from "app/icons/facilities_spa.svg"
import { ReactComponent as SpecialityDiningIcon } from "app/icons/facilities_speciality_dining.svg"
import { ReactComponent as SwimmingPoolIcon } from "app/icons/facilities_swimming_pool.svg"
import { ReactComponent as TennisIcon } from "app/icons/facilities_tennis.svg"
import { ReactComponent as WaterSportsIcon } from "app/icons/facilities_water_sports.svg"
import { ReactComponent as WinterSportsIcon } from "app/icons/facilities_winter_sports.svg"

import { ReactComponent as ConferencesIcon } from "app/icons/hosted_events_conferences.svg"
import { ReactComponent as ConventionsIcon } from "app/icons/hosted_events_congresses_conventions.svg"
import { ReactComponent as MeetingsIcon } from "app/icons/hosted_events_meetings.svg"

import { ReactComponent as ExhibitionsIcon } from "app/icons/hosted_events_exhibitions.svg"
import { ReactComponent as ExperientialIcon } from "app/icons/hosted_events_experiential.svg"
import { ReactComponent as IncentivesIcon } from "app/icons/hosted_events_incentives.svg"
import { ReactComponent as SustainableIcon } from "app/icons/hosted_events_sustainable.svg"

import { ReactComponent as YesIcon } from "app/icons/yes.svg"
import { LEISURE_FACILITIES, TYPE_OF_EVENTS_HOSTED } from "./venueConstants"

export const LEISURE_FACILITIES_ICONS: {
  [id in typeof LEISURE_FACILITIES[number]]: JSX.Element
} = {
  Beach: <BeachIcon />,
  Club: <ClubIcon />,
  Casino: <CasinoIcon />,
  Entertainment: <EntertainmentIcon />,
  Golf: <GolfIcon />,
  Gym: <GymIcon />,
  "Kids' Club": <KidsClubIcon />,
  Spa: <SpaIcon />,
  "Speciality Dining": <SpecialityDiningIcon />,
  "Swimming Pool": <SwimmingPoolIcon />,
  Tennis: <TennisIcon />,
  "Water Sports": <WaterSportsIcon />,
  "Winter Sports": <WinterSportsIcon />,
}

export const TYPE_OF_EVENTS_HOSTED_ICONS: {
  [id in typeof TYPE_OF_EVENTS_HOSTED[number]]: JSX.Element
} = {
  Incentives: <IncentivesIcon />,
  "Congresses/Conventions": <ConventionsIcon />,
  Conferences: <ConferencesIcon />,
  Meetings: <MeetingsIcon />,
  "Sustainable Events": <SustainableIcon />,
  "Experiential Events": <ExperientialIcon />,
  Exhibitions: <ExhibitionsIcon />,
}

export const HEALTHCARE_COMPLIANT_ICONS: {
  [id: string]: JSX.Element
} = {
  yes: <YesIcon />,
}
