import { generatedApi as api } from "./api"
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    sellerMeetingList: build.query<
      SellerMeetingListApiResponse,
      SellerMeetingListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/meeting/`,
        params: { limit: queryArg.limit, offset: queryArg.offset },
      }),
    }),
    sellerMeetingCreate: build.mutation<
      SellerMeetingCreateApiResponse,
      SellerMeetingCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/meeting/`,
        method: "POST",
        body: queryArg.meeting,
      }),
    }),
    sellerMeetingVenueList: build.query<
      SellerMeetingVenueListApiResponse,
      SellerMeetingVenueListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/meeting/${queryArg.meetingPk}/venue/`,
      }),
    }),
    sellerMeetingVenueCreate: build.mutation<
      SellerMeetingVenueCreateApiResponse,
      SellerMeetingVenueCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/meeting/${queryArg.meetingPk}/venue/`,
        method: "POST",
        body: queryArg.meetingVenue,
      }),
    }),
    sellerMeetingVenueRetrieve: build.query<
      SellerMeetingVenueRetrieveApiResponse,
      SellerMeetingVenueRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/meeting/${queryArg.meetingPk}/venue/${queryArg.venueId}/`,
      }),
    }),
    sellerMeetingVenueUpdate: build.mutation<
      SellerMeetingVenueUpdateApiResponse,
      SellerMeetingVenueUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/meeting/${queryArg.meetingPk}/venue/${queryArg.venueId}/`,
        method: "PUT",
        body: queryArg.meetingVenue,
      }),
    }),
    sellerMeetingVenuePartialUpdate: build.mutation<
      SellerMeetingVenuePartialUpdateApiResponse,
      SellerMeetingVenuePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/meeting/${queryArg.meetingPk}/venue/${queryArg.venueId}/`,
        method: "PATCH",
        body: queryArg.patchedMeetingVenue,
      }),
    }),
    sellerMeetingVenueDestroy: build.mutation<
      SellerMeetingVenueDestroyApiResponse,
      SellerMeetingVenueDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/meeting/${queryArg.meetingPk}/venue/${queryArg.venueId}/`,
        method: "DELETE",
      }),
    }),
    sellerMeetingVenueVenuesUpdate: build.mutation<
      SellerMeetingVenueVenuesUpdateApiResponse,
      SellerMeetingVenueVenuesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/meeting/${queryArg.meetingPk}/venue/venues/`,
        method: "PUT",
        body: queryArg.meetingVenues,
      }),
    }),
    sellerMeetingRetrieve: build.query<
      SellerMeetingRetrieveApiResponse,
      SellerMeetingRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/seller/meeting/${queryArg.id}/` }),
    }),
    sellerMeetingUpdate: build.mutation<
      SellerMeetingUpdateApiResponse,
      SellerMeetingUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/meeting/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.meeting,
      }),
    }),
    sellerMeetingPartialUpdate: build.mutation<
      SellerMeetingPartialUpdateApiResponse,
      SellerMeetingPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/meeting/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedMeeting,
      }),
    }),
    sellerMeetingDestroy: build.mutation<
      SellerMeetingDestroyApiResponse,
      SellerMeetingDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/meeting/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    sellerVenueList: build.query<
      SellerVenueListApiResponse,
      SellerVenueListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/`,
        params: { limit: queryArg.limit, offset: queryArg.offset },
      }),
    }),
    sellerVenueCreate: build.mutation<
      SellerVenueCreateApiResponse,
      SellerVenueCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/`,
        method: "POST",
        body: queryArg.sellerVenue,
      }),
    }),
    sellerVenueRetrieve: build.query<
      SellerVenueRetrieveApiResponse,
      SellerVenueRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/seller/venue/${queryArg.id}/` }),
    }),
    sellerVenueUpdate: build.mutation<
      SellerVenueUpdateApiResponse,
      SellerVenueUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.sellerVenue,
      }),
    }),
    sellerVenuePartialUpdate: build.mutation<
      SellerVenuePartialUpdateApiResponse,
      SellerVenuePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSellerVenue,
      }),
    }),
    sellerVenueDestroy: build.mutation<
      SellerVenueDestroyApiResponse,
      SellerVenueDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    sellerVenueHeroImageUpdate: build.mutation<
      SellerVenueHeroImageUpdateApiResponse,
      SellerVenueHeroImageUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.id}/hero-image/`,
        method: "PUT",
        body: queryArg.sellerVenueHeroImage,
      }),
    }),
    sellerVenueThumbnailUpdate: build.mutation<
      SellerVenueThumbnailUpdateApiResponse,
      SellerVenueThumbnailUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.id}/thumbnail/`,
        method: "PUT",
        body: queryArg.sellerVenueThumbnail,
      }),
    }),
    sellerVenueManagerList: build.query<
      SellerVenueManagerListApiResponse,
      SellerVenueManagerListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/manager/`,
      }),
    }),
    sellerVenueManagerCreate: build.mutation<
      SellerVenueManagerCreateApiResponse,
      SellerVenueManagerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/manager/`,
        method: "POST",
        body: queryArg.venueManager,
      }),
    }),
    sellerVenueManagerRetrieve: build.query<
      SellerVenueManagerRetrieveApiResponse,
      SellerVenueManagerRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/manager/${queryArg.id}/`,
      }),
    }),
    sellerVenueManagerDestroy: build.mutation<
      SellerVenueManagerDestroyApiResponse,
      SellerVenueManagerDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/manager/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    sellerVenuePhotoList: build.query<
      SellerVenuePhotoListApiResponse,
      SellerVenuePhotoListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/photo/`,
      }),
    }),
    sellerVenuePhotoCreate: build.mutation<
      SellerVenuePhotoCreateApiResponse,
      SellerVenuePhotoCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/photo/`,
        method: "POST",
        body: queryArg.sellerVenuePhoto,
      }),
    }),
    sellerVenuePhotoRetrieve: build.query<
      SellerVenuePhotoRetrieveApiResponse,
      SellerVenuePhotoRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/photo/${queryArg.id}/`,
      }),
    }),
    sellerVenuePhotoUpdate: build.mutation<
      SellerVenuePhotoUpdateApiResponse,
      SellerVenuePhotoUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/photo/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.sellerVenuePhoto,
      }),
    }),
    sellerVenuePhotoPartialUpdate: build.mutation<
      SellerVenuePhotoPartialUpdateApiResponse,
      SellerVenuePhotoPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/photo/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSellerVenuePhoto,
      }),
    }),
    sellerVenuePhotoDestroy: build.mutation<
      SellerVenuePhotoDestroyApiResponse,
      SellerVenuePhotoDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/photo/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    sellerVenueSpaceList: build.query<
      SellerVenueSpaceListApiResponse,
      SellerVenueSpaceListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/space/`,
      }),
    }),
    sellerVenueSpaceCreate: build.mutation<
      SellerVenueSpaceCreateApiResponse,
      SellerVenueSpaceCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/space/`,
        method: "POST",
        body: queryArg.sellerVenueSpace,
      }),
    }),
    sellerVenueSpaceRetrieve: build.query<
      SellerVenueSpaceRetrieveApiResponse,
      SellerVenueSpaceRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/space/${queryArg.id}/`,
      }),
    }),
    sellerVenueSpaceUpdate: build.mutation<
      SellerVenueSpaceUpdateApiResponse,
      SellerVenueSpaceUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/space/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.sellerVenueSpace,
      }),
    }),
    sellerVenueSpacePartialUpdate: build.mutation<
      SellerVenueSpacePartialUpdateApiResponse,
      SellerVenueSpacePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/space/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSellerVenueSpace,
      }),
    }),
    sellerVenueSpaceDestroy: build.mutation<
      SellerVenueSpaceDestroyApiResponse,
      SellerVenueSpaceDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/space/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    sellerVenueSpaceAndroidSceneUpdate: build.mutation<
      SellerVenueSpaceAndroidSceneUpdateApiResponse,
      SellerVenueSpaceAndroidSceneUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/space/${queryArg.id}/android_scene/`,
        method: "PUT",
        body: queryArg.sellerVenueSpaceAndroidScene,
      }),
    }),
    sellerVenueSpaceIosSceneUpdate: build.mutation<
      SellerVenueSpaceIosSceneUpdateApiResponse,
      SellerVenueSpaceIosSceneUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/space/${queryArg.id}/ios_scene/`,
        method: "PUT",
        body: queryArg.sellerVenueSpaceIosScene,
      }),
    }),
    sellerVenueSpaceQuestSceneUpdate: build.mutation<
      SellerVenueSpaceQuestSceneUpdateApiResponse,
      SellerVenueSpaceQuestSceneUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/space/${queryArg.id}/quest_scene/`,
        method: "PUT",
        body: queryArg.sellerVenueSpaceQuestScene,
      }),
    }),
    sellerVenueSpaceThumbnailUpdate: build.mutation<
      SellerVenueSpaceThumbnailUpdateApiResponse,
      SellerVenueSpaceThumbnailUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/space/${queryArg.id}/thumbnail/`,
        method: "PUT",
        body: queryArg.sellerVenueSpaceThumbnail,
      }),
    }),
    sellerVenueVideoList: build.query<
      SellerVenueVideoListApiResponse,
      SellerVenueVideoListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/video/`,
      }),
    }),
    sellerVenueVideoCreate: build.mutation<
      SellerVenueVideoCreateApiResponse,
      SellerVenueVideoCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/video/`,
        method: "POST",
        body: queryArg.sellerVenueVideo,
      }),
    }),
    sellerVenueVideoRetrieve: build.query<
      SellerVenueVideoRetrieveApiResponse,
      SellerVenueVideoRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/video/${queryArg.id}/`,
      }),
    }),
    sellerVenueVideoUpdate: build.mutation<
      SellerVenueVideoUpdateApiResponse,
      SellerVenueVideoUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/video/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.sellerVenueVideo,
      }),
    }),
    sellerVenueVideoPartialUpdate: build.mutation<
      SellerVenueVideoPartialUpdateApiResponse,
      SellerVenueVideoPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/video/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSellerVenueVideo,
      }),
    }),
    sellerVenueVideoDestroy: build.mutation<
      SellerVenueVideoDestroyApiResponse,
      SellerVenueVideoDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/${queryArg.venuePk}/video/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    sellerVenueAutocompleteList: build.query<
      SellerVenueAutocompleteListApiResponse,
      SellerVenueAutocompleteListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/autocomplete/`,
        params: { limit: queryArg.limit, search: queryArg.search },
      }),
    }),
    sellerVenueAutocompleteRetrieve: build.query<
      SellerVenueAutocompleteRetrieveApiResponse,
      SellerVenueAutocompleteRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/autocomplete/${queryArg.id}/`,
      }),
    }),
    sellerVenueDraftRetrieve: build.query<
      SellerVenueDraftRetrieveApiResponse,
      SellerVenueDraftRetrieveApiArg
    >({
      query: () => ({ url: `/api/seller/venue/draft/` }),
    }),
    sellerVenueDraftCreate: build.mutation<
      SellerVenueDraftCreateApiResponse,
      SellerVenueDraftCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/`,
        method: "POST",
        body: queryArg.sellerVenue,
      }),
    }),
    sellerVenueDraftUpdate: build.mutation<
      SellerVenueDraftUpdateApiResponse,
      SellerVenueDraftUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/`,
        method: "PUT",
        body: queryArg.sellerVenue,
      }),
    }),
    sellerVenueDraftPartialUpdate: build.mutation<
      SellerVenueDraftPartialUpdateApiResponse,
      SellerVenueDraftPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/`,
        method: "PATCH",
        body: queryArg.patchedSellerVenue,
      }),
    }),
    sellerVenueDraftDestroy: build.mutation<
      SellerVenueDraftDestroyApiResponse,
      SellerVenueDraftDestroyApiArg
    >({
      query: () => ({ url: `/api/seller/venue/draft/`, method: "DELETE" }),
    }),
    sellerVenueDraftHeroImageUpdate: build.mutation<
      SellerVenueDraftHeroImageUpdateApiResponse,
      SellerVenueDraftHeroImageUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/hero-image/`,
        method: "PUT",
        body: queryArg.sellerVenueHeroImage,
      }),
    }),
    sellerVenueDraftManagerList: build.query<
      SellerVenueDraftManagerListApiResponse,
      SellerVenueDraftManagerListApiArg
    >({
      query: () => ({ url: `/api/seller/venue/draft/manager/` }),
    }),
    sellerVenueDraftManagerCreate: build.mutation<
      SellerVenueDraftManagerCreateApiResponse,
      SellerVenueDraftManagerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/manager/`,
        method: "POST",
        body: queryArg.venueManager,
      }),
    }),
    sellerVenueDraftManagerRetrieve: build.query<
      SellerVenueDraftManagerRetrieveApiResponse,
      SellerVenueDraftManagerRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/manager/${queryArg.id}/`,
      }),
    }),
    sellerVenueDraftManagerDestroy: build.mutation<
      SellerVenueDraftManagerDestroyApiResponse,
      SellerVenueDraftManagerDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/manager/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    sellerVenueDraftPhotoList: build.query<
      SellerVenueDraftPhotoListApiResponse,
      SellerVenueDraftPhotoListApiArg
    >({
      query: () => ({ url: `/api/seller/venue/draft/photo/` }),
    }),
    sellerVenueDraftPhotoCreate: build.mutation<
      SellerVenueDraftPhotoCreateApiResponse,
      SellerVenueDraftPhotoCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/photo/`,
        method: "POST",
        body: queryArg.sellerVenuePhoto,
      }),
    }),
    sellerVenueDraftPhotoRetrieve: build.query<
      SellerVenueDraftPhotoRetrieveApiResponse,
      SellerVenueDraftPhotoRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/photo/${queryArg.id}/`,
      }),
    }),
    sellerVenueDraftPhotoUpdate: build.mutation<
      SellerVenueDraftPhotoUpdateApiResponse,
      SellerVenueDraftPhotoUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/photo/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.sellerVenuePhoto,
      }),
    }),
    sellerVenueDraftPhotoPartialUpdate: build.mutation<
      SellerVenueDraftPhotoPartialUpdateApiResponse,
      SellerVenueDraftPhotoPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/photo/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSellerVenuePhoto,
      }),
    }),
    sellerVenueDraftPhotoDestroy: build.mutation<
      SellerVenueDraftPhotoDestroyApiResponse,
      SellerVenueDraftPhotoDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/photo/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    sellerVenueDraftSpaceList: build.query<
      SellerVenueDraftSpaceListApiResponse,
      SellerVenueDraftSpaceListApiArg
    >({
      query: () => ({ url: `/api/seller/venue/draft/space/` }),
    }),
    sellerVenueDraftSpaceCreate: build.mutation<
      SellerVenueDraftSpaceCreateApiResponse,
      SellerVenueDraftSpaceCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/space/`,
        method: "POST",
        body: queryArg.sellerVenueSpace,
      }),
    }),
    sellerVenueDraftSpaceRetrieve: build.query<
      SellerVenueDraftSpaceRetrieveApiResponse,
      SellerVenueDraftSpaceRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/space/${queryArg.id}/`,
      }),
    }),
    sellerVenueDraftSpaceUpdate: build.mutation<
      SellerVenueDraftSpaceUpdateApiResponse,
      SellerVenueDraftSpaceUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/space/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.sellerVenueSpace,
      }),
    }),
    sellerVenueDraftSpacePartialUpdate: build.mutation<
      SellerVenueDraftSpacePartialUpdateApiResponse,
      SellerVenueDraftSpacePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/space/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSellerVenueSpace,
      }),
    }),
    sellerVenueDraftSpaceDestroy: build.mutation<
      SellerVenueDraftSpaceDestroyApiResponse,
      SellerVenueDraftSpaceDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/space/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    sellerVenueDraftSpaceAndroidSceneUpdate: build.mutation<
      SellerVenueDraftSpaceAndroidSceneUpdateApiResponse,
      SellerVenueDraftSpaceAndroidSceneUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/space/${queryArg.id}/android_scene/`,
        method: "PUT",
        body: queryArg.sellerVenueSpaceAndroidScene,
      }),
    }),
    sellerVenueDraftSpaceIosSceneUpdate: build.mutation<
      SellerVenueDraftSpaceIosSceneUpdateApiResponse,
      SellerVenueDraftSpaceIosSceneUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/space/${queryArg.id}/ios_scene/`,
        method: "PUT",
        body: queryArg.sellerVenueSpaceIosScene,
      }),
    }),
    sellerVenueDraftSpaceQuestSceneUpdate: build.mutation<
      SellerVenueDraftSpaceQuestSceneUpdateApiResponse,
      SellerVenueDraftSpaceQuestSceneUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/space/${queryArg.id}/quest_scene/`,
        method: "PUT",
        body: queryArg.sellerVenueSpaceQuestScene,
      }),
    }),
    sellerVenueDraftSpaceThumbnailUpdate: build.mutation<
      SellerVenueDraftSpaceThumbnailUpdateApiResponse,
      SellerVenueDraftSpaceThumbnailUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/space/${queryArg.id}/thumbnail/`,
        method: "PUT",
        body: queryArg.sellerVenueSpaceThumbnail,
      }),
    }),
    sellerVenueDraftThumbnailUpdate: build.mutation<
      SellerVenueDraftThumbnailUpdateApiResponse,
      SellerVenueDraftThumbnailUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/thumbnail/`,
        method: "PUT",
        body: queryArg.sellerVenueThumbnail,
      }),
    }),
    sellerVenueDraftVideoList: build.query<
      SellerVenueDraftVideoListApiResponse,
      SellerVenueDraftVideoListApiArg
    >({
      query: () => ({ url: `/api/seller/venue/draft/video/` }),
    }),
    sellerVenueDraftVideoCreate: build.mutation<
      SellerVenueDraftVideoCreateApiResponse,
      SellerVenueDraftVideoCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/video/`,
        method: "POST",
        body: queryArg.sellerVenueVideo,
      }),
    }),
    sellerVenueDraftVideoRetrieve: build.query<
      SellerVenueDraftVideoRetrieveApiResponse,
      SellerVenueDraftVideoRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/video/${queryArg.id}/`,
      }),
    }),
    sellerVenueDraftVideoUpdate: build.mutation<
      SellerVenueDraftVideoUpdateApiResponse,
      SellerVenueDraftVideoUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/video/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.sellerVenueVideo,
      }),
    }),
    sellerVenueDraftVideoPartialUpdate: build.mutation<
      SellerVenueDraftVideoPartialUpdateApiResponse,
      SellerVenueDraftVideoPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/video/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSellerVenueVideo,
      }),
    }),
    sellerVenueDraftVideoDestroy: build.mutation<
      SellerVenueDraftVideoDestroyApiResponse,
      SellerVenueDraftVideoDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/draft/video/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    sellerVenuePreviewList: build.query<
      SellerVenuePreviewListApiResponse,
      SellerVenuePreviewListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/preview/`,
        params: { limit: queryArg.limit, offset: queryArg.offset },
      }),
    }),
    sellerVenuePreviewRetrieve: build.query<
      SellerVenuePreviewRetrieveApiResponse,
      SellerVenuePreviewRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/seller/venue/preview/${queryArg.id}/`,
      }),
    }),
    sellerVenuePreviewDraftRetrieve: build.query<
      SellerVenuePreviewDraftRetrieveApiResponse,
      SellerVenuePreviewDraftRetrieveApiArg
    >({
      query: () => ({ url: `/api/seller/venue/preview/draft/` }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as sellerGeneratedApi }
export type SellerMeetingListApiResponse =
  /** status 200  */ PaginatedMeetingList
export type SellerMeetingListApiArg = {
  /** Number of results to return per page. */
  limit?: number
  /** The initial index from which to return the results. */
  offset?: number
}
export type SellerMeetingCreateApiResponse = /** status 201  */ Meeting
export type SellerMeetingCreateApiArg = {
  meeting: Meeting
}
export type SellerMeetingVenueListApiResponse =
  /** status 200  */ MeetingVenue[]
export type SellerMeetingVenueListApiArg = {
  meetingPk: number
}
export type SellerMeetingVenueCreateApiResponse =
  /** status 201  */ MeetingVenue
export type SellerMeetingVenueCreateApiArg = {
  meetingPk: number
  meetingVenue: MeetingVenue
}
export type SellerMeetingVenueRetrieveApiResponse =
  /** status 200  */ MeetingVenue
export type SellerMeetingVenueRetrieveApiArg = {
  meetingPk: number
  venueId: number
}
export type SellerMeetingVenueUpdateApiResponse =
  /** status 200  */ MeetingVenue
export type SellerMeetingVenueUpdateApiArg = {
  meetingPk: number
  venueId: number
  meetingVenue: MeetingVenue
}
export type SellerMeetingVenuePartialUpdateApiResponse =
  /** status 200  */ MeetingVenue
export type SellerMeetingVenuePartialUpdateApiArg = {
  meetingPk: number
  venueId: number
  patchedMeetingVenue: PatchedMeetingVenue
}
export type SellerMeetingVenueDestroyApiResponse = unknown
export type SellerMeetingVenueDestroyApiArg = {
  meetingPk: number
  venueId: number
}
export type SellerMeetingVenueVenuesUpdateApiResponse =
  /** status 200  */ MeetingVenue
export type SellerMeetingVenueVenuesUpdateApiArg = {
  meetingPk: number
  meetingVenues: MeetingVenues
}
export type SellerMeetingRetrieveApiResponse = /** status 200  */ Meeting
export type SellerMeetingRetrieveApiArg = {
  /** A unique integer value identifying this private meeting. */
  id: number
}
export type SellerMeetingUpdateApiResponse = /** status 200  */ Meeting
export type SellerMeetingUpdateApiArg = {
  /** A unique integer value identifying this private meeting. */
  id: number
  meeting: Meeting
}
export type SellerMeetingPartialUpdateApiResponse = /** status 200  */ Meeting
export type SellerMeetingPartialUpdateApiArg = {
  /** A unique integer value identifying this private meeting. */
  id: number
  patchedMeeting: PatchedMeeting
}
export type SellerMeetingDestroyApiResponse = unknown
export type SellerMeetingDestroyApiArg = {
  /** A unique integer value identifying this private meeting. */
  id: number
}
export type SellerVenueListApiResponse =
  /** status 200  */ PaginatedSellerVenueList
export type SellerVenueListApiArg = {
  /** Number of results to return per page. */
  limit?: number
  /** The initial index from which to return the results. */
  offset?: number
}
export type SellerVenueCreateApiResponse = /** status 201  */ SellerVenue
export type SellerVenueCreateApiArg = {
  sellerVenue: SellerVenue
}
export type SellerVenueRetrieveApiResponse = /** status 200  */ SellerVenue
export type SellerVenueRetrieveApiArg = {
  id: string
}
export type SellerVenueUpdateApiResponse = /** status 200  */ SellerVenue
export type SellerVenueUpdateApiArg = {
  id: string
  sellerVenue: SellerVenue
}
export type SellerVenuePartialUpdateApiResponse = /** status 200  */ SellerVenue
export type SellerVenuePartialUpdateApiArg = {
  id: string
  patchedSellerVenue: PatchedSellerVenue
}
export type SellerVenueDestroyApiResponse = unknown
export type SellerVenueDestroyApiArg = {
  id: string
}
export type SellerVenueHeroImageUpdateApiResponse =
  /** status 200  */ SellerVenueHeroImage
export type SellerVenueHeroImageUpdateApiArg = {
  id: string
  sellerVenueHeroImage: SellerVenueHeroImage
}
export type SellerVenueThumbnailUpdateApiResponse =
  /** status 200  */ SellerVenueThumbnail
export type SellerVenueThumbnailUpdateApiArg = {
  id: string
  sellerVenueThumbnail: SellerVenueThumbnail
}
export type SellerVenueManagerListApiResponse =
  /** status 200  */ VenueManager[]
export type SellerVenueManagerListApiArg = {
  venuePk: string
}
export type SellerVenueManagerCreateApiResponse =
  /** status 201  */ VenueManager
export type SellerVenueManagerCreateApiArg = {
  venuePk: string
  venueManager: VenueManager
}
export type SellerVenueManagerRetrieveApiResponse =
  /** status 200  */ VenueManager
export type SellerVenueManagerRetrieveApiArg = {
  id: string
  venuePk: string
}
export type SellerVenueManagerDestroyApiResponse = unknown
export type SellerVenueManagerDestroyApiArg = {
  id: string
  venuePk: string
}
export type SellerVenuePhotoListApiResponse =
  /** status 200  */ SellerVenuePhoto[]
export type SellerVenuePhotoListApiArg = {
  venuePk: string
}
export type SellerVenuePhotoCreateApiResponse =
  /** status 201  */ SellerVenuePhoto
export type SellerVenuePhotoCreateApiArg = {
  venuePk: string
  sellerVenuePhoto: SellerVenuePhoto
}
export type SellerVenuePhotoRetrieveApiResponse =
  /** status 200  */ SellerVenuePhoto
export type SellerVenuePhotoRetrieveApiArg = {
  id: string
  venuePk: string
}
export type SellerVenuePhotoUpdateApiResponse =
  /** status 200  */ SellerVenuePhoto
export type SellerVenuePhotoUpdateApiArg = {
  id: string
  venuePk: string
  sellerVenuePhoto: SellerVenuePhoto
}
export type SellerVenuePhotoPartialUpdateApiResponse =
  /** status 200  */ SellerVenuePhoto
export type SellerVenuePhotoPartialUpdateApiArg = {
  id: string
  venuePk: string
  patchedSellerVenuePhoto: PatchedSellerVenuePhoto
}
export type SellerVenuePhotoDestroyApiResponse = unknown
export type SellerVenuePhotoDestroyApiArg = {
  id: string
  venuePk: string
}
export type SellerVenueSpaceListApiResponse =
  /** status 200  */ SellerVenueSpace[]
export type SellerVenueSpaceListApiArg = {
  venuePk: string
}
export type SellerVenueSpaceCreateApiResponse =
  /** status 201  */ SellerVenueSpace
export type SellerVenueSpaceCreateApiArg = {
  venuePk: string
  sellerVenueSpace: SellerVenueSpace
}
export type SellerVenueSpaceRetrieveApiResponse =
  /** status 200  */ SellerVenueSpace
export type SellerVenueSpaceRetrieveApiArg = {
  id: string
  venuePk: string
}
export type SellerVenueSpaceUpdateApiResponse =
  /** status 200  */ SellerVenueSpace
export type SellerVenueSpaceUpdateApiArg = {
  id: string
  venuePk: string
  sellerVenueSpace: SellerVenueSpace
}
export type SellerVenueSpacePartialUpdateApiResponse =
  /** status 200  */ SellerVenueSpace
export type SellerVenueSpacePartialUpdateApiArg = {
  id: string
  venuePk: string
  patchedSellerVenueSpace: PatchedSellerVenueSpace
}
export type SellerVenueSpaceDestroyApiResponse = unknown
export type SellerVenueSpaceDestroyApiArg = {
  id: string
  venuePk: string
}
export type SellerVenueSpaceAndroidSceneUpdateApiResponse =
  /** status 200  */ SellerVenueSpaceAndroidScene
export type SellerVenueSpaceAndroidSceneUpdateApiArg = {
  id: string
  venuePk: string
  sellerVenueSpaceAndroidScene: SellerVenueSpaceAndroidScene
}
export type SellerVenueSpaceIosSceneUpdateApiResponse =
  /** status 200  */ SellerVenueSpaceIosScene
export type SellerVenueSpaceIosSceneUpdateApiArg = {
  id: string
  venuePk: string
  sellerVenueSpaceIosScene: SellerVenueSpaceIosScene
}
export type SellerVenueSpaceQuestSceneUpdateApiResponse =
  /** status 200  */ SellerVenueSpaceQuestScene
export type SellerVenueSpaceQuestSceneUpdateApiArg = {
  id: string
  venuePk: string
  sellerVenueSpaceQuestScene: SellerVenueSpaceQuestScene
}
export type SellerVenueSpaceThumbnailUpdateApiResponse =
  /** status 200  */ SellerVenueSpaceThumbnail
export type SellerVenueSpaceThumbnailUpdateApiArg = {
  id: string
  venuePk: string
  sellerVenueSpaceThumbnail: SellerVenueSpaceThumbnail
}
export type SellerVenueVideoListApiResponse =
  /** status 200  */ SellerVenueVideo[]
export type SellerVenueVideoListApiArg = {
  venuePk: string
}
export type SellerVenueVideoCreateApiResponse =
  /** status 201  */ SellerVenueVideo
export type SellerVenueVideoCreateApiArg = {
  venuePk: string
  sellerVenueVideo: SellerVenueVideo
}
export type SellerVenueVideoRetrieveApiResponse =
  /** status 200  */ SellerVenueVideo
export type SellerVenueVideoRetrieveApiArg = {
  id: string
  venuePk: string
}
export type SellerVenueVideoUpdateApiResponse =
  /** status 200  */ SellerVenueVideo
export type SellerVenueVideoUpdateApiArg = {
  id: string
  venuePk: string
  sellerVenueVideo: SellerVenueVideo
}
export type SellerVenueVideoPartialUpdateApiResponse =
  /** status 200  */ SellerVenueVideo
export type SellerVenueVideoPartialUpdateApiArg = {
  id: string
  venuePk: string
  patchedSellerVenueVideo: PatchedSellerVenueVideo
}
export type SellerVenueVideoDestroyApiResponse = unknown
export type SellerVenueVideoDestroyApiArg = {
  id: string
  venuePk: string
}
export type SellerVenueAutocompleteListApiResponse =
  /** status 200  */ AutocompleteCandidate[]
export type SellerVenueAutocompleteListApiArg = {
  /** Max number of results */
  limit?: number
  /** Search terms separated by space */
  search: string
}
export type SellerVenueAutocompleteRetrieveApiResponse =
  /** status 200  */ AutocompleteCandidateDetail
export type SellerVenueAutocompleteRetrieveApiArg = {
  /** A unique integer value identifying this imported venue. */
  id: number
}
export type SellerVenueDraftRetrieveApiResponse = /** status 200  */ SellerVenue
export type SellerVenueDraftRetrieveApiArg = void
export type SellerVenueDraftCreateApiResponse = /** status 200  */ SellerVenue
export type SellerVenueDraftCreateApiArg = {
  sellerVenue: SellerVenue
}
export type SellerVenueDraftUpdateApiResponse = /** status 200  */ SellerVenue
export type SellerVenueDraftUpdateApiArg = {
  sellerVenue: SellerVenue
}
export type SellerVenueDraftPartialUpdateApiResponse =
  /** status 200  */ SellerVenue
export type SellerVenueDraftPartialUpdateApiArg = {
  patchedSellerVenue: PatchedSellerVenue
}
export type SellerVenueDraftDestroyApiResponse = unknown
export type SellerVenueDraftDestroyApiArg = void
export type SellerVenueDraftHeroImageUpdateApiResponse =
  /** status 200  */ SellerVenueHeroImage
export type SellerVenueDraftHeroImageUpdateApiArg = {
  sellerVenueHeroImage: SellerVenueHeroImage
}
export type SellerVenueDraftManagerListApiResponse =
  /** status 200  */ VenueManager[]
export type SellerVenueDraftManagerListApiArg = void
export type SellerVenueDraftManagerCreateApiResponse =
  /** status 201  */ VenueManager
export type SellerVenueDraftManagerCreateApiArg = {
  venueManager: VenueManager
}
export type SellerVenueDraftManagerRetrieveApiResponse =
  /** status 200  */ VenueManager
export type SellerVenueDraftManagerRetrieveApiArg = {
  id: string
}
export type SellerVenueDraftManagerDestroyApiResponse = unknown
export type SellerVenueDraftManagerDestroyApiArg = {
  id: string
}
export type SellerVenueDraftPhotoListApiResponse =
  /** status 200  */ SellerVenuePhoto[]
export type SellerVenueDraftPhotoListApiArg = void
export type SellerVenueDraftPhotoCreateApiResponse =
  /** status 201  */ SellerVenuePhoto
export type SellerVenueDraftPhotoCreateApiArg = {
  sellerVenuePhoto: SellerVenuePhoto
}
export type SellerVenueDraftPhotoRetrieveApiResponse =
  /** status 200  */ SellerVenuePhoto
export type SellerVenueDraftPhotoRetrieveApiArg = {
  id: string
}
export type SellerVenueDraftPhotoUpdateApiResponse =
  /** status 200  */ SellerVenuePhoto
export type SellerVenueDraftPhotoUpdateApiArg = {
  id: string
  sellerVenuePhoto: SellerVenuePhoto
}
export type SellerVenueDraftPhotoPartialUpdateApiResponse =
  /** status 200  */ SellerVenuePhoto
export type SellerVenueDraftPhotoPartialUpdateApiArg = {
  id: string
  patchedSellerVenuePhoto: PatchedSellerVenuePhoto
}
export type SellerVenueDraftPhotoDestroyApiResponse = unknown
export type SellerVenueDraftPhotoDestroyApiArg = {
  id: string
}
export type SellerVenueDraftSpaceListApiResponse =
  /** status 200  */ SellerVenueSpace[]
export type SellerVenueDraftSpaceListApiArg = void
export type SellerVenueDraftSpaceCreateApiResponse =
  /** status 201  */ SellerVenueSpace
export type SellerVenueDraftSpaceCreateApiArg = {
  sellerVenueSpace: SellerVenueSpace
}
export type SellerVenueDraftSpaceRetrieveApiResponse =
  /** status 200  */ SellerVenueSpace
export type SellerVenueDraftSpaceRetrieveApiArg = {
  id: string
}
export type SellerVenueDraftSpaceUpdateApiResponse =
  /** status 200  */ SellerVenueSpace
export type SellerVenueDraftSpaceUpdateApiArg = {
  id: string
  sellerVenueSpace: SellerVenueSpace
}
export type SellerVenueDraftSpacePartialUpdateApiResponse =
  /** status 200  */ SellerVenueSpace
export type SellerVenueDraftSpacePartialUpdateApiArg = {
  id: string
  patchedSellerVenueSpace: PatchedSellerVenueSpace
}
export type SellerVenueDraftSpaceDestroyApiResponse = unknown
export type SellerVenueDraftSpaceDestroyApiArg = {
  id: string
}
export type SellerVenueDraftSpaceAndroidSceneUpdateApiResponse =
  /** status 200  */ SellerVenueSpaceAndroidScene
export type SellerVenueDraftSpaceAndroidSceneUpdateApiArg = {
  id: string
  sellerVenueSpaceAndroidScene: SellerVenueSpaceAndroidScene
}
export type SellerVenueDraftSpaceIosSceneUpdateApiResponse =
  /** status 200  */ SellerVenueSpaceIosScene
export type SellerVenueDraftSpaceIosSceneUpdateApiArg = {
  id: string
  sellerVenueSpaceIosScene: SellerVenueSpaceIosScene
}
export type SellerVenueDraftSpaceQuestSceneUpdateApiResponse =
  /** status 200  */ SellerVenueSpaceQuestScene
export type SellerVenueDraftSpaceQuestSceneUpdateApiArg = {
  id: string
  sellerVenueSpaceQuestScene: SellerVenueSpaceQuestScene
}
export type SellerVenueDraftSpaceThumbnailUpdateApiResponse =
  /** status 200  */ SellerVenueSpaceThumbnail
export type SellerVenueDraftSpaceThumbnailUpdateApiArg = {
  id: string
  sellerVenueSpaceThumbnail: SellerVenueSpaceThumbnail
}
export type SellerVenueDraftThumbnailUpdateApiResponse =
  /** status 200  */ SellerVenueThumbnail
export type SellerVenueDraftThumbnailUpdateApiArg = {
  sellerVenueThumbnail: SellerVenueThumbnail
}
export type SellerVenueDraftVideoListApiResponse =
  /** status 200  */ SellerVenueVideo[]
export type SellerVenueDraftVideoListApiArg = void
export type SellerVenueDraftVideoCreateApiResponse =
  /** status 201  */ SellerVenueVideo
export type SellerVenueDraftVideoCreateApiArg = {
  sellerVenueVideo: SellerVenueVideo
}
export type SellerVenueDraftVideoRetrieveApiResponse =
  /** status 200  */ SellerVenueVideo
export type SellerVenueDraftVideoRetrieveApiArg = {
  id: string
}
export type SellerVenueDraftVideoUpdateApiResponse =
  /** status 200  */ SellerVenueVideo
export type SellerVenueDraftVideoUpdateApiArg = {
  id: string
  sellerVenueVideo: SellerVenueVideo
}
export type SellerVenueDraftVideoPartialUpdateApiResponse =
  /** status 200  */ SellerVenueVideo
export type SellerVenueDraftVideoPartialUpdateApiArg = {
  id: string
  patchedSellerVenueVideo: PatchedSellerVenueVideo
}
export type SellerVenueDraftVideoDestroyApiResponse = unknown
export type SellerVenueDraftVideoDestroyApiArg = {
  id: string
}
export type SellerVenuePreviewListApiResponse =
  /** status 200  */ PaginatedVenueList
export type SellerVenuePreviewListApiArg = {
  /** Number of results to return per page. */
  limit?: number
  /** The initial index from which to return the results. */
  offset?: number
}
export type SellerVenuePreviewRetrieveApiResponse = /** status 200  */ Venue
export type SellerVenuePreviewRetrieveApiArg = {
  id: string
}
export type SellerVenuePreviewDraftRetrieveApiResponse =
  /** status 200  */ Venue
export type SellerVenuePreviewDraftRetrieveApiArg = void
export type MemberOfEnum =
  | "Accor Hotels"
  | "Aman"
  | "Anantara Hotels, Resorts & Spas"
  | "Andaz"
  | "Angsana"
  | "Autograph Collection"
  | "Avani Hotels & Resorts"
  | "Banyan Tree"
  | "Barri\u00E8re"
  | "Belmond"
  | "Best Western Hotels & Resorts"
  | "Bulgari Hotels & Resorts"
  | "Canopy by Hilton"
  | "Centara Hotels & Resorts"
  | "Choice Hotels"
  | "Conrad Hotels"
  | "Crowne Plaza Hotels & Resorts"
  | "Curio Collection by Hilton"
  | "Dedica Anthology Hotels"
  | "Delta Hotels by Marriott"
  | "Design Hotels"
  | "Disney Parks, Experiences and Products"
  | "DoubleTree by Hilton"
  | "Dusit Hotels & Resorts"
  | "Edition"
  | "Elewana Collection"
  | "Fairmont"
  | "Four Seasons"
  | "Gran Meli\u00E1 Hotels & Resorts"
  | "Grand Hyatt"
  | "Grand Mercure"
  | "Hesperia Hotels & Resorts"
  | "Hilton"
  | "HOSHINOYA"
  | "Hyatt"
  | "Hyatt Regency"
  | "InterContinental Hotels & Resorts"
  | "Interstate Hotels & Resorts"
  | "Jumeirah"
  | "JW Marriott"
  | "Kempinski Hotels"
  | "Kimpton Hotels"
  | "Langham Hotels & Resorts"
  | "Le M\u00E9ridien"
  | "Loews Hotels"
  | "Lotte Hotels"
  | "Luxury Collection"
  | "Mandarin Oriental Hotel Group"
  | "Marriott"
  | "Meli\u00E1 Hotels & Resorts"
  | "MGM Resorts"
  | "Millennium Hotels & Resorts"
  | "NH Collection Hotels"
  | "NH Hotels"
  | "Oberoi Hotels & Resorts"
  | "Okura Hotels & Resorts"
  | "Omni Hotels & Resorts"
  | "Pan Pacific Hotels Group"
  | "Park Hyatt"
  | "Park Plaza"
  | "Preferred Hotels & Resorts"
  | "Prince Hotels"
  | "Radisson Blu"
  | "Radisson Collection"
  | "Raffles Hotels & Resorts"
  | "Regent Hotels & Resorts"
  | "Renaissance Hotels"
  | "Ritz-Carlton"
  | "RIU Hotels & Resorts"
  | "Rocco Forte Hotels"
  | "Rosewood Hotels & Resorts"
  | "Scandic Hotels"
  | "Shangri-La"
  | "Sheraton"
  | "Sofitel"
  | "Soneva"
  | "St. Regis"
  | "Swiss\u00F4tel"
  | "Tivoli Hotels & Resorts"
  | "Tokyu Hotels"
  | "Toyoko Inn"
  | "Travelodge"
  | "Treebo"
  | "Tribute Portfolio"
  | "W Hotels"
  | "Waldorf Astoria Hotels & Resorts"
  | "Warwick Hotels & Resorts"
  | "Westgate Resorts"
  | "Westin Hotels & Resorts"
  | "Wyndham"
export type ConsortiumMembershipEnum =
  | "Leading Hotels of the World (LHW)"
  | "Minor Hotels"
  | "Relais & Ch\u00E2teaux"
  | "Signature Travel Network"
  | "Small Luxury Hotels of the World (SLH)"
  | "Traveller Made"
  | "Virtuoso"
export type HealthcareCompliantEnum = "yes" | "unknown"
export type BlankEnum = ""
export type NullEnum = null
export type PropertyTypeEnum =
  | "Airport"
  | "Beach Resort"
  | "Boutique Hotel"
  | "City Centre"
  | "Conference Hotel"
  | "Countryside"
  | "Golf & Leisure Resort"
  | "Serviced Apartments"
  | "Ski Resort"
  | "Spa Retreat"
export type StarRatingEnum = 3 | 4 | 5
export type NumberOfBedroomsEnum =
  | "Under 100"
  | "100 - 250"
  | "251-500"
  | "500+"
export type NumberOfMeetingRoomsEnum =
  | "None"
  | "Under 5"
  | "5-10"
  | "11-20"
  | "20+"
export type TypeOfEventsHostedEnum =
  | "Incentives"
  | "Congresses/Conventions"
  | "Conferences"
  | "Meetings"
  | "Sustainable Events"
  | "Experiential Events"
  | "Exhibitions"
export type TotalMeetingSpaceEnum =
  | "No Meeting Rooms"
  | "1 - 250 sq. m / 10 - 2'500 sq. ft"
  | "251 - 1'000 sq. m / 2'501 - 10'000 sq. ft"
  | "1'001 - 2'500 sq. m / 10'001 - 27'000 sq. ft"
  | "2'501 - 5'000 sq. m / 27'001 - 54'000 sq. ft"
  | "5'001 - 10'000 sq. m / 54'001 - 100'000 sq. ft"
  | "10'000+ sq. m / 100'000+ sq. ft"
export type SizeOfLargestMeetingRoomEnum =
  | "No Meeting Rooms"
  | "1 - 250 sq. m / 10 - 2'500 sq. ft"
  | "251 - 1'000 sq. m / 2'501 - 10'000 sq. ft"
  | "1'000+ sq. m / 10'000+ sq. ft"
export type MaximumCapacityOfLargestMeetingRoomEnum =
  | "N/A"
  | "Under 50"
  | "50-100"
  | "101-200"
  | "201-500"
  | "501-1'000"
  | "1'001-5'000"
  | "5'000+"
export type AverageDailyRateEnum =
  | "under $150"
  | "$150-250"
  | "$250-500"
  | "$500+"
  | "under \u20AC100"
  | "\u20AC100-200"
  | "\u20AC200-300"
  | "\u20AC300+"
export type DistanceToNearestAirportEnum =
  | "1 - 10 km / 0.5 - 5 Miles"
  | "10 - 50 km / 5 - 30 Miles"
  | "50+ km / 30+ Miles"
export type LeisureFacilitiesEnum =
  | "Beach"
  | "Casino"
  | "Club"
  | "Entertainment"
  | "Golf"
  | "Gym"
  | "Kids' Club"
  | "Spa"
  | "Speciality Dining"
  | "Swimming Pool"
  | "Tennis"
  | "Water Sports"
  | "Winter Sports"
export type BestTimeOfYearForGroupsEnum =
  | "January"
  | "February"
  | "March"
  | "April"
  | "May"
  | "June"
  | "July"
  | "August"
  | "September"
  | "October"
  | "November"
  | "December"
export type VenuePhoto = {
  id: number
  image: string
  link: string | null
  thumbnail_link: string
  title: string | null
}
export type SellerVenueSpace = {
  name: string
  size?: number | null
  ceiling_height?: number | null
  dimension_l?: number | null
  dimension_w?: number | null
  natural_lighting?: boolean | null
  capacity_theater?: number | null
  capacity_cabaret?: number | null
  capacity_classroom?: number | null
  capacity_ushape?: number | null
  capacity_boardroom?: number | null
  capacity_banquet?: number | null
  capacity_cocktail?: number | null
  matterport_url?: string | null
  id: number
  last_modified: string
  matterport_modified: string | null
  thumbnail_link: string | null
  quest_scene_link: string | null
  ios_scene_link: string | null
  android_scene_link: string | null
  quest_scene_last_modified: string | null
  ios_scene_last_modified: string | null
  android_scene_last_modified: string | null
}
export type AvailabilityEnum = "PUBLIC" | "PRIVATE"
export type SellerVenue = {
  name: string
  brand?: string | null
  description?: string | null
  street_address?: string | null
  city?: string | null
  postal_code?: string | null
  state?: string | null
  country?: string | null
  latitude?: string | null
  longitude?: string | null
  website?: string | null
  member_of?: MemberOfEnum[] | null
  consortium_membership?: ConsortiumMembershipEnum[] | null
  sustainable_accreditation?: string | null
  healthcare_compliant?: (HealthcareCompliantEnum | BlankEnum | NullEnum) | null
  healthcare_accreditation?: string | null
  property_type?: (PropertyTypeEnum | BlankEnum | NullEnum) | null
  star_rating?: (StarRatingEnum | NullEnum) | null
  date_of_last_renovation?: number | null
  number_of_bedrooms?: (NumberOfBedroomsEnum | BlankEnum | NullEnum) | null
  number_of_meeting_rooms?:
    | (NumberOfMeetingRoomsEnum | BlankEnum | NullEnum)
    | null
  type_of_events_hosted?: TypeOfEventsHostedEnum[] | null
  total_meeting_space?: (TotalMeetingSpaceEnum | BlankEnum | NullEnum) | null
  size_of_largest_meeting_room?:
    | (SizeOfLargestMeetingRoomEnum | BlankEnum | NullEnum)
    | null
  maximum_capacity_of_largest_meeting_room?:
    | (MaximumCapacityOfLargestMeetingRoomEnum | BlankEnum | NullEnum)
    | null
  average_daily_rate?: ((AverageDailyRateEnum | NullEnum) | null)[] | null
  nearest_airports?: string | null
  distance_to_nearest_airport?:
    | (DistanceToNearestAirportEnum | BlankEnum | NullEnum)
    | null
  leisure_facilities?: LeisureFacilitiesEnum[] | null
  best_time_of_year_for_groups?: BestTimeOfYearForGroupsEnum[] | null
  maximum_capacity_of_largest_meeting_room_exact?: number | null
  id: number
  logo_link: string | null
  hero_image_link: string | null
  thumbnail_link: string | null
  photos?: VenuePhoto[]
  spaces: SellerVenueSpace[]
  draft?: boolean
  owner?: number
  availability?: AvailabilityEnum
  source_imported_venue_id?: number
}
export type Meeting = {
  id: number
  meeting_code: string
  expires_at: string
  expired: boolean
  venues: SellerVenue[]
}
export type PaginatedMeetingList = {
  count?: number
  next?: string | null
  previous?: string | null
  results?: Meeting[]
}
export type MeetingVenue = {
  venue_id: number
}
export type PatchedMeetingVenue = {
  venue_id?: number
}
export type MeetingVenues = {
  venue_ids: number[]
}
export type PatchedMeeting = {
  id?: number
  meeting_code?: string
  expires_at?: string
  expired?: boolean
  venues?: SellerVenue[]
}
export type PaginatedSellerVenueList = {
  count?: number
  next?: string | null
  previous?: string | null
  results?: SellerVenue[]
}
export type PatchedSellerVenue = {
  name?: string
  brand?: string | null
  description?: string | null
  street_address?: string | null
  city?: string | null
  postal_code?: string | null
  state?: string | null
  country?: string | null
  latitude?: string | null
  longitude?: string | null
  website?: string | null
  member_of?: MemberOfEnum[] | null
  consortium_membership?: ConsortiumMembershipEnum[] | null
  sustainable_accreditation?: string | null
  healthcare_compliant?: (HealthcareCompliantEnum | BlankEnum | NullEnum) | null
  healthcare_accreditation?: string | null
  property_type?: (PropertyTypeEnum | BlankEnum | NullEnum) | null
  star_rating?: (StarRatingEnum | NullEnum) | null
  date_of_last_renovation?: number | null
  number_of_bedrooms?: (NumberOfBedroomsEnum | BlankEnum | NullEnum) | null
  number_of_meeting_rooms?:
    | (NumberOfMeetingRoomsEnum | BlankEnum | NullEnum)
    | null
  type_of_events_hosted?: TypeOfEventsHostedEnum[] | null
  total_meeting_space?: (TotalMeetingSpaceEnum | BlankEnum | NullEnum) | null
  size_of_largest_meeting_room?:
    | (SizeOfLargestMeetingRoomEnum | BlankEnum | NullEnum)
    | null
  maximum_capacity_of_largest_meeting_room?:
    | (MaximumCapacityOfLargestMeetingRoomEnum | BlankEnum | NullEnum)
    | null
  average_daily_rate?: ((AverageDailyRateEnum | NullEnum) | null)[] | null
  nearest_airports?: string | null
  distance_to_nearest_airport?:
    | (DistanceToNearestAirportEnum | BlankEnum | NullEnum)
    | null
  leisure_facilities?: LeisureFacilitiesEnum[] | null
  best_time_of_year_for_groups?: BestTimeOfYearForGroupsEnum[] | null
  maximum_capacity_of_largest_meeting_room_exact?: number | null
  id?: number
  logo_link?: string | null
  hero_image_link?: string | null
  thumbnail_link?: string | null
  photos?: VenuePhoto[]
  spaces?: SellerVenueSpace[]
  draft?: boolean
  owner?: number
  availability?: AvailabilityEnum
  source_imported_venue_id?: number
}
export type SellerVenueHeroImage = {
  hero_image?: string | null
}
export type SellerVenueThumbnail = {
  thumbnail?: string | null
}
export type UserShort = {
  id: number
  email: string
  username: string
  first_name: string
  last_name: string
}
export type VenueManagerRoleEnum = "manager" | "owner"
export type VenueManager = {
  email: string
  id: number
  user: UserShort
  invited: boolean
  added_at: string
  role?: VenueManagerRoleEnum
}
export type SellerVenuePhoto = {
  image: string
  title?: string | null
  id: number
  created_at: string
  thumbnail: string | null
}
export type PatchedSellerVenuePhoto = {
  image?: string
  title?: string | null
  id?: number
  created_at?: string
  thumbnail?: string | null
}
export type PatchedSellerVenueSpace = {
  name?: string
  size?: number | null
  ceiling_height?: number | null
  dimension_l?: number | null
  dimension_w?: number | null
  natural_lighting?: boolean | null
  capacity_theater?: number | null
  capacity_cabaret?: number | null
  capacity_classroom?: number | null
  capacity_ushape?: number | null
  capacity_boardroom?: number | null
  capacity_banquet?: number | null
  capacity_cocktail?: number | null
  matterport_url?: string | null
  id?: number
  last_modified?: string
  matterport_modified?: string | null
  thumbnail_link?: string | null
  quest_scene_link?: string | null
  ios_scene_link?: string | null
  android_scene_link?: string | null
  quest_scene_last_modified?: string | null
  ios_scene_last_modified?: string | null
  android_scene_last_modified?: string | null
}
export type SellerVenueSpaceAndroidScene = {
  vr_scene_android?: string | null
}
export type SellerVenueSpaceIosScene = {
  vr_scene_ios?: string | null
}
export type SellerVenueSpaceQuestScene = {
  vr_scene_quest?: string | null
}
export type SellerVenueSpaceThumbnail = {
  thumbnail?: string | null
}
export type SellerVenueVideo = {
  video: string
  title?: string | null
  thumbnail?: string | null
  id: number
  created_at: string
}
export type PatchedSellerVenueVideo = {
  video?: string
  title?: string | null
  thumbnail?: string | null
  id?: number
  created_at?: string
}
export type AutocompleteCandidate = {
  id: number
  name: string
}
export type AutocompleteCandidateDetail = {
  name: string
  brand?: string | null
  description?: string | null
  street_address?: string | null
  city?: string | null
  postal_code?: string | null
  state?: string | null
  country?: string | null
  latitude?: string | null
  longitude?: string | null
  website?: string | null
  member_of?: MemberOfEnum[] | null
  consortium_membership?: ConsortiumMembershipEnum[] | null
  sustainable_accreditation?: string | null
  healthcare_compliant?: (HealthcareCompliantEnum | BlankEnum | NullEnum) | null
  healthcare_accreditation?: string | null
  property_type?: (PropertyTypeEnum | BlankEnum | NullEnum) | null
  star_rating?: (StarRatingEnum | NullEnum) | null
  date_of_last_renovation?: number | null
  number_of_bedrooms?: (NumberOfBedroomsEnum | BlankEnum | NullEnum) | null
  number_of_meeting_rooms?:
    | (NumberOfMeetingRoomsEnum | BlankEnum | NullEnum)
    | null
  type_of_events_hosted?: TypeOfEventsHostedEnum[] | null
  total_meeting_space?: (TotalMeetingSpaceEnum | BlankEnum | NullEnum) | null
  size_of_largest_meeting_room?:
    | (SizeOfLargestMeetingRoomEnum | BlankEnum | NullEnum)
    | null
  maximum_capacity_of_largest_meeting_room?:
    | (MaximumCapacityOfLargestMeetingRoomEnum | BlankEnum | NullEnum)
    | null
  average_daily_rate?: ((AverageDailyRateEnum | NullEnum) | null)[] | null
  nearest_airports?: string | null
  distance_to_nearest_airport?:
    | (DistanceToNearestAirportEnum | BlankEnum | NullEnum)
    | null
  leisure_facilities?: LeisureFacilitiesEnum[] | null
  best_time_of_year_for_groups?: BestTimeOfYearForGroupsEnum[] | null
}
export type VenueSpace = {
  id: number
  thumbnail: string | null
  available_in: string[]
  name: string
  size?: number | null
  ceiling_height?: number | null
  dimension_l?: number | null
  dimension_w?: number | null
  natural_lighting?: boolean | null
  capacity_theater?: number | null
  capacity_cabaret?: number | null
  capacity_classroom?: number | null
  capacity_ushape?: number | null
  capacity_boardroom?: number | null
  capacity_banquet?: number | null
  capacity_cocktail?: number | null
  last_modified: string
  matterport_url?: string | null
  matterport_modified?: string | null
  vr_scene_quest?: string | null
  quest_scene_last_modified?: string | null
  vr_scene_ios?: string | null
  ios_scene_last_modified?: string | null
  vr_scene_android?: string | null
  android_scene_last_modified?: string | null
  guided_tour_json?: string | null
  guided_tour_wav?: string | null
}
export type Venue = {
  name: string
  brand?: string | null
  description?: string | null
  street_address?: string | null
  city?: string | null
  postal_code?: string | null
  state?: string | null
  country?: string | null
  latitude?: string | null
  longitude?: string | null
  website?: string | null
  member_of?: MemberOfEnum[] | null
  consortium_membership?: ConsortiumMembershipEnum[] | null
  sustainable_accreditation?: string | null
  healthcare_compliant?: (HealthcareCompliantEnum | BlankEnum | NullEnum) | null
  healthcare_accreditation?: string | null
  property_type?: (PropertyTypeEnum | BlankEnum | NullEnum) | null
  star_rating?: (StarRatingEnum | NullEnum) | null
  date_of_last_renovation?: number | null
  number_of_bedrooms?: (NumberOfBedroomsEnum | BlankEnum | NullEnum) | null
  number_of_meeting_rooms?:
    | (NumberOfMeetingRoomsEnum | BlankEnum | NullEnum)
    | null
  type_of_events_hosted?: TypeOfEventsHostedEnum[] | null
  total_meeting_space?: (TotalMeetingSpaceEnum | BlankEnum | NullEnum) | null
  size_of_largest_meeting_room?:
    | (SizeOfLargestMeetingRoomEnum | BlankEnum | NullEnum)
    | null
  maximum_capacity_of_largest_meeting_room?:
    | (MaximumCapacityOfLargestMeetingRoomEnum | BlankEnum | NullEnum)
    | null
  average_daily_rate?: ((AverageDailyRateEnum | NullEnum) | null)[] | null
  nearest_airports?: string | null
  distance_to_nearest_airport?:
    | (DistanceToNearestAirportEnum | BlankEnum | NullEnum)
    | null
  leisure_facilities?: LeisureFacilitiesEnum[] | null
  best_time_of_year_for_groups?: BestTimeOfYearForGroupsEnum[] | null
  maximum_capacity_of_largest_meeting_room_exact?: number | null
  id: number
  logo_link: string | null
  hero_image_link: string | null
  thumbnail_link: string | null
  photos?: VenuePhoto[]
  spaces?: VenueSpace[]
}
export type PaginatedVenueList = {
  count?: number
  next?: string | null
  previous?: string | null
  results?: Venue[]
}
