import { Box, Divider, Typography } from "@mui/material"
import { MeetingDeleteDialog } from "features/seller/MeetingDeleteDialog"
// import { ControlledSelectField } from "lib/components/form/ControlledSelectField"
import { useSellerMeetingListQuery } from "api/sellerApi"
import { LoadingIndicator } from "app/LoadingIndicator"
import { TopBar } from "app/TopBar"
import dayjs from "dayjs"
import { MeetingsContextMenu } from "features/seller/MeetingsContextMenu"
import { PlusButton } from "lib/components/CustomButton"
import { CustomTooltip } from "lib/components/Tooltip"
import { VENUE_THUMBNAIL_PLACEHOLDER } from "lib/public"
import { Route, Routes, useNavigate } from "react-router-dom"
import { EmptyMeetings } from "./EmptyMeetings"

function ActiveStatus() {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Box
        sx={{
          height: 8,
          width: 8,
          borderRadius: 4,
          backgroundColor: "#6DC270",
        }}
      ></Box>
      <Typography
        variant="body13"
        color="custom.dark900"
        flex={1}
        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
      >
        Active
      </Typography>
    </Box>
  )
}

function ExpiredStatus() {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Box
        sx={{
          height: 8,
          width: 8,
          borderRadius: 4,
          backgroundColor: "#E4E4EF",
        }}
      ></Box>
      <Typography
        variant="body13"
        color="custom.dark600"
        flex={1}
        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
      >
        Expired
      </Typography>
    </Box>
  )
}

export function SellerMeetings() {
  const { data: meetings, isFetching } = useSellerMeetingListQuery({})
  // useLoader(isFetching)
  const navigate = useNavigate()

  return (
    <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <TopBar title="Meetings" />

      <Routes>
        <Route path=":deleteId/delete" element={<MeetingDeleteDialog />} />
      </Routes>

      <Box
        sx={{
          display: "flex",
          flex: "1 1 0",
          flexDirection: "column",
          overflowY: "scroll",
        }}
      >
        {isFetching || !meetings ? (
          <LoadingIndicator />
        ) : !meetings.results?.length ? (
          <EmptyMeetings />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "custom.light600",
              borderRadius: 3,
              padding: 4,
              flexGrow: 1,
              margin: 3,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ flexGrow: 0 }}>
                <Typography
                  variant="h4"
                  color="custom.dark800"
                  flex={1}
                  sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  Your meetings
                </Typography>
              </Box>
              <Box sx={{ flex: "1 1 0" }}></Box>
              <Box sx={{ flexGrow: 0 }}>
                <PlusButton href="/meetings/new" variant="contained">
                  Add meeting
                </PlusButton>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
              <Divider sx={{ borderBottom: "1px solid #6464BB10" }} />
              <Box sx={{ display: "flex", py: 2, gap: 1 }}>
                <Typography
                  variant="body14"
                  color="custom.dark900"
                  flex={1}
                  sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  Access code
                </Typography>
                <Typography
                  variant="body14"
                  color="custom.dark900"
                  flex={1}
                  sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  Status
                </Typography>
                <Typography
                  variant="body14"
                  color="custom.dark900"
                  flex={4}
                  sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  Venues
                </Typography>
                <Typography
                  variant="body14"
                  color="custom.dark900"
                  flex={1}
                  sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  Expiration Date
                </Typography>
                <Box sx={{ display: "flex", flex: "48px 0 0" }}></Box>
              </Box>
              <Divider sx={{ borderBottom: "1px solid #6464BB10" }} />
              {meetings.results?.map((meeting) => {
                return (
                  <Box key={meeting.id}>
                    <Box
                      onClick={() => navigate(`/meetings/${meeting.id}`)}
                      sx={{
                        position: "relative",
                        display: "flex",
                        cursor: "pointer",
                        height: 82,
                        alignItems: "center",
                        gap: 1,
                        "&:hover": {
                          mx: -2,
                          px: 2,
                          height: 84,
                          my: "-1px",
                          backgroundColor: "white",
                          boxShadow: "0px 3px 14px rgba(17, 17, 128, 0.04)",
                          borderRadius: 2,
                        },
                      }}
                    >
                      <Typography
                        variant="h4"
                        color="custom.dark900"
                        flex={1}
                        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {meeting.meeting_code}
                      </Typography>
                      <Typography
                        variant="body13"
                        color="custom.dark900"
                        flex={1}
                        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {meeting.expired ? <ExpiredStatus /> : <ActiveStatus />}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          flex: 4,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexGrow: 1,
                            gap: 1,
                            overflow: "hidden",
                          }}
                        >
                          {meeting.venues.slice(0, 5).map((venue) => {
                            return (
                              <CustomTooltip
                                title={venue.name}
                                placement="top"
                                key={venue.id}
                              >
                                <Box
                                  sx={{
                                    height: 52,
                                    flex: "94px 0 0",
                                    borderRadius: 2,
                                    backgroundImage: `url(${
                                      venue.thumbnail_link ||
                                      VENUE_THUMBNAIL_PLACEHOLDER
                                    })`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                  }}
                                />
                              </CustomTooltip>
                            )
                          })}
                        </Box>
                        {meeting.venues.length > 5 && (
                          <Box
                            sx={{
                              height: 52,
                              flex: "94px 0 0",
                              borderRadius: 2,
                              backgroundColor: "custom.light700",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body13"
                              color="custom.accentsecondary"
                            >
                              +{meeting.venues.length - 5}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      <Typography
                        variant="body13"
                        color="custom.dark900"
                        flex={1}
                        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {dayjs(meeting.expires_at).format("MMMM D, YYYY")}
                      </Typography>
                      <Box
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                        }}
                      >
                        <MeetingsContextMenu meeting={meeting} />
                      </Box>
                    </Box>
                    <Divider sx={{ borderBottom: "1px solid #6464BB10" }} />
                  </Box>
                )
              })}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
