type Country = {
  country: string
  phone_code: string | string[]
  states: string[]
}

export const COUNTRIES: Record<string, Country> = {
  AD: {
    country: "Andorra",
    phone_code: "376",
    states: [
      "Andorra la Vella",
      "Canillo",
      "Encamp",
      "Escaldes-Engordany",
      "La Massana",
      "Ordino",
      "Sant Juli\u00e0 de L\u00f2ria",
    ],
  },
  AE: {
    country: "United Arab Emirates",
    phone_code: "971",
    states: [
      "Abu Dhabi Emirate",
      "Ajman Emirate",
      "Dubai",
      "Fujairah",
      "Ras al-Khaimah",
      "Sharjah Emirate",
      "Umm al-Quwain",
    ],
  },
  AF: {
    country: "Afghanistan",
    phone_code: "93",
    states: [
      "Badakhshan",
      "Badghis",
      "Baghlan",
      "Balkh",
      "Bamyan",
      "Daykundi",
      "Farah",
      "Faryab",
      "Ghazni",
      "Gh\u014dr",
      "Helmand",
      "Herat",
      "Jowzjan",
      "Kabul",
      "Kandahar",
      "Kapisa",
      "Khost",
      "Kunar",
      "Kunduz Province",
      "Laghman",
      "Logar",
      "Nangarhar",
      "Nimruz",
      "Nuristan",
      "Paktia",
      "Paktika",
      "Panjshir",
      "Parwan",
      "Samangan",
      "Sar-e Pol",
      "Takhar",
      "Urozgan",
      "Zabul",
    ],
  },
  AG: {
    country: "Antigua And Barbuda",
    phone_code: "1-268",
    states: [
      "Barbuda",
      "Redonda",
      "Saint George Parish",
      "Saint John Parish",
      "Saint Mary Parish",
      "Saint Paul Parish",
      "Saint Peter Parish",
      "Saint Philip Parish",
    ],
  },
  AI: {
    country: "Anguilla",
    phone_code: "1-264",
    states: [],
  },
  AL: {
    country: "Albania",
    phone_code: "355",
    states: [
      "Berat County",
      "Berat District",
      "Bulqiz\u00eb District",
      "Delvin\u00eb District",
      "Devoll District",
      "Dib\u00ebr County",
      "Dib\u00ebr District",
      "Durr\u00ebs County",
      "Durr\u00ebs District",
      "Elbasan County",
      "Fier County",
      "Fier District",
      "Gjirokast\u00ebr County",
      "Gjirokast\u00ebr District",
      "Gramsh District",
      "Has District",
      "Kavaj\u00eb District",
      "Kolonj\u00eb District",
      "Kor\u00e7\u00eb County",
      "Kor\u00e7\u00eb District",
      "Kruj\u00eb District",
      "Ku\u00e7ov\u00eb District",
      "Kuk\u00ebs County",
      "Kuk\u00ebs District",
      "Kurbin District",
      "Lezh\u00eb County",
      "Lezh\u00eb District",
      "Librazhd District",
      "Lushnj\u00eb District",
      "Mal\u00ebsi e Madhe District",
      "Mallakast\u00ebr District",
      "Mat District",
      "Mirdit\u00eb District",
      "Peqin District",
      "P\u00ebrmet District",
      "Pogradec District",
      "Puk\u00eb District",
      "Sarand\u00eb District",
      "Shkod\u00ebr County",
      "Shkod\u00ebr District",
      "Skrapar District",
      "Tepelen\u00eb District",
      "Tirana County",
      "Tirana District",
      "Tropoj\u00eb District",
      "Vlor\u00eb County",
      "Vlor\u00eb District",
    ],
  },
  AM: {
    country: "Armenia",
    phone_code: "374",
    states: [
      "Aragatsotn Region",
      "Ararat Province",
      "Armavir Region",
      "Gegharkunik Province",
      "Kotayk Region",
      "Lori Region",
      "Shirak Region",
      "Syunik Province",
      "Tavush Region",
      "Vayots Dzor Region",
      "Yerevan",
    ],
  },
  AO: {
    country: "Angola",
    phone_code: "244",
    states: [
      "Bengo Province",
      "Benguela Province",
      "Bi\u00e9 Province",
      "Cabinda Province",
      "Cuando Cubango Province",
      "Cuanza Norte Province",
      "Cuanza Sul",
      "Cunene Province",
      "Huambo Province",
      "Hu\u00edla Province",
      "Luanda Province",
      "Lunda Norte Province",
      "Lunda Sul Province",
      "Malanje Province",
      "Moxico Province",
      "U\u00edge Province",
      "Zaire Province",
    ],
  },
  AQ: {
    country: "Antarctica",
    phone_code: "672",
    states: [],
  },
  AR: {
    country: "Argentina",
    phone_code: "54",
    states: [
      "Buenos Aires",
      "Catamarca",
      "Chaco",
      "Chubut",
      "Ciudad Aut\u00f3noma de Buenos Aires",
      "C\u00f3rdoba",
      "Corrientes",
      "Entre R\u00edos",
      "Formosa",
      "Jujuy",
      "La Pampa",
      "La Rioja",
      "Mendoza",
      "Misiones",
      "Neuqu\u00e9n",
      "R\u00edo Negro",
      "Salta",
      "San Juan",
      "San Luis",
      "Santa Cruz",
      "Santa Fe",
      "Santiago del Estero",
      "Tierra del Fuego",
      "Tucum\u00e1n",
    ],
  },
  AS: {
    country: "American Samoa",
    phone_code: "1-684",
    states: [],
  },
  AT: {
    country: "Austria",
    phone_code: "43",
    states: [
      "Burgenland",
      "Carinthia",
      "Lower Austria",
      "Salzburg",
      "Styria",
      "Tyrol",
      "Upper Austria",
      "Vienna",
      "Vorarlberg",
    ],
  },
  AU: {
    country: "Australia",
    phone_code: "61",
    states: [
      "Australian Capital Territory",
      "New South Wales",
      "Northern Territory",
      "Queensland",
      "South Australia",
      "Tasmania",
      "Victoria",
      "Western Australia",
    ],
  },
  AW: {
    country: "Aruba",
    phone_code: "297",
    states: [],
  },
  AX: {
    country: "Aland Islands",
    phone_code: "358-18",
    states: [],
  },
  AZ: {
    country: "Azerbaijan",
    phone_code: "994",
    states: [
      "Absheron District",
      "Agdam District",
      "Agdash District",
      "Aghjabadi District",
      "Agstafa District",
      "Agsu District",
      "Astara District",
      "Babek District",
      "Baku",
      "Balakan District",
      "Barda District",
      "Beylagan District",
      "Bilasuvar District",
      "Dashkasan District",
      "Fizuli District",
      "Ganja",
      "G\u0259d\u0259b\u0259y",
      "Gobustan District",
      "Goranboy District",
      "Goychay",
      "Goygol District",
      "Hajigabul District",
      "Imishli District",
      "Ismailli District",
      "Jabrayil District",
      "Jalilabad District",
      "Julfa District",
      "Kalbajar District",
      "Kangarli District",
      "Khachmaz District",
      "Khizi District",
      "Khojali District",
      "Kurdamir District",
      "Lachin District",
      "Lankaran",
      "Lankaran District",
      "Lerik District",
      "Martuni",
      "Masally District",
      "Mingachevir",
      "Nakhchivan Autonomous Republic",
      "Neftchala District",
      "Oghuz District",
      "Ordubad District",
      "Qabala District",
      "Qakh District",
      "Qazakh District",
      "Quba District",
      "Qubadli District",
      "Qusar District",
      "Saatly District",
      "Sabirabad District",
      "Sadarak District",
      "Salyan District",
      "Samukh District",
      "Shabran District",
      "Shahbuz District",
      "Shaki",
      "Shaki District",
      "Shamakhi District",
      "Shamkir District",
      "Sharur District",
      "Shirvan",
      "Shusha District",
      "Siazan District",
      "Sumqayit",
      "Tartar District",
      "Tovuz District",
      "Ujar District",
      "Yardymli District",
      "Yevlakh",
      "Yevlakh District",
      "Zangilan District",
      "Zaqatala District",
      "Zardab District",
    ],
  },
  BA: {
    country: "Bosnia and Herzegovina",
    phone_code: "387",
    states: [
      "Bosnian Podrinje Canton",
      "Br\u010dko District",
      "Canton 10",
      "Central Bosnia Canton",
      "Federation of Bosnia and Herzegovina",
      "Herzegovina-Neretva Canton",
      "Posavina Canton",
      "Republika Srpska",
      "Sarajevo Canton",
      "Tuzla Canton",
      "Una-Sana Canton",
      "West Herzegovina Canton",
      "Zenica-Doboj Canton",
    ],
  },
  BB: {
    country: "Barbados",
    phone_code: "1-246",
    states: [
      "Christ Church",
      "Saint Andrew",
      "Saint George",
      "Saint James",
      "Saint John",
      "Saint Joseph",
      "Saint Lucy",
      "Saint Michael",
      "Saint Peter",
      "Saint Philip",
      "Saint Thomas",
    ],
  },
  BD: {
    country: "Bangladesh",
    phone_code: "880",
    states: [
      "Bagerhat District",
      "Bahadia",
      "Bandarban District",
      "Barguna District",
      "Barisal District",
      "Barisal Division",
      "Bhola District",
      "Bogra District",
      "Brahmanbaria District",
      "Chandpur District",
      "Chapai Nawabganj District",
      "Chittagong District",
      "Chittagong Division",
      "Chuadanga District",
      "Comilla District",
      "Cox's Bazar District",
      "Dhaka District",
      "Dhaka Division",
      "Dinajpur District",
      "Faridpur District",
      "Feni District",
      "Gaibandha District",
      "Gazipur District",
      "Gopalganj District",
      "Habiganj District",
      "Jamalpur District",
      "Jessore District",
      "Jhalokati District",
      "Jhenaidah District",
      "Joypurhat District",
      "Khagrachari District",
      "Khulna District",
      "Khulna Division",
      "Kishoreganj District",
      "Kurigram District",
      "Kushtia District",
      "Lakshmipur District",
      "Lalmonirhat District",
      "Madaripur District",
      "Meherpur District",
      "Moulvibazar District",
      "Munshiganj District",
      "Mymensingh District",
      "Mymensingh Division",
      "Naogaon District",
      "Narail District",
      "Narayanganj District",
      "Natore District",
      "Netrokona District",
      "Nilphamari District",
      "Noakhali District",
      "Pabna District",
      "Panchagarh District",
      "Patuakhali District",
      "Pirojpur District",
      "Rajbari District",
      "Rajshahi District",
      "Rajshahi Division",
      "Rangamati Hill District",
      "Rangpur District",
      "Rangpur Division",
      "Satkhira District",
      "Shariatpur District",
      "Sherpur District",
      "Sirajganj District",
      "Sunamganj District",
      "Sylhet District",
      "Sylhet Division",
      "Tangail District",
      "Thakurgaon District",
    ],
  },
  BE: {
    country: "Belgium",
    phone_code: "32",
    states: [
      "Antwerp",
      "Brussels-Capital Region",
      "East Flanders",
      "Flanders",
      "Flemish Brabant",
      "Hainaut",
      "Li\u00e8ge",
      "Limburg",
      "Luxembourg",
      "Namur",
      "Wallonia",
      "Walloon Brabant",
      "West Flanders",
    ],
  },
  BF: {
    country: "Burkina Faso",
    phone_code: "226",
    states: [
      "Bal\u00e9 Province",
      "Bam Province",
      "Banwa Province",
      "Baz\u00e8ga Province",
      "Boucle du Mouhoun Region",
      "Bougouriba Province",
      "Boulgou",
      "Cascades Region",
      "Centre",
      "Centre-Est Region",
      "Centre-Nord Region",
      "Centre-Ouest Region",
      "Centre-Sud Region",
      "Como\u00e9 Province",
      "Est Region",
      "Ganzourgou Province",
      "Gnagna Province",
      "Gourma Province",
      "Hauts-Bassins Region",
      "Houet Province",
      "Ioba Province",
      "Kadiogo Province",
      "K\u00e9n\u00e9dougou Province",
      "Komondjari Province",
      "Kompienga Province",
      "Kossi Province",
      "Koulp\u00e9logo Province",
      "Kouritenga Province",
      "Kourw\u00e9ogo Province",
      "L\u00e9raba Province",
      "Loroum Province",
      "Mouhoun",
      "Nahouri Province",
      "Namentenga Province",
      "Nayala Province",
      "Nord Region, Burkina Faso",
      "Noumbiel Province",
      "Oubritenga Province",
      "Oudalan Province",
      "Passor\u00e9 Province",
      "Plateau-Central Region",
      "Poni Province",
      "Sahel Region",
      "Sangui\u00e9 Province",
      "Sanmatenga Province",
      "S\u00e9no Province",
      "Sissili Province",
      "Soum Province",
      "Sourou Province",
      "Sud-Ouest Region",
      "Tapoa Province",
      "Tuy Province",
      "Yagha Province",
      "Yatenga Province",
      "Ziro Province",
      "Zondoma Province",
      "Zoundw\u00e9ogo Province",
    ],
  },
  BG: {
    country: "Bulgaria",
    phone_code: "359",
    states: [
      "Blagoevgrad Province",
      "Burgas Province",
      "Dobrich Province",
      "Gabrovo Province",
      "Haskovo Province",
      "Kardzhali Province",
      "Kyustendil Province",
      "Lovech Province",
      "Montana Province",
      "Pazardzhik Province",
      "Pernik Province",
      "Pleven Province",
      "Plovdiv Province",
      "Razgrad Province",
      "Ruse Province",
      "Shumen",
      "Silistra Province",
      "Sliven Province",
      "Smolyan Province",
      "Sofia City Province",
      "Sofia Province",
      "Stara Zagora Province",
      "Targovishte Province",
      "Varna Province",
      "Veliko Tarnovo Province",
      "Vidin Province",
      "Vratsa Province",
      "Yambol Province",
    ],
  },
  BH: {
    country: "Bahrain",
    phone_code: "973",
    states: [
      "Capital Governorate",
      "Central Governorate",
      "Muharraq Governorate",
      "Northern Governorate",
      "Southern Governorate",
    ],
  },
  BI: {
    country: "Burundi",
    phone_code: "257",
    states: [
      "Bubanza Province",
      "Bujumbura Mairie Province",
      "Bujumbura Rural Province",
      "Bururi Province",
      "Cankuzo Province",
      "Cibitoke Province",
      "Gitega Province",
      "Karuzi Province",
      "Kayanza Province",
      "Kirundo Province",
      "Makamba Province",
      "Muramvya Province",
      "Muyinga Province",
      "Mwaro Province",
      "Ngozi Province",
      "Rumonge Province",
      "Rutana Province",
      "Ruyigi Province",
    ],
  },
  BJ: {
    country: "Benin",
    phone_code: "229",
    states: [
      "Alibori Department",
      "Atakora Department",
      "Atlantique Department",
      "Borgou Department",
      "Collines Department",
      "Donga Department",
      "Kouffo Department",
      "Littoral Department",
      "Mono Department",
      "Ou\u00e9m\u00e9 Department",
      "Plateau Department",
      "Zou Department",
    ],
  },
  BL: {
    country: "Saint-Barthelemy",
    phone_code: "590",
    states: [],
  },
  BM: {
    country: "Bermuda",
    phone_code: "1-441",
    states: [
      "Devonshire Parish",
      "Hamilton Parish",
      "Paget Parish",
      "Pembroke Parish",
      "Saint George's Parish",
      "Sandys Parish",
      "Smith's Parish,",
      "Southampton Parish",
      "Warwick Parish",
    ],
  },
  BN: {
    country: "Brunei",
    phone_code: "673",
    states: [
      "Belait District",
      "Brunei-Muara District",
      "Temburong District",
      "Tutong District",
    ],
  },
  BO: {
    country: "Bolivia",
    phone_code: "591",
    states: [
      "Beni Department",
      "Chuquisaca Department",
      "Cochabamba Department",
      "La Paz Department",
      "Oruro Department",
      "Pando Department",
      "Potos\u00ed Department",
      "Santa Cruz Department",
      "Tarija Department",
    ],
  },
  BQ: {
    country: "Bonaire, Sint Eustatius and Saba",
    phone_code: "599",
    states: ["Bonaire", "Saba", "Sint Eustatius"],
  },
  BR: {
    country: "Brazil",
    phone_code: "55",
    states: [
      "Acre",
      "Alagoas",
      "Amap\u00e1",
      "Amazonas",
      "Bahia",
      "Cear\u00e1",
      "Distrito Federal",
      "Esp\u00edrito Santo",
      "Goi\u00e1s",
      "Maranh\u00e3o",
      "Mato Grosso",
      "Mato Grosso do Sul",
      "Minas Gerais",
      "Par\u00e1",
      "Para\u00edba",
      "Paran\u00e1",
      "Pernambuco",
      "Piau\u00ed",
      "Rio de Janeiro",
      "Rio Grande do Norte",
      "Rio Grande do Sul",
      "Rond\u00f4nia",
      "Roraima",
      "Santa Catarina",
      "S\u00e3o Paulo",
      "Sergipe",
      "Tocantins",
    ],
  },
  BS: {
    country: "The Bahamas",
    phone_code: "1-242",
    states: [
      "Acklins",
      "Acklins and Crooked Islands",
      "Berry Islands",
      "Bimini",
      "Black Point",
      "Cat Island",
      "Central Abaco",
      "Central Andros",
      "Central Eleuthera",
      "Crooked Island",
      "East Grand Bahama",
      "Exuma",
      "Freeport",
      "Fresh Creek",
      "Governor's Harbour",
      "Grand Cay",
      "Green Turtle Cay",
      "Harbour Island",
      "High Rock",
      "Hope Town",
      "Inagua",
      "Kemps Bay",
      "Long Island",
      "Mangrove Cay",
      "Marsh Harbour",
      "Mayaguana District",
      "New Providence",
      "Nichollstown and Berry Islands",
      "North Abaco",
      "North Andros",
      "North Eleuthera",
      "Ragged Island",
      "Rock Sound",
      "Rum Cay District",
      "San Salvador and Rum Cay",
      "San Salvador Island",
      "Sandy Point",
      "South Abaco",
      "South Andros",
      "South Eleuthera",
      "Spanish Wells",
      "West Grand Bahama",
    ],
  },
  BT: {
    country: "Bhutan",
    phone_code: "975",
    states: [
      "Bumthang District",
      "Chukha District",
      "Dagana District",
      "Gasa District",
      "Haa District",
      "Lhuntse District",
      "Mongar District",
      "Paro District",
      "Pemagatshel District",
      "Punakha District",
      "Samdrup Jongkhar District",
      "Samtse District",
      "Sarpang District",
      "Thimphu District",
      "Trashigang District",
      "Trongsa District",
      "Tsirang District",
      "Wangdue Phodrang District",
      "Zhemgang District",
    ],
  },
  BV: {
    country: "Bouvet Island",
    phone_code: "0055",
    states: [],
  },
  BW: {
    country: "Botswana",
    phone_code: "267",
    states: [
      "Central District",
      "Ghanzi District",
      "Kgalagadi District",
      "Kgatleng District",
      "Kweneng District",
      "Ngamiland",
      "North-East District",
      "North-West District",
      "South-East District",
      "Southern District",
    ],
  },
  BY: {
    country: "Belarus",
    phone_code: "375",
    states: [
      "Brest Region",
      "Gomel Region",
      "Grodno Region",
      "Minsk",
      "Minsk Region",
      "Mogilev Region",
      "Vitebsk Region",
    ],
  },
  BZ: {
    country: "Belize",
    phone_code: "501",
    states: [
      "Belize District",
      "Cayo District",
      "Corozal District",
      "Orange Walk District",
      "Stann Creek District",
      "Toledo District",
    ],
  },
  CA: {
    country: "Canada",
    phone_code: "1",
    states: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon",
    ],
  },
  CC: {
    country: "Cocos (Keeling) Islands",
    phone_code: "61",
    states: [],
  },
  CD: {
    country: "Democratic Republic of the Congo",
    phone_code: "243",
    states: [
      "Bas-U\u00e9l\u00e9",
      "\u00c9quateur",
      "Haut-Katanga",
      "Haut-Lomami",
      "Haut-U\u00e9l\u00e9",
      "Ituri",
      "Kasa\u00ef",
      "Kasa\u00ef Central",
      "Kasa\u00ef Oriental",
      "Kinshasa",
      "Kongo Central",
      "Kwango",
      "Kwilu",
      "Lomami",
      "Lualaba",
      "Mai-Ndombe",
      "Maniema",
      "Mongala",
      "Nord-Kivu",
      "Nord-Ubangi",
      "Sankuru",
      "Sud-Kivu",
      "Sud-Ubangi",
      "Tanganyika",
      "Tshopo",
      "Tshuapa",
    ],
  },
  CF: {
    country: "Central African Republic",
    phone_code: "236",
    states: [
      "Bamingui-Bangoran Prefecture",
      "Bangui",
      "Basse-Kotto Prefecture",
      "Haut-Mbomou Prefecture",
      "Haute-Kotto Prefecture",
      "K\u00e9mo Prefecture",
      "Lobaye Prefecture",
      "Mamb\u00e9r\u00e9-Kad\u00e9\u00ef",
      "Mbomou Prefecture",
      "Nana-Gr\u00e9bizi Economic Prefecture",
      "Nana-Mamb\u00e9r\u00e9 Prefecture",
      "Ombella-M'Poko Prefecture",
      "Ouaka Prefecture",
      "Ouham Prefecture",
      "Ouham-Pend\u00e9 Prefecture",
      "Sangha-Mba\u00e9r\u00e9",
      "Vakaga Prefecture",
    ],
  },
  CG: {
    country: "Congo",
    phone_code: "242",
    states: [
      "Bouenza Department",
      "Brazzaville",
      "Cuvette Department",
      "Cuvette-Ouest Department",
      "Kouilou Department",
      "L\u00e9koumou Department",
      "Likouala Department",
      "Niari Department",
      "Plateaux Department",
      "Pointe-Noire",
      "Pool Department",
      "Sangha Department",
    ],
  },
  CH: {
    country: "Switzerland",
    phone_code: "41",
    states: [
      "Aargau",
      "Appenzell Ausserrhoden",
      "Appenzell Innerrhoden",
      "Basel-Land",
      "Basel-Stadt",
      "Bern",
      "Fribourg",
      "Geneva",
      "Glarus",
      "Graub\u00fcnden",
      "Jura",
      "Lucerne",
      "Neuch\u00e2tel",
      "Nidwalden",
      "Obwalden",
      "Schaffhausen",
      "Schwyz",
      "Solothurn",
      "St. Gallen",
      "Thurgau",
      "Ticino",
      "Uri",
      "Valais",
      "Vaud",
      "Zug",
      "Z\u00fcrich",
    ],
  },
  CI: {
    country: "Cote D'Ivoire (Ivory Coast)",
    phone_code: "225",
    states: [
      "Abidjan",
      "Agn\u00e9by",
      "Bafing Region",
      "Bas-Sassandra District",
      "Bas-Sassandra Region",
      "Como\u00e9 District",
      "Dengu\u00e9l\u00e9 District",
      "Dengu\u00e9l\u00e9 Region",
      "Dix-Huit Montagnes",
      "Fromager",
      "G\u00f4h-Djiboua District",
      "Haut-Sassandra",
      "Lacs District",
      "Lacs Region",
      "Lagunes District",
      "Lagunes region",
      "Marahou\u00e9 Region",
      "Montagnes District",
      "Moyen-Cavally",
      "Moyen-Como\u00e9",
      "N'zi-Como\u00e9",
      "Sassandra-Marahou\u00e9 District",
      "Savanes Region",
      "Sud-Bandama",
      "Sud-Como\u00e9",
      "Vall\u00e9e du Bandama District",
      "Vall\u00e9e du Bandama Region",
      "Woroba District",
      "Worodougou",
      "Yamoussoukro",
      "Zanzan Region",
    ],
  },
  CK: {
    country: "Cook Islands",
    phone_code: "682",
    states: [],
  },
  CL: {
    country: "Chile",
    phone_code: "56",
    states: [
      "Ais\u00e9n del General Carlos Iba\u00f1ez del Campo",
      "Antofagasta",
      "Arica y Parinacota",
      "Atacama",
      "Biob\u00edo",
      "Coquimbo",
      "La Araucan\u00eda",
      "Libertador General Bernardo O'Higgins",
      "Los Lagos",
      "Los R\u00edos",
      "Magallanes y de la Ant\u00e1rtica Chilena",
      "Maule",
      "\u00d1uble",
      "Regi\u00f3n Metropolitana de Santiago",
      "Tarapac\u00e1",
      "Valpara\u00edso",
    ],
  },
  CM: {
    country: "Cameroon",
    phone_code: "237",
    states: [
      "Adamawa",
      "Centre",
      "East",
      "Far North",
      "Littoral",
      "North",
      "Northwest",
      "South",
      "Southwest",
      "West",
    ],
  },
  CN: {
    country: "China",
    phone_code: "86",
    states: [
      "Anhui",
      "Beijing",
      "Chongqing",
      "Fujian",
      "Gansu",
      "Guangdong",
      "Guangxi Zhuang",
      "Guizhou",
      "Hainan",
      "Hebei",
      "Heilongjiang",
      "Henan",
      "Hong Kong SAR",
      "Hubei",
      "Hunan",
      "Inner Mongolia",
      "Jiangsu",
      "Jiangxi",
      "Jilin",
      "Liaoning",
      "Macau SAR",
      "Ningxia Huizu",
      "Qinghai",
      "Shaanxi",
      "Shandong",
      "Shanghai",
      "Shanxi",
      "Sichuan",
      "Taiwan",
      "Tianjin",
      "Xinjiang",
      "Xizang",
      "Yunnan",
      "Zhejiang",
    ],
  },
  CO: {
    country: "Colombia",
    phone_code: "57",
    states: [
      "Amazonas",
      "Antioquia",
      "Arauca",
      "Archipi\u00e9lago de San Andr\u00e9s, Providencia y Santa Catalina",
      "Atl\u00e1ntico",
      "Bogot\u00e1 D.C.",
      "Bol\u00edvar",
      "Boyac\u00e1",
      "Caldas",
      "Caquet\u00e1",
      "Casanare",
      "Cauca",
      "Cesar",
      "Choc\u00f3",
      "C\u00f3rdoba",
      "Cundinamarca",
      "Guain\u00eda",
      "Guaviare",
      "Huila",
      "La Guajira",
      "Magdalena",
      "Meta",
      "Nari\u00f1o",
      "Norte de Santander",
      "Putumayo",
      "Quind\u00edo",
      "Risaralda",
      "Santander",
      "Sucre",
      "Tolima",
      "Valle del Cauca",
      "Vaup\u00e9s",
      "Vichada",
    ],
  },
  CR: {
    country: "Costa Rica",
    phone_code: "506",
    states: [
      "Alajuela Province",
      "Guanacaste Province",
      "Heredia Province",
      "Lim\u00f3n Province",
      "Provincia de Cartago",
      "Puntarenas Province",
      "San Jos\u00e9 Province",
    ],
  },
  CU: {
    country: "Cuba",
    phone_code: "53",
    states: [
      "Artemisa Province",
      "Camag\u00fcey Province",
      "Ciego de \u00c1vila Province",
      "Cienfuegos Province",
      "Granma Province",
      "Guant\u00e1namo Province",
      "Havana Province",
      "Holgu\u00edn Province",
      "Isla de la Juventud",
      "Las Tunas Province",
      "Matanzas Province",
      "Mayabeque Province",
      "Pinar del R\u00edo Province",
      "Sancti Sp\u00edritus Province",
      "Santiago de Cuba Province",
      "Villa Clara Province",
    ],
  },
  CV: {
    country: "Cape Verde",
    phone_code: "238",
    states: [
      "Barlavento Islands",
      "Boa Vista",
      "Brava",
      "Maio Municipality",
      "Mosteiros",
      "Paul",
      "Porto Novo",
      "Praia",
      "Ribeira Brava Municipality",
      "Ribeira Grande",
      "Ribeira Grande de Santiago",
      "Sal",
      "Santa Catarina",
      "Santa Catarina do Fogo",
      "Santa Cruz",
      "S\u00e3o Domingos",
      "S\u00e3o Filipe",
      "S\u00e3o Louren\u00e7o dos \u00d3rg\u00e3os",
      "S\u00e3o Miguel",
      "S\u00e3o Vicente",
      "Sotavento Islands",
      "Tarrafal",
      "Tarrafal de S\u00e3o Nicolau",
    ],
  },
  CW: {
    country: "Cura\u00e7ao",
    phone_code: "599",
    states: [],
  },
  CX: {
    country: "Christmas Island",
    phone_code: "61",
    states: [],
  },
  CY: {
    country: "Cyprus",
    phone_code: "357",
    states: [
      "Famagusta District (Ma\u011fusa)",
      "Kyrenia District (Keryneia)",
      "Larnaca District (Larnaka)",
      "Limassol District (Leymasun)",
      "Nicosia District (Lefko\u015fa)",
      "Paphos District (Pafos)",
    ],
  },
  CZ: {
    country: "Czech Republic",
    phone_code: "420",
    states: [
      "Bene\u0161ov",
      "Beroun",
      "Blansko",
      "B\u0159eclav",
      "Brno-m\u011bsto",
      "Brno-venkov",
      "Brunt\u00e1l",
      "\u010cesk\u00e1 L\u00edpa",
      "\u010cesk\u00e9 Bud\u011bjovice",
      "\u010cesk\u00fd Krumlov",
      "Cheb",
      "Chomutov",
      "Chrudim",
      "D\u011b\u010d\u00edn",
      "Doma\u017elice",
      "Fr\u00fddek-M\u00edstek",
      "Havl\u00ed\u010dk\u016fv Brod",
      "Hodon\u00edn",
      "Hradec Kr\u00e1lov\u00e9",
      "Jablonec nad Nisou",
      "Jesen\u00edk",
      "Ji\u010d\u00edn",
      "Jihlava",
      "Jiho\u010desk\u00fd kraj",
      "Jihomoravsk\u00fd kraj",
      "Jind\u0159ich\u016fv Hradec",
      "Karlovarsk\u00fd kraj",
      "Karlovy Vary",
      "Karvin\u00e1",
      "Kladno",
      "Klatovy",
      "Kol\u00edn",
      "Kraj Vyso\u010dina",
      "Kr\u00e1lov\u00e9hradeck\u00fd kraj",
      "Krom\u011b\u0159\u00ed\u017e",
      "Kutn\u00e1 Hora",
      "Liberec",
      "Libereck\u00fd kraj",
      "Litom\u011b\u0159ice",
      "Louny",
      "M\u011bln\u00edk",
      "Mlad\u00e1 Boleslav",
      "Moravskoslezsk\u00fd kraj",
      "Most",
      "N\u00e1chod",
      "Nov\u00fd Ji\u010d\u00edn",
      "Nymburk",
      "Olomouc",
      "Olomouck\u00fd kraj",
      "Opava",
      "Ostrava-m\u011bsto",
      "Pardubice",
      "Pardubick\u00fd kraj",
      "Pelh\u0159imov",
      "P\u00edsek",
      "Plze\u0148-jih",
      "Plze\u0148-m\u011bsto",
      "Plze\u0148-sever",
      "Plze\u0148sk\u00fd kraj",
      "Prachatice",
      "Praha-v\u00fdchod",
      "Praha-z\u00e1pad",
      "Praha, Hlavn\u00ed m\u011bsto",
      "P\u0159erov",
      "P\u0159\u00edbram",
      "Prost\u011bjov",
      "Rakovn\u00edk",
      "Rokycany",
      "Rychnov nad Kn\u011b\u017enou",
      "Semily",
      "Sokolov",
      "Strakonice",
      "St\u0159edo\u010desk\u00fd kraj",
      "\u0160umperk",
      "Svitavy",
      "T\u00e1bor",
      "Tachov",
      "Teplice",
      "T\u0159eb\u00ed\u010d",
      "Trutnov",
      "Uhersk\u00e9 Hradi\u0161t\u011b",
      "\u00dasteck\u00fd kraj",
      "\u00dast\u00ed nad Labem",
      "\u00dast\u00ed nad Orlic\u00ed",
      "Vset\u00edn",
      "Vy\u0161kov",
      "\u017d\u010f\u00e1r nad S\u00e1zavou",
      "Zl\u00edn",
      "Zl\u00ednsk\u00fd kraj",
      "Znojmo",
    ],
  },
  DE: {
    country: "Germany",
    phone_code: "49",
    states: [
      "Baden-W\u00fcrttemberg",
      "Bavaria",
      "Berlin",
      "Brandenburg",
      "Bremen",
      "Hamburg",
      "Hesse",
      "Lower Saxony",
      "Mecklenburg-Vorpommern",
      "North Rhine-Westphalia",
      "Rhineland-Palatinate",
      "Saarland",
      "Saxony",
      "Saxony-Anhalt",
      "Schleswig-Holstein",
      "Thuringia",
    ],
  },
  DJ: {
    country: "Djibouti",
    phone_code: "253",
    states: [
      "Ali Sabieh Region",
      "Arta Region",
      "Dikhil Region",
      "Djibouti",
      "Obock Region",
      "Tadjourah Region",
    ],
  },
  DK: {
    country: "Denmark",
    phone_code: "45",
    states: [
      "Capital Region of Denmark",
      "Central Denmark Region",
      "North Denmark Region",
      "Region of Southern Denmark",
      "Region Zealand",
    ],
  },
  DM: {
    country: "Dominica",
    phone_code: "1-767",
    states: [
      "Saint Andrew Parish",
      "Saint David Parish",
      "Saint George Parish",
      "Saint John Parish",
      "Saint Joseph Parish",
      "Saint Luke Parish",
      "Saint Mark Parish",
      "Saint Patrick Parish",
      "Saint Paul Parish",
      "Saint Peter Parish",
    ],
  },
  DO: {
    country: "Dominican Republic",
    phone_code: ["1-809", "1-829"],
    states: [
      "Azua Province",
      "Baoruco Province",
      "Barahona Province",
      "Dajab\u00f3n Province",
      "Distrito Nacional",
      "Duarte Province",
      "El Seibo Province",
      "Espaillat Province",
      "Hato Mayor Province",
      "Hermanas Mirabal Province",
      "Independencia",
      "La Altagracia Province",
      "La Romana Province",
      "La Vega Province",
      "Mar\u00eda Trinidad S\u00e1nchez Province",
      "Monse\u00f1or Nouel Province",
      "Monte Cristi Province",
      "Monte Plata Province",
      "Pedernales Province",
      "Peravia Province",
      "Puerto Plata Province",
      "Saman\u00e1 Province",
      "San Crist\u00f3bal Province",
      "San Jos\u00e9 de Ocoa Province",
      "San Juan Province",
      "San Pedro de Macor\u00eds",
      "S\u00e1nchez Ram\u00edrez Province",
      "Santiago Province",
      "Santiago Rodr\u00edguez Province",
      "Santo Domingo Province",
      "Valverde Province",
    ],
  },
  DZ: {
    country: "Algeria",
    phone_code: "213",
    states: [
      "Adrar",
      "A\u00efn Defla",
      "A\u00efn T\u00e9mouchent",
      "Algiers",
      "Annaba",
      "Batna",
      "B\u00e9char",
      "B\u00e9ja\u00efa",
      "B\u00e9ni Abb\u00e8s",
      "Biskra",
      "Blida",
      "Bordj Baji Mokhtar",
      "Bordj Bou Arr\u00e9ridj",
      "Bou\u00efra",
      "Boumerd\u00e8s",
      "Chlef",
      "Constantine",
      "Djanet",
      "Djelfa",
      "El Bayadh",
      "El M'ghair",
      "El Menia",
      "El Oued",
      "El Tarf",
      "Gharda\u00efa",
      "Guelma",
      "Illizi",
      "In Guezzam",
      "In Salah",
      "Jijel",
      "Khenchela",
      "Laghouat",
      "M'Sila",
      "Mascara",
      "M\u00e9d\u00e9a",
      "Mila",
      "Mostaganem",
      "Naama",
      "Oran",
      "Ouargla",
      "Ouled Djellal",
      "Oum El Bouaghi",
      "Relizane",
      "Sa\u00efda",
      "S\u00e9tif",
      "Sidi Bel Abb\u00e8s",
      "Skikda",
      "Souk Ahras",
      "Tamanghasset",
      "T\u00e9bessa",
      "Tiaret",
      "Timimoun",
      "Tindouf",
      "Tipasa",
      "Tissemsilt",
      "Tizi Ouzou",
      "Tlemcen",
      "Touggourt",
    ],
  },
  EC: {
    country: "Ecuador",
    phone_code: "593",
    states: [
      "Azuay",
      "Bol\u00edvar",
      "Ca\u00f1ar",
      "Carchi",
      "Chimborazo",
      "Cotopaxi",
      "El Oro",
      "Esmeraldas",
      "Gal\u00e1pagos",
      "Guayas",
      "Imbabura",
      "Loja",
      "Los R\u00edos",
      "Manab\u00ed",
      "Morona-Santiago",
      "Napo",
      "Orellana",
      "Pastaza",
      "Pichincha",
      "Santa Elena",
      "Santo Domingo de los Ts\u00e1chilas",
      "Sucumb\u00edos",
      "Tungurahua",
      "Zamora Chinchipe",
    ],
  },
  EE: {
    country: "Estonia",
    phone_code: "372",
    states: [
      "Harju County",
      "Hiiu County",
      "Ida-Viru County",
      "J\u00e4rva County",
      "J\u00f5geva County",
      "L\u00e4\u00e4ne County",
      "L\u00e4\u00e4ne-Viru County",
      "P\u00e4rnu County",
      "P\u00f5lva County",
      "Rapla County",
      "Saare County",
      "Tartu County",
      "Valga County",
      "Viljandi County",
      "V\u00f5ru County",
    ],
  },
  EG: {
    country: "Egypt",
    phone_code: "20",
    states: [
      "Alexandria",
      "Aswan",
      "Asyut",
      "Beheira",
      "Beni Suef",
      "Cairo",
      "Dakahlia",
      "Damietta",
      "Faiyum",
      "Gharbia",
      "Giza",
      "Ismailia",
      "Kafr el-Sheikh",
      "Luxor",
      "Matrouh",
      "Minya",
      "Monufia",
      "New Valley",
      "North Sinai",
      "Port Said",
      "Qalyubia",
      "Qena",
      "Red Sea",
      "Sharqia",
      "Sohag",
      "South Sinai",
      "Suez",
    ],
  },
  EH: {
    country: "Western Sahara",
    phone_code: "212",
    states: [],
  },
  ER: {
    country: "Eritrea",
    phone_code: "291",
    states: [
      "Anseba Region",
      "Debub Region",
      "Gash-Barka Region",
      "Maekel Region",
      "Northern Red Sea Region",
      "Southern Red Sea Region",
    ],
  },
  ES: {
    country: "Spain",
    phone_code: "34",
    states: [
      "Andalusia",
      "Aragon",
      "Asturias",
      "\u00c1vila",
      "Balearic Islands",
      "Basque Country",
      "Burgos Province",
      "Canary Islands",
      "Cantabria",
      "Castile and Le\u00f3n",
      "Castilla La Mancha",
      "Catalonia",
      "Ceuta",
      "Extremadura",
      "Galicia",
      "La Rioja",
      "L\u00e9on",
      "Madrid",
      "Melilla",
      "Murcia",
      "Navarra",
      "Palencia Province",
      "Salamanca Province",
      "Segovia Province",
      "Soria Province",
      "Valencia",
      "Valladolid Province",
      "Zamora Province",
    ],
  },
  ET: {
    country: "Ethiopia",
    phone_code: "251",
    states: [
      "Addis Ababa",
      "Afar Region",
      "Amhara Region",
      "Benishangul-Gumuz Region",
      "Dire Dawa",
      "Gambela Region",
      "Harari Region",
      "Oromia Region",
      "Somali Region",
      "Southern Nations, Nationalities, and Peoples' Region",
      "Tigray Region",
    ],
  },
  FI: {
    country: "Finland",
    phone_code: "358",
    states: [
      "\u00c5land Islands",
      "Central Finland",
      "Central Ostrobothnia",
      "Eastern Finland Province",
      "Finland Proper",
      "Kainuu",
      "Kymenlaakso",
      "Lapland",
      "North Karelia",
      "Northern Ostrobothnia",
      "Northern Savonia",
      "Ostrobothnia",
      "Oulu Province",
      "P\u00e4ij\u00e4nne Tavastia",
      "Pirkanmaa",
      "Satakunta",
      "South Karelia",
      "Southern Ostrobothnia",
      "Southern Savonia",
      "Tavastia Proper",
      "Uusimaa",
    ],
  },
  FJ: {
    country: "Fiji Islands",
    phone_code: "679",
    states: [
      "Ba",
      "Bua",
      "Cakaudrove",
      "Central Division",
      "Eastern Division",
      "Kadavu",
      "Lau",
      "Lomaiviti",
      "Macuata",
      "Nadroga-Navosa",
      "Naitasiri",
      "Namosi",
      "Northern Division",
      "Ra",
      "Rewa",
      "Rotuma",
      "Serua",
      "Tailevu",
      "Western Division",
    ],
  },
  FK: {
    country: "Falkland Islands",
    phone_code: "500",
    states: [],
  },
  FM: {
    country: "Micronesia",
    phone_code: "691",
    states: ["Chuuk State", "Kosrae State", "Pohnpei State", "Yap State"],
  },
  FO: {
    country: "Faroe Islands",
    phone_code: "298",
    states: [],
  },
  FR: {
    country: "France",
    phone_code: "33",
    states: [
      "Ain",
      "Aisne",
      "Allier",
      "Alpes-de-Haute-Provence",
      "Alpes-Maritimes",
      "Alsace",
      "Ard\u00e8che",
      "Ardennes",
      "Ari\u00e8ge",
      "Aube",
      "Aude",
      "Auvergne-Rh\u00f4ne-Alpes",
      "Aveyron",
      "Bas-Rhin",
      "Bouches-du-Rh\u00f4ne",
      "Bourgogne-Franche-Comt\u00e9",
      "Bretagne",
      "Calvados",
      "Cantal",
      "Centre-Val de Loire",
      "Charente",
      "Charente-Maritime",
      "Cher",
      "Clipperton",
      "Corr\u00e8ze",
      "Corse",
      "Corse-du-Sud",
      "C\u00f4te-d'Or",
      "C\u00f4tes-d'Armor",
      "Creuse",
      "Deux-S\u00e8vres",
      "Dordogne",
      "Doubs",
      "Dr\u00f4me",
      "Essonne",
      "Eure",
      "Eure-et-Loir",
      "Finist\u00e8re",
      "French Guiana",
      "French Polynesia",
      "French Southern and Antarctic Lands",
      "Gard",
      "Gers",
      "Gironde",
      "Grand-Est",
      "Guadeloupe",
      "Haut-Rhin",
      "Haute-Corse",
      "Haute-Garonne",
      "Haute-Loire",
      "Haute-Marne",
      "Haute-Sa\u00f4ne",
      "Haute-Savoie",
      "Haute-Vienne",
      "Hautes-Alpes",
      "Hautes-Pyr\u00e9n\u00e9es",
      "Hauts-de-France",
      "Hauts-de-Seine",
      "H\u00e9rault",
      "\u00cele-de-France",
      "Ille-et-Vilaine",
      "Indre",
      "Indre-et-Loire",
      "Is\u00e8re",
      "Jura",
      "La R\u00e9union",
      "Landes",
      "Loir-et-Cher",
      "Loire",
      "Loire-Atlantique",
      "Loiret",
      "Lot",
      "Lot-et-Garonne",
      "Loz\u00e8re",
      "Maine-et-Loire",
      "Manche",
      "Marne",
      "Martinique",
      "Mayenne",
      "Mayotte",
      "M\u00e9tropole de Lyon",
      "Meurthe-et-Moselle",
      "Meuse",
      "Morbihan",
      "Moselle",
      "Ni\u00e8vre",
      "Nord",
      "Normandie",
      "Nouvelle-Aquitaine",
      "Occitanie",
      "Oise",
      "Orne",
      "Paris",
      "Pas-de-Calais",
      "Pays-de-la-Loire",
      "Provence-Alpes-C\u00f4te-d\u2019Azur",
      "Puy-de-D\u00f4me",
      "Pyr\u00e9n\u00e9es-Atlantiques",
      "Pyr\u00e9n\u00e9es-Orientales",
      "Rh\u00f4ne",
      "Saint Pierre and Miquelon",
      "Saint-Barth\u00e9lemy",
      "Saint-Martin",
      "Sa\u00f4ne-et-Loire",
      "Sarthe",
      "Savoie",
      "Seine-et-Marne",
      "Seine-Maritime",
      "Seine-Saint-Denis",
      "Somme",
      "Tarn",
      "Tarn-et-Garonne",
      "Territoire de Belfort",
      "Val-d'Oise",
      "Val-de-Marne",
      "Var",
      "Vaucluse",
      "Vend\u00e9e",
      "Vienne",
      "Vosges",
      "Wallis and Futuna",
      "Yonne",
      "Yvelines",
    ],
  },
  GA: {
    country: "Gabon",
    phone_code: "241",
    states: [
      "Estuaire Province",
      "Haut-Ogoou\u00e9 Province",
      "Moyen-Ogoou\u00e9 Province",
      "Ngouni\u00e9 Province",
      "Nyanga Province",
      "Ogoou\u00e9-Ivindo Province",
      "Ogoou\u00e9-Lolo Province",
      "Ogoou\u00e9-Maritime Province",
      "Woleu-Ntem Province",
    ],
  },
  GB: {
    country: "United Kingdom",
    phone_code: "44",
    states: [
      "Aberdeen",
      "Aberdeenshire",
      "Angus",
      "Antrim",
      "Antrim and Newtownabbey",
      "Ards",
      "Ards and North Down",
      "Argyll and Bute",
      "Armagh City and District Council",
      "Armagh, Banbridge and Craigavon",
      "Ascension Island",
      "Ballymena Borough",
      "Ballymoney",
      "Banbridge",
      "Barnsley",
      "Bath and North East Somerset",
      "Bedford",
      "Belfast district",
      "Birmingham",
      "Blackburn with Darwen",
      "Blackpool",
      "Blaenau Gwent County Borough",
      "Bolton",
      "Bournemouth",
      "Bracknell Forest",
      "Bradford",
      "Bridgend County Borough",
      "Brighton and Hove",
      "Buckinghamshire",
      "Bury",
      "Caerphilly County Borough",
      "Calderdale",
      "Cambridgeshire",
      "Carmarthenshire",
      "Carrickfergus Borough Council",
      "Castlereagh",
      "Causeway Coast and Glens",
      "Central Bedfordshire",
      "Ceredigion",
      "Cheshire East",
      "Cheshire West and Chester",
      "City and County of Cardiff",
      "City and County of Swansea",
      "City of Bristol",
      "City of Derby",
      "City of Kingston upon Hull",
      "City of Leicester",
      "City of London",
      "City of Nottingham",
      "City of Peterborough",
      "City of Plymouth",
      "City of Portsmouth",
      "City of Southampton",
      "City of Stoke-on-Trent",
      "City of Sunderland",
      "City of Westminster",
      "City of Wolverhampton",
      "City of York",
      "Clackmannanshire",
      "Coleraine Borough Council",
      "Conwy County Borough",
      "Cookstown District Council",
      "Cornwall",
      "County Durham",
      "Coventry",
      "Craigavon Borough Council",
      "Cumbria",
      "Darlington",
      "Denbighshire",
      "Derbyshire",
      "Derry City and Strabane",
      "Derry City Council",
      "Devon",
      "Doncaster",
      "Dorset",
      "Down District Council",
      "Dudley",
      "Dumfries and Galloway",
      "Dundee",
      "Dungannon and South Tyrone Borough Council",
      "East Ayrshire",
      "East Dunbartonshire",
      "East Lothian",
      "East Renfrewshire",
      "East Riding of Yorkshire",
      "East Sussex",
      "Edinburgh",
      "England",
      "Essex",
      "Falkirk",
      "Fermanagh and Omagh",
      "Fermanagh District Council",
      "Fife",
      "Flintshire",
      "Gateshead",
      "Glasgow",
      "Gloucestershire",
      "Gwynedd",
      "Halton",
      "Hampshire",
      "Hartlepool",
      "Herefordshire",
      "Hertfordshire",
      "Highland",
      "Inverclyde",
      "Isle of Wight",
      "Isles of Scilly",
      "Kent",
      "Kirklees",
      "Knowsley",
      "Lancashire",
      "Larne Borough Council",
      "Leeds",
      "Leicestershire",
      "Limavady Borough Council",
      "Lincolnshire",
      "Lisburn and Castlereagh",
      "Lisburn City Council",
      "Liverpool",
      "London Borough of Barking and Dagenham",
      "London Borough of Barnet",
      "London Borough of Bexley",
      "London Borough of Brent",
      "London Borough of Bromley",
      "London Borough of Camden",
      "London Borough of Croydon",
      "London Borough of Ealing",
      "London Borough of Enfield",
      "London Borough of Hackney",
      "London Borough of Hammersmith and Fulham",
      "London Borough of Haringey",
      "London Borough of Harrow",
      "London Borough of Havering",
      "London Borough of Hillingdon",
      "London Borough of Hounslow",
      "London Borough of Islington",
      "London Borough of Lambeth",
      "London Borough of Lewisham",
      "London Borough of Merton",
      "London Borough of Newham",
      "London Borough of Redbridge",
      "London Borough of Richmond upon Thames",
      "London Borough of Southwark",
      "London Borough of Sutton",
      "London Borough of Tower Hamlets",
      "London Borough of Waltham Forest",
      "London Borough of Wandsworth",
      "Magherafelt District Council",
      "Manchester",
      "Medway",
      "Merthyr Tydfil County Borough",
      "Metropolitan Borough of Wigan",
      "Mid and East Antrim",
      "Mid Ulster",
      "Middlesbrough",
      "Midlothian",
      "Milton Keynes",
      "Monmouthshire",
      "Moray",
      "Moyle District Council",
      "Neath Port Talbot County Borough",
      "Newcastle upon Tyne",
      "Newport",
      "Newry and Mourne District Council",
      "Newry, Mourne and Down",
      "Newtownabbey Borough Council",
      "Norfolk",
      "North Ayrshire",
      "North Down Borough Council",
      "North East Lincolnshire",
      "North Lanarkshire",
      "North Lincolnshire",
      "North Somerset",
      "North Tyneside",
      "North Yorkshire",
      "Northamptonshire",
      "Northern Ireland",
      "Northumberland",
      "Nottinghamshire",
      "Oldham",
      "Omagh District Council",
      "Orkney Islands",
      "Outer Hebrides",
      "Oxfordshire",
      "Pembrokeshire",
      "Perth and Kinross",
      "Poole",
      "Powys",
      "Reading",
      "Redcar and Cleveland",
      "Renfrewshire",
      "Rhondda Cynon Taf",
      "Rochdale",
      "Rotherham",
      "Royal Borough of Greenwich",
      "Royal Borough of Kensington and Chelsea",
      "Royal Borough of Kingston upon Thames",
      "Rutland",
      "Saint Helena",
      "Salford",
      "Sandwell",
      "Scotland",
      "Scottish Borders",
      "Sefton",
      "Sheffield",
      "Shetland Islands",
      "Shropshire",
      "Slough",
      "Solihull",
      "Somerset",
      "South Ayrshire",
      "South Gloucestershire",
      "South Lanarkshire",
      "South Tyneside",
      "Southend-on-Sea",
      "St Helens",
      "Staffordshire",
      "Stirling",
      "Stockport",
      "Stockton-on-Tees",
      "Strabane District Council",
      "Suffolk",
      "Surrey",
      "Swindon",
      "Tameside",
      "Telford and Wrekin",
      "Thurrock",
      "Torbay",
      "Torfaen",
      "Trafford",
      "United Kingdom",
      "Vale of Glamorgan",
      "Wakefield",
      "Wales",
      "Walsall",
      "Warrington",
      "Warwickshire",
      "West Berkshire",
      "West Dunbartonshire",
      "West Lothian",
      "West Sussex",
      "Wiltshire",
      "Windsor and Maidenhead",
      "Wirral",
      "Wokingham",
      "Worcestershire",
      "Wrexham County Borough",
    ],
  },
  GD: {
    country: "Grenada",
    phone_code: "1-473",
    states: [
      "Carriacou and Petite Martinique",
      "Saint Andrew Parish",
      "Saint David Parish",
      "Saint George Parish",
      "Saint John Parish",
      "Saint Mark Parish",
      "Saint Patrick Parish",
    ],
  },
  GE: {
    country: "Georgia",
    phone_code: "995",
    states: [
      "Adjara",
      "Autonomous Republic of Abkhazia",
      "Guria",
      "Imereti",
      "Kakheti",
      "Khelvachauri Municipality",
      "Kvemo Kartli",
      "Mtskheta-Mtianeti",
      "Racha-Lechkhumi and Kvemo Svaneti",
      "Samegrelo-Zemo Svaneti",
      "Samtskhe-Javakheti",
      "Senaki Municipality",
      "Shida Kartli",
      "Tbilisi",
    ],
  },
  GF: {
    country: "French Guiana",
    phone_code: "594",
    states: [],
  },
  GG: {
    country: "Guernsey and Alderney",
    phone_code: "44-1481",
    states: [],
  },
  GH: {
    country: "Ghana",
    phone_code: "233",
    states: [
      "Ahafo",
      "Ashanti",
      "Bono",
      "Bono East",
      "Central",
      "Eastern",
      "Greater Accra",
      "North East",
      "Northern",
      "Oti",
      "Savannah",
      "Upper East",
      "Upper West",
      "Volta",
      "Western",
      "Western North",
    ],
  },
  GI: {
    country: "Gibraltar",
    phone_code: "350",
    states: [],
  },
  GL: {
    country: "Greenland",
    phone_code: "299",
    states: [],
  },
  GM: {
    country: "Gambia The",
    phone_code: "220",
    states: [
      "Banjul",
      "Central River Division",
      "Lower River Division",
      "North Bank Division",
      "Upper River Division",
      "West Coast Division",
    ],
  },
  GN: {
    country: "Guinea",
    phone_code: "224",
    states: [
      "Beyla Prefecture",
      "Boffa Prefecture",
      "Bok\u00e9 Prefecture",
      "Bok\u00e9 Region",
      "Conakry",
      "Coyah Prefecture",
      "Dabola Prefecture",
      "Dalaba Prefecture",
      "Dinguiraye Prefecture",
      "Dubr\u00e9ka Prefecture",
      "Faranah Prefecture",
      "For\u00e9cariah Prefecture",
      "Fria Prefecture",
      "Gaoual Prefecture",
      "Gu\u00e9ck\u00e9dou Prefecture",
      "Kankan Prefecture",
      "Kankan Region",
      "K\u00e9rouan\u00e9 Prefecture",
      "Kindia Prefecture",
      "Kindia Region",
      "Kissidougou Prefecture",
      "Koubia Prefecture",
      "Koundara Prefecture",
      "Kouroussa Prefecture",
      "Lab\u00e9 Prefecture",
      "Lab\u00e9 Region",
      "L\u00e9louma Prefecture",
      "Lola Prefecture",
      "Macenta Prefecture",
      "Mali Prefecture",
      "Mamou Prefecture",
      "Mamou Region",
      "Mandiana Prefecture",
      "Nz\u00e9r\u00e9kor\u00e9 Prefecture",
      "Nz\u00e9r\u00e9kor\u00e9 Region",
      "Pita Prefecture",
      "Siguiri Prefecture",
      "T\u00e9lim\u00e9l\u00e9 Prefecture",
      "Tougu\u00e9 Prefecture",
      "Yomou Prefecture",
    ],
  },
  GP: {
    country: "Guadeloupe",
    phone_code: "590",
    states: [],
  },
  GQ: {
    country: "Equatorial Guinea",
    phone_code: "240",
    states: [
      "Annob\u00f3n Province",
      "Bioko Norte Province",
      "Bioko Sur Province",
      "Centro Sur Province",
      "Insular Region",
      "Ki\u00e9-Ntem Province",
      "Litoral Province",
      "R\u00edo Muni",
      "Wele-Nzas Province",
    ],
  },
  GR: {
    country: "Greece",
    phone_code: "30",
    states: [
      "Achaea Regional Unit",
      "Aetolia-Acarnania Regional Unit",
      "Arcadia Prefecture",
      "Argolis Regional Unit",
      "Attica Region",
      "Boeotia Regional Unit",
      "Central Greece Region",
      "Central Macedonia",
      "Chania Regional Unit",
      "Corfu Prefecture",
      "Corinthia Regional Unit",
      "Crete Region",
      "Drama Regional Unit",
      "East Attica Regional Unit",
      "East Macedonia and Thrace",
      "Epirus Region",
      "Euboea",
      "Grevena Prefecture",
      "Imathia Regional Unit",
      "Ioannina Regional Unit",
      "Ionian Islands Region",
      "Karditsa Regional Unit",
      "Kastoria Regional Unit",
      "Kefalonia Prefecture",
      "Kilkis Regional Unit",
      "Kozani Prefecture",
      "Laconia",
      "Larissa Prefecture",
      "Lefkada Regional Unit",
      "Pella Regional Unit",
      "Peloponnese Region",
      "Phthiotis Prefecture",
      "Preveza Prefecture",
      "Serres Prefecture",
      "South Aegean",
      "Thessaloniki Regional Unit",
      "West Greece Region",
      "West Macedonia Region",
    ],
  },
  GS: {
    country: "South Georgia",
    phone_code: "500",
    states: [],
  },
  GT: {
    country: "Guatemala",
    phone_code: "502",
    states: [
      "Alta Verapaz Department",
      "Baja Verapaz Department",
      "Chimaltenango Department",
      "Chiquimula Department",
      "El Progreso Department",
      "Escuintla Department",
      "Guatemala Department",
      "Huehuetenango Department",
      "Izabal Department",
      "Jalapa Department",
      "Jutiapa Department",
      "Pet\u00e9n Department",
      "Quetzaltenango Department",
      "Quich\u00e9 Department",
      "Retalhuleu Department",
      "Sacatep\u00e9quez Department",
      "San Marcos Department",
      "Santa Rosa Department",
      "Solol\u00e1 Department",
      "Suchitep\u00e9quez Department",
      "Totonicap\u00e1n Department",
    ],
  },
  GU: {
    country: "Guam",
    phone_code: "1-671",
    states: [],
  },
  GW: {
    country: "Guinea-Bissau",
    phone_code: "245",
    states: [
      "Bafat\u00e1",
      "Biombo Region",
      "Bolama Region",
      "Cacheu Region",
      "Gab\u00fa Region",
      "Leste Province",
      "Norte Province",
      "Oio Region",
      "Quinara Region",
      "Sul Province",
      "Tombali Region",
    ],
  },
  GY: {
    country: "Guyana",
    phone_code: "592",
    states: [
      "Barima-Waini",
      "Cuyuni-Mazaruni",
      "Demerara-Mahaica",
      "East Berbice-Corentyne",
      "Essequibo Islands-West Demerara",
      "Mahaica-Berbice",
      "Pomeroon-Supenaam",
      "Potaro-Siparuni",
      "Upper Demerara-Berbice",
      "Upper Takutu-Upper Essequibo",
    ],
  },
  HK: {
    country: "Hong Kong S.A.R.",
    phone_code: "852",
    states: [
      "Central and Western District",
      "Eastern",
      "Islands District",
      "Kowloon City",
      "Kwai Tsing",
      "Kwun Tong",
      "North",
      "Sai Kung District",
      "Sha Tin",
      "Sham Shui Po",
      "Southern",
      "Tai Po District",
      "Tsuen Wan District",
      "Tuen Mun",
      "Wan Chai",
      "Wong Tai Sin",
      "Yau Tsim Mong",
      "Yuen Long District",
    ],
  },
  HM: {
    country: "Heard Island and McDonald Islands",
    phone_code: "672",
    states: [],
  },
  HN: {
    country: "Honduras",
    phone_code: "504",
    states: [
      "Atl\u00e1ntida Department",
      "Bay Islands Department",
      "Choluteca Department",
      "Col\u00f3n Department",
      "Comayagua Department",
      "Cop\u00e1n Department",
      "Cort\u00e9s Department",
      "El Para\u00edso Department",
      "Francisco Moraz\u00e1n Department",
      "Gracias a Dios Department",
      "Intibuc\u00e1 Department",
      "La Paz Department",
      "Lempira Department",
      "Ocotepeque Department",
      "Olancho Department",
      "Santa B\u00e1rbara Department",
      "Valle Department",
      "Yoro Department",
    ],
  },
  HR: {
    country: "Croatia",
    phone_code: "385",
    states: [
      "Bjelovar-Bilogora",
      "Brod-Posavina",
      "Dubrovnik-Neretva",
      "Istria",
      "Karlovac",
      "Koprivnica-Kri\u017eevci",
      "Krapina-Zagorje",
      "Lika-Senj",
      "Me\u0111imurje",
      "Osijek-Baranja",
      "Po\u017eega-Slavonia",
      "Primorje-Gorski Kotar",
      "\u0160ibenik-Knin",
      "Sisak-Moslavina",
      "Split-Dalmatia",
      "Vara\u017edin",
      "Virovitica-Podravina",
      "Vukovar-Syrmia",
      "Zadar",
      "Zagreb",
      "Zagreb",
    ],
  },
  HT: {
    country: "Haiti",
    phone_code: "509",
    states: [
      "Artibonite",
      "Centre",
      "Grand'Anse",
      "Nippes",
      "Nord",
      "Nord-Est",
      "Nord-Ouest",
      "Ouest",
      "Sud",
      "Sud-Est",
    ],
  },
  HU: {
    country: "Hungary",
    phone_code: "36",
    states: [
      "B\u00e1cs-Kiskun",
      "Baranya",
      "B\u00e9k\u00e9s",
      "B\u00e9k\u00e9scsaba",
      "Borsod-Aba\u00faj-Zempl\u00e9n",
      "Budapest",
      "Csongr\u00e1d County",
      "Debrecen",
      "Duna\u00fajv\u00e1ros",
      "Eger",
      "\u00c9rd",
      "Fej\u00e9r County",
      "Gy\u0151r",
      "Gy\u0151r-Moson-Sopron County",
      "Hajd\u00fa-Bihar County",
      "Heves County",
      "H\u00f3dmez\u0151v\u00e1s\u00e1rhely",
      "J\u00e1sz-Nagykun-Szolnok County",
      "Kaposv\u00e1r",
      "Kecskem\u00e9t",
      "Kom\u00e1rom-Esztergom",
      "Miskolc",
      "Nagykanizsa",
      "N\u00f3gr\u00e1d County",
      "Ny\u00edregyh\u00e1za",
      "P\u00e9cs",
      "Pest County",
      "Salg\u00f3tarj\u00e1n",
      "Somogy County",
      "Sopron",
      "Szabolcs-Szatm\u00e1r-Bereg County",
      "Szeged",
      "Sz\u00e9kesfeh\u00e9rv\u00e1r",
      "Szeksz\u00e1rd",
      "Szolnok",
      "Szombathely",
      "Tatab\u00e1nya",
      "Tolna County",
      "Vas County",
      "Veszpr\u00e9m",
      "Veszpr\u00e9m County",
      "Zala County",
      "Zalaegerszeg",
    ],
  },
  ID: {
    country: "Indonesia",
    phone_code: "62",
    states: [
      "Aceh",
      "Bali",
      "Banten",
      "Bengkulu",
      "DI Yogyakarta",
      "DKI Jakarta",
      "Gorontalo",
      "Jambi",
      "Jawa Barat",
      "Jawa Tengah",
      "Jawa Timur",
      "Kalimantan Barat",
      "Kalimantan Selatan",
      "Kalimantan Tengah",
      "Kalimantan Timur",
      "Kalimantan Utara",
      "Kepulauan Bangka Belitung",
      "Kepulauan Riau",
      "Lampung",
      "Maluku",
      "Maluku Utara",
      "Nusa Tenggara Barat",
      "Nusa Tenggara Timur",
      "Papua",
      "Papua Barat",
      "Riau",
      "Sulawesi Barat",
      "Sulawesi Selatan",
      "Sulawesi Tengah",
      "Sulawesi Tenggara",
      "Sulawesi Utara",
      "Sumatera Barat",
      "Sumatera Selatan",
      "Sumatera Utara",
    ],
  },
  IE: {
    country: "Ireland",
    phone_code: "353",
    states: [
      "Carlow",
      "Cavan",
      "Clare",
      "Connacht",
      "Cork",
      "Donegal",
      "Dublin",
      "Galway",
      "Kerry",
      "Kildare",
      "Kilkenny",
      "Laois",
      "Leinster",
      "Limerick",
      "Longford",
      "Louth",
      "Mayo",
      "Meath",
      "Monaghan",
      "Munster",
      "Offaly",
      "Roscommon",
      "Sligo",
      "Tipperary",
      "Ulster",
      "Waterford",
      "Westmeath",
      "Wexford",
      "Wicklow",
    ],
  },
  IL: {
    country: "Israel",
    phone_code: "972",
    states: [
      "Central District",
      "Haifa District",
      "Jerusalem District",
      "Northern District",
      "Southern District",
      "Tel Aviv District",
    ],
  },
  IM: {
    country: "Man (Isle of)",
    phone_code: "44-1624",
    states: [],
  },
  IN: {
    country: "India",
    phone_code: "91",
    states: [
      "Andaman and Nicobar Islands",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chhattisgarh",
      "Dadra and Nagar Haveli and Daman and Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Ladakh",
      "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Puducherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal",
    ],
  },
  IO: {
    country: "British Indian Ocean Territory",
    phone_code: "246",
    states: [],
  },
  IQ: {
    country: "Iraq",
    phone_code: "964",
    states: [
      "Al Anbar Governorate",
      "Al Muthanna Governorate",
      "Al-Q\u0101disiyyah Governorate",
      "Babylon Governorate",
      "Baghdad Governorate",
      "Basra Governorate",
      "Dhi Qar Governorate",
      "Diyala Governorate",
      "Dohuk Governorate",
      "Erbil Governorate",
      "Karbala Governorate",
      "Kirkuk Governorate",
      "Maysan Governorate",
      "Najaf Governorate",
      "Nineveh Governorate",
      "Saladin Governorate",
      "Sulaymaniyah Governorate",
      "Wasit Governorate",
    ],
  },
  IR: {
    country: "Iran",
    phone_code: "98",
    states: [
      "Alborz",
      "Ardabil",
      "Bushehr",
      "Chaharmahal and Bakhtiari",
      "East Azerbaijan",
      "Fars",
      "Gilan",
      "Golestan",
      "Hamadan",
      "Hormozgan",
      "Ilam",
      "Isfahan",
      "Kerman",
      "Kermanshah",
      "Khuzestan",
      "Kohgiluyeh and Boyer-Ahmad",
      "Kurdistan",
      "Lorestan",
      "Markazi",
      "Mazandaran",
      "North Khorasan",
      "Qazvin",
      "Qom",
      "Razavi Khorasan",
      "Semnan",
      "Sistan and Baluchestan",
      "South Khorasan",
      "Tehran",
      "West Azarbaijan",
      "Yazd",
      "Zanjan",
    ],
  },
  IS: {
    country: "Iceland",
    phone_code: "354",
    states: [
      "Capital Region",
      "Eastern Region",
      "Northeastern Region",
      "Northwestern Region",
      "Southern Peninsula Region",
      "Southern Region",
      "Western Region",
      "Westfjords",
    ],
  },
  IT: {
    country: "Italy",
    phone_code: "39",
    states: [
      "Abruzzo",
      "Agrigento",
      "Alessandria",
      "Ancona",
      "Aosta Valley",
      "Apulia",
      "Ascoli Piceno",
      "Asti",
      "Avellino",
      "Bari",
      "Barletta-Andria-Trani",
      "Basilicata",
      "Belluno",
      "Benevento",
      "Bergamo",
      "Biella",
      "Bologna",
      "Brescia",
      "Brindisi",
      "Cagliari",
      "Calabria",
      "Caltanissetta",
      "Campania",
      "Campobasso",
      "Caserta",
      "Catania",
      "Catanzaro",
      "Chieti",
      "Como",
      "Cosenza",
      "Cremona",
      "Crotone",
      "Cuneo",
      "Emilia-Romagna",
      "Enna",
      "Fermo",
      "Ferrara",
      "Florence",
      "Foggia",
      "Forl\u00ec-Cesena",
      "Friuli\u2013Venezia Giulia",
      "Frosinone",
      "Genoa",
      "Gorizia",
      "Grosseto",
      "Imperia",
      "Isernia",
      "L'Aquila",
      "La Spezia",
      "Latina",
      "Lazio",
      "Lecce",
      "Lecco",
      "Liguria",
      "Livorno",
      "Lodi",
      "Lombardy",
      "Lucca",
      "Macerata",
      "Mantua",
      "Marche",
      "Massa and Carrara",
      "Matera",
      "Medio Campidano",
      "Messina",
      "Milan",
      "Modena",
      "Molise",
      "Monza and Brianza",
      "Naples",
      "Novara",
      "Nuoro",
      "Oristano",
      "Padua",
      "Palermo",
      "Parma",
      "Pavia",
      "Perugia",
      "Pesaro and Urbino",
      "Pescara",
      "Piacenza",
      "Piedmont",
      "Pisa",
      "Pistoia",
      "Pordenone",
      "Potenza",
      "Prato",
      "Ragusa",
      "Ravenna",
      "Reggio Calabria",
      "Reggio Emilia",
      "Rieti",
      "Rimini",
      "Rome",
      "Rovigo",
      "Salerno",
      "Sardinia",
      "Sassari",
      "Savona",
      "Sicily",
      "Siena",
      "Siracusa",
      "Sondrio",
      "South Sardinia",
      "South Tyrol",
      "Taranto",
      "Teramo",
      "Terni",
      "Trapani",
      "Trentino",
      "Trentino-South Tyrol",
      "Treviso",
      "Trieste",
      "Turin",
      "Tuscany",
      "Udine",
      "Umbria",
      "Varese",
      "Veneto",
      "Venice",
      "Verbano-Cusio-Ossola",
      "Vercelli",
      "Verona",
      "Vibo Valentia",
      "Vicenza",
      "Viterbo",
    ],
  },
  JE: {
    country: "Jersey",
    phone_code: "44-1534",
    states: [],
  },
  JM: {
    country: "Jamaica",
    phone_code: "1-876",
    states: [
      "Clarendon Parish",
      "Hanover Parish",
      "Kingston Parish",
      "Manchester Parish",
      "Portland Parish",
      "Saint Andrew",
      "Saint Ann Parish",
      "Saint Catherine Parish",
      "Saint Elizabeth Parish",
      "Saint James Parish",
      "Saint Mary Parish",
      "Saint Thomas Parish",
      "Trelawny Parish",
      "Westmoreland Parish",
    ],
  },
  JO: {
    country: "Jordan",
    phone_code: "962",
    states: [
      "Ajloun Governorate",
      "Amman Governorate",
      "Aqaba Governorate",
      "Balqa Governorate",
      "Irbid Governorate",
      "Jerash Governorate",
      "Karak Governorate",
      "Ma'an Governorate",
      "Madaba Governorate",
      "Mafraq Governorate",
      "Tafilah Governorate",
      "Zarqa Governorate",
    ],
  },
  JP: {
    country: "Japan",
    phone_code: "81",
    states: [
      "Aichi Prefecture",
      "Akita Prefecture",
      "Aomori Prefecture",
      "Chiba Prefecture",
      "Ehime Prefecture",
      "Fukui Prefecture",
      "Fukuoka Prefecture",
      "Fukushima Prefecture",
      "Gifu Prefecture",
      "Gunma Prefecture",
      "Hiroshima Prefecture",
      "Hokkaid\u014d Prefecture",
      "Hy\u014dgo Prefecture",
      "Ibaraki Prefecture",
      "Ishikawa Prefecture",
      "Iwate Prefecture",
      "Kagawa Prefecture",
      "Kagoshima Prefecture",
      "Kanagawa Prefecture",
      "K\u014dchi Prefecture",
      "Kumamoto Prefecture",
      "Ky\u014dto Prefecture",
      "Mie Prefecture",
      "Miyagi Prefecture",
      "Miyazaki Prefecture",
      "Nagano Prefecture",
      "Nagasaki Prefecture",
      "Nara Prefecture",
      "Niigata Prefecture",
      "\u014cita Prefecture",
      "Okayama Prefecture",
      "Okinawa Prefecture",
      "\u014csaka Prefecture",
      "Saga Prefecture",
      "Saitama Prefecture",
      "Shiga Prefecture",
      "Shimane Prefecture",
      "Shizuoka Prefecture",
      "Tochigi Prefecture",
      "Tokushima Prefecture",
      "Tokyo",
      "Tottori Prefecture",
      "Toyama Prefecture",
      "Wakayama Prefecture",
      "Yamagata Prefecture",
      "Yamaguchi Prefecture",
      "Yamanashi Prefecture",
    ],
  },
  KE: {
    country: "Kenya",
    phone_code: "254",
    states: [
      "Baringo",
      "Bomet",
      "Bungoma",
      "Busia",
      "Elgeyo-Marakwet",
      "Embu",
      "Garissa",
      "Homa Bay",
      "Isiolo",
      "Kajiado",
      "Kakamega",
      "Kericho",
      "Kiambu",
      "Kilifi",
      "Kirinyaga",
      "Kisii",
      "Kisumu",
      "Kitui",
      "Kwale",
      "Laikipia",
      "Lamu",
      "Machakos",
      "Makueni",
      "Mandera",
      "Marsabit",
      "Meru",
      "Migori",
      "Mombasa",
      "Murang'a",
      "Nairobi City",
      "Nakuru",
      "Nandi",
      "Narok",
      "Nyamira",
      "Nyandarua",
      "Nyeri",
      "Samburu",
      "Siaya",
      "Taita\u2013Taveta",
      "Tana River",
      "Tharaka-Nithi",
      "Trans Nzoia",
      "Turkana",
      "Uasin Gishu",
      "Vihiga",
      "Wajir",
      "West Pokot",
    ],
  },
  KG: {
    country: "Kyrgyzstan",
    phone_code: "996",
    states: [
      "Batken Region",
      "Bishkek",
      "Chuy Region",
      "Issyk-Kul Region",
      "Jalal-Abad Region",
      "Naryn Region",
      "Osh",
      "Osh Region",
      "Talas Region",
    ],
  },
  KH: {
    country: "Cambodia",
    phone_code: "855",
    states: [
      "Banteay Meanchey",
      "Battambang",
      "Kampong Cham",
      "Kampong Chhnang",
      "Kampong Speu",
      "Kampong Thom",
      "Kampot",
      "Kandal",
      "Kep",
      "Koh Kong",
      "Kratie",
      "Mondulkiri",
      "Oddar Meanchey",
      "Pailin",
      "Phnom Penh",
      "Preah Vihear",
      "Prey Veng",
      "Pursat",
      "Ratanakiri",
      "Siem Reap",
      "Sihanoukville",
      "Stung Treng",
      "Svay Rieng",
      "Takeo",
    ],
  },
  KI: {
    country: "Kiribati",
    phone_code: "686",
    states: ["Gilbert Islands", "Line Islands", "Phoenix Islands"],
  },
  KM: {
    country: "Comoros",
    phone_code: "269",
    states: ["Anjouan", "Grande Comore", "Moh\u00e9li"],
  },
  KN: {
    country: "Saint Kitts And Nevis",
    phone_code: "1-869",
    states: [
      "Christ Church Nichola Town Parish",
      "Nevis",
      "Saint Anne Sandy Point Parish",
      "Saint George Gingerland Parish",
      "Saint James Windward Parish",
      "Saint John Capisterre Parish",
      "Saint John Figtree Parish",
      "Saint Kitts",
      "Saint Mary Cayon Parish",
      "Saint Paul Capisterre Parish",
      "Saint Paul Charlestown Parish",
      "Saint Peter Basseterre Parish",
      "Saint Thomas Lowland Parish",
      "Saint Thomas Middle Island Parish",
      "Trinity Palmetto Point Parish",
    ],
  },
  KP: {
    country: "North Korea",
    phone_code: "850",
    states: [
      "Chagang Province",
      "Kangwon Province",
      "North Hamgyong Province",
      "North Hwanghae Province",
      "North Pyongan Province",
      "Pyongyang",
      "Rason",
      "Ryanggang Province",
      "South Hamgyong Province",
      "South Hwanghae Province",
      "South Pyongan Province",
    ],
  },
  KR: {
    country: "South Korea",
    phone_code: "82",
    states: [
      "Busan",
      "Daegu",
      "Daejeon",
      "Gangwon Province",
      "Gwangju",
      "Gyeonggi Province",
      "Incheon",
      "Jeju",
      "North Chungcheong Province",
      "North Gyeongsang Province",
      "North Jeolla Province",
      "Sejong City",
      "Seoul",
      "South Chungcheong Province",
      "South Gyeongsang Province",
      "South Jeolla Province",
      "Ulsan",
    ],
  },
  KW: {
    country: "Kuwait",
    phone_code: "965",
    states: [
      "Al Ahmadi Governorate",
      "Al Farwaniyah Governorate",
      "Al Jahra Governorate",
      "Capital Governorate",
      "Hawalli Governorate",
      "Mubarak Al-Kabeer Governorate",
    ],
  },
  KY: {
    country: "Cayman Islands",
    phone_code: "1-345",
    states: [],
  },
  KZ: {
    country: "Kazakhstan",
    phone_code: "7",
    states: [
      "Akmola Region",
      "Aktobe Region",
      "Almaty",
      "Almaty Region",
      "Atyrau Region",
      "Baikonur",
      "East Kazakhstan Region",
      "Jambyl Region",
      "Karaganda Region",
      "Kostanay Region",
      "Kyzylorda Region",
      "Mangystau Region",
      "North Kazakhstan Region",
      "Nur-Sultan",
      "Pavlodar Region",
      "Turkestan Region",
      "West Kazakhstan Province",
    ],
  },
  LA: {
    country: "Laos",
    phone_code: "856",
    states: [
      "Attapeu Province",
      "Bokeo Province",
      "Bolikhamsai Province",
      "Champasak Province",
      "Houaphanh Province",
      "Khammouane Province",
      "Luang Namtha Province",
      "Luang Prabang Province",
      "Oudomxay Province",
      "Phongsaly Province",
      "Sainyabuli Province",
      "Salavan Province",
      "Savannakhet Province",
      "Sekong Province",
      "Vientiane Prefecture",
      "Vientiane Province",
      "Xaisomboun",
      "Xaisomboun Province",
      "Xiangkhouang Province",
    ],
  },
  LB: {
    country: "Lebanon",
    phone_code: "961",
    states: [
      "Akkar Governorate",
      "Baalbek-Hermel Governorate",
      "Beirut Governorate",
      "Beqaa Governorate",
      "Mount Lebanon Governorate",
      "Nabatieh Governorate",
      "North Governorate",
      "South Governorate",
    ],
  },
  LC: {
    country: "Saint Lucia",
    phone_code: "1-758",
    states: [
      "Anse la Raye Quarter",
      "Canaries",
      "Castries Quarter",
      "Choiseul Quarter",
      "Dauphin Quarter",
      "Dennery Quarter",
      "Gros Islet Quarter",
      "Laborie Quarter",
      "Micoud Quarter",
      "Praslin Quarter",
      "Soufri\u00e8re Quarter",
      "Vieux Fort Quarter",
    ],
  },
  LI: {
    country: "Liechtenstein",
    phone_code: "423",
    states: [
      "Balzers",
      "Eschen",
      "Gamprin",
      "Mauren",
      "Planken",
      "Ruggell",
      "Schaan",
      "Schellenberg",
      "Triesen",
      "Triesenberg",
      "Vaduz",
    ],
  },
  LK: {
    country: "Sri Lanka",
    phone_code: "94",
    states: [
      "Ampara District",
      "Anuradhapura District",
      "Badulla District",
      "Batticaloa District",
      "Central Province",
      "Colombo District",
      "Eastern Province",
      "Galle District",
      "Gampaha District",
      "Hambantota District",
      "Jaffna District",
      "Kalutara District",
      "Kandy District",
      "Kegalle District",
      "Kilinochchi District",
      "Mannar District",
      "Matale District",
      "Matara District",
      "Monaragala District",
      "Mullaitivu District",
      "North Central Province",
      "North Western Province",
      "Northern Province",
      "Nuwara Eliya District",
      "Polonnaruwa District",
      "Puttalam District",
      "Ratnapura district",
      "Sabaragamuwa Province",
      "Southern Province",
      "Trincomalee District",
      "Uva Province",
      "Vavuniya District",
      "Western Province",
    ],
  },
  LR: {
    country: "Liberia",
    phone_code: "231",
    states: [
      "Bomi County",
      "Bong County",
      "Gbarpolu County",
      "Grand Bassa County",
      "Grand Cape Mount County",
      "Grand Gedeh County",
      "Grand Kru County",
      "Lofa County",
      "Margibi County",
      "Maryland County",
      "Montserrado County",
      "Nimba",
      "River Cess County",
      "River Gee County",
      "Sinoe County",
    ],
  },
  LS: {
    country: "Lesotho",
    phone_code: "266",
    states: [
      "Berea District",
      "Butha-Buthe District",
      "Leribe District",
      "Mafeteng District",
      "Maseru District",
      "Mohale's Hoek District",
      "Mokhotlong District",
      "Qacha's Nek District",
      "Quthing District",
      "Thaba-Tseka District",
    ],
  },
  LT: {
    country: "Lithuania",
    phone_code: "370",
    states: [
      "Akmen\u0117 District Municipality",
      "Alytus City Municipality",
      "Alytus County",
      "Alytus District Municipality",
      "Bir\u0161tonas Municipality",
      "Bir\u017eai District Municipality",
      "Druskininkai municipality",
      "Elektr\u0117nai municipality",
      "Ignalina District Municipality",
      "Jonava District Municipality",
      "Joni\u0161kis District Municipality",
      "Jurbarkas District Municipality",
      "Kai\u0161iadorys District Municipality",
      "Kalvarija municipality",
      "Kaunas City Municipality",
      "Kaunas County",
      "Kaunas District Municipality",
      "Kazl\u0173 R\u016bda municipality",
      "K\u0117dainiai District Municipality",
      "Kelm\u0117 District Municipality",
      "Klaipeda City Municipality",
      "Klaip\u0117da County",
      "Klaip\u0117da District Municipality",
      "Kretinga District Municipality",
      "Kupi\u0161kis District Municipality",
      "Lazdijai District Municipality",
      "Marijampol\u0117 County",
      "Marijampol\u0117 Municipality",
      "Ma\u017eeikiai District Municipality",
      "Mol\u0117tai District Municipality",
      "Neringa Municipality",
      "Pag\u0117giai municipality",
      "Pakruojis District Municipality",
      "Palanga City Municipality",
      "Panev\u0117\u017eys City Municipality",
      "Panev\u0117\u017eys County",
      "Panev\u0117\u017eys District Municipality",
      "Pasvalys District Municipality",
      "Plung\u0117 District Municipality",
      "Prienai District Municipality",
      "Radvili\u0161kis District Municipality",
      "Raseiniai District Municipality",
      "Rietavas municipality",
      "Roki\u0161kis District Municipality",
      "\u0160akiai District Municipality",
      "\u0160al\u010dininkai District Municipality",
      "\u0160iauliai City Municipality",
      "\u0160iauliai County",
      "\u0160iauliai District Municipality",
      "\u0160ilal\u0117 District Municipality",
      "\u0160ilut\u0117 District Municipality",
      "\u0160irvintos District Municipality",
      "Skuodas District Municipality",
      "\u0160ven\u010dionys District Municipality",
      "Taurag\u0117 County",
      "Taurag\u0117 District Municipality",
      "Tel\u0161iai County",
      "Tel\u0161iai District Municipality",
      "Trakai District Municipality",
      "Ukmerg\u0117 District Municipality",
      "Utena County",
      "Utena District Municipality",
      "Var\u0117na District Municipality",
      "Vilkavi\u0161kis District Municipality",
      "Vilnius City Municipality",
      "Vilnius County",
      "Vilnius District Municipality",
      "Visaginas Municipality",
      "Zarasai District Municipality",
    ],
  },
  LU: {
    country: "Luxembourg",
    phone_code: "352",
    states: [
      "Canton of Capellen",
      "Canton of Clervaux",
      "Canton of Diekirch",
      "Canton of Echternach",
      "Canton of Esch-sur-Alzette",
      "Canton of Grevenmacher",
      "Canton of Luxembourg",
      "Canton of Mersch",
      "Canton of Redange",
      "Canton of Remich",
      "Canton of Vianden",
      "Canton of Wiltz",
      "Diekirch District",
      "Grevenmacher District",
      "Luxembourg District",
    ],
  },
  LV: {
    country: "Latvia",
    phone_code: "371",
    states: [
      "Aglona Municipality",
      "Aizkraukle Municipality",
      "Aizpute Municipality",
      "Akn\u012bste Municipality",
      "Aloja Municipality",
      "Alsunga Municipality",
      "Al\u016bksne Municipality",
      "Amata Municipality",
      "Ape Municipality",
      "Auce Municipality",
      "Bab\u012bte Municipality",
      "Baldone Municipality",
      "Baltinava Municipality",
      "Balvi Municipality",
      "Bauska Municipality",
      "Bever\u012bna Municipality",
      "Broc\u0113ni Municipality",
      "Burtnieki Municipality",
      "Carnikava Municipality",
      "C\u0113sis Municipality",
      "Cesvaine Municipality",
      "Cibla Municipality",
      "Dagda Municipality",
      "Daugavpils",
      "Daugavpils Municipality",
      "Dobele Municipality",
      "Dundaga Municipality",
      "Durbe Municipality",
      "Engure Municipality",
      "\u0112rg\u013ci Municipality",
      "Garkalne Municipality",
      "Grobi\u0146a Municipality",
      "Gulbene Municipality",
      "Iecava Municipality",
      "Ik\u0161\u0137ile Municipality",
      "Il\u016bkste Municipality",
      "In\u010dukalns Municipality",
      "Jaunjelgava Municipality",
      "Jaunpiebalga Municipality",
      "Jaunpils Municipality",
      "J\u0113kabpils",
      "J\u0113kabpils Municipality",
      "Jelgava",
      "Jelgava Municipality",
      "J\u016brmala",
      "Kandava Municipality",
      "K\u0101rsava Municipality",
      "\u0136egums Municipality",
      "\u0136ekava Municipality",
      "Koc\u0113ni Municipality",
      "Koknese Municipality",
      "Kr\u0101slava Municipality",
      "Krimulda Municipality",
      "Krustpils Municipality",
      "Kuld\u012bga Municipality",
      "Lielv\u0101rde Municipality",
      "Liep\u0101ja",
      "L\u012bgatne Municipality",
      "Limba\u017ei Municipality",
      "L\u012bv\u0101ni Municipality",
      "Lub\u0101na Municipality",
      "Ludza Municipality",
      "Madona Municipality",
      "M\u0101lpils Municipality",
      "M\u0101rupe Municipality",
      "Mazsalaca Municipality",
      "M\u0113rsrags Municipality",
      "Nauk\u0161\u0113ni Municipality",
      "Nereta Municipality",
      "N\u012bca Municipality",
      "Ogre Municipality",
      "Olaine Municipality",
      "Ozolnieki Municipality",
      "P\u0101rgauja Municipality",
      "P\u0101vilosta Municipality",
      "P\u013cavi\u0146as Municipality",
      "Prei\u013ci Municipality",
      "Priekule Municipality",
      "Prieku\u013ci Municipality",
      "Rauna Municipality",
      "R\u0113zekne",
      "R\u0113zekne Municipality",
      "Riebi\u0146i Municipality",
      "Riga",
      "Roja Municipality",
      "Ropa\u017ei Municipality",
      "Rucava Municipality",
      "Rug\u0101ji Municipality",
      "R\u016bjiena Municipality",
      "Rund\u0101le Municipality",
      "Sala Municipality",
      "Salacgr\u012bva Municipality",
      "Salaspils Municipality",
      "Saldus Municipality",
      "Saulkrasti Municipality",
      "S\u0113ja Municipality",
      "Sigulda Municipality",
      "Skr\u012bveri Municipality",
      "Skrunda Municipality",
      "Smiltene Municipality",
      "Stopi\u0146i Municipality",
      "Stren\u010di Municipality",
      "Talsi Municipality",
      "T\u0113rvete Municipality",
      "Tukums Municipality",
      "Vai\u0146ode Municipality",
      "Valka Municipality",
      "Valmiera",
      "Varak\u013c\u0101ni Municipality",
      "V\u0101rkava Municipality",
      "Vecpiebalga Municipality",
      "Vecumnieki Municipality",
      "Ventspils",
      "Ventspils Municipality",
      "Vies\u012bte Municipality",
      "Vi\u013caka Municipality",
      "Vi\u013c\u0101ni Municipality",
      "Zilupe Municipality",
    ],
  },
  LY: {
    country: "Libya",
    phone_code: "218",
    states: [
      "Al Wahat District",
      "Benghazi",
      "Derna District",
      "Ghat District",
      "Jabal al Akhdar",
      "Jabal al Gharbi District",
      "Jafara",
      "Jufra",
      "Kufra District",
      "Marj District",
      "Misrata District",
      "Murqub",
      "Murzuq District",
      "Nalut District",
      "Nuqat al Khams",
      "Sabha District",
      "Sirte District",
      "Tripoli District",
      "Wadi al Hayaa District",
      "Wadi al Shatii District",
      "Zawiya District",
    ],
  },
  MA: {
    country: "Morocco",
    phone_code: "212",
    states: [
      "Agadir-Ida-Ou-Tanane",
      "Al Haouz",
      "Al Hoce\u00efma",
      "Aousserd (EH)",
      "Assa-Zag (EH-partial)",
      "Azilal",
      "B\u00e9ni Mellal",
      "B\u00e9ni Mellal-Kh\u00e9nifra",
      "Benslimane",
      "Berkane",
      "Berrechid",
      "Boujdour (EH)",
      "Boulemane",
      "Casablanca",
      "Casablanca-Settat",
      "Chefchaouen",
      "Chichaoua",
      "Chtouka-Ait Baha",
      "Dakhla-Oued Ed-Dahab (EH)",
      "Dr\u00e2a-Tafilalet",
      "Driouch",
      "El Hajeb",
      "El Jadida",
      "El Kel\u00e2a des Sraghna",
      "Errachidia",
      "Es-Semara (EH-partial)",
      "Essaouira",
      "Fahs-Anjra",
      "F\u00e8s",
      "F\u00e8s-Mekn\u00e8s",
      "Figuig",
      "Fquih Ben Salah",
      "Guelmim",
      "Guelmim-Oued Noun (EH-partial)",
      "Guercif",
      "Ifrane",
      "Inezgane-Ait Melloul",
      "Jerada",
      "K\u00e9nitra",
      "Kh\u00e9misset",
      "Kh\u00e9nifra",
      "Khouribga",
      "L'Oriental",
      "La\u00e2youne (EH)",
      "La\u00e2youne-Sakia El Hamra (EH-partial)",
      "Larache",
      "M\u2019diq-Fnideq",
      "Marrakech",
      "Marrakesh-Safi",
      "M\u00e9diouna",
      "Mekn\u00e8s",
      "Midelt",
      "Mohammadia",
      "Moulay Yacoub",
      "Nador",
      "Nouaceur",
      "Ouarzazate",
      "Oued Ed-Dahab (EH)",
      "Ouezzane",
      "Oujda-Angad",
      "Rabat",
      "Rabat-Sal\u00e9-K\u00e9nitra",
      "Rehamna",
      "Safi",
      "Sal\u00e9",
      "Sefrou",
      "Settat",
      "Sidi Bennour",
      "Sidi Ifni",
      "Sidi Kacem",
      "Sidi Slimane",
      "Skhirate-T\u00e9mara",
      "Souss-Massa",
      "Tan-Tan (EH-partial)",
      "Tanger-Assilah",
      "Tanger-T\u00e9touan-Al Hoce\u00efma",
      "Taounate",
      "Taourirt",
      "Tarfaya (EH-partial)",
      "Taroudannt",
      "Tata",
      "Taza",
      "T\u00e9touan",
      "Tinghir",
      "Tiznit",
      "Youssoufia",
      "Zagora",
    ],
  },
  MC: {
    country: "Monaco",
    phone_code: "377",
    states: ["La Colle", "La Condamine", "Moneghetti"],
  },
  MD: {
    country: "Moldova",
    phone_code: "373",
    states: [
      "Anenii Noi District",
      "B\u0103l\u021bi Municipality",
      "Basarabeasca District",
      "Bender Municipality",
      "Briceni District",
      "Cahul District",
      "C\u0103l\u0103ra\u0219i District",
      "Cantemir District",
      "C\u0103u\u0219eni District",
      "Chi\u0219in\u0103u Municipality",
      "Cimi\u0219lia District",
      "Criuleni District",
      "Dondu\u0219eni District",
      "Drochia District",
      "Dub\u0103sari District",
      "Edine\u021b District",
      "F\u0103le\u0219ti District",
      "Flore\u0219ti District",
      "Gagauzia",
      "Glodeni District",
      "H\u00eence\u0219ti District",
      "Ialoveni District",
      "Nisporeni District",
      "Ocni\u021ba District",
      "Orhei District",
      "Rezina District",
      "R\u00ee\u0219cani District",
      "S\u00eengerei District",
      "\u0218old\u0103ne\u0219ti District",
      "Soroca District",
      "\u0218tefan Vod\u0103 District",
      "Str\u0103\u0219eni District",
      "Taraclia District",
      "Telene\u0219ti District",
      "Transnistria autonomous territorial unit",
      "Ungheni District",
    ],
  },
  ME: {
    country: "Montenegro",
    phone_code: "382",
    states: [
      "Andrijevica Municipality",
      "Bar Municipality",
      "Berane Municipality",
      "Bijelo Polje Municipality",
      "Budva Municipality",
      "Danilovgrad Municipality",
      "Gusinje Municipality",
      "Kola\u0161in Municipality",
      "Kotor Municipality",
      "Mojkovac Municipality",
      "Nik\u0161i\u0107 Municipality",
      "Old Royal Capital Cetinje",
      "Petnjica Municipality",
      "Plav Municipality",
      "Pljevlja Municipality",
      "Plu\u017eine Municipality",
      "Podgorica Municipality",
      "Ro\u017eaje Municipality",
      "\u0160avnik Municipality",
      "Tivat Municipality",
      "Ulcinj Municipality",
      "\u017dabljak Municipality",
    ],
  },
  MF: {
    country: "Saint-Martin (French part)",
    phone_code: "590",
    states: [],
  },
  MG: {
    country: "Madagascar",
    phone_code: "261",
    states: [
      "Antananarivo Province",
      "Antsiranana Province",
      "Fianarantsoa Province",
      "Mahajanga Province",
      "Toamasina Province",
      "Toliara Province",
    ],
  },
  MH: {
    country: "Marshall Islands",
    phone_code: "692",
    states: ["Ralik Chain", "Ratak Chain"],
  },
  MK: {
    country: "Macedonia",
    phone_code: "389",
    states: [
      "Aerodrom Municipality",
      "Ara\u010dinovo Municipality",
      "Berovo Municipality",
      "Bitola Municipality",
      "Bogdanci Municipality",
      "Bogovinje Municipality",
      "Bosilovo Municipality",
      "Brvenica Municipality",
      "Butel Municipality",
      "\u010cair Municipality",
      "\u010ca\u0161ka Municipality",
      "Centar Municipality",
      "Centar \u017dupa Municipality",
      "\u010ce\u0161inovo-Oble\u0161evo Municipality",
      "\u010cu\u010der-Sandevo Municipality",
      "Debarca Municipality",
      "Del\u010devo Municipality",
      "Demir Hisar Municipality",
      "Demir Kapija Municipality",
      "Dojran Municipality",
      "Dolneni Municipality",
      "Drugovo Municipality",
      "Gazi Baba Municipality",
      "Gevgelija Municipality",
      "Gjor\u010de Petrov Municipality",
      "Gostivar Municipality",
      "Gradsko Municipality",
      "Greater Skopje",
      "Ilinden Municipality",
      "Jegunovce Municipality",
      "Karbinci",
      "Karpo\u0161 Municipality",
      "Kavadarci Municipality",
      "Ki\u010devo Municipality",
      "Kisela Voda Municipality",
      "Ko\u010dani Municipality",
      "Kon\u010de Municipality",
      "Kratovo Municipality",
      "Kriva Palanka Municipality",
      "Krivoga\u0161tani Municipality",
      "Kru\u0161evo Municipality",
      "Kumanovo Municipality",
      "Lipkovo Municipality",
      "Lozovo Municipality",
      "Makedonska Kamenica Municipality",
      "Makedonski Brod Municipality",
      "Mavrovo and Rostu\u0161a Municipality",
      "Mogila Municipality",
      "Negotino Municipality",
      "Novaci Municipality",
      "Novo Selo Municipality",
      "Ohrid Municipality",
      "Oslomej Municipality",
      "Peh\u010devo Municipality",
      "Petrovec Municipality",
      "Plasnica Municipality",
      "Prilep Municipality",
      "Probi\u0161tip Municipality",
      "Radovi\u0161 Municipality",
      "Rankovce Municipality",
      "Resen Municipality",
      "Rosoman Municipality",
      "Saraj Municipality",
      "Sopi\u0161te Municipality",
      "Staro Nagori\u010dane Municipality",
      "\u0160tip Municipality",
      "Struga Municipality",
      "Strumica Municipality",
      "Studeni\u010dani Municipality",
      "\u0160uto Orizari Municipality",
      "Sveti Nikole Municipality",
      "Tearce Municipality",
      "Tetovo Municipality",
      "Valandovo Municipality",
      "Vasilevo Municipality",
      "Veles Municipality",
      "Vev\u010dani Municipality",
      "Vinica Municipality",
      "Vrane\u0161tica Municipality",
      "Vrap\u010di\u0161te Municipality",
      "Zajas Municipality",
      "Zelenikovo Municipality",
      "\u017delino Municipality",
      "Zrnovci Municipality",
    ],
  },
  ML: {
    country: "Mali",
    phone_code: "223",
    states: [
      "Bamako",
      "Gao Region",
      "Kayes Region",
      "Kidal Region",
      "Koulikoro Region",
      "M\u00e9naka Region",
      "Mopti Region",
      "S\u00e9gou Region",
      "Sikasso Region",
      "Taoud\u00e9nit Region",
      "Tombouctou Region",
    ],
  },
  MM: {
    country: "Myanmar",
    phone_code: "95",
    states: [
      "Ayeyarwady Region",
      "Bago",
      "Chin State",
      "Kachin State",
      "Kayah State",
      "Kayin State",
      "Magway Region",
      "Mandalay Region",
      "Mon State",
      "Naypyidaw Union Territory",
      "Rakhine State",
      "Sagaing Region",
      "Shan State",
      "Tanintharyi Region",
      "Yangon Region",
    ],
  },
  MN: {
    country: "Mongolia",
    phone_code: "976",
    states: [
      "Arkhangai Province",
      "Bayan-\u00d6lgii Province",
      "Bayankhongor Province",
      "Bulgan Province",
      "Darkhan-Uul Province",
      "Dornod Province",
      "Dornogovi Province",
      "Dundgovi Province",
      "Govi-Altai Province",
      "Govis\u00fcmber Province",
      "Khentii Province",
      "Khovd Province",
      "Kh\u00f6vsg\u00f6l Province",
      "\u00d6mn\u00f6govi Province",
      "Orkhon Province",
      "\u00d6v\u00f6rkhangai Province",
      "Selenge Province",
      "S\u00fckhbaatar Province",
      "T\u00f6v Province",
      "Uvs Province",
      "Zavkhan Province",
    ],
  },
  MO: {
    country: "Macau S.A.R.",
    phone_code: "853",
    states: [],
  },
  MP: {
    country: "Northern Mariana Islands",
    phone_code: "1-670",
    states: [],
  },
  MQ: {
    country: "Martinique",
    phone_code: "596",
    states: [],
  },
  MR: {
    country: "Mauritania",
    phone_code: "222",
    states: [
      "Adrar",
      "Assaba",
      "Brakna",
      "Dakhlet Nouadhibou",
      "Gorgol",
      "Guidimaka",
      "Hodh Ech Chargui",
      "Hodh El Gharbi",
      "Inchiri",
      "Nouakchott-Nord",
      "Nouakchott-Ouest",
      "Nouakchott-Sud",
      "Tagant",
      "Tiris Zemmour",
      "Trarza",
    ],
  },
  MS: {
    country: "Montserrat",
    phone_code: "1-664",
    states: [],
  },
  MT: {
    country: "Malta",
    phone_code: "356",
    states: [
      "Attard",
      "Balzan",
      "Birgu",
      "Birkirkara",
      "Bir\u017cebbu\u0121a",
      "Cospicua",
      "Dingli",
      "Fgura",
      "Floriana",
      "Fontana",
      "G\u0127ajnsielem",
      "G\u0127arb",
      "G\u0127arg\u0127ur",
      "G\u0127asri",
      "G\u0127axaq",
      "Gudja",
      "G\u017cira",
      "\u0126amrun",
      "Iklin",
      "Kalkara",
      "Ker\u010bem",
      "Kirkop",
      "Lija",
      "Luqa",
      "Marsa",
      "Marsaskala",
      "Marsaxlokk",
      "Mdina",
      "Mellie\u0127a",
      "M\u0121arr",
      "Mosta",
      "Mqabba",
      "Msida",
      "Mtarfa",
      "Munxar",
      "Nadur",
      "Naxxar",
      "Paola",
      "Pembroke",
      "Piet\u00e0",
      "Qala",
      "Qormi",
      "Qrendi",
      "Rabat",
      "Saint Lawrence",
      "San \u0120wann",
      "Sannat",
      "Santa Lu\u010bija",
      "Santa Venera",
      "Senglea",
      "Si\u0121\u0121iewi",
      "Sliema",
      "St. Julian's",
      "St. Paul's Bay",
      "Swieqi",
      "Ta' Xbiex",
      "Tarxien",
      "Valletta",
      "Victoria",
      "Xag\u0127ra",
      "Xewkija",
      "Xg\u0127ajra",
      "\u017babbar",
      "\u017bebbu\u0121 Gozo",
      "\u017bebbu\u0121 Malta",
      "\u017bejtun",
      "\u017burrieq",
    ],
  },
  MU: {
    country: "Mauritius",
    phone_code: "230",
    states: [
      "Agal\u00e9ga",
      "Beau Bassin-Rose Hill",
      "Cargados Carajos",
      "Curepipe",
      "Flacq District",
      "Grand Port District",
      "Moka District",
      "Pamplemousses District",
      "Plaines Wilhems District",
      "Port Louis",
      "Port Louis District",
      "Quatre Bornes",
      "Rivi\u00e8re du Rempart District",
      "Rivi\u00e8re Noire District",
      "Rodrigues",
      "Savanne District",
      "Vacoas-Phoenix",
    ],
  },
  MV: {
    country: "Maldives",
    phone_code: "960",
    states: [
      "Addu Atoll",
      "Alif Alif Atoll",
      "Alif Dhaal Atoll",
      "Central Province",
      "Dhaalu Atoll",
      "Faafu Atoll",
      "Gaafu Alif Atoll",
      "Gaafu Dhaalu Atoll",
      "Gnaviyani Atoll",
      "Haa Alif Atoll",
      "Haa Dhaalu Atoll",
      "Kaafu Atoll",
      "Laamu Atoll",
      "Lhaviyani Atoll",
      "Mal\u00e9",
      "Meemu Atoll",
      "Noonu Atoll",
      "North Central Province",
      "North Province",
      "Raa Atoll",
      "Shaviyani Atoll",
      "South Central Province",
      "South Province",
      "Thaa Atoll",
      "Upper South Province",
      "Vaavu Atoll",
    ],
  },
  MW: {
    country: "Malawi",
    phone_code: "265",
    states: [
      "Balaka District",
      "Blantyre District",
      "Central Region",
      "Chikwawa District",
      "Chiradzulu District",
      "Chitipa district",
      "Dedza District",
      "Dowa District",
      "Karonga District",
      "Kasungu District",
      "Likoma District",
      "Lilongwe District",
      "Machinga District",
      "Mangochi District",
      "Mchinji District",
      "Mulanje District",
      "Mwanza District",
      "Mzimba District",
      "Nkhata Bay District",
      "Nkhotakota District",
      "Northern Region",
      "Nsanje District",
      "Ntcheu District",
      "Ntchisi District",
      "Phalombe District",
      "Rumphi District",
      "Salima District",
      "Southern Region",
      "Thyolo District",
      "Zomba District",
    ],
  },
  MX: {
    country: "Mexico",
    phone_code: "52",
    states: [
      "Aguascalientes",
      "Baja California",
      "Baja California Sur",
      "Campeche",
      "Chiapas",
      "Chihuahua",
      "Ciudad de M\u00e9xico",
      "Coahuila de Zaragoza",
      "Colima",
      "Durango",
      "Estado de M\u00e9xico",
      "Guanajuato",
      "Guerrero",
      "Hidalgo",
      "Jalisco",
      "Michoac\u00e1n de Ocampo",
      "Morelos",
      "Nayarit",
      "Nuevo Le\u00f3n",
      "Oaxaca",
      "Puebla",
      "Quer\u00e9taro",
      "Quintana Roo",
      "San Luis Potos\u00ed",
      "Sinaloa",
      "Sonora",
      "Tabasco",
      "Tamaulipas",
      "Tlaxcala",
      "Veracruz de Ignacio de la Llave",
      "Yucat\u00e1n",
      "Zacatecas",
    ],
  },
  MY: {
    country: "Malaysia",
    phone_code: "60",
    states: [
      "Johor",
      "Kedah",
      "Kelantan",
      "Kuala Lumpur",
      "Labuan",
      "Malacca",
      "Negeri Sembilan",
      "Pahang",
      "Penang",
      "Perak",
      "Perlis",
      "Putrajaya",
      "Sabah",
      "Sarawak",
      "Selangor",
      "Terengganu",
    ],
  },
  MZ: {
    country: "Mozambique",
    phone_code: "258",
    states: [
      "Cabo Delgado Province",
      "Gaza Province",
      "Inhambane Province",
      "Manica Province",
      "Maputo",
      "Maputo Province",
      "Nampula Province",
      "Niassa Province",
      "Sofala Province",
      "Tete Province",
      "Zambezia Province",
    ],
  },
  NA: {
    country: "Namibia",
    phone_code: "264",
    states: [
      "Erongo Region",
      "Hardap Region",
      "Karas Region",
      "Kavango East Region",
      "Kavango West Region",
      "Khomas Region",
      "Kunene Region",
      "Ohangwena Region",
      "Omaheke Region",
      "Omusati Region",
      "Oshana Region",
      "Oshikoto Region",
      "Otjozondjupa Region",
      "Zambezi Region",
    ],
  },
  NC: {
    country: "New Caledonia",
    phone_code: "687",
    states: [],
  },
  NE: {
    country: "Niger",
    phone_code: "227",
    states: [
      "Agadez Region",
      "Diffa Region",
      "Dosso Region",
      "Maradi Region",
      "Tahoua Region",
      "Tillab\u00e9ri Region",
      "Zinder Region",
    ],
  },
  NF: {
    country: "Norfolk Island",
    phone_code: "672",
    states: [],
  },
  NG: {
    country: "Nigeria",
    phone_code: "234",
    states: [
      "Abia",
      "Abuja Federal Capital Territory",
      "Adamawa",
      "Akwa Ibom",
      "Anambra",
      "Bauchi",
      "Bayelsa",
      "Benue",
      "Borno",
      "Cross River",
      "Delta",
      "Ebonyi",
      "Edo",
      "Ekiti",
      "Enugu",
      "Gombe",
      "Imo",
      "Jigawa",
      "Kaduna",
      "Kano",
      "Katsina",
      "Kebbi",
      "Kogi",
      "Kwara",
      "Lagos",
      "Nasarawa",
      "Niger",
      "Ogun",
      "Ondo",
      "Osun",
      "Oyo",
      "Plateau",
      "Rivers",
      "Sokoto",
      "Taraba",
      "Yobe",
      "Zamfara",
    ],
  },
  NI: {
    country: "Nicaragua",
    phone_code: "505",
    states: [
      "Boaco",
      "Carazo",
      "Chinandega",
      "Chontales",
      "Estel\u00ed",
      "Granada",
      "Jinotega",
      "Le\u00f3n",
      "Madriz",
      "Managua",
      "Masaya",
      "Matagalpa",
      "North Caribbean Coast",
      "Nueva Segovia",
      "R\u00edo San Juan",
      "Rivas",
      "South Caribbean Coast",
    ],
  },
  NL: {
    country: "Netherlands",
    phone_code: "31",
    states: [
      "Bonaire",
      "Drenthe",
      "Flevoland",
      "Friesland",
      "Gelderland",
      "Groningen",
      "Limburg",
      "North Brabant",
      "North Holland",
      "Overijssel",
      "Saba",
      "Sint Eustatius",
      "South Holland",
      "Utrecht",
      "Zeeland",
    ],
  },
  NO: {
    country: "Norway",
    phone_code: "47",
    states: [
      "Akershus",
      "Buskerud",
      "Finnmark",
      "Hedmark",
      "Hordaland",
      "Jan Mayen",
      "M\u00f8re og Romsdal",
      "Nord-Tr\u00f8ndelag",
      "Nordland",
      "Oppland",
      "Oslo",
      "\u00d8stfold",
      "Rogaland",
      "Sogn og Fjordane",
      "S\u00f8r-Tr\u00f8ndelag",
      "Svalbard",
      "Telemark",
      "Troms",
      "Tr\u00f8ndelag",
      "Vest-Agder",
      "Vestfold",
    ],
  },
  NP: {
    country: "Nepal",
    phone_code: "977",
    states: [
      "Bagmati Zone",
      "Bheri Zone",
      "Central Region",
      "Dhaulagiri Zone",
      "Eastern Development Region",
      "Far-Western Development Region",
      "Gandaki Zone",
      "Janakpur Zone",
      "Karnali Zone",
      "Kosi Zone",
      "Lumbini Zone",
      "Mahakali Zone",
      "Mechi Zone",
      "Mid-Western Region",
      "Narayani Zone",
      "Rapti Zone",
      "Sagarmatha Zone",
      "Seti Zone",
      "Western Region",
    ],
  },
  NR: {
    country: "Nauru",
    phone_code: "674",
    states: [
      "Aiwo District",
      "Anabar District",
      "Anetan District",
      "Anibare District",
      "Baiti District",
      "Boe District",
      "Buada District",
      "Denigomodu District",
      "Ewa District",
      "Ijuw District",
      "Meneng District",
      "Nibok District",
      "Uaboe District",
      "Yaren District",
    ],
  },
  NU: {
    country: "Niue",
    phone_code: "683",
    states: [],
  },
  NZ: {
    country: "New Zealand",
    phone_code: "64",
    states: [
      "Auckland Region",
      "Bay of Plenty Region",
      "Canterbury Region",
      "Chatham Islands",
      "Gisborne District",
      "Hawke's Bay Region",
      "Manawatu-Wanganui Region",
      "Marlborough Region",
      "Nelson Region",
      "Northland Region",
      "Otago Region",
      "Southland Region",
      "Taranaki Region",
      "Tasman District",
      "Waikato Region",
      "Wellington Region",
      "West Coast Region",
    ],
  },
  OM: {
    country: "Oman",
    phone_code: "968",
    states: [
      "Ad Dakhiliyah Governorate",
      "Ad Dhahirah Governorate",
      "Al Batinah North Governorate",
      "Al Batinah Region",
      "Al Batinah South Governorate",
      "Al Buraimi Governorate",
      "Al Wusta Governorate",
      "Ash Sharqiyah North Governorate",
      "Ash Sharqiyah Region",
      "Ash Sharqiyah South Governorate",
      "Dhofar Governorate",
      "Musandam Governorate",
      "Muscat Governorate",
    ],
  },
  PA: {
    country: "Panama",
    phone_code: "507",
    states: [
      "Bocas del Toro Province",
      "Chiriqu\u00ed Province",
      "Cocl\u00e9 Province",
      "Col\u00f3n Province",
      "Dari\u00e9n Province",
      "Ember\u00e1-Wounaan Comarca",
      "Guna Yala",
      "Herrera Province",
      "Los Santos Province",
      "Ng\u00f6be-Bugl\u00e9 Comarca",
      "Panam\u00e1 Oeste Province",
      "Panam\u00e1 Province",
      "Veraguas Province",
    ],
  },
  PE: {
    country: "Peru",
    phone_code: "51",
    states: [
      "Amazonas",
      "\u00c1ncash",
      "Apur\u00edmac",
      "Arequipa",
      "Ayacucho",
      "Cajamarca",
      "Callao",
      "Cusco",
      "Huancavelica",
      "Huanuco",
      "Ica",
      "Jun\u00edn",
      "La Libertad",
      "Lambayeque",
      "Lima",
      "Loreto",
      "Madre de Dios",
      "Moquegua",
      "Pasco",
      "Piura",
      "Puno",
      "San Mart\u00edn",
      "Tacna",
      "Tumbes",
      "Ucayali",
    ],
  },
  PF: {
    country: "French Polynesia",
    phone_code: "689",
    states: [],
  },
  PG: {
    country: "Papua new Guinea",
    phone_code: "675",
    states: [
      "Bougainville",
      "Central Province",
      "Chimbu Province",
      "East New Britain",
      "Eastern Highlands Province",
      "Enga Province",
      "Gulf",
      "Hela",
      "Jiwaka Province",
      "Madang Province",
      "Manus Province",
      "Milne Bay Province",
      "Morobe Province",
      "New Ireland Province",
      "Oro Province",
      "Port Moresby",
      "Sandaun Province",
      "Southern Highlands Province",
      "West New Britain Province",
      "Western Highlands Province",
      "Western Province",
    ],
  },
  PH: {
    country: "Philippines",
    phone_code: "63",
    states: [
      "Abra",
      "Agusan del Norte",
      "Agusan del Sur",
      "Aklan",
      "Albay",
      "Antique",
      "Apayao",
      "Aurora",
      "Autonomous Region in Muslim Mindanao",
      "Basilan",
      "Bataan",
      "Batanes",
      "Batangas",
      "Benguet",
      "Bicol Region",
      "Biliran",
      "Bohol",
      "Bukidnon",
      "Bulacan",
      "Cagayan",
      "Cagayan Valley",
      "Calabarzon",
      "Camarines Norte",
      "Camarines Sur",
      "Camiguin",
      "Capiz",
      "Caraga",
      "Catanduanes",
      "Cavite",
      "Cebu",
      "Central Luzon",
      "Central Visayas",
      "Compostela Valley",
      "Cordillera Administrative Region",
      "Cotabato",
      "Davao del Norte",
      "Davao del Sur",
      "Davao Occidental",
      "Davao Oriental",
      "Davao Region",
      "Dinagat Islands",
      "Eastern Samar",
      "Eastern Visayas",
      "Guimaras",
      "Ifugao",
      "Ilocos Norte",
      "Ilocos Region",
      "Ilocos Sur",
      "Iloilo",
      "Isabela",
      "Kalinga",
      "La Union",
      "Laguna",
      "Lanao del Norte",
      "Lanao del Sur",
      "Leyte",
      "Maguindanao",
      "Marinduque",
      "Masbate",
      "Metro Manila",
      "Mimaropa",
      "Misamis Occidental",
      "Misamis Oriental",
      "Mountain Province",
      "Negros Occidental",
      "Negros Oriental",
      "Northern Mindanao",
      "Northern Samar",
      "Nueva Ecija",
      "Nueva Vizcaya",
      "Occidental Mindoro",
      "Oriental Mindoro",
      "Palawan",
      "Pampanga",
      "Pangasinan",
      "Quezon",
      "Quirino",
      "Rizal",
      "Romblon",
      "Sarangani",
      "Siquijor",
      "Soccsksargen",
      "Sorsogon",
      "South Cotabato",
      "Southern Leyte",
      "Sultan Kudarat",
      "Sulu",
      "Surigao del Norte",
      "Surigao del Sur",
      "Tarlac",
      "Tawi-Tawi",
      "Western Visayas",
      "Zambales",
      "Zamboanga del Norte",
      "Zamboanga del Sur",
      "Zamboanga Peninsula",
      "Zamboanga Sibugay",
    ],
  },
  PK: {
    country: "Pakistan",
    phone_code: "92",
    states: [
      "Azad Kashmir",
      "Balochistan",
      "Federally Administered Tribal Areas",
      "Gilgit-Baltistan",
      "Islamabad Capital Territory",
      "Khyber Pakhtunkhwa",
      "Punjab",
      "Sindh",
    ],
  },
  PL: {
    country: "Poland",
    phone_code: "48",
    states: [
      "Greater Poland Voivodeship",
      "Kuyavian-Pomeranian Voivodeship",
      "Lesser Poland Voivodeship",
      "Lower Silesian Voivodeship",
      "Lublin Voivodeship",
      "Lubusz Voivodeship",
      "\u0141\u00f3d\u017a Voivodeship",
      "Masovian Voivodeship",
      "Opole Voivodeship",
      "Podkarpackie Voivodeship",
      "Podlaskie Voivodeship",
      "Pomeranian Voivodeship",
      "Silesian Voivodeship",
      "\u015awi\u0119tokrzyskie Voivodeship",
      "Warmian-Masurian Voivodeship",
      "West Pomeranian Voivodeship",
    ],
  },
  PM: {
    country: "Saint Pierre and Miquelon",
    phone_code: "508",
    states: [],
  },
  PN: {
    country: "Pitcairn Island",
    phone_code: "870",
    states: [],
  },
  PR: {
    country: "Puerto Rico",
    phone_code: ["1-787", "1-939"],
    states: [
      "Arecibo",
      "Bayamon",
      "Caguas",
      "Carolina",
      "Guaynabo",
      "Mayag\u00fcez",
      "Ponce",
      "San Juan",
      "Toa Baja",
      "Trujillo Alto",
    ],
  },
  PS: {
    country: "Palestinian Territory Occupied",
    phone_code: "970",
    states: [],
  },
  PT: {
    country: "Portugal",
    phone_code: "351",
    states: [
      "A\u00e7ores",
      "Aveiro",
      "Beja",
      "Braga",
      "Bragan\u00e7a",
      "Castelo Branco",
      "Coimbra",
      "\u00c9vora",
      "Faro",
      "Guarda",
      "Leiria",
      "Lisbon",
      "Madeira",
      "Portalegre",
      "Porto",
      "Santar\u00e9m",
      "Set\u00fabal",
      "Viana do Castelo",
      "Vila Real",
      "Viseu",
    ],
  },
  PW: {
    country: "Palau",
    phone_code: "680",
    states: [
      "Aimeliik",
      "Airai",
      "Angaur",
      "Hatohobei",
      "Kayangel",
      "Koror",
      "Melekeok",
      "Ngaraard",
      "Ngarchelong",
      "Ngardmau",
      "Ngatpang",
      "Ngchesar",
      "Ngeremlengui",
      "Ngiwal",
      "Peleliu",
      "Sonsorol",
    ],
  },
  PY: {
    country: "Paraguay",
    phone_code: "595",
    states: [
      "Alto Paraguay Department",
      "Alto Paran\u00e1 Department",
      "Amambay Department",
      "Boquer\u00f3n Department",
      "Caaguaz\u00fa",
      "Caazap\u00e1",
      "Canindey\u00fa",
      "Central Department",
      "Concepci\u00f3n Department",
      "Cordillera Department",
      "Guair\u00e1 Department",
      "Itap\u00faa",
      "Misiones Department",
      "\u00d1eembuc\u00fa Department",
      "Paraguar\u00ed Department",
      "Presidente Hayes Department",
      "San Pedro Department",
    ],
  },
  QA: {
    country: "Qatar",
    phone_code: "974",
    states: [
      "Al Daayen",
      "Al Khor",
      "Al Rayyan Municipality",
      "Al Wakrah",
      "Al-Shahaniya",
      "Doha",
      "Madinat ash Shamal",
      "Umm Salal Municipality",
    ],
  },
  RE: {
    country: "Reunion",
    phone_code: "262",
    states: [],
  },
  RO: {
    country: "Romania",
    phone_code: "40",
    states: [
      "Alba",
      "Arad County",
      "Arges",
      "Bac\u0103u County",
      "Bihor County",
      "Bistri\u021ba-N\u0103s\u0103ud County",
      "Boto\u0219ani County",
      "Braila",
      "Bra\u0219ov County",
      "Bucharest",
      "Buz\u0103u County",
      "C\u0103l\u0103ra\u0219i County",
      "Cara\u0219-Severin County",
      "Cluj County",
      "Constan\u021ba County",
      "Covasna County",
      "D\u00e2mbovi\u021ba County",
      "Dolj County",
      "Gala\u021bi County",
      "Giurgiu County",
      "Gorj County",
      "Harghita County",
      "Hunedoara County",
      "Ialomi\u021ba County",
      "Ia\u0219i County",
      "Ilfov County",
      "Maramure\u0219 County",
      "Mehedin\u021bi County",
      "Mure\u0219 County",
      "Neam\u021b County",
      "Olt County",
      "Prahova County",
      "S\u0103laj County",
      "Satu Mare County",
      "Sibiu County",
      "Suceava County",
      "Teleorman County",
      "Timi\u0219 County",
      "Tulcea County",
      "V\u00e2lcea County",
      "Vaslui County",
      "Vrancea County",
    ],
  },
  RS: {
    country: "Serbia",
    phone_code: "381",
    states: [
      "Belgrade",
      "Bor District",
      "Brani\u010devo District",
      "Central Banat District",
      "Jablanica District",
      "Kolubara District",
      "Ma\u010dva District",
      "Moravica District",
      "Ni\u0161ava District",
      "North Ba\u010dka District",
      "North Banat District",
      "P\u010dinja District",
      "Pirot District",
      "Podunavlje District",
      "Pomoravlje District",
      "Rasina District",
      "Ra\u0161ka District",
      "South Ba\u010dka District",
      "South Banat District",
      "Srem District",
      "\u0160umadija District",
      "Toplica District",
      "Vojvodina",
      "West Ba\u010dka District",
      "Zaje\u010dar District",
      "Zlatibor District",
    ],
  },
  RU: {
    country: "Russia",
    phone_code: "7",
    states: [
      "Altai Krai",
      "Altai Republic",
      "Amur Oblast",
      "Arkhangelsk",
      "Astrakhan Oblast",
      "Belgorod Oblast",
      "Bryansk Oblast",
      "Chechen Republic",
      "Chelyabinsk Oblast",
      "Chukotka Autonomous Okrug",
      "Chuvash Republic",
      "Irkutsk",
      "Ivanovo Oblast",
      "Jewish Autonomous Oblast",
      "Kabardino-Balkar Republic",
      "Kaliningrad",
      "Kaluga Oblast",
      "Kamchatka Krai",
      "Karachay-Cherkess Republic",
      "Kemerovo Oblast",
      "Khabarovsk Krai",
      "Khanty-Mansi Autonomous Okrug",
      "Kirov Oblast",
      "Komi Republic",
      "Kostroma Oblast",
      "Krasnodar Krai",
      "Krasnoyarsk Krai",
      "Kurgan Oblast",
      "Kursk Oblast",
      "Leningrad Oblast",
      "Lipetsk Oblast",
      "Magadan Oblast",
      "Mari El Republic",
      "Moscow",
      "Moscow Oblast",
      "Murmansk Oblast",
      "Nenets Autonomous Okrug",
      "Nizhny Novgorod Oblast",
      "Novgorod Oblast",
      "Novosibirsk",
      "Omsk Oblast",
      "Orenburg Oblast",
      "Oryol Oblast",
      "Penza Oblast",
      "Perm Krai",
      "Primorsky Krai",
      "Pskov Oblast",
      "Republic of Adygea",
      "Republic of Bashkortostan",
      "Republic of Buryatia",
      "Republic of Dagestan",
      "Republic of Ingushetia",
      "Republic of Kalmykia",
      "Republic of Karelia",
      "Republic of Khakassia",
      "Republic of Mordovia",
      "Republic of North Ossetia-Alania",
      "Republic of Tatarstan",
      "Rostov Oblast",
      "Ryazan Oblast",
      "Saint Petersburg",
      "Sakha Republic",
      "Sakhalin",
      "Samara Oblast",
      "Saratov Oblast",
      "Sevastopol",
      "Smolensk Oblast",
      "Stavropol Krai",
      "Sverdlovsk",
      "Tambov Oblast",
      "Tomsk Oblast",
      "Tula Oblast",
      "Tuva Republic",
      "Tver Oblast",
      "Tyumen Oblast",
      "Udmurt Republic",
      "Ulyanovsk Oblast",
      "Vladimir Oblast",
      "Volgograd Oblast",
      "Vologda Oblast",
      "Voronezh Oblast",
      "Yamalo-Nenets Autonomous Okrug",
      "Yaroslavl Oblast",
      "Zabaykalsky Krai",
    ],
  },
  RW: {
    country: "Rwanda",
    phone_code: "250",
    states: [
      "Eastern Province",
      "Kigali district",
      "Northern Province",
      "Southern Province",
      "Western Province",
    ],
  },
  SA: {
    country: "Saudi Arabia",
    phone_code: "966",
    states: [
      "'Asir",
      "Al Bahah",
      "Al Jawf",
      "Al Madinah",
      "Al-Qassim",
      "Eastern Province",
      "Ha'il",
      "Jizan",
      "Makkah",
      "Najran",
      "Northern Borders",
      "Riyadh",
      "Tabuk",
    ],
  },
  SB: {
    country: "Solomon Islands",
    phone_code: "677",
    states: [
      "Central Province",
      "Choiseul Province",
      "Guadalcanal Province",
      "Honiara",
      "Isabel Province",
      "Makira-Ulawa Province",
      "Malaita Province",
      "Rennell and Bellona Province",
      "Temotu Province",
      "Western Province",
    ],
  },
  SC: {
    country: "Seychelles",
    phone_code: "248",
    states: [
      "Anse Boileau",
      "Anse Royale",
      "Anse-aux-Pins",
      "Au Cap",
      "Baie Lazare",
      "Baie Sainte Anne",
      "Beau Vallon",
      "Bel Air",
      "Bel Ombre",
      "Cascade",
      "Glacis",
      "Grand'Anse Mah\u00e9",
      "Grand'Anse Praslin",
      "La Digue",
      "La Rivi\u00e8re Anglaise",
      "Les Mamelles",
      "Mont Buxton",
      "Mont Fleuri",
      "Plaisance",
      "Pointe La Rue",
      "Port Glaud",
      "Roche Caiman",
      "Saint Louis",
      "Takamaka",
    ],
  },
  SD: {
    country: "Sudan",
    phone_code: "249",
    states: [
      "Al Jazirah",
      "Al Qadarif",
      "Blue Nile",
      "Central Darfur",
      "East Darfur",
      "Kassala",
      "Khartoum",
      "North Darfur",
      "North Kordofan",
      "Northern",
      "Red Sea",
      "River Nile",
      "Sennar",
      "South Darfur",
      "South Kordofan",
      "West Darfur",
      "West Kordofan",
      "White Nile",
    ],
  },
  SE: {
    country: "Sweden",
    phone_code: "46",
    states: [
      "Blekinge",
      "Dalarna County",
      "G\u00e4vleborg County",
      "Gotland County",
      "Halland County",
      "J\u00f6nk\u00f6ping County",
      "Kalmar County",
      "Kronoberg County",
      "Norrbotten County",
      "\u00d6rebro County",
      "\u00d6sterg\u00f6tland County",
      "Sk\u00e5ne County",
      "S\u00f6dermanland County",
      "Stockholm County",
      "Uppsala County",
      "V\u00e4rmland County",
      "V\u00e4sterbotten County",
      "V\u00e4sternorrland County",
      "V\u00e4stmanland County",
      "V\u00e4stra G\u00f6taland County",
    ],
  },
  SG: {
    country: "Singapore",
    phone_code: "65",
    states: [
      "Central Singapore Community Development Council",
      "North East Community Development Council",
      "North West Community Development Council",
      "South East Community Development Council",
      "South West Community Development Council",
    ],
  },
  SH: {
    country: "Saint Helena",
    phone_code: "290",
    states: [],
  },
  SI: {
    country: "Slovenia",
    phone_code: "386",
    states: [
      "Ajdov\u0161\u010dina Municipality",
      "Ankaran Municipality",
      "Beltinci Municipality",
      "Benedikt Municipality",
      "Bistrica ob Sotli Municipality",
      "Bled Municipality",
      "Bloke Municipality",
      "Bohinj Municipality",
      "Borovnica Municipality",
      "Bovec Municipality",
      "Braslov\u010de Municipality",
      "Brda Municipality",
      "Bre\u017eice Municipality",
      "Brezovica Municipality",
      "Cankova Municipality",
      "Cerklje na Gorenjskem Municipality",
      "Cerknica Municipality",
      "Cerkno Municipality",
      "Cerkvenjak Municipality",
      "City Municipality of Celje",
      "City Municipality of Novo Mesto",
      "\u010cren\u0161ovci Municipality",
      "\u010crna na Koro\u0161kem Municipality",
      "\u010crnomelj Municipality",
      "Destrnik Municipality",
      "Diva\u010da Municipality",
      "Dobje Municipality",
      "Dobrepolje Municipality",
      "Dobrna Municipality",
      "Dobrova\u2013Polhov Gradec Municipality",
      "Dobrovnik Municipality",
      "Dol pri Ljubljani Municipality",
      "Dolenjske Toplice Municipality",
      "Dom\u017eale Municipality",
      "Dornava Municipality",
      "Dravograd Municipality",
      "Duplek Municipality",
      "Gorenja Vas\u2013Poljane Municipality",
      "Gori\u0161nica Municipality",
      "Gorje Municipality",
      "Gornja Radgona Municipality",
      "Gornji Grad Municipality",
      "Gornji Petrovci Municipality",
      "Grad Municipality",
      "Grosuplje Municipality",
      "Hajdina Municipality",
      "Ho\u010de\u2013Slivnica Municipality",
      "Hodo\u0161 Municipality",
      "Horjul Municipality",
      "Hrastnik Municipality",
      "Hrpelje\u2013Kozina Municipality",
      "Idrija Municipality",
      "Ig Municipality",
      "Ivan\u010dna Gorica Municipality",
      "Izola Municipality",
      "Jesenice Municipality",
      "Jezersko Municipality",
      "Jur\u0161inci Municipality",
      "Kamnik Municipality",
      "Kanal ob So\u010di Municipality",
      "Kidri\u010devo Municipality",
      "Kobarid Municipality",
      "Kobilje Municipality",
      "Ko\u010devje Municipality",
      "Komen Municipality",
      "Komenda Municipality",
      "Koper City Municipality",
      "Kostanjevica na Krki Municipality",
      "Kostel Municipality",
      "Kozje Municipality",
      "Kranj City Municipality",
      "Kranjska Gora Municipality",
      "Kri\u017eevci Municipality",
      "Kungota",
      "Kuzma Municipality",
      "La\u0161ko Municipality",
      "Lenart Municipality",
      "Lendava Municipality",
      "Litija Municipality",
      "Ljubljana City Municipality",
      "Ljubno Municipality",
      "Ljutomer Municipality",
      "Log\u2013Dragomer Municipality",
      "Logatec Municipality",
      "Lo\u0161ka Dolina Municipality",
      "Lo\u0161ki Potok Municipality",
      "Lovrenc na Pohorju Municipality",
      "Lu\u010de Municipality",
      "Lukovica Municipality",
      "Maj\u0161perk Municipality",
      "Makole Municipality",
      "Maribor City Municipality",
      "Markovci Municipality",
      "Medvode Municipality",
      "Menge\u0161 Municipality",
      "Metlika Municipality",
      "Me\u017eica Municipality",
      "Miklav\u017e na Dravskem Polju Municipality",
      "Miren\u2013Kostanjevica Municipality",
      "Mirna Municipality",
      "Mirna Pe\u010d Municipality",
      "Mislinja Municipality",
      "Mokronog\u2013Trebelno Municipality",
      "Morav\u010de Municipality",
      "Moravske Toplice Municipality",
      "Mozirje Municipality",
      "Municipality of Apa\u010de",
      "Municipality of Cirkulane",
      "Municipality of Ilirska Bistrica",
      "Municipality of Kr\u0161ko",
      "Municipality of \u0160kofljica",
      "Murska Sobota City Municipality",
      "Muta Municipality",
      "Naklo Municipality",
      "Nazarje Municipality",
      "Nova Gorica City Municipality",
      "Odranci Municipality",
      "Oplotnica",
      "Ormo\u017e Municipality",
      "Osilnica Municipality",
      "Pesnica Municipality",
      "Piran Municipality",
      "Pivka Municipality",
      "Pod\u010detrtek Municipality",
      "Podlehnik Municipality",
      "Podvelka Municipality",
      "Polj\u010dane Municipality",
      "Polzela Municipality",
      "Postojna Municipality",
      "Prebold Municipality",
      "Preddvor Municipality",
      "Prevalje Municipality",
      "Ptuj City Municipality",
      "Puconci Municipality",
      "Ra\u010de\u2013Fram Municipality",
      "Rade\u010de Municipality",
      "Radenci Municipality",
      "Radlje ob Dravi Municipality",
      "Radovljica Municipality",
      "Ravne na Koro\u0161kem Municipality",
      "Razkri\u017eje Municipality",
      "Re\u010dica ob Savinji Municipality",
      "Ren\u010de\u2013Vogrsko Municipality",
      "Ribnica Municipality",
      "Ribnica na Pohorju Municipality",
      "Roga\u0161ka Slatina Municipality",
      "Roga\u0161ovci Municipality",
      "Rogatec Municipality",
      "Ru\u0161e Municipality",
      "\u0160alovci Municipality",
      "Selnica ob Dravi Municipality",
      "Semi\u010d Municipality",
      "\u0160empeter\u2013Vrtojba Municipality",
      "\u0160en\u010dur Municipality",
      "\u0160entilj Municipality",
      "\u0160entjernej Municipality",
      "\u0160entjur Municipality",
      "\u0160entrupert Municipality",
      "Sevnica Municipality",
      "Se\u017eana Municipality",
      "\u0160kocjan Municipality",
      "\u0160kofja Loka Municipality",
      "Slovenj Gradec City Municipality",
      "Slovenska Bistrica Municipality",
      "Slovenske Konjice Municipality",
      "\u0160marje pri Jel\u0161ah Municipality",
      "\u0160marje\u0161ke Toplice Municipality",
      "\u0160martno ob Paki Municipality",
      "\u0160martno pri Litiji Municipality",
      "Sodra\u017eica Municipality",
      "Sol\u010dava Municipality",
      "\u0160o\u0161tanj Municipality",
      "Sredi\u0161\u010de ob Dravi",
      "Star\u0161e Municipality",
      "\u0160tore Municipality",
      "Stra\u017ea Municipality",
      "Sveta Ana Municipality",
      "Sveta Trojica v Slovenskih Goricah Municipality",
      "Sveti Andra\u017e v Slovenskih Goricah Municipality",
      "Sveti Jurij ob \u0160\u010davnici Municipality",
      "Sveti Jurij v Slovenskih Goricah Municipality",
      "Sveti Toma\u017e Municipality",
      "Tabor Municipality",
      "Ti\u0161ina Municipality",
      "Tolmin Municipality",
      "Trbovlje Municipality",
      "Trebnje Municipality",
      "Trnovska Vas Municipality",
      "Tr\u017ei\u010d Municipality",
      "Trzin Municipality",
      "Turni\u0161\u010de Municipality",
      "Velika Polana Municipality",
      "Velike La\u0161\u010de Municipality",
      "Ver\u017eej Municipality",
      "Videm Municipality",
      "Vipava Municipality",
      "Vitanje Municipality",
      "Vodice Municipality",
      "Vojnik Municipality",
      "Vransko Municipality",
      "Vrhnika Municipality",
      "Vuzenica Municipality",
      "Zagorje ob Savi Municipality",
      "\u017dalec Municipality",
      "Zavr\u010d Municipality",
      "\u017delezniki Municipality",
      "\u017detale Municipality",
      "\u017diri Municipality",
      "\u017dirovnica Municipality",
      "Zre\u010de Municipality",
      "\u017du\u017eemberk Municipality",
    ],
  },
  SJ: {
    country: "Svalbard And Jan Mayen Islands",
    phone_code: "47",
    states: [],
  },
  SK: {
    country: "Slovakia",
    phone_code: "421",
    states: [
      "Bansk\u00e1 Bystrica Region",
      "Bratislava Region",
      "Ko\u0161ice Region",
      "Nitra Region",
      "Pre\u0161ov Region",
      "Tren\u010d\u00edn Region",
      "Trnava Region",
      "\u017dilina Region",
    ],
  },
  SL: {
    country: "Sierra Leone",
    phone_code: "232",
    states: [
      "Eastern Province",
      "Northern Province",
      "Southern Province",
      "Western Area",
    ],
  },
  SM: {
    country: "San Marino",
    phone_code: "378",
    states: [
      "Acquaviva",
      "Borgo Maggiore",
      "Chiesanuova",
      "Domagnano",
      "Faetano",
      "Fiorentino",
      "Montegiardino",
      "San Marino",
      "Serravalle",
    ],
  },
  SN: {
    country: "Senegal",
    phone_code: "221",
    states: [
      "Dakar",
      "Diourbel Region",
      "Fatick",
      "Kaffrine",
      "Kaolack",
      "K\u00e9dougou",
      "Kolda",
      "Louga",
      "Matam",
      "Saint-Louis",
      "S\u00e9dhiou",
      "Tambacounda Region",
      "Thi\u00e8s Region",
      "Ziguinchor",
    ],
  },
  SO: {
    country: "Somalia",
    phone_code: "252",
    states: [
      "Awdal Region",
      "Bakool",
      "Banaadir",
      "Bari",
      "Bay",
      "Galguduud",
      "Gedo",
      "Hiran",
      "Lower Juba",
      "Lower Shebelle",
      "Middle Juba",
      "Middle Shebelle",
      "Mudug",
      "Nugal",
      "Sanaag Region",
      "Togdheer Region",
    ],
  },
  SR: {
    country: "Suriname",
    phone_code: "597",
    states: [
      "Brokopondo District",
      "Commewijne District",
      "Coronie District",
      "Marowijne District",
      "Nickerie District",
      "Para District",
      "Paramaribo District",
      "Saramacca District",
      "Sipaliwini District",
      "Wanica District",
    ],
  },
  SS: {
    country: "South Sudan",
    phone_code: "211",
    states: [
      "Central Equatoria",
      "Eastern Equatoria",
      "Jonglei State",
      "Lakes",
      "Northern Bahr el Ghazal",
      "Unity",
      "Upper Nile",
      "Warrap",
      "Western Bahr el Ghazal",
      "Western Equatoria",
    ],
  },
  ST: {
    country: "Sao Tome and Principe",
    phone_code: "239",
    states: ["Pr\u00edncipe Province", "S\u00e3o Tom\u00e9 Province"],
  },
  SV: {
    country: "El Salvador",
    phone_code: "503",
    states: [
      "Ahuachap\u00e1n Department",
      "Caba\u00f1as Department",
      "Chalatenango Department",
      "Cuscatl\u00e1n Department",
      "La Libertad Department",
      "La Paz Department",
      "La Uni\u00f3n Department",
      "Moraz\u00e1n Department",
      "San Miguel Department",
      "San Salvador Department",
      "San Vicente Department",
      "Santa Ana Department",
      "Sonsonate Department",
      "Usulut\u00e1n Department",
    ],
  },
  SX: {
    country: "Sint Maarten (Dutch part)",
    phone_code: "1721",
    states: [],
  },
  SY: {
    country: "Syria",
    phone_code: "963",
    states: [
      "Al-Hasakah Governorate",
      "Al-Raqqah Governorate",
      "Aleppo Governorate",
      "As-Suwayda Governorate",
      "Damascus Governorate",
      "Daraa Governorate",
      "Deir ez-Zor Governorate",
      "Hama Governorate",
      "Homs Governorate",
      "Idlib Governorate",
      "Latakia Governorate",
      "Quneitra Governorate",
      "Rif Dimashq Governorate",
      "Tartus Governorate",
    ],
  },
  SZ: {
    country: "Swaziland",
    phone_code: "268",
    states: [
      "Hhohho District",
      "Lubombo District",
      "Manzini District",
      "Shiselweni District",
    ],
  },
  TC: {
    country: "Turks And Caicos Islands",
    phone_code: "1-649",
    states: [],
  },
  TD: {
    country: "Chad",
    phone_code: "235",
    states: [
      "Bahr el Gazel",
      "Batha Region",
      "Borkou",
      "Ennedi Region",
      "Ennedi-Est",
      "Ennedi-Ouest",
      "Gu\u00e9ra Region",
      "Hadjer-Lamis",
      "Kanem Region",
      "Lac Region",
      "Logone Occidental Region",
      "Logone Oriental Region",
      "Mandoul Region",
      "Mayo-Kebbi Est Region",
      "Mayo-Kebbi Ouest Region",
      "Moyen-Chari Region",
      "N'Djamena",
      "Ouadda\u00ef Region",
      "Salamat Region",
      "Sila Region",
      "Tandjil\u00e9 Region",
      "Tibesti Region",
      "Wadi Fira Region",
    ],
  },
  TF: {
    country: "French Southern Territories",
    phone_code: "262",
    states: [],
  },
  TG: {
    country: "Togo",
    phone_code: "228",
    states: [
      "Centrale Region",
      "Kara Region",
      "Maritime",
      "Plateaux Region",
      "Savanes Region",
    ],
  },
  TH: {
    country: "Thailand",
    phone_code: "66",
    states: [
      "Amnat Charoen",
      "Ang Thong",
      "Bangkok",
      "Bueng Kan",
      "Buri Ram",
      "Chachoengsao",
      "Chai Nat",
      "Chaiyaphum",
      "Chanthaburi",
      "Chiang Mai",
      "Chiang Rai",
      "Chon Buri",
      "Chumphon",
      "Kalasin",
      "Kamphaeng Phet",
      "Kanchanaburi",
      "Khon Kaen",
      "Krabi",
      "Lampang",
      "Lamphun",
      "Loei",
      "Lop Buri",
      "Mae Hong Son",
      "Maha Sarakham",
      "Mukdahan",
      "Nakhon Nayok",
      "Nakhon Pathom",
      "Nakhon Phanom",
      "Nakhon Ratchasima",
      "Nakhon Sawan",
      "Nakhon Si Thammarat",
      "Nan",
      "Narathiwat",
      "Nong Bua Lam Phu",
      "Nong Khai",
      "Nonthaburi",
      "Pathum Thani",
      "Pattani",
      "Pattaya",
      "Phangnga",
      "Phatthalung",
      "Phayao",
      "Phetchabun",
      "Phetchaburi",
      "Phichit",
      "Phitsanulok",
      "Phra Nakhon Si Ayutthaya",
      "Phrae",
      "Phuket",
      "Prachin Buri",
      "Prachuap Khiri Khan",
      "Ranong",
      "Ratchaburi",
      "Rayong",
      "Roi Et",
      "Sa Kaeo",
      "Sakon Nakhon",
      "Samut Prakan",
      "Samut Sakhon",
      "Samut Songkhram",
      "Saraburi",
      "Satun",
      "Si Sa Ket",
      "Sing Buri",
      "Songkhla",
      "Sukhothai",
      "Suphan Buri",
      "Surat Thani",
      "Surin",
      "Tak",
      "Trang",
      "Trat",
      "Ubon Ratchathani",
      "Udon Thani",
      "Uthai Thani",
      "Uttaradit",
      "Yala",
      "Yasothon",
    ],
  },
  TJ: {
    country: "Tajikistan",
    phone_code: "992",
    states: [
      "districts of Republican Subordination",
      "Gorno-Badakhshan Autonomous Province",
      "Khatlon Province",
      "Sughd Province",
    ],
  },
  TK: {
    country: "Tokelau",
    phone_code: "690",
    states: [],
  },
  TL: {
    country: "East Timor",
    phone_code: "670",
    states: [
      "Aileu municipality",
      "Ainaro Municipality",
      "Baucau Municipality",
      "Bobonaro Municipality",
      "Cova Lima Municipality",
      "Dili municipality",
      "Ermera District",
      "Laut\u00e9m Municipality",
      "Liqui\u00e7\u00e1 Municipality",
      "Manatuto District",
      "Manufahi Municipality",
      "Viqueque Municipality",
    ],
  },
  TM: {
    country: "Turkmenistan",
    phone_code: "993",
    states: [
      "Ahal Region",
      "Ashgabat",
      "Balkan Region",
      "Da\u015foguz Region",
      "Lebap Region",
      "Mary Region",
    ],
  },
  TN: {
    country: "Tunisia",
    phone_code: "216",
    states: [
      "Ariana Governorate",
      "Ben Arous Governorate",
      "Bizerte Governorate",
      "Gab\u00e8s Governorate",
      "Gafsa Governorate",
      "Jendouba Governorate",
      "Kairouan Governorate",
      "Kasserine Governorate",
      "Kassrine",
      "Kebili Governorate",
      "Kef Governorate",
      "Mahdia Governorate",
      "Manouba Governorate",
      "Medenine Governorate",
      "Monastir Governorate",
      "Sfax Governorate",
      "Sidi Bouzid Governorate",
      "Siliana Governorate",
      "Sousse Governorate",
      "Tataouine Governorate",
      "Tozeur Governorate",
      "Tunis Governorate",
      "Zaghouan Governorate",
    ],
  },
  TO: {
    country: "Tonga",
    phone_code: "676",
    states: ["Ha\u02bbapai", "\u02bbEua", "Niuas", "Tongatapu", "Vava\u02bbu"],
  },
  TR: {
    country: "Turkey",
    phone_code: "90",
    states: [
      "Adana",
      "Ad\u0131yaman",
      "Afyonkarahisar",
      "A\u011fr\u0131",
      "Aksaray",
      "Amasya",
      "Ankara",
      "Antalya",
      "Ardahan",
      "Artvin",
      "Ayd\u0131n",
      "Bal\u0131kesir",
      "Bart\u0131n",
      "Batman",
      "Bayburt",
      "Bilecik",
      "Bing\u00f6l",
      "Bitlis",
      "Bolu",
      "Burdur",
      "Bursa",
      "\u00c7anakkale",
      "\u00c7ank\u0131r\u0131",
      "\u00c7orum",
      "Denizli",
      "Diyarbak\u0131r",
      "D\u00fczce",
      "Edirne",
      "Elaz\u0131\u011f",
      "Erzincan",
      "Erzurum",
      "Eski\u015fehir",
      "Gaziantep",
      "Giresun",
      "G\u00fcm\u00fc\u015fhane",
      "Hakk\u00e2ri",
      "Hatay",
      "I\u011fd\u0131r",
      "Isparta",
      "Istanbul",
      "\u0130zmir",
      "Kahramanmara\u015f",
      "Karab\u00fck",
      "Karaman",
      "Kars",
      "Kastamonu",
      "Kayseri",
      "Kilis",
      "K\u0131r\u0131kkale",
      "K\u0131rklareli",
      "K\u0131r\u015fehir",
      "Kocaeli",
      "Konya",
      "K\u00fctahya",
      "Malatya",
      "Manisa",
      "Mardin",
      "Mersin",
      "Mu\u011fla",
      "Mu\u015f",
      "Nev\u015fehir",
      "Ni\u011fde",
      "Ordu",
      "Osmaniye",
      "Rize",
      "Sakarya",
      "Samsun",
      "\u015eanl\u0131urfa",
      "Siirt",
      "Sinop",
      "Sivas",
      "\u015e\u0131rnak",
      "Tekirda\u011f",
      "Tokat",
      "Trabzon",
      "Tunceli",
      "U\u015fak",
      "Van",
      "Yalova",
      "Yozgat",
      "Zonguldak",
    ],
  },
  TT: {
    country: "Trinidad And Tobago",
    phone_code: "1-868",
    states: [
      "Arima",
      "Chaguanas",
      "Couva-Tabaquite-Talparo Regional Corporation",
      "Diego Martin Regional Corporation",
      "Eastern Tobago",
      "Penal-Debe Regional Corporation",
      "Point Fortin",
      "Port of Spain",
      "Princes Town Regional Corporation",
      "Rio Claro-Mayaro Regional Corporation",
      "San Fernando",
      "San Juan-Laventille Regional Corporation",
      "Sangre Grande Regional Corporation",
      "Siparia Regional Corporation",
      "Tunapuna-Piarco Regional Corporation",
      "Western Tobago",
    ],
  },
  TV: {
    country: "Tuvalu",
    phone_code: "688",
    states: [
      "Funafuti",
      "Nanumanga",
      "Nanumea",
      "Niutao Island Council",
      "Nui",
      "Nukufetau",
      "Nukulaelae",
      "Vaitupu",
    ],
  },
  TW: {
    country: "Taiwan",
    phone_code: "886",
    states: [
      "Changhua",
      "Chiayi",
      "Chiayi",
      "Hsinchu",
      "Hsinchu",
      "Hualien",
      "Kaohsiung",
      "Keelung",
      "Kinmen",
      "Lienchiang",
      "Miaoli",
      "Nantou",
      "New Taipei",
      "Penghu",
      "Pingtung",
      "Taichung",
      "Tainan",
      "Taipei",
      "Taitung",
      "Taoyuan",
      "Yilan",
      "Yunlin",
    ],
  },
  TZ: {
    country: "Tanzania",
    phone_code: "255",
    states: [
      "Arusha",
      "Dar es Salaam",
      "Dodoma",
      "Geita",
      "Iringa",
      "Kagera",
      "Katavi",
      "Kigoma",
      "Kilimanjaro",
      "Lindi",
      "Manyara",
      "Mara",
      "Mbeya",
      "Morogoro",
      "Mtwara",
      "Mwanza",
      "Njombe",
      "Pemba North",
      "Pemba South",
      "Pwani",
      "Rukwa",
      "Ruvuma",
      "Shinyanga",
      "Simiyu",
      "Singida",
      "Songwe",
      "Tabora",
      "Tanga",
      "Zanzibar North",
      "Zanzibar South",
      "Zanzibar West",
    ],
  },
  UA: {
    country: "Ukraine",
    phone_code: "380",
    states: [
      "Autonomous Republic of Crimea",
      "Cherkaska oblast",
      "Chernihivska oblast",
      "Chernivetska oblast",
      "Dnipropetrovska oblast",
      "Donetska oblast",
      "Ivano-Frankivska oblast",
      "Kharkivska oblast",
      "Khersonska oblast",
      "Khmelnytska oblast",
      "Kirovohradska oblast",
      "Kyiv",
      "Kyivska oblast",
      "Luhanska oblast",
      "Lvivska oblast",
      "Mykolaivska oblast",
      "Odeska oblast",
      "Poltavska oblast",
      "Rivnenska oblast",
      "Sumska oblast",
      "Ternopilska oblast",
      "Vinnytska oblast",
      "Volynska oblast",
      "Zakarpatska Oblast",
      "Zaporizka oblast",
      "Zhytomyrska oblast",
    ],
  },
  UG: {
    country: "Uganda",
    phone_code: "256",
    states: [
      "Abim District",
      "Adjumani District",
      "Agago District",
      "Alebtong District",
      "Amolatar District",
      "Amudat District",
      "Amuria District",
      "Amuru District",
      "Apac District",
      "Arua District",
      "Budaka District",
      "Bududa District",
      "Bugiri District",
      "Buhweju District",
      "Buikwe District",
      "Bukedea District",
      "Bukomansimbi District",
      "Bukwo District",
      "Bulambuli District",
      "Buliisa District",
      "Bundibugyo District",
      "Bunyangabu District",
      "Bushenyi District",
      "Busia District",
      "Butaleja District",
      "Butambala District",
      "Butebo District",
      "Buvuma District",
      "Buyende District",
      "Central Region",
      "Dokolo District",
      "Eastern Region",
      "Gomba District",
      "Gulu District",
      "Ibanda District",
      "Iganga District",
      "Isingiro District",
      "Jinja District",
      "Kaabong District",
      "Kabale District",
      "Kabarole District",
      "Kaberamaido District",
      "Kagadi District",
      "Kakumiro District",
      "Kalangala District",
      "Kaliro District",
      "Kalungu District",
      "Kampala District",
      "Kamuli District",
      "Kamwenge District",
      "Kanungu District",
      "Kapchorwa District",
      "Kasese District",
      "Katakwi District",
      "Kayunga District",
      "Kibaale District",
      "Kiboga District",
      "Kibuku District",
      "Kiruhura District",
      "Kiryandongo District",
      "Kisoro District",
      "Kitgum District",
      "Koboko District",
      "Kole District",
      "Kotido District",
      "Kumi District",
      "Kween District",
      "Kyankwanzi District",
      "Kyegegwa District",
      "Kyenjojo District",
      "Kyotera District",
      "Lamwo District",
      "Lira District",
      "Luuka District",
      "Luwero District",
      "Lwengo District",
      "Lyantonde District",
      "Manafwa District",
      "Maracha District",
      "Masaka District",
      "Masindi District",
      "Mayuge District",
      "Mbale District",
      "Mbarara District",
      "Mitooma District",
      "Mityana District",
      "Moroto District",
      "Moyo District",
      "Mpigi District",
      "Mubende District",
      "Mukono District",
      "Nakapiripirit District",
      "Nakaseke District",
      "Nakasongola District",
      "Namayingo District",
      "Namisindwa District",
      "Namutumba District",
      "Napak District",
      "Nebbi District",
      "Ngora District",
      "Northern Region",
      "Ntoroko District",
      "Ntungamo District",
      "Nwoya District",
      "Omoro District",
      "Otuke District",
      "Oyam District",
      "Pader District",
      "Pakwach District",
      "Pallisa District",
      "Rakai District",
      "Rubanda District",
      "Rubirizi District",
      "Rukiga District",
      "Rukungiri District",
      "Sembabule District",
      "Serere District",
      "Sheema District",
      "Sironko District",
      "Soroti District",
      "Tororo District",
      "Wakiso District",
      "Western Region",
      "Yumbe District",
      "Zombo District",
    ],
  },
  UM: {
    country: "United States Minor Outlying Islands",
    phone_code: "1",
    states: [],
  },
  US: {
    country: "United States",
    phone_code: "1",
    states: [
      "Alabama",
      "Alaska",
      "American Samoa",
      "Arizona",
      "Arkansas",
      "Baker Island",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Howland Island",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Jarvis Island",
      "Johnston Atoll",
      "Kansas",
      "Kentucky",
      "Kingman Reef",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Midway Atoll",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Navassa Island",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Northern Mariana Islands",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Palmyra Atoll",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "United States Minor Outlying Islands",
      "United States Virgin Islands",
      "Utah",
      "Vermont",
      "Virginia",
      "Wake Island",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
  },
  UY: {
    country: "Uruguay",
    phone_code: "598",
    states: [
      "Artigas Department",
      "Canelones Department",
      "Cerro Largo Department",
      "Colonia Department",
      "Durazno Department",
      "Flores Department",
      "Florida Department",
      "Lavalleja Department",
      "Maldonado Department",
      "Montevideo Department",
      "Paysand\u00fa Department",
      "R\u00edo Negro Department",
      "Rivera Department",
      "Rocha Department",
      "Salto Department",
      "San Jos\u00e9 Department",
      "Soriano Department",
      "Tacuaremb\u00f3 Department",
      "Treinta y Tres Department",
    ],
  },
  UZ: {
    country: "Uzbekistan",
    phone_code: "998",
    states: [
      "Andijan Region",
      "Bukhara Region",
      "Fergana Region",
      "Jizzakh Region",
      "Karakalpakstan",
      "Namangan Region",
      "Navoiy Region",
      "Qashqadaryo Region",
      "Samarqand Region",
      "Sirdaryo Region",
      "Surxondaryo Region",
      "Tashkent",
      "Tashkent Region",
      "Xorazm Region",
    ],
  },
  VA: {
    country: "Vatican City State (Holy See)",
    phone_code: "379",
    states: [],
  },
  VC: {
    country: "Saint Vincent And The Grenadines",
    phone_code: "1-784",
    states: [
      "Charlotte Parish",
      "Grenadines Parish",
      "Saint Andrew Parish",
      "Saint David Parish",
      "Saint George Parish",
      "Saint Patrick Parish",
    ],
  },
  VE: {
    country: "Venezuela",
    phone_code: "58",
    states: [
      "Amazonas",
      "Anzo\u00e1tegui",
      "Apure",
      "Aragua",
      "Barinas",
      "Bol\u00edvar",
      "Carabobo",
      "Cojedes",
      "Delta Amacuro",
      "Distrito Capital",
      "Falc\u00f3n",
      "Federal Dependencies of Venezuela",
      "Gu\u00e1rico",
      "La Guaira",
      "Lara",
      "M\u00e9rida",
      "Miranda",
      "Monagas",
      "Nueva Esparta",
      "Portuguesa",
      "Sucre",
      "T\u00e1chira",
      "Trujillo",
      "Yaracuy",
      "Zulia",
    ],
  },
  VG: {
    country: "Virgin Islands (British)",
    phone_code: "1-284",
    states: [],
  },
  VI: {
    country: "Virgin Islands (US)",
    phone_code: "1-340",
    states: ["Saint Croix", "Saint John", "Saint Thomas"],
  },
  VN: {
    country: "Vietnam",
    phone_code: "84",
    states: [
      "An Giang",
      "B\u00e0 R\u1ecba-V\u0169ng T\u00e0u",
      "B\u1eafc Giang",
      "B\u1eafc K\u1ea1n",
      "B\u1ea1c Li\u00eau",
      "B\u1eafc Ninh",
      "B\u1ebfn Tre",
      "B\u00ecnh D\u01b0\u01a1ng",
      "B\u00ecnh \u0110\u1ecbnh",
      "B\u00ecnh Ph\u01b0\u1edbc",
      "B\u00ecnh Thu\u1eadn",
      "C\u00e0 Mau",
      "C\u1ea7n Th\u01a1",
      "Cao B\u1eb1ng",
      "\u0110\u00e0 N\u1eb5ng",
      "\u0110\u1eafk L\u1eafk",
      "\u0110\u1eafk N\u00f4ng",
      "\u0110i\u1ec7n Bi\u00ean",
      "\u0110\u1ed3ng Nai",
      "\u0110\u1ed3ng Th\u00e1p",
      "Gia Lai",
      "H\u00e0 Giang",
      "H\u00e0 Nam",
      "H\u00e0 N\u1ed9i",
      "H\u00e0 T\u0129nh",
      "H\u1ea3i D\u01b0\u01a1ng",
      "H\u1ea3i Ph\u00f2ng",
      "H\u1eadu Giang",
      "H\u1ed3 Ch\u00ed Minh",
      "H\u00f2a B\u00ecnh",
      "H\u01b0ng Y\u00ean",
      "Kh\u00e1nh H\u00f2a",
      "Ki\u00ean Giang",
      "Kon Tum",
      "Lai Ch\u00e2u",
      "L\u00e2m \u0110\u1ed3ng",
      "L\u1ea1ng S\u01a1n",
      "L\u00e0o Cai",
      "Long An",
      "Nam \u0110\u1ecbnh",
      "Ngh\u1ec7 An",
      "Ninh B\u00ecnh",
      "Ninh Thu\u1eadn",
      "Ph\u00fa Th\u1ecd",
      "Ph\u00fa Y\u00ean",
      "Qu\u1ea3ng B\u00ecnh",
      "Qu\u1ea3ng Nam",
      "Qu\u1ea3ng Ng\u00e3i",
      "Qu\u1ea3ng Ninh",
      "Qu\u1ea3ng Tr\u1ecb",
      "S\u00f3c Tr\u0103ng",
      "S\u01a1n La",
      "T\u00e2y Ninh",
      "Th\u00e1i B\u00ecnh",
      "Th\u00e1i Nguy\u00ean",
      "Thanh H\u00f3a",
      "Th\u1eeba Thi\u00ean-Hu\u1ebf",
      "Ti\u1ec1n Giang",
      "Tr\u00e0 Vinh",
      "Tuy\u00ean Quang",
      "V\u0129nh Long",
      "V\u0129nh Ph\u00fac",
      "Y\u00ean B\u00e1i",
    ],
  },
  VU: {
    country: "Vanuatu",
    phone_code: "678",
    states: ["Malampa", "Penama", "Sanma", "Shefa", "Tafea", "Torba"],
  },
  WF: {
    country: "Wallis And Futuna Islands",
    phone_code: "681",
    states: [],
  },
  WS: {
    country: "Samoa",
    phone_code: "685",
    states: [
      "A'ana",
      "Aiga-i-le-Tai",
      "Atua",
      "Fa'asaleleaga",
      "Gaga'emauga",
      "Gaga'ifomauga",
      "Palauli",
      "Satupa'itea",
      "Tuamasaga",
      "Va'a-o-Fonoti",
      "Vaisigano",
    ],
  },
  XK: {
    country: "Kosovo",
    phone_code: "383",
    states: [
      "\u0110akovica District (Gjakove)",
      "Gjilan District",
      "Kosovska Mitrovica District",
      "Pe\u0107 District",
      "Pristina (Pri\u015ftine)",
      "Prizren District",
      "Uro\u0161evac District (Ferizaj)",
    ],
  },
  YE: {
    country: "Yemen",
    phone_code: "967",
    states: [
      "'Adan Governorate",
      "'Amran Governorate",
      "Abyan Governorate",
      "Al Bayda' Governorate",
      "Al Hudaydah Governorate",
      "Al Jawf Governorate",
      "Al Mahrah Governorate",
      "Al Mahwit Governorate",
      "Dhamar Governorate",
      "Hadhramaut Governorate",
      "Hajjah Governorate",
      "Ibb Governorate",
      "Lahij Governorate",
      "Ma'rib Governorate",
      "Raymah Governorate",
      "Saada Governorate",
      "Sana'a",
      "Sana'a Governorate",
      "Shabwah Governorate",
      "Socotra Governorate",
      "Ta'izz Governorate",
    ],
  },
  YT: {
    country: "Mayotte",
    phone_code: "262",
    states: [],
  },
  ZA: {
    country: "South Africa",
    phone_code: "27",
    states: [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "North West",
      "Northern Cape",
      "Western Cape",
    ],
  },
  ZM: {
    country: "Zambia",
    phone_code: "260",
    states: [
      "Central Province",
      "Copperbelt Province",
      "Eastern Province",
      "Luapula Province",
      "Lusaka Province",
      "Muchinga Province",
      "Northern Province",
      "Northwestern Province",
      "Southern Province",
      "Western Province",
    ],
  },
  ZW: {
    country: "Zimbabwe",
    phone_code: "263",
    states: [
      "Bulawayo Province",
      "Harare Province",
      "Manicaland",
      "Mashonaland Central Province",
      "Mashonaland East Province",
      "Mashonaland West Province",
      "Masvingo Province",
      "Matabeleland North Province",
      "Matabeleland South Province",
      "Midlands Province",
    ],
  },
}
