import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  styled,
} from "@mui/material"
import { ReactComponent as CancelIcon } from "lib/components/form/icons/close.svg"

export type CustomDialogProps = DialogProps & {
  disabled?: boolean
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "24px",
    backgroundColor: theme.palette.custom.light700,
  },
}))

export const CustomDialog = (props: CustomDialogProps) => (
  <StyledDialog maxWidth={false} {...props}>
    {props.children}
    <Button
      sx={{
        minWidth: 40,
        width: 40,
        height: 40,
        position: "absolute",
        right: 32,
        top: 20,
      }}
      onClick={(e) => props.onClose && props.onClose(e, "backdropClick")}
      disabled={props.disabled}
    >
      <CancelIcon width={20} height={20} style={{ margin: "auto" }} />
    </Button>
  </StyledDialog>
)

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.custom.light700,
  padding: "20px 24px 20px",
  position: "relative",
  borderRadius: 24,
}))

export const CustomDialogTitle = ({ children, ...props }: DialogTitleProps) => (
  <StyledDialogTitle variant="h2" color="custom.dark800" {...props}>
    {children}
  </StyledDialogTitle>
)

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  ".MuiDialogTitle-root+&": {
    padding: 24,
  },
  borderRadius: "24px",
  backgroundColor: theme.palette.custom.light500,
  "&.MuiDialogContent-root": {
    padding: "24px",
  },
}))

export const CustomDialogContent = StyledDialogContent

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: "24px",
}))

export const CustomDialogActions = StyledDialogActions
