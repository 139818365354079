import { yupResolver } from "@hookform/resolvers/yup"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { CustomButton } from "lib/components/CustomButton"
import { ControlledMultiSelectField } from "lib/components/form/ControlledMultiSelectField"
import { ControlledSelectField } from "lib/components/form/ControlledSelectField"
import { ControlledTextField } from "lib/components/form/ControlledTextField"
import { MSG_FIELD_REQUIRED } from "lib/messages"
import { useRedirectAuthorizedUser } from "lib/useRedirectAuthorizedUser"
import { useServerFormValidation } from "lib/useServerFormValidation"
import { useTheme } from "lib/useTheme"
import * as React from "react"
import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { usePartialUpdateProfile_DEPRECATED_Mutation } from "services/oldUserApi"
import * as yup from "yup"
import { SignUpData, SignUpWizardProps } from "./BuyerProfile"
import { useSelectItems } from "./lib"

const COMPANY_TYPE_CHOICES = [
  "Agency / Third Party Planner",
  "Association",
  "Association Management Company",
  "Corporate",
  "Independent Planner",
  "Medical Communication Agency",
  "PCO",
  "Public Sector",
  "Venue Finder",
]

const TYPE_OF_EVENTS_CHOICES = [
  "Incentives",
  "Congress/Convention",
  "Conferences",
  "High-end events",
  "Meetings",
  "Sustainable Events",
  "Experiential Events",
  "Exhibitions",
  "Other",
]

const EVENTS_ORGANISED_PER_YEAR_CHOICES = [
  "0-5",
  "6-10",
  "11-20",
  "21-50",
  "51-100",
  "100+",
]

// const AVERAGE_DAILY_RATE_CHOICES = [
//   "under $150",
//   "$150-250",
//   "$250-500",
//   "$500+",
//   "under €100",
//   "€100-200",
//   "€200-300",
//   "€300+",
// ]

const INDUSTRIES_WORKED_WITH_CHOICES = [
  "Aerospace/Naval/Defence",
  "Agriculture",
  "Automotive",
  "Biotechnology",
  "Chemicals",
  "Construction",
  "Consumer Goods",
  "Cosmetics",
  "Education",
  "Entertainment/Music",
  "Environmental Services",
  "Fashion/Retail",
  "Financial Services",
  "Food & Beverages",
  "Government",
  "Hospitality / Travel",
  "Human Resources/Staffing/Recruiting",
  "Industrial/Engineering",
  "Insurance",
  "IT & Technology",
  "Legal",
  "Management Consulting",
  "Manufacture",
  "Media",
  "Medical",
  "Mining & Metals",
  "Nonprofit Organization Management",
  "Pharmaceuticals",
  "Public Relations and Communications",
  "Publishing",
  "Real Estate",
  "Sport",
  "Telecommunications",
  "Think Tanks",
  "Tobacco",
  "Transport & Logistics",
  "Veterinary",
  "Water/Oil & Gas/Energy",
  "Wellness & Fitness",
]

function convertWizardDataToRequestData(wizardData: SignUpData) {
  const { phone_code, phone } = wizardData

  const data = {
    buyer_profile: {
      ...wizardData,
      phone_number: `+${phone_code}${phone}`,
    },
  }
  return data
}

type StepFormValues = {
  website: string
  company_type: string
  type_of_events_organised: string[]
  type_of_events_organised_other: string
  events_organised_per_year: string
  //   average_daily_rate: string
  industries_worked_with: string[]
}

const schema = yup
  .object({
    website: yup.string(),
    company_type: yup.string().required(MSG_FIELD_REQUIRED),
    type_of_events_organised: yup
      .array(yup.string())
      .min(1, MSG_FIELD_REQUIRED),
    type_of_events_organised_other: yup
      .string()
      .when(
        "type_of_events_organised",
        (type_of_events_organised: string[], schema) => {
          console.log("validation", type_of_events_organised)
          return type_of_events_organised.findIndex((v) => v === "Other") === -1
            ? schema
            : schema.required("Please provide type of events organized")
        }
      ),
    events_organised_per_year: yup.string().required(MSG_FIELD_REQUIRED),
    // average_daily_rate: yup.string().required(MSG_FIELD_REQUIRED),
    industries_worked_with: yup.array(yup.string()).min(1, MSG_FIELD_REQUIRED),
  })
  .required()

export function BuyerProfileCompanyInfo(props: SignUpWizardProps) {
  useRedirectAuthorizedUser()
  const theme = useTheme()

  const { bulkUpdate, wizardData, user, setStep, setValidationError } = props
  const [partialUpdate, { isLoading, error, isSuccess }] =
    usePartialUpdateProfile_DEPRECATED_Mutation()

  const validateError =
    error && "status" in error && error.status == 400 && "data" in error
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error?.data as Record<string, unknown>)
      : undefined

  useEffect(() => {
    if (validateError) {
      setValidationError(validateError)
    }
  }, [validateError, setValidationError])

  const navigate = useNavigate()
  useEffect(() => {
    if (isSuccess) {
      navigate("/profile/buyer/")
    }
  }, [isSuccess, navigate, setStep])

  // FIXME: this is a quick hack to make submitting work.
  // Posting data should go from something like reducer (async thunks)
  // Also, it's better to be controlled by the parent component.
  const [needSubmit, setNeedSubmit] = useState(false)
  const onSubmit = (data: StepFormValues) => {
    bulkUpdate(data)
    setNeedSubmit(true)
  }
  useEffect(() => {
    if (needSubmit) {
      setNeedSubmit(false)
      const data = convertWizardDataToRequestData(wizardData)
      partialUpdate(data)
    }
  }, [needSubmit, partialUpdate, wizardData])

  const formMethods = useForm<StepFormValues>({
    defaultValues: wizardData,
    mode: "onBlur",
    resolver: yupResolver(schema),
  })

  const type_of_events_organised = formMethods.watch("type_of_events_organised")

  const validationError = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { non_field_errors, ...validationError } =
      wizardData.validationError || {}
    return validationError
  }, [wizardData])
  useServerFormValidation(validationError, formMethods.setError, true)

  const companyTypeChoices = useSelectItems(COMPANY_TYPE_CHOICES)
  const typeOfEventsChoices = useSelectItems(TYPE_OF_EVENTS_CHOICES)
  const eventsOrganisedPerYearChoices = useSelectItems(
    EVENTS_ORGANISED_PER_YEAR_CHOICES
  )
  //   const averageDailyRateChoices = useSelectItems(AVERAGE_DAILY_RATE_CHOICES)
  const industriesWorkedWithChoices = useSelectItems(
    INDUSTRIES_WORKED_WITH_CHOICES
  )

  const disabled = isLoading

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
      <Box
        mt="66px"
        width="506px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        gap={2}
      >
        <Typography variant="h2" color={theme.palette.custom.dark900}>
          Company information
        </Typography>
        <Typography variant="body14" color={theme.palette.custom.dark700}>
          Hi {user.first_name}, before you can explore the metaverse,
          <br />
          please complete your profile below
        </Typography>
      </Box>

      <FormProvider {...formMethods}>
        <Box
          component="form"
          noValidate
          onSubmit={formMethods.handleSubmit(onSubmit)}
          pt={3}
          width="330px"
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <ControlledTextField
            label="Website"
            name="website"
            autoComplete="website"
            disabled={disabled}
          />
          <ControlledSelectField
            label="Company type"
            name="company_type"
            items={companyTypeChoices}
          />
          <ControlledMultiSelectField
            label="Type of events organised"
            name="type_of_events_organised"
            items={typeOfEventsChoices}
          />
          {type_of_events_organised.indexOf("Other") !== -1 && (
            <ControlledTextField
              label="Type of events organised: Other"
              name="type_of_events_organised_other"
            />
          )}
          <ControlledSelectField
            label="Number of events organised per year"
            name="events_organised_per_year"
            items={eventsOrganisedPerYearChoices}
          />
          {/* <ControlledSelectField
            label="Average daily rate"
            name="average_daily_rate"
            items={averageDailyRateChoices}
          /> */}
          <ControlledMultiSelectField
            label="Industries worked with"
            name="industries_worked_with"
            items={industriesWorkedWithChoices}
          />

          <Box display="flex" gap={2}>
            <CustomButton
              onClick={() => setStep("contact")}
              type="button"
              variant="outlined"
            >
              Back
            </CustomButton>
            <CustomButton
              type="submit"
              variant="contained"
              fullWidth
              disabled={disabled}
            >
              Create account
            </CustomButton>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  )
}
