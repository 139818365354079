import { Box, Typography } from "@mui/material"
import { useAuthUser } from "features/auth/useAuthUser"
import { useEffect, useState } from "react"
import { ReactComponent as CogIcon } from "./icons/cog.svg"
import rpmConnectImage from "./icons/rpm-connect.jpg"
import UpdateAvatarUrl from "./UpdateAvatarUrl"

function render(modelUrl: string, callback: (imageUrl: string) => void) {
  const params = {
    model: modelUrl,
    scene: "fullbody-portrait-v1",
  }
  const http = new XMLHttpRequest()
  http.open("POST", `https://render.readyplayer.me/render`)
  http.setRequestHeader("Content-type", "application/json")
  http.send(JSON.stringify(params))
  http.onload = function () {
    if (http.status !== 200) return

    // alert(http.responseText)
    const imageUrl = JSON.parse(http.responseText)["renders"][0]
    callback(imageUrl)
  }
}

export function ProfileRPMAvatar() {
  const { user: profile } = useAuthUser()

  const [isAvatarDialogOpen, setAvatarDialogOpen] = useState(false)
  const openAvatarDialog = () => setAvatarDialogOpen(true)
  const closeAvatarDialog = () => setAvatarDialogOpen(false)

  const [isLoading, setIsLoading] = useState(false)
  const [renderedAvatarUrl, setRenderedAvatarUrl] = useState(
    profile?.rpm_avatar_url
  )
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined)

  const currentAvatarUrl = profile?.rpm_avatar_url

  useEffect(() => {
    if (
      currentAvatarUrl &&
      !isLoading &&
      (!imageUrl || renderedAvatarUrl !== currentAvatarUrl)
    ) {
      setIsLoading(true)
      render(currentAvatarUrl, (imageUrl: string) => {
        setImageUrl(imageUrl)
        setRenderedAvatarUrl(currentAvatarUrl)
        setIsLoading(false)
      })
    }
    // else if (!currentAvatarUrl) {
    //   setAvatarDialogOpen(true)
    // }
  }, [imageUrl, currentAvatarUrl, isLoading, renderedAvatarUrl])

  if (!profile) return null

  return (
    <>
      {isAvatarDialogOpen && (
        <UpdateAvatarUrl handleClose={closeAvatarDialog} />
      )}
      <Box
        sx={{
          width: "330px",
          height: "330px",
          borderRadius: "16px",
          position: "relative",
        }}
        onClick={openAvatarDialog}
      >
        {profile.rpm_avatar_url ? (
          <Box>
            {imageUrl && !isLoading ? (
              <Box
                component="img"
                src={imageUrl}
                sx={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  borderRadius: "16px",
                }}
              />
            ) : (
              <Typography>Loading...</Typography>
            )}
            <Box
              sx={{
                position: "absolute",
                top: "10px",
                right: "8px",
                width: "40px",
                height: "40px",
                boxShadow: "0px 2px 3px rgba(17, 17, 128, 0.04)",
                borderRadius: "8px",
                backgroundColor: "white",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: "24px",
                  height: "24px",
                  padding: 0,
                  margin: "auto",
                }}
              >
                <CogIcon />
              </Box>
            </Box>
          </Box>
        ) : (
          <img src={rpmConnectImage} />
        )}
      </Box>
    </>
  )
}
