import { Divider, FormHelperText } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { useTheme } from "lib/useTheme"
import { ReactElement, ReactNode, useMemo } from "react"
import { CustomMenuItem, MenuSx } from "../Menu"
import { InputLabelSx } from "./CustomTextField"

export type SelectSearchItem = {
  value: string | undefined
  label?: string | undefined | ReactElement
}

export interface SearchSelectFieldProps<T> {
  label: string
  name: string
  value?: T
  onChange?: (e: SelectChangeEvent<T>) => void
  items: SelectSearchItem[]
  renderValue?: (value: T) => ReactNode
  join?: "right"
  error?: boolean
  helperText?: string
  onBlur?: () => void
  disabled?: boolean
  defaultValue?: T
  variant?: "outlined" | "standard" | "filled"
  width?: string
  height?: string
}

export function SearchSelectField<T>(props: SearchSelectFieldProps<T>) {
  const {
    label,
    name,
    value,
    onChange,
    items,
    renderValue,
    join,
    error,
    helperText,
    onBlur,
    variant = "filled",
    width,
    height,
  } = props

  const theme = useTheme()

  const choices = useMemo(() => {
    return items.map((sizeVal, idx) =>
      sizeVal.value ? (
        <CustomMenuItem key={idx} value={sizeVal.value}>
          {sizeVal.label || sizeVal.value}
        </CustomMenuItem>
      ) : (
        <Divider key={idx} />
      )
    )
  }, [items])

  return (
    <FormControl
      error={error}
      disabled={props.disabled}
      variant={variant}
      sx={{
        flexGrow: 1,
        "& .MuiInputBase-root": {
          backgroundColor: theme.palette.custom.light500,
          border: "1px solid #E7E7EC",
          borderRadius: "12px",
          ...(width ? { width } : {}),
          ...(height ? { height } : {}),
          ...(join === "right"
            ? {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderRightWidth: 0,
              }
            : {}),
        },
        "& .MuiInputBase-root:hover": {
          borderColor: "#E5E5EA",
          backgroundColor: "white",
        },
        "& .MuiInputBase-root.Mui-focused": {
          borderColor: theme.palette.custom.dark500,
          backgroundColor: "white",
        },
        "& .MuiInputBase-input:focus": {
          borderColor: theme.palette.custom.dark500,
          backgroundColor: "white",
          borderRadius: "12px",
        },
        "& .MuiInputBase-root .MuiSelect-select": {
          display: "flex",
          ...theme.typography.body13,
          color: theme.palette.custom.dark900,
        },
        "& .MuiInputBase-root .MuiSelect-select:focus": {
          ...(props.width || props.height
            ? {
                paddingTop: 0,
                paddingBottom: 0,
              }
            : {}),
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        ...InputLabelSx(theme),
      }}
    >
      <InputLabel
        sx={{ color: theme.palette.custom.dark600 }}
        id="demo-simple-select-filled-label"
      >
        {label}
      </InputLabel>
      <Select
        disableUnderline
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={value}
        name={name}
        onChange={onChange}
        renderValue={renderValue}
        multiple={Array.isArray(value)}
        inputProps={{ onBlur }}
        MenuProps={{ sx: MenuSx }}
      >
        {choices}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}
