// In this file we manipulate with RTK cache invalidation management
// documentation is here: https://redux-toolkit.js.org/rtk-query/api/created-api/code-splitting#enhanceendpoints

import { sellerGeneratedApi } from "./seller"

export const sellerApi = sellerGeneratedApi.enhanceEndpoints({
  addTagTypes: [
    "ManagersList",
    "VenuesList",
    "DraftManagersList",
    "SellerVenue",
    "SellerVenueDraft",
    "MeetingsList",
    "Meeting",
    "SellerVenueSpaceList",
  ],
  endpoints: {
    sellerVenueManagerList: {
      providesTags: ["ManagersList", "LoginChanged"],
    },
    sellerVenueManagerDestroy: {
      invalidatesTags: ["ManagersList", "VenuesList"],
    },
    sellerVenueManagerCreate: {
      invalidatesTags: ["ManagersList"],
    },
    sellerVenueDraftManagerList: {
      providesTags: ["DraftManagersList", "LoginChanged"],
    },
    sellerVenueDraftManagerCreate: {
      invalidatesTags: ["DraftManagersList"],
    },
    sellerVenueDraftManagerDestroy: {
      invalidatesTags: ["DraftManagersList"],
    },
    sellerVenueRetrieve: {
      providesTags: (result) =>
        result
          ? [{ type: "SellerVenue" as const, id: result.id }, "LoginChanged"]
          : ["SellerVenue", "LoginChanged"],
    },
    sellerVenueSpaceCreate: {
      invalidatesTags: (result) =>
        result ? ["SellerVenue", "VenuesList"] : [],
    },
    sellerVenueSpacePartialUpdate: {
      invalidatesTags: (result) =>
        result ? ["SellerVenue", "VenuesList"] : [],
    },
    sellerVenueSpaceDestroy: {
      invalidatesTags: ["SellerVenue", "VenuesList"],
    },
    sellerVenueThumbnailUpdate: {
      invalidatesTags: (result, error, arg) => [
        { type: "SellerVenue", id: arg.id },
        "VenuesList",
        "MeetingsList",
      ],
    },
    sellerVenueSpaceThumbnailUpdate: {
      invalidatesTags: ["SellerVenue"],
    },
    sellerVenueHeroImageUpdate: {
      invalidatesTags: (result, error, arg) => [
        { type: "SellerVenue", id: arg.id },
        "VenuesList",
        "MeetingsList",
      ],
    },
    sellerVenueDraftCreate: {
      invalidatesTags: ["VenuesList"],
    },
    sellerVenueDraftRetrieve: {
      providesTags: ["SellerVenueDraft", "LoginChanged"],
    },
    sellerVenueDraftSpaceCreate: {
      invalidatesTags: (result) => (result ? ["SellerVenueDraft"] : []),
    },
    sellerVenueDraftSpacePartialUpdate: {
      invalidatesTags: (result) => (result ? ["SellerVenueDraft"] : []),
    },
    sellerVenueDraftSpaceDestroy: {
      invalidatesTags: ["SellerVenueDraft"],
    },
    sellerVenueDraftSpaceThumbnailUpdate: {
      invalidatesTags: ["SellerVenueDraft"],
    },
    sellerMeetingCreate: {
      invalidatesTags: ["MeetingsList"],
    },
    sellerMeetingList: {
      providesTags: ["MeetingsList", "LoginChanged"],
    },
    sellerMeetingDestroy: {
      invalidatesTags: ["MeetingsList"],
    },
    sellerMeetingUpdate: {
      invalidatesTags: (result, error, arg) => [
        { type: "Meeting", id: arg.id },
        "MeetingsList",
      ],
    },
    sellerMeetingRetrieve: {
      providesTags: (result) =>
        result
          ? [{ type: "Meeting" as const, id: result.id }, "LoginChanged"]
          : ["Meeting", "LoginChanged"],
    },
    sellerMeetingVenueVenuesUpdate: {
      invalidatesTags: (result, error, arg) => [
        { type: "Meeting", id: arg.meetingPk },
        "MeetingsList",
      ],
    },
    sellerVenueDestroy: {
      invalidatesTags: ["VenuesList"],
    },
    sellerVenueList: {
      providesTags: ["LoginChanged", "VenuesList"],
    },
    sellerVenueUpdate: {
      invalidatesTags: (result, error, arg) => [
        { type: "SellerVenue", id: arg.id },
        "VenuesList",
        "MeetingsList",
      ],
    },
    sellerVenueDraftManagerRetrieve: {
      providesTags: ["LoginChanged"],
    },
    sellerVenueManagerRetrieve: {
      providesTags: ["LoginChanged"],
    },
    sellerVenuePreviewRetrieve: {
      providesTags: ["LoginChanged"],
    },
    sellerVenueDraftDestroy: {
      invalidatesTags: ["DraftManagersList", "SellerVenueDraft"],
    },
  },
})

export const {
  useSellerVenueDraftDestroyMutation,
  useSellerVenueListQuery,
  useSellerVenueRetrieveQuery,
  useSellerVenueDraftRetrieveQuery,
  useSellerVenueUpdateMutation,
  useSellerVenuePartialUpdateMutation,
  useSellerVenueDestroyMutation,
  useSellerVenueDraftCreateMutation,
  useSellerVenueSpaceCreateMutation,
  useSellerVenueDraftSpaceCreateMutation,
  useSellerVenueSpacePartialUpdateMutation,
  useSellerVenueDraftSpacePartialUpdateMutation,
  useSellerVenueSpaceDestroyMutation,
  useSellerVenueDraftSpaceDestroyMutation,
  useSellerVenueSpaceThumbnailUpdateMutation,
  useSellerVenueDraftSpaceThumbnailUpdateMutation,
  useSellerVenueDraftThumbnailUpdateMutation,
  useSellerVenueThumbnailUpdateMutation,
  useSellerVenueHeroImageUpdateMutation,
  useSellerVenueDraftHeroImageUpdateMutation,
  useSellerVenuePhotoCreateMutation,
  useSellerVenueDraftPhotoCreateMutation,
  useSellerVenuePhotoDestroyMutation,
  useSellerVenueDraftPhotoDestroyMutation,
  useSellerVenueAutocompleteListQuery,
  useSellerVenueAutocompleteRetrieveQuery,
  useSellerVenueManagerCreateMutation,
  useSellerVenueDraftManagerCreateMutation,
  useSellerVenueManagerDestroyMutation,
  useSellerVenueDraftManagerDestroyMutation,
  useSellerVenueManagerListQuery,
  useSellerVenueDraftManagerListQuery,
  useSellerVenueManagerRetrieveQuery,
  useSellerVenueDraftManagerRetrieveQuery,
  useSellerVenuePreviewRetrieveQuery,
  useSellerMeetingCreateMutation,
  useSellerMeetingUpdateMutation,
  useSellerMeetingListQuery,
  useSellerMeetingRetrieveQuery,
  useSellerMeetingDestroyMutation,
  useSellerMeetingVenueVenuesUpdateMutation,
} = sellerApi
