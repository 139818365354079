import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useAuthorizedUserOrRedirect } from "lib/useAuthorizedUserOrRedirect"
import { useCallback, useMemo, useReducer } from "react"
import { User } from "services/types"
import AuthPageContainer from "../AuthPageContainer"
import { BuyerProfileCompanyInfo } from "./BuyerProfileCompanyInfo"
import { BuyerProfileDetails } from "./BuyerProfileDetails"

type ValidationError = Record<string, string[]>

export type SignUpData = {
  company_name: string
  job_title: string
  country: string
  state: string
  city: string
  phone_code: string
  phone: string

  website: string
  company_type: string
  type_of_events_organised: string[]
  type_of_events_organised_other: string
  events_organised_per_year: string
  industries_worked_with: string[]

  step: "contact" | "company"
  validationError?: ValidationError
}

const stepFields: Record<SignUpData["step"], string[]> = {
  contact: [
    "company_name",
    "job_title",
    "country",
    "state",
    "city",
    "phone_code",
    "phone",
  ],
  company: [
    "website",
    "company_type",
    "type_of_events_organised",
    "type_of_events_organised_other",
    "events_organised_per_year",
    "industries_worked_with",
  ],
}

const initialState: SignUpData = {
  company_name: "",
  job_title: "",
  country: "",
  state: "",
  city: "",
  phone_code: "",
  phone: "",

  website: "",
  company_type: "",
  type_of_events_organised: [],
  type_of_events_organised_other: "",
  events_organised_per_year: "",
  industries_worked_with: [],

  step: "contact",
}

const wizardSlice = createSlice({
  name: "wizardSlice",
  initialState,
  reducers: {
    validationError: (state, action: PayloadAction<ValidationError>) => {
      state.validationError = action.payload
    },

    bulkUpdate: (state, action: PayloadAction<Partial<SignUpData>>) => ({
      ...state,
      ...action.payload,
    }),

    step: (state, action: PayloadAction<SignUpData["step"]>) => {
      state.step = action.payload
    },
  },
})

export interface SignUpWizardProps {
  wizardData: SignUpData
  user: User
  setStep: (step: SignUpData["step"]) => void
  setValidationError: (error: unknown) => void
  bulkUpdate: (data: Partial<SignUpData>) => void
}

function BuyerProfileWizard() {
  const { user } = useAuthorizedUserOrRedirect()

  const [state, dispatch] = useReducer(wizardSlice.reducer, initialState)

  const bulkUpdate = useCallback(
    (data: Partial<SignUpData>) =>
      dispatch(wizardSlice.actions.bulkUpdate(data)),
    [dispatch]
  )

  const setStep = useCallback(
    (step: SignUpData["step"]) => dispatch(wizardSlice.actions.step(step)),
    [dispatch]
  )

  const setValidationError = useCallback(
    (_validationError: unknown) => {
      // Omit username as it's not used in the form but it's still returned from backend if email is not set
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { username, ...validationError } =
        _validationError as ValidationError
      console.log("validation error", validationError)
      dispatch(wizardSlice.actions.validationError(validationError))

      const failedStep = (Object.keys(stepFields) as SignUpData["step"][]).find(
        (step) => {
          for (const field of Object.keys(
            validationError as Record<string, unknown>
          )) {
            if (stepFields[step].includes(field)) return true
          }
        }
      )
      console.log("error", validationError, failedStep)
      if (failedStep) setStep(failedStep)
    },
    [dispatch, setStep]
  )

  const stepProps: SignUpWizardProps | undefined = useMemo(() => {
    return user
      ? {
          wizardData: state,
          user,
          setStep,
          setValidationError,
          bulkUpdate,
        }
      : undefined
  }, [state, user, setStep, setValidationError, bulkUpdate])

  if (!stepProps) return null

  return (
    <>
      {state.step === "contact" && <BuyerProfileDetails {...stepProps} />}
      {state.step === "company" && <BuyerProfileCompanyInfo {...stepProps} />}
    </>
  )
}

export default function BuyerProfile() {
  return (
    <AuthPageContainer>
      <BuyerProfileWizard />
    </AuthPageContainer>
  )
}
