import { List, ListItem } from "@mui/material"
import Box from "@mui/material/Box"
import { ReactComponent as CalendarIcon } from "app/icons/calendar.svg"
import { ReactComponent as DashboardIcon } from "app/icons/dashboard.svg"
import { ReactComponent as MeetingsIcon } from "app/icons/meetings.svg"
import { ReactComponent as MyVenuesIcon } from "app/icons/seller-venues.svg"
import { ReactComponent as SettingsIcon } from "app/icons/settings.svg"
import { LeftMenuItem } from "lib/components/LeftMenuItem"
import { LogoIcon } from "lib/components/Logo"
import { NavigationButton } from "lib/components/NavigationButton"
import { useAuthorizedUserOrRedirect } from "lib/useAuthorizedUserOrRedirect"
import { Location, matchPath, useLocation } from "react-router"
import { Link, NavLink } from "react-router-dom"
import { ReactComponent as BackIcon } from "./icons/back-to-search.svg"
import { ReactComponent as RCalendar } from "./icons/calendar.svg"
import { ReactComponent as RSearch } from "./icons/search.svg"

import { ReactComponent as RSocial } from "./icons/social.svg"

const BUYER_MENU_ITEMS: LeftMenuItem[] = [
  {
    NOT_IMPLEMENTED_YET: true,
    label: "Social feed",
    icon: <RSocial />,
    link: "/feed/",
  },
  {
    NOT_IMPLEMENTED_YET: true,
    label: "Calendar",
    icon: <RCalendar />,
    link: "/calendar/",
  },
  {
    NOT_IMPLEMENTED_YET: true,
    label: "Search",
    icon: <RSearch />,
    link: "/venues/",
    isActive: (location: Location) => location.pathname.startsWith("/venues"),
  },
]

const SELLER_MENU_ITEMS: LeftMenuItem[] = [
  {
    NOT_IMPLEMENTED_YET: true,
    label: "Dashboard",
    icon: <DashboardIcon />,
    link: "/seller/",
    isActive: (location: Location) =>
      !!matchPath("/seller/", location.pathname),
  },
  {
    label: "Venues",
    icon: <MyVenuesIcon />,
    link: "/seller/venues/",
    isActive: (location: Location) =>
      !!matchPath("/seller/venues/*", location.pathname),
  },
  {
    label: "Meetings",
    icon: <MeetingsIcon />,
    link: "/meetings/",
    isActive: (location: Location) =>
      !!matchPath("/meetings/*", location.pathname),
  },
  {
    NOT_IMPLEMENTED_YET: true,
    label: "Calendar",
    icon: <CalendarIcon />,
    link: "/seller/calendar/",
    isActive: (location: Location) =>
      !!matchPath("/seller/calendar/", location.pathname),
  },
  {
    NOT_IMPLEMENTED_YET: true,
    label: "Settings",
    icon: <SettingsIcon />,
    link: "/seller/settings/",
    isActive: (location: Location) =>
      !!matchPath("/seller/settings/", location.pathname),
  },
]

export function LeftNavigation() {
  const location = useLocation()
  const { user } = useAuthorizedUserOrRedirect()

  const menuItems: LeftMenuItem[] = user?.isSeller
    ? SELLER_MENU_ITEMS
    : user?.isBuyer || user?.isOther
    ? BUYER_MENU_ITEMS
    : []

  return (
    <Box
      sx={{
        overflow: "auto",
        minWidth: 118,
        px: 1,
        pt: 3,
        display: { xs: "none", md: "flex" },
        flexDirection: "column",
        backgroundColor: "custom.light500",
      }}
    >
      <Box
        sx={{
          height: 102,
          width: 102,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Link to={user?.defaultLink ?? "/"}>
          <LogoIcon size={57} />
        </Link>
      </Box>

      <List sx={{ flexGrow: 1 }}>
        {menuItems
          .filter((item) => !item.NOT_IMPLEMENTED_YET)
          .map((item) => (
            <ListItem key={item.label} sx={{ p: 0, pt: 1 }}>
              <NavigationButton {...item} />
            </ListItem>
          ))}
      </List>

      {user?.isBuyer && location.pathname.match(/^\/venues\/\d+/) && (
        <Box>
          <NavLink to="/venues/">
            <BackIcon />
          </NavLink>
        </Box>
      )}
    </Box>
  )
}
