import { useEffect } from "react"
import { useLogout } from "./useLogout"

export function Logout() {
  const logout = useLogout()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => logout(), [])

  return null
}
