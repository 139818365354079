import { TextFieldProps } from "@mui/material"
import { Controller } from "react-hook-form"
import { CustomPasswordField } from "./CustomPasswordField"

type ControlledPasswordFieldProps = Omit<TextFieldProps, "name"> & {
  name: string
}

export function ControlledPasswordField(props: ControlledPasswordFieldProps) {
  return (
    <Controller
      name={props.name}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <CustomPasswordField
          {...props}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={!!error}
          helperText={error ? error.message : props.helperText}
        />
      )}
    />
  )
}
