import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import { LogoIcon, LogoName2 } from "lib/components/Logo"
import { useTheme } from "lib/useTheme"
import { ReactNode } from "react"

export default function AuthPageContainer({
  children,
}: {
  children: ReactNode
}) {
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <Box
        component="main"
        className="full-height"
        sx={{ flexGrow: 1, display: "flex" }}
      >
        <CssBaseline />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: theme.palette.custom.light500,
            backgroundImage: "url(/img/auth-background.png)",
            backgroundSize: "cover",
          }}
        >
          <Box mt="46px" display="flex" alignItems="center" gap="22px">
            <LogoIcon size={41} />
            <LogoName2 />
          </Box>
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  )
}
