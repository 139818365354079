import { Box, Typography } from "@mui/material"
import MuiAlert, { AlertProps } from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import Stack from "@mui/material/Stack"
import { ReactComponent as SuccessIcon } from "app/icons/check-circled-outline.svg"
import { ReactComponent as ErrorIcon } from "app/icons/error-triangle-outline.svg"
import { ReactComponent as InfoIcon } from "app/icons/info-outline.svg"
import { ReactComponent as CloseIcon } from "lib/components/form/icons/close-white.svg"
import { never } from "lib/helpers"
import { useTheme } from "lib/useTheme"
import * as React from "react"
import { useCallback } from "react"

export type CustomSnackbarProps =
  | OpennedCustomSnackbarProps
  | ClosedCustomSnackbarProps

export type OpennedCustomSnackbarProps = {
  open: true
  type: "error" | "success" | "info"
  message: string
}

export type ClosedCustomSnackbarProps = {
  open: false
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const renderIcon = (type: OpennedCustomSnackbarProps["type"]) => {
  switch (type) {
    case "error":
      return <ErrorIcon />
    case "info":
      return <InfoIcon />
    case "success":
      return <SuccessIcon />
    default:
      never(type)
  }
}

export default function CustomSnackbar(
  props: CustomSnackbarProps & { close: () => void }
) {
  const theme = useTheme()

  const renderColor = useCallback(
    (type: OpennedCustomSnackbarProps["type"]): string => {
      switch (type) {
        case "error":
          return theme.palette.error.main
        case "info":
          return theme.palette.info.main
        case "success":
          return theme.palette.success.main
        default:
          never(type)
      }
    },
    [theme]
  )

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    props.close()
  }

  return (
    <Stack spacing={2} sx={{ width: "100%", zIndex: 1500 }}>
      {props.open && (
        <Snackbar
          open={props.open}
          autoHideDuration={6000}
          onClose={handleClose}
          style={{
            left: 16,
            bottom: 16,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              backgroundColor: renderColor(props.type),
              borderRadius: 4,
              minWidth: 359,
              height: 48,
              pl: 3,
              pr: 2,
            }}
          >
            {renderIcon(props.type)}
            <Typography variant="body13" color="white">
              {props.message}
            </Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(0, 0, 0, 0.06);",
                borderRadius: "11px",
                width: 32,
                height: 32,
                cursor: "pointer",
              }}
              onClick={props.close}
            >
              <CloseIcon />
            </Box>
          </Box>
        </Snackbar>
      )}
    </Stack>
  )
}
