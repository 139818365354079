import { Divider, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import { useSellerVenuePreviewRetrieveQuery } from "api/sellerApi"
import { useVenuesRetrieveQuery } from "api/venuesApi"
import { useParams } from "react-router-dom"
import {
  LEISURE_FACILITIES,
  TYPE_OF_EVENTS_HOSTED,
} from "services/venueConstants"
import {
  HEALTHCARE_COMPLIANT_ICONS,
  LEISURE_FACILITIES_ICONS,
  TYPE_OF_EVENTS_HOSTED_ICONS,
} from "services/venueIcons"

export function VenueOverview({ sellerPreview }: { sellerPreview: boolean }) {
  const { id: strId } = useParams<{ id: string }>()
  const id = strId ? parseInt(strId) : undefined
  const { data: usualVenue } = useVenuesRetrieveQuery(
    id && !sellerPreview ? { id } : skipToken
  )
  const { data: previewVenue } = useSellerVenuePreviewRetrieveQuery(
    strId && sellerPreview ? { id: strId } : skipToken
  )
  const venue = usualVenue || previewVenue

  if (!venue) {
    // TODO spinner
    return null
  }

  const InformationBox = ({
    label,
    value,
  }: {
    label: string
    value: string
  }) => (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body14">{label}</Typography>
      <Typography
        variant="body13"
        sx={{
          fontWeight: 400,
          mt: "6px",
        }}
      >
        {value}
      </Typography>
    </Box>
  )

  const InformationBoxSmall = ({
    label,
    value,
  }: {
    label: string
    value: string
  }) => (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body11" sx={{ color: "custom.dark700" }}>
        {label}
      </Typography>
      <Typography variant="body13" sx={{ mt: "4px" }}>
        {value}
      </Typography>
    </Box>
  )

  return (
    <Box sx={{ width: "100%", color: "custom.dark900" }}>
      <Typography variant="h2" component="h2">
        Overview
      </Typography>
      <Box
        sx={{
          mt: "26px",
          display: "flex",
        }}
      >
        <Box
          sx={{
            flex: "676px",
            flexDirection: "column",
            pb: "10px",
          }}
        >
          {venue.leisure_facilities && (
            <>
              <Typography variant="body14" sx={{ display: "block" }}>
                Leisure Facilities
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "2px",
                  mt: "6px",
                  mr: 9,
                  flexWrap: "wrap",
                }}
              >
                {(
                  venue.leisure_facilities as typeof LEISURE_FACILITIES[number][]
                ).map((facility) => (
                  <Box
                    key={facility}
                    sx={{
                      width: "200px",
                      height: "56px",
                      borderRadius: 2,
                      alignItems: "center",
                      pl: 2,
                      display: "flex",
                      gap: "6px",
                      boxShadow: "0 2px 1px 0 rgba(17, 17, 128, 0.04)",
                      backgroundColor: "custom.light500",
                    }}
                  >
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {LEISURE_FACILITIES_ICONS[facility]}
                    </Box>
                    <Typography variant="body13" sx={{ fontWeight: 400 }}>
                      {facility}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </>
          )}
          <Box sx={{ mr: 9, display: "flex", gap: 1 }}>
            {!!venue.member_of?.length && (
              <Box sx={{ flex: "50%", mt: 4 }}>
                <InformationBox
                  label="Member of"
                  value={venue.member_of.join(", ")}
                />
              </Box>
            )}

            {!!venue.consortium_membership?.length && (
              <Box sx={{ flex: "50%", mt: 4 }}>
                <InformationBox
                  label="Consortium Membership"
                  value={venue.consortium_membership.join(", ")}
                />
              </Box>
            )}
          </Box>
          {venue.sustainable_accreditation && (
            <Box sx={{ mr: 9, mt: 4 }}>
              <InformationBox
                label="Sustainable Accreditation"
                value={venue.sustainable_accreditation}
              />
            </Box>
          )}
        </Box>

        <Box sx={{ flex: "534px", display: "flex" }}>
          <Divider
            sx={{
              backgroundColor: "custom.dark500",
              opacity: ".3",
              mr: 4,
            }}
            orientation="vertical"
            flexItem
          ></Divider>
          <Box
            sx={{
              display: "flex",
              flex: "50%",
              flexDirection: "column",
              gap: 4,
              mr: 1,
            }}
          >
            {!!venue.average_daily_rate?.length && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body11" sx={{ color: "custom.dark700" }}>
                  Average Delegate Rate
                </Typography>
                <Typography variant="h4" sx={{ mt: "4px" }}>
                  {venue.average_daily_rate.join(", ")}
                </Typography>
              </Box>
            )}
            {venue.property_type && (
              <InformationBoxSmall
                label="Property Type"
                value={venue.property_type}
              />
            )}
            {venue.healthcare_compliant === "yes" && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body11" sx={{ color: "custom.dark700" }}>
                  Healthcare compliant
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 1,
                    alignItems: "center",
                    mt: "4px",
                  }}
                >
                  {HEALTHCARE_COMPLIANT_ICONS[venue.healthcare_compliant]}
                  <Typography variant="body13">
                    {venue.healthcare_compliant.charAt(0).toUpperCase() +
                      venue.healthcare_compliant.slice(1)}
                  </Typography>
                </Box>
              </Box>
            )}
            {venue.distance_to_nearest_airport && (
              <InformationBoxSmall
                label="Distance to Nearest Airports"
                value={venue.distance_to_nearest_airport}
              />
            )}
            {!!venue.type_of_events_hosted?.length && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="body11"
                  sx={{ color: "custom.dark700", mb: "9px" }}
                >
                  Type of Events Hosted
                </Typography>
                {(
                  venue.type_of_events_hosted as typeof TYPE_OF_EVENTS_HOSTED[number][]
                ).map((type) => (
                  <Box
                    key={type}
                    sx={{
                      display: "flex",
                      columnGap: 1,
                      alignItems: "center",
                      mb: "14px",
                    }}
                  >
                    {TYPE_OF_EVENTS_HOSTED_ICONS[type]}
                    <Typography variant="body13" sx={{ fontWeight: 400 }}>
                      {type}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: "50%",
              flexDirection: "column",
              gap: 4,
            }}
          >
            {venue.number_of_bedrooms && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body11" sx={{ color: "custom.dark700" }}>
                  Bedrooms
                </Typography>
                <Typography variant="h4" sx={{ mt: "4px" }}>
                  {venue.number_of_bedrooms}
                </Typography>
              </Box>
            )}

            {venue.date_of_last_renovation && (
              <InformationBoxSmall
                label="Last Renovation"
                value={`${venue.date_of_last_renovation}`}
              />
            )}
            {venue.nearest_airports && (
              <InformationBoxSmall
                label="Nearest Airports"
                value={venue.nearest_airports}
              />
            )}
            {!!venue.best_time_of_year_for_groups?.length && (
              <Box
                sx={{ display: "flex", flexDirection: "column", rowGap: "6px" }}
              >
                <Typography variant="body11" sx={{ color: "custom.dark700" }}>
                  High season
                </Typography>
                {venue.best_time_of_year_for_groups.map((month) => (
                  <Typography
                    key={month}
                    variant="body13"
                    sx={{ fontWeight: 400 }}
                  >
                    {month}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
