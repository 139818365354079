import { Link } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { TopBar } from "app/TopBar"
import { useOnlyForBuyerOrOther } from "lib/useOnlyForBuyerOrOther"
import { useTheme } from "lib/useTheme"
import { ReactComponent as OKIcon } from "../icons/ok.svg"

export function BuyerProfileCreated() {
  const theme = useTheme()
  const { user } = useOnlyForBuyerOrOther()

  if (!user) return null

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        backgroundColor: "custom.light700",
      }}
    >
      <TopBar title="" />

      <Box
        sx={{
          backgroundColor: "custom.light600",
          marginTop: "24px",
          marginLeft: "24px",
          marginRight: "24px",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          height: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Box
          width="506px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          gap={2}
        >
          <OKIcon />

          <Typography
            variant="h2"
            whiteSpace="normal"
            color={theme.palette.custom.dark900}
          >
            {user.first_name}, thank you for creating your buyer profile!
          </Typography>

          <Typography variant="body14" color={theme.palette.custom.dark700}>
            RendezVerse will be available soon for you to discover new venues
            and explore digital twins.
            <br />
            <br />
            In the meantime, contact our team{" "}
            <Link href="mailto:info@rendezverse.com" underline="hover">
              <Typography variant="body14" color="custom.accentsecondary">
                here
              </Typography>{" "}
            </Link>
            if you have any questions or would like a personal demo of
            RendezVerse.
          </Typography>

          {/* <CustomButton variant="contained" href={profile.defaultLink}>
          Continue
        </CustomButton> */}
        </Box>
      </Box>
    </Box>
  )
}
