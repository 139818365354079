import { Controller } from "react-hook-form"
import { SearchSelectField, SearchSelectFieldProps } from "./SearchSelectField"

type ControlledSelectFieldProps = Omit<
  SearchSelectFieldProps<string>,
  "name"
> & {
  name: string
}

export function ControlledSelectField(props: ControlledSelectFieldProps) {
  return (
    <Controller
      name={props.name}
      defaultValue={props.defaultValue}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <SearchSelectField
          {...props}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={!!error}
          disabled={props.disabled}
          helperText={error && error.message}
        />
      )}
    />
  )
}
