import { Alert } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { CustomLink } from "lib/components/CustomLink"
import { CustomPasswordField, CustomTextField } from "lib/components/FormField"
import { useRedirectAuthorizedUser } from "lib/useRedirectAuthorizedUser"
import { useTheme } from "lib/useTheme"
import { FormEvent, useState } from "react"
import { Route, Routes } from "react-router-dom"
import { useLogin_DEPRECATED_Mutation } from "services/oldUserApi"
import { getErrorData, LoginRequest } from "services/types"
import { CustomButton } from "../../lib/components/CustomButton"
import AuthPageContainer from "./AuthPageContainer"
import NotVerified from "./signin/NotVerified"
import SignInBlocked from "./signin/SignInBlocked"

export function SignInPage() {
  const theme = useTheme()

  const [login, { isLoading, error }] = useLogin_DEPRECATED_Mutation()
  const errorData = getErrorData<LoginRequest>(error)
  useRedirectAuthorizedUser()
  const [loginFieldError, setLoginError] = useState<string | undefined>()
  const [passwordFieldError, setPasswordError] = useState<string | undefined>()
  const [loginInputValue, setLogin] = useState<string>("")
  const [passwordInputValue, setPassword] = useState<string>("")

  const validate = (form: FormData): boolean => {
    const login = (form.get("login") || "").toString()
    const password = (form.get("password") || "").toString()
    if (login.length == 0 || login.indexOf("@") == -1) {
      // XXX email validation
      setLoginError("Wrong email specified")
      return false
    } else {
      setLoginError(undefined)
    }
    if (password.length == 0) {
      // XXX password complexity validation
      setPasswordError("Wrong password specified")
      return false
    }
    setPasswordError(undefined)
    return true
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    if (!validate(formData)) return
    const data: LoginRequest = {
      login: (formData.get("login") || "").toString(),
      password: (formData.get("password") || "").toString(),
    }

    login(data)
  }

  const disabled = isLoading

  return (
    <Box
      sx={{ width: "328px" }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={1}
    >
      <Typography variant="h2" color={theme.palette.custom.dark900} pt="140px">
        Welcome to RendezVerse
      </Typography>
      <Typography variant="body14" color={theme.palette.custom.dark700}>
        Sign in below to step into the metaverse
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        flexGrow={1}
        width="100%"
        pt={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2}
      >
        {errorData && (
          <Alert severity="error">
            {errorData.detail || "Internal server error"}
          </Alert>
        )}
        <CustomTextField
          error={loginFieldError !== undefined}
          helperText={loginFieldError}
          fullWidth
          id="login"
          name="login"
          label="Email address"
          type="email"
          disabled={disabled}
          autoComplete="email"
          autoFocus
          value={loginInputValue}
          onChange={(e) => setLogin(e.target.value)}
        />
        <CustomPasswordField
          error={passwordFieldError !== undefined}
          helperText={passwordFieldError}
          fullWidth
          id="password"
          name="password"
          label="Password"
          disabled={disabled}
          autoComplete="current-password"
          value={passwordInputValue}
          onChange={(e) => setPassword(e.target.value)}
        />
        <CustomButton
          type="submit"
          disabled={disabled}
          fullWidth
          variant="contained"
        >
          Sign in
        </CustomButton>
        <CustomLink href="/forgot-password/">Forgot password?</CustomLink>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="12px"
          pt="40px"
        >
          <Typography variant="h3" color={theme.palette.custom.dark800}>
            {"Don't have an account?"}
          </Typography>
          <CustomButton variant="outlined" href="/signup/">
            Sign up now
          </CustomButton>
        </Box>
      </Box>
    </Box>
  )
}

export default function SignIn() {
  return (
    <AuthPageContainer>
      <Routes>
        <Route path="/*" element={<SignInPage />} />
        <Route path="/blocked" element={<SignInBlocked />} />
        <Route path="/not-verified" element={<NotVerified />} />
      </Routes>
    </AuthPageContainer>
  )
}
