import { Box, BoxProps, Typography } from "@mui/material"

function TabButtonActive({ children, ...props }: BoxProps) {
  return (
    <Box
      padding="4px 16px"
      sx={{
        backgroundColor: "custom.light500",
        borderRadius: "8px",
        cursor: "default",
      }}
      {...props}
    >
      <Typography variant="body12" color="custom.dark900">
        {children}
      </Typography>
    </Box>
  )
}

function TabButtonInactive({ children, ...props }: BoxProps) {
  return (
    <Box padding="4px 16px" sx={{ cursor: "pointer" }} {...props}>
      <Typography variant="body12" color="custom.dark600">
        {children}
      </Typography>
    </Box>
  )
}

export function TabButton({
  active,
  ...props
}: BoxProps & { active?: boolean }) {
  return active ? (
    <TabButtonActive {...props} />
  ) : (
    <TabButtonInactive {...props} />
  )
}

export type TabButtonItem = {
  active: boolean
  onClick: () => void
  label: string
}

export type TabButtonGroupProps = BoxProps & { items?: TabButtonItem[] }

export function TabButtonGroup({
  children,
  sx,
  items,
  ...props
}: TabButtonGroupProps) {
  children = items
    ? items.map(({ label, ...props }, index) => (
        <TabButton key={index} {...props}>
          {label}
        </TabButton>
      ))
    : children

  return (
    <Box
      display="flex"
      padding="4px"
      sx={{
        backgroundColor: "custom.light800",
        borderRadius: "12px",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
