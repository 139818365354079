import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getCookie } from "lib/cookies"

export const api = createApi({
  reducerPath: "api",
  tagTypes: ["User"],

  baseQuery: fetchBaseQuery({
    baseUrl: "/api/",
    prepareHeaders: (headers) => {
      const csrftoken = getCookie("csrftoken")
      if (csrftoken) headers.set("X-CSRFToken", csrftoken)
      return headers
    },
  }),
  endpoints: () => ({}),
})
