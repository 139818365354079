/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react"
import { matchPath, useLocation, useNavigate } from "react-router"
import { useBlocker } from "./useBlocker"
import { useBoolean } from "./useBoolean"

export function useCallbackPrompt(
  when: boolean,
  excludedPathPatterns?: string[]
): [boolean, () => void, () => void] {
  const navigate = useNavigate()
  const location = useLocation()
  const [promptVisibility, showPrompt, hidePrompt] = useBoolean(false)
  const [lastLocation, setLastLocation] = useState<string | null>(null)
  const [confirmedNavigation, enableRedirect, disableRedirect] =
    useBoolean(false)

  const isDifferentPaths = useCallback(
    (nextPath) => {
      return excludedPathPatterns
        ? !excludedPathPatterns.some(
            (pattern) => !!matchPath(pattern, nextPath)
          )
        : nextPath !== location.pathname
    },
    [excludedPathPatterns, location]
  )

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      // in if condition we are checking next location and current location are equals or not
      setLastLocation(nextLocation.location.pathname)

      if (
        !confirmedNavigation &&
        isDifferentPaths(nextLocation.location.pathname)
      ) {
        showPrompt()
      } else {
        enableRedirect()
      }
    },
    [confirmedNavigation]
  )

  const confirmNavigation = useCallback(() => {
    hidePrompt()
    enableRedirect()
  }, [])

  const cancelNavigation = useCallback(() => {
    hidePrompt()
  }, [])

  useEffect(() => {
    if (
      confirmedNavigation &&
      lastLocation &&
      lastLocation !== location.pathname
    ) {
      navigate(lastLocation)
      disableRedirect()
    }
  }, [confirmedNavigation, lastLocation])

  useBlocker(handleBlockedNavigation, when)

  return [promptVisibility, confirmNavigation, cancelNavigation]
}
