import {
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
  styled,
  Theme,
} from "@mui/material"

export const MenuSx = {
  "& .MuiPaper-root": {
    borderRadius: "12px",
  },
  "& .MuiList-root": {
    paddingTop: 0,
    paddingBottom: 0,
  },
}

export const MenuItemSx = (theme: Theme) => ({
  "&.MuiMenuItem-root": {
    ...theme.typography.body13,
    color: theme.palette.custom.dark700,
    height: "40px",
  },
  "&.MuiMenuItem-root:hover": {
    backgroundColor: theme.palette.custom.accentlight,
    color: theme.palette.custom.accentpressed,
  },
  "&.MuiMenuItem-root.Mui-selected": {
    backgroundColor: theme.palette.custom.accentprimary,
    color: theme.palette.custom.light500,
  },

  "&.MuiAutocomplete-option": {
    ...theme.typography.body13,
    color: theme.palette.custom.dark700,
    height: "40px",
  },
  "&.MuiAutocomplete-option.Mui-focused": {
    backgroundColor: theme.palette.custom.accentlight,
    color: theme.palette.custom.accentpressed,
  },
  "&.MuiAutocomplete-option.Mui-focusVisible": {
    backgroundColor: theme.palette.custom.accentlight,
    color: theme.palette.custom.accentpressed,
  },
  "&.MuiAutocomplete-option.Mui-selected": {
    backgroundColor: theme.palette.custom.accentprimary,
    color: theme.palette.custom.light500,
  },
})

export const CustomMenu = styled(Menu)<MenuProps>(() => MenuSx)

export const CustomMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) =>
  MenuItemSx(theme)
)
