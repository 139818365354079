import { SvgIcon, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid"
import { SellerVenueSpace } from "api/seller"
import { VenueSpace } from "api/venues"
import { useOpenVenueSpaceDialog } from "features/seller/VenueSpaceDialog"
import { VrIcons } from "lib/components/vr"
import { VENUE_THUMBNAIL_PLACEHOLDER } from "lib/public"
import { ReactNode, useEffect, useRef } from "react"
import { ReactComponent as VR360Icon } from "./icons/360.svg"
import { ReactComponent as CapacityBanquetIcon } from "./icons/capacity-banquet.svg"
import { ReactComponent as CapacityBoardroomIcon } from "./icons/capacity-boardroom.svg"
import { ReactComponent as CapacityCabaretIcon } from "./icons/capacity-cabaret.svg"
import { ReactComponent as CapacityClassroomIcon } from "./icons/capacity-classroom.svg"
import { ReactComponent as CapacityCocktailIcon } from "./icons/capacity-cocktail.svg"
import { ReactComponent as CapacityTheaterIcon } from "./icons/capacity-theater.svg"
import { ReactComponent as CapacityUShapeIcon } from "./icons/capacity-u-shape.svg"
import { ReactComponent as CeilingIcon } from "./icons/ceiling.svg"
import { ReactComponent as CheckIcon } from "./icons/check-circled-outline.svg"
import { ReactComponent as DimensionIcon } from "./icons/dimension.svg"
import { ReactComponent as NaturalLightingIcon } from "./icons/natural-lighting.svg"
import { ReactComponent as SizeIcon } from "./icons/size.svg"
import { ReactComponent as UserIcon } from "./icons/user.svg"
import { ReactComponent as VRHeadsetIcon } from "./icons/vr.svg"
import {
  isSellerSpace360,
  isSellerSpace3D,
  isSpace360,
  isSpace3D,
} from "./utils"
import { VenueSpaceContextMenu } from "./VenueSpaceContextMenu"

export type Space = VenueSpace | (Partial<SellerVenueSpace> & { id: number })

const EmptyCell = () => (
  <Typography
    variant="body12"
    fontWeight={600}
    color="custom.dark500"
    sx={{ verticalAlign: "middle", lineHeight: "16px" }}
  >
    —
  </Typography>
)

const renderNumberCell = ({ formattedValue }: GridRenderCellParams<Space>) =>
  formattedValue ? (
    <Typography variant="body12" color="custom.dark900">
      {formattedValue}
    </Typography>
  ) : (
    <EmptyCell />
  )

const IconsHeader = ({
  label,
  children,
}: {
  label?: string
  children?: ReactNode
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyItems: "end",
      alignItems: "center",
      gap: "6px",
    }}
  >
    <Box sx={{ display: "flex", gap: "6px" }}>{children}</Box>
    <Typography
      variant="body14"
      color="custom.dark900"
      lineHeight="130%"
      textAlign="center"
      whiteSpace="break-spaces"
    >
      {label}
    </Typography>
  </Box>
)

function renderIconsHeader(icons?: ReactNode) {
  // eslint-disable-next-line react/display-name
  return ({
    colDef: { headerName },
  }: GridColumnHeaderParams<unknown, Space>) => (
    <IconsHeader label={headerName}>{icons}</IconsHeader>
  )
}

const renderCapacityCell = ({ formattedValue }: GridRenderCellParams<Space>) =>
  formattedValue ? (
    <>
      <SvgIcon
        component={UserIcon}
        sx={{
          fontSize: "16px",
          verticalAlign: "middle",
          lineHeight: "16px",
          path: { color: "white" },
        }}
        inheritViewBox
      />
      <Typography
        variant="body12"
        fontWeight={600}
        color="custom.dark900"
        sx={{ verticalAlign: "middle", lineHeight: "16px" }}
      >
        {formattedValue}
      </Typography>
    </>
  ) : (
    <EmptyCell />
  )

const baseColumnWidth = 80
const capacityColumnWidth = 70
const availableInAppColumnWidth = 100

let columns: GridColDef<Space>[] = [
  {
    field: "thumbnail",
    headerName: "Name",
    flex: 1,
    minWidth: 210,
    renderCell: ({ row }) => (
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <Box
          component="img"
          src={
            ("thumbnail" in row ? row.thumbnail : row.thumbnail_link) ||
            VENUE_THUMBNAIL_PLACEHOLDER
          }
          sx={{
            height: "48px",
            width: "86px",
            borderRadius: "4px",
            objectFit: "cover",
          }}
        />
        <Box width="100px" sx={{ whiteSpace: "break-spaces" }}>
          <Typography variant="body14" color="custom.dark900">
            {row.name}
          </Typography>
        </Box>
      </Box>
    ),
    renderHeader: ({ colDef }) => (
      <Typography variant="body14" color="custom.dark900">
        {colDef.headerName}
      </Typography>
    ),
    headerAlign: "left",
    sortable: false,
  },
  {
    field: "size",
    headerName: "Size",
    flex: 1,
    minWidth: baseColumnWidth,
    valueFormatter: ({ value }) => value && `${value} sq/m`,
    renderCell: renderNumberCell,
    renderHeader: renderIconsHeader(<SizeIcon />),
  },
  {
    field: "ceiling_height",
    headerName: "Ceiling",
    flex: 1,
    minWidth: baseColumnWidth,
    valueFormatter: ({ value }) => value && `${value} m`,
    renderCell: renderNumberCell,
    renderHeader: renderIconsHeader(<CeilingIcon />),
  },
  {
    field: "dimensions",
    headerName: "Dimensions",
    valueGetter: ({ row: { dimension_l, dimension_w } }) =>
      dimension_l && dimension_w ? `${dimension_l} m x ${dimension_w} m` : "",
    renderCell: renderNumberCell,
    renderHeader: renderIconsHeader(<DimensionIcon />),
    flex: 1,
    minWidth: baseColumnWidth,
  },
  {
    field: "natural_lighting",
    headerName: "Natural lighting",
    renderCell: (params) =>
      params.row.natural_lighting ? <CheckIcon /> : <EmptyCell />,
    renderHeader: renderIconsHeader(<NaturalLightingIcon />),
    flex: 1,
    minWidth: capacityColumnWidth,
  },
  {
    field: "capacity_theater",
    headerName: "Theater",
    renderCell: renderCapacityCell,
    renderHeader: renderIconsHeader(<CapacityTheaterIcon />),
    flex: 1,
    minWidth: capacityColumnWidth,
  },
  {
    field: "capacity_cabaret",
    headerName: "Cabaret",
    renderCell: renderCapacityCell,
    renderHeader: renderIconsHeader(<CapacityCabaretIcon />),
    flex: 1,
    minWidth: capacityColumnWidth,
  },
  {
    field: "capacity_classroom",
    headerName: "Classroom",
    renderCell: renderCapacityCell,
    renderHeader: renderIconsHeader(<CapacityClassroomIcon />),
    flex: 1,
    minWidth: capacityColumnWidth,
  },
  {
    field: "capacity_ushape",
    headerName: "U-Shape",
    renderCell: renderCapacityCell,
    renderHeader: renderIconsHeader(<CapacityUShapeIcon />),
    flex: 1,
    minWidth: capacityColumnWidth,
  },
  {
    field: "capacity_boardroom",
    headerName: "Boardroom",
    renderCell: renderCapacityCell,
    renderHeader: renderIconsHeader(<CapacityBoardroomIcon />),
    flex: 1,
    minWidth: capacityColumnWidth,
  },
  {
    field: "capacity_banquet",
    headerName: "Banquet",
    renderCell: renderCapacityCell,
    renderHeader: renderIconsHeader(<CapacityBanquetIcon />),
    flex: 1,
    minWidth: capacityColumnWidth,
  },
  {
    field: "capacity_cocktail",
    headerName: "Cocktail",
    renderCell: renderCapacityCell,
    renderHeader: renderIconsHeader(<CapacityCocktailIcon />),
    flex: 1,
    minWidth: capacityColumnWidth,
  },
  {
    field: "avialable_in_app",
    headerName: "Type",
    renderCell: ({ row }) => {
      const isBuyer = "available_in" in row

      const isVr = isBuyer ? isSpace3D(row) : isSellerSpace3D(row)
      const is360 = isBuyer ? isSpace360(row) : isSellerSpace360(row)

      return isVr || is360 ? (
        <VrIcons isVr={!!isVr} is360={!!is360} />
      ) : (
        <EmptyCell />
      )
    },
    renderHeader: renderIconsHeader(
      <>
        <VRHeadsetIcon />
        <VR360Icon />
      </>
    ),
    flex: 1,
    minWidth: availableInAppColumnWidth,
  },
]

const contextMenuColumn = (venueId?: number) =>
  ({
    field: "contextMenu",
    headerName: "",
    renderCell: ({ row }) => (
      <VenueSpaceContextMenu
        space={row as SellerVenueSpace}
        venueId={venueId}
      />
    ),
    sortable: false,
    flex: 1,
    minWidth: 80,
  } as GridColDef<Space>)

columns = columns.map(({ headerAlign, ...colDef }) => ({
  ...colDef,
  align: "center",
  headerAlign: headerAlign || "center",
  sortable: false,
}))

type VenueSpacesProps = {
  spaces: Space[]
  editable?: boolean
  venueId?: number
}

export function VenueSpaces({ spaces, editable, venueId }: VenueSpacesProps) {
  const openVenueSpaceDialog = useOpenVenueSpaceDialog()
  const openSpaceEdit = (spaceId: number) =>
    openVenueSpaceDialog(venueId, spaceId)

  const grid = useRef<HTMLDivElement>(null)

  // AG: This is hack to remove keyboard navigation from grid
  useEffect(() => {
    if (grid.current) {
      const children = grid.current.querySelector(
        ".MuiDataGrid-columnHeadersInner"
      )?.children

      if (children)
        for (let i = 0; i < children.length; i++) {
          children[i].setAttribute("tabIndex", "-1")
        }
    }
  }, [grid])

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        ref={grid}
        rows={spaces}
        columns={editable ? [...columns, contextMenuColumn(venueId)] : columns}
        rowHeight={80}
        headerHeight={100}
        autoHeight
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        hideFooter
        onRowClick={(params) => {
          const space = params.row as SellerVenueSpace
          openSpaceEdit(space.id)
        }}
        components={{ ColumnResizeIcon: () => null }}
        sx={{
          "&.MuiDataGrid-root": {
            border: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader": {
            padding: 0,
            pointerEvents: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-row:hover": {
            backgroundColor: "custom.light500",
            borderRadius: 2,
            boxShadow: "0px 3px 14px rgba(17, 17, 128, 0.04)",
          },
          "&.MuiDataGrid-root .MuiDataGrid-row": {
            cursor: "pointer",
          },
        }}
      />
    </Box>
  )
}
