import { api } from "./api"
import { DeviceActivationRequest, DeviceActivationResult } from "./types"

const deviceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    activateDevice: builder.mutation<
      DeviceActivationResult,
      DeviceActivationRequest
    >({
      query: (body) => ({
        url: "device/activate/",
        method: "post",
        body,
      }),
    }),
  }),
})

export const { useActivateDeviceMutation } = deviceApi
