import { SerializedError } from "@reduxjs/toolkit"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { useEffect, useMemo } from "react"
import { useNavigate } from "react-router"
import { useProfile_DEPRECATED_Query } from "services/oldUserApi"
import { User } from "services/types"

const DEFAULT_LINKS = {
  seller: "/seller/venues/",
  buyer: "/profile-created",
  other: "/venues/",
}

export const EMAIL_VERIFICATION_DISABLED =
  !!process.env.REACT_APP_EMAIL_VERIFICATION_DISABLED

export function useAuthUser(): {
  user:
    | (User & {
        isBuyer: boolean
        isSeller: boolean
        isOther: boolean
        defaultLink: string
      })
    | undefined
  isSuccess: boolean
  isError: boolean
  isFetching: boolean
  error: FetchBaseQueryError | SerializedError | undefined
} {
  const {
    data: user,
    isSuccess,
    isError,
    isFetching,
    error,
  } = useProfile_DEPRECATED_Query()

  const navigate = useNavigate()
  useEffect(() => {
    if (!EMAIL_VERIFICATION_DISABLED && user && !user.email_verified)
      navigate("/signin/not-verified")
  }, [user, navigate])

  return useMemo(
    () => ({
      user:
        user && !isFetching && isSuccess
          ? {
              ...user,
              isBuyer: user.role == "buyer",
              isSeller: user.role == "seller",
              isOther: user.role == "other",
              defaultLink: getUserDefaultLink(user),
            }
          : undefined,
      isSuccess,
      isError,
      isFetching,
      error,
    }),
    [error, isError, isFetching, isSuccess, user]
  )
}
function getUserDefaultLink(user: User): string {
  if (!user.role) return "/user-type"

  if (user.role === "buyer" && !user.buyer_profile) return "/profile/buyer/"

  return DEFAULT_LINKS[user.role]
}
