import { Box, Typography } from "@mui/material"
import { PlusButton } from "lib/components/CustomButton"
import { ReactComponent as EmptyIcon } from "./icons/empty.svg"

export function EmptyVenueManagers({ openDialog }: { openDialog: () => void }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "custom.light600",
        borderRadius: 3,
        padding: 4,
        margin: 3,
        gap: 2,
      }}
    >
      <EmptyIcon />
      <Typography variant="h2" color="custom.dark900">
        No venue managers added yet
      </Typography>
      <Typography variant="body14" color="custom.dark900">
        Add your first venue manager
      </Typography>
      <Box />
      <PlusButton variant="contained" onClick={openDialog}>
        Add venue manager
      </PlusButton>
    </Box>
  )
}
