import { Box, Link, Typography } from "@mui/material"
import AuthPageContainer from "features/auth/AuthPageContainer"
import { useAuthorizedUserOrRedirect } from "lib/useAuthorizedUserOrRedirect"
import { useSnackbar } from "lib/useSnackbar"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { usePartialUpdateProfile_DEPRECATED_Mutation } from "services/oldUserApi"
import { UserRole } from "services/types"
import { ReactComponent as ArrowIcon } from "./icons/arrow-right.svg"

function UserTypeCard({
  label,
  children,
  onClick,
}: {
  label: string
  children: string
  onClick?: () => void
}) {
  return (
    <Box
      flex="1 1 0"
      padding="24px 24px 24px 32px"
      border="2px solid"
      m="2px"
      borderColor="custom.accentsecondary"
      borderRadius={2}
      textAlign="left"
      sx={{
        ":hover": {
          borderWidth: "4px",
          m: 0,
          boxShadow: "0px 7px 14px rgba(12, 177, 217, 0.13)",
          cursor: "pointer",
        },
      }}
      onClick={onClick}
    >
      <Box display="flex" flexDirection="column" gap="12px">
        <Box flexGrow={1} display="flex" justifyContent="space-between">
          <Typography variant="h3" color="custom.accentsecondary">
            {label}
          </Typography>
          <ArrowIcon />
        </Box>
        <Typography variant="body14" color="custom.dark700" pr="36px">
          {children}
        </Typography>
      </Box>
    </Box>
  )
}

export function SelectUserType() {
  const { user } = useAuthorizedUserOrRedirect()

  const navigate = useNavigate()
  useEffect(() => {
    if (user && user.role) navigate(user.defaultLink)
  }, [navigate, user])

  const [patchUser] = usePartialUpdateProfile_DEPRECATED_Mutation()
  const { showError } = useSnackbar()

  const setRole = (role: UserRole) =>
    patchUser({ role })
      .unwrap()
      .catch(() => showError("Error: cannot update user role"))

  const setBuyer = () => setRole("buyer")
  const setSeller = () => setRole("seller")

  if (!user) return null

  return (
    <AuthPageContainer>
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        gap={7}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Typography variant="h2" color="custom.dark900">
          Select your account type
        </Typography>

        <Box display="flex" gap={2} width="664px">
          <UserTypeCard label="Seller" onClick={setSeller}>
            {
              "Select this account if own or manage a venue where you'd like to host virtual meetings."
            }
          </UserTypeCard>
          <UserTypeCard label="Buyer" onClick={setBuyer}>
            {
              "Select this account if you'd like access to our world-wide directory of venues; or if you have been invited to a RendezVerse meeting."
            }
          </UserTypeCard>
        </Box>

        <Typography variant="body14" color="custom.dark700">
          If neither of these types fits you, please reach out to us at &nbsp;
          <Link href="mailto:info@rendezverse.com" underline="hover">
            <Typography variant="body14" color="custom.accentsecondary">
              info@rendezverse.com
            </Typography>
          </Link>
        </Typography>
      </Box>
    </AuthPageContainer>
  )
}
