import { Grid } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useTheme } from "lib/useTheme"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useResendVerify_DEPRECATED_Mutation } from "services/oldUserApi"
import { CustomButton } from "../../../lib/components/CustomButton"
import { useAuthUser } from "../useAuthUser"
import { ReactComponent as NotVerifiedIcon } from "./icons/not-verified.svg"

export default function NotVerified() {
  const theme = useTheme()

  const { user } = useAuthUser()

  const navigate = useNavigate()
  useEffect(() => {
    if (user?.email_verified) {
      navigate("/")
    }
  }, [user, navigate])

  const [resendVerify, { isLoading }] = useResendVerify_DEPRECATED_Mutation()
  const email = user?.email
  const resendRequest = () => {
    if (email) {
      resendVerify({ email })
    }
  }
  const resendVerifyDisabled = isLoading

  if (!user) return null

  return (
    <Box
      sx={{
        width: "500px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ pt: "140px" }}></Box>
      <NotVerifiedIcon width="86px" />
      <Box sx={{ pt: 1 }}></Box>
      <Typography
        component="h2"
        variant="h2"
        color={theme.palette.custom.dark900}
      >
        Your mail has not been verified
      </Typography>
      <Box sx={{ pt: 2 }}></Box>
      <Typography
        variant="body14"
        color={theme.palette.custom.dark700}
        sx={{ fontWeight: 600 }}
      >
        Additional instructions have been sent to your email
      </Typography>
      <Typography
        color={theme.palette.custom.accentsecondary}
        sx={{ fontWeight: 600 }}
        variant="body14"
      >
        {email}
      </Typography>
      <Box sx={{ pt: 4 }}></Box>
      <Box
        sx={{
          flexGrow: 1,
          px: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ pt: 2 }}></Box>
        <Grid container spacing={2}>
          <Grid item xs>
            <CustomButton
              type="button"
              onClick={resendRequest}
              disabled={resendVerifyDisabled}
              fullWidth
              variant="contained"
            >
              Resend request
            </CustomButton>
          </Grid>
          {/* <Grid item xs>
            <CustomButton
              type="button"
              onClick={resendRequest}
              fullWidth
              variant="outlined"
            >
              Change email
            </CustomButton>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  )
}
