import { Box, Divider, Typography } from "@mui/material"
import { SellerVenue } from "api/seller"
import { useSellerVenueListQuery } from "api/sellerApi"
import { LoadingIndicator } from "app/LoadingIndicator"
import { TopBar } from "app/TopBar"
import { VenueDeleteDialog } from "features/seller/VenueDeleteDialog"
import { StarsView } from "features/venues/StarsView"
import { isSellerVenue360, isSellerVenue3D } from "features/venues/utils"
import { PlusButton } from "lib/components/CustomButton"
import { VrIcons } from "lib/components/vr"
import { VENUE_THUMBNAIL_PLACEHOLDER } from "lib/public"
import { useNavigate, useParams } from "react-router-dom"
import { EmptyVenues } from "./EmptyVenues"
import { VenueContextMenu } from "./VenueContextMenu"

function VenueRow({ venue }: { venue: SellerVenue }) {
  // const availability = venue.availability
  // const [updateVenue] = useSellerVenuePartialUpdateMutation()
  // const setAvailability = (availability: AvailabilityEnum) => {
  //   updateVenue({
  //     id: venue.id.toString(),
  //     patchedSellerVenue: { availability: availability },
  //   }).unwrap()
  // }

  const navigate = useNavigate()

  return (
    <Box>
      <Box
        display="flex"
        sx={{
          py: "16px",
          cursor: "pointer",
          "&:hover": {
            mx: -2,
            px: 2,
            py: "17px",
            my: "-1px",
            backgroundColor: "white",
            boxShadow: "0px 3px 14px rgba(17, 17, 128, 0.04)",
            borderRadius: 2,
          },
        }}
        gap="24px"
        alignItems="center"
        onClick={() => navigate(`/seller/venues/${venue.id}`)}
      >
        <Box flex={1} display="flex" gap="24px">
          <Box
            sx={{
              width: "139px",
              height: "77px",
              borderRadius: "8px",
              backgroundImage: `url(${
                venue.thumbnail_link || VENUE_THUMBNAIL_PLACEHOLDER
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Box
            display="flex"
            flexDirection="column"
            gap="6px"
            justifyContent="center"
          >
            <Box>
              <Typography variant="h4" color="custom.dark800">
                {venue.name}
              </Typography>
            </Box>
            <Box
              lineHeight="15px"
              display="flex"
              flexDirection="column"
              gap="2px"
            >
              <Typography
                variant="body12"
                color="custom.dark600"
                lineHeight="15px"
              >
                {[venue.country, venue.city].filter((v) => !!v).join(", ")}
              </Typography>
              {venue.star_rating && (
                <Box color="custom.dark700">
                  <StarsView rating={venue.star_rating} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box width="80px" textAlign="center">
          {venue.spaces.length ? (
            <Typography variant="body14">{venue.spaces.length}</Typography>
          ) : (
            <Typography variant="body14" color="custom.dark500">
              -
            </Typography>
          )}
        </Box>
        {/* <Box width="110px">
        <SearchSelectField
          items={[
            { value: "PUBLIC", label: "Visible" },
            { value: "PRIVATE", label: "Hosted" },
            // { value: "GUIDED", label: "Hidden" },
          ]}
          label={""}
          name={""}
          value={availability}
          onChange={(e) => setAvailability(e.target.value as AvailabilityEnum)}
          variant="outlined"
          width="110px"
          height="40px"
        />
      </Box> */}
        <Box width="80px">
          <VrIcons
            isVr={isSellerVenue3D(venue)}
            is360={isSellerVenue360(venue)}
          />
        </Box>
        <Box
          width="48px"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <VenueContextMenu venue={venue} />
        </Box>
      </Box>
      <Divider sx={{ borderBottom: "1px solid #6464BB10" }} />
    </Box>
  )
}

function VenuesListContent({ venues }: { venues: SellerVenue[] }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 0",
        overflowY: "scroll",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          backgroundColor: "custom.light600",
          borderRadius: 3,
          p: 4,
          margin: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flexGrow: 0 }}>
            <Typography
              variant="h4"
              color="custom.dark800"
              flex={1}
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              Your venues
            </Typography>
          </Box>
          <Box sx={{ flex: "1 1 0" }}></Box>
          <Box sx={{ flexGrow: 0 }}>
            <PlusButton href="/seller/venues/new" variant="contained">
              Add venue
            </PlusButton>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
          <Divider sx={{ borderBottom: "1px solid #6464BB10" }} />
          <Box sx={{ display: "flex", py: 2, gap: 3 }}>
            <Typography
              variant="body14"
              color="custom.dark900"
              flex={10}
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              Name
            </Typography>
            <Typography
              variant="body14"
              color="custom.dark900"
              width="80px"
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
              textAlign="center"
            >
              Scenes
            </Typography>
            {/* <Typography
          variant="body14"
          color="custom.dark900"
          width="110px"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          Status
        </Typography> */}
            <Typography
              variant="body14"
              color="custom.dark900"
              width="80px"
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              Scene type
            </Typography>
            <Typography
              variant="body14"
              color="custom.dark900"
              width="48px"
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
            ></Typography>
          </Box>
          <Divider sx={{ borderBottom: "1px solid #6464BB10" }} />
        </Box>

        <Box>
          {venues.map((venue) => (
            <VenueRow key={venue.id} venue={venue} />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export function VenuesList() {
  const { data: venues, isFetching } = useSellerVenueListQuery({})
  // useLoader(isFetching)

  const { deleteId } = useParams()

  return (
    <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <TopBar title="Venues" />

      {deleteId && <VenueDeleteDialog />}

      {!venues || isFetching ? (
        <LoadingIndicator />
      ) : !venues.count || !venues.results ? (
        <EmptyVenues />
      ) : (
        <VenuesListContent venues={venues.results} />
      )}
    </Box>
  )
}
