import { Box, Typography } from "@mui/material"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import {
  useSellerVenueDestroyMutation,
  useSellerVenueRetrieveQuery,
} from "api/sellerApi"
import { LoadingIndicator } from "app/LoadingIndicator"
import { CustomButton } from "lib/components/CustomButton"
import {
  CustomDialog,
  CustomDialogContent,
  CustomDialogTitle,
} from "lib/components/Dialog"
import { useSnackbar } from "lib/useSnackbar"
import { useNavigate, useParams } from "react-router-dom"
import { ReactComponent as TrashBigIcon } from "./icons/trash-big.svg"

export function VenueDeleteDialog() {
  const navigate = useNavigate()
  const close = () => navigate("/seller/venues")

  const { deleteId: id } = useParams()

  const { data: venue, isFetching } = useSellerVenueRetrieveQuery(
    id ? { id } : skipToken
  )

  const [deleteVenueRequest, { isLoading: isDeleting }] =
    useSellerVenueDestroyMutation()
  const { showError } = useSnackbar()
  const deleteVenue = () => {
    if (!id) return
    deleteVenueRequest({ id })
      .unwrap()
      .then(close)
      .catch(() => showError("Error deleting venue"))
  }
  const disabled = isDeleting

  return (
    <CustomDialog open onClose={close}>
      <CustomDialogTitle>Delete venue</CustomDialogTitle>
      <CustomDialogContent>
        <Box
          sx={{ margin: "60px 162px" }}
          maxWidth="400px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          gap="16px"
        >
          {isFetching || !venue ? (
            <LoadingIndicator />
          ) : (
            <>
              <TrashBigIcon />
              <Typography variant="h3" color="custom.dark900">
                Delete {venue.name}?
              </Typography>
              <Typography variant="body14" color="custom.dark700">
                This venue will be permanently deleted.
                <br /> Are you sure you want to proceed?
              </Typography>
              <Box />
              <Box>
                <Box display="flex" gap="24px">
                  <CustomButton
                    variant="outlined"
                    disabled={disabled}
                    onClick={close}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    color="warning"
                    disabled={disabled}
                    onClick={deleteVenue}
                  >
                    Delete venue
                  </CustomButton>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </CustomDialogContent>
    </CustomDialog>
  )
}
