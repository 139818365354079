// In this file we manipulate with RTK cache invalidation management
// documentation is here: https://redux-toolkit.js.org/rtk-query/api/created-api/code-splitting#enhanceendpoints

import { userGeneratedApi } from "./users"

const usersApi = userGeneratedApi.enhanceEndpoints({})

export const {
  useUsersRegisterCreateMutation,
  useUsersInvitationRetrieveQuery,
  useUsersInvitationUpdateMutation,
  useUsersProfilePartialUpdateMutation,
} = usersApi
