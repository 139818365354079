import { useAuthUser } from "features/auth/useAuthUser"
import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

/**
 * Utility hook to redirect users to their corresponding pages
 */
export function useRedirectAuthorizedUser() {
  const { user } = useAuthUser()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (user) {
      navigate(searchParams.get("redirect") || user.defaultLink)
    }
  }, [navigate, searchParams, user])

  return { user }
}
