export const HOTEL_GROUPS = [
  "Accor Hotels",
  "Aman",
  "Anantara Hotels, Resorts & Spas",
  "Andaz",
  "Angsana",
  "Autograph Collection",
  "Avani Hotels & Resorts",
  "Banyan Tree",
  "Barrière",
  "Belmond",
  "Best Western Hotels & Resorts",
  "Bulgari Hotels & Resorts",
  "Canopy by Hilton",
  "Centara Hotels & Resorts",
  "Choice Hotels",
  "Conrad Hotels",
  "Crowne Plaza Hotels & Resorts",
  "Curio Collection by Hilton",
  "Dedica Anthology Hotels",
  "Delta Hotels by Marriott",
  "Design Hotels",
  "Disney Parks, Experiences and Products",
  "DoubleTree by Hilton",
  "Dusit Hotels & Resorts",
  "Edition",
  "Elewana Collection",
  "Fairmont",
  "Four Seasons",
  "Gran Meliá Hotels & Resorts",
  "Grand Hyatt",
  "Grand Mercure",
  "Hesperia Hotels & Resorts",
  "Hilton",
  "HOSHINOYA",
  "Hyatt",
  "Hyatt Regency",
  "InterContinental Hotels & Resorts",
  "Interstate Hotels & Resorts",
  "Jumeirah",
  "JW Marriott",
  "Kempinski Hotels",
  "Kimpton Hotels",
  "Langham Hotels & Resorts",
  "Le Méridien",
  "Loews Hotels",
  "Lotte Hotels",
  "Luxury Collection",
  "Mandarin Oriental Hotel Group",
  "Marriott",
  "Meliá Hotels & Resorts",
  "MGM Resorts",
  "Millennium Hotels & Resorts",
  "NH Collection Hotels",
  "NH Hotels",
  "Oberoi Hotels & Resorts",
  "Okura Hotels & Resorts",
  "Omni Hotels & Resorts",
  "Pan Pacific Hotels Group",
  "Park Hyatt",
  "Park Plaza",
  "Preferred Hotels & Resorts",
  "Prince Hotels",
  "Radisson Blu",
  "Radisson Collection",
  "Raffles Hotels & Resorts",
  "Regent Hotels & Resorts",
  "Renaissance Hotels",
  "Ritz-Carlton",
  "RIU Hotels & Resorts",
  "Rocco Forte Hotels",
  "Rosewood Hotels & Resorts",
  "Scandic Hotels",
  "Shangri-La",
  "Sheraton",
  "Sofitel",
  "Soneva",
  "St. Regis",
  "Swissôtel",
  "Tivoli Hotels & Resorts",
  "Tokyu Hotels",
  "Toyoko Inn",
  "Travelodge",
  "Treebo",
  "Tribute Portfolio",
  "W Hotels",
  "Waldorf Astoria Hotels & Resorts",
  "Warwick Hotels & Resorts",
  "Westgate Resorts",
  "Westin Hotels & Resorts",
  "Wyndham",
] as const

export const HOTEL_CONSORTIUMS = [
  "Leading Hotels of the World (LHW)",
  "Minor Hotels",
  "Relais & Châteaux",
  "Signature Travel Network",
  "Small Luxury Hotels of the World (SLH)",
  "Traveller Made",
  "Virtuoso",
] as const

export const PROPERTY_TYPES = [
  "Airport",
  "Beach Resort",
  "Boutique Hotel",
  "City Centre",
  "Conference Hotel",
  "Countryside",
  "Golf & Leisure Resort",
  "Serviced Apartments",
  "Ski Resort",
  "Spa Retreat",
] as const

export const NUMBER_OF_BEDROOMS = [
  "Under 100",
  "100 - 250",
  "251-500",
  "500+",
] as const

export const NUMBER_OF_MEETING_ROOMS = [
  "None",
  "Under 5",
  "5-10",
  "11-20",
  "20+",
] as const

export const TYPE_OF_EVENTS_HOSTED = [
  "Incentives",
  "Congresses/Conventions",
  "Conferences",
  "Meetings",
  "Sustainable Events",
  "Experiential Events",
  "Exhibitions",
] as const

export const TOTAL_MEETING_SPACE = [
  "No meeting rooms",
  "1 - 250 sq. m / 10 - 2'500 sq. ft",
  "251 - 1'000 sq. m / 2'501 - 10'000 sq. ft",
  "1'001 - 2'500 sq. m / 10'001 - 27'000 sq. ft",
  "2'501 - 5'000 sq. m / 27'001 - 54'000 sq. ft",
  "5'001 - 10'000 sq. m / 54'001 - 100'000 sq. ft",
  "10'000+ sq. m / 100'000+ sq. ft",
] as const

export const SIZE_OF_LARGEST_MEETING_ROOM = [
  "No meeting rooms",
  "1 - 250 sq. m / 10 - 2'500 sq. ft",
  "251 - 1'000 sq. m / 2'501 - 10'000 sq. ft",
  "1'000+ sq. m / 10'000+ sq. ft",
] as const

export const MAXIMUM_CAPACITY_OF_LARGEST_MEETING_ROOM = [
  "N/A",
  "Under 50",
  "50-100",
  "101-200",
  "201-500",
  "501-1'000",
  "1'001-5'000",
  "5'000+",
] as const

export const AVERAGE_DAILY_RATE = [
  "under $150",
  "$150-250",
  "$250-500",
  "$500+",
  "under €100",
  "€100-200",
  "€200-300",
  "€300+",
] as const

export const DISTANCE_TO_NEAREST_AIRPORT = [
  "1 - 10 km / 0.5 - 5 Miles",
  "10 - 50 km / 5 - 30 Miles",
  "50+ km / 30+ Miles",
] as const

export const LEISURE_FACILITIES = [
  "Beach",
  "Casino",
  "Club",
  "Entertainment",
  "Golf",
  "Gym",
  "Kids' Club",
  "Spa",
  "Speciality Dining",
  "Swimming Pool",
  "Tennis",
  "Water Sports",
  "Winter Sports",
] as const

export const BEST_TIME_OF_YEAR_FOR_GROUPS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const
