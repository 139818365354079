import { Typography } from "@mui/material"
import { Link, LinkProps } from "react-router-dom"

type CustomLinkProps = Omit<LinkProps, "to"> & {
  href: string
}

export function CustomLink({ href, ...rest }: CustomLinkProps) {
  return (
    <Link to={href} {...rest} style={{ textDecoration: "none" }}>
      <Typography
        variant="body14"
        color="custom.accentsecondary"
        sx={{ "&:hover": { borderBottom: "1px solid" } }}
      >
        {rest.children}
      </Typography>
    </Link>
  )
}
