import { Controller } from "react-hook-form"
import { MultiSelectField, MultiSelectFieldProps } from "./MultiSelectField"

type ControlledMultiSelectFieldProps = Omit<
  MultiSelectFieldProps,
  "name" | "onDeleteChange"
> & {
  name: string
}

export function ControlledMultiSelectField(
  props: ControlledMultiSelectFieldProps
) {
  return (
    <Controller
      name={props.name}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => (
        <MultiSelectField
          {...props}
          value={value}
          onChange={(value) => {
            onChange(value)
          }}
          onDeleteChange={({ value }) => onChange(value)}
          onBlur={onBlur}
          error={!!error}
          helperText={error && error.message}
        />
      )}
    />
  )
}
